import {
  INITIALIZE_FINANCIAL_ACCOUNT,
  INITIALIZE_FINANCIAL_BALANCE,
  INITIALIZE_FINANCIAL_ITEM,
} from "../../../core/actions/types/constant";
import * as S from "../../../core/reducers/Finance/state";
import * as A from "../../../core/actions/types/finance";

export * from "./input";
export * from "./planning";
export * from "./currency";

export const initializeFinancialItem = (
  items: S.ItemMaster[],
  itemGroups: S.ItemGroupMaster[],
): A.InitializeFinancialItemAction => {
  return {
    type: INITIALIZE_FINANCIAL_ITEM,
    payload: {
      items,
      itemGroups,
    },
  };
};

export const initializeFinancialAccount = (
  accounts: S.AccountMaster[],
  accountTypes: S.AccountTypeMaster[],
): A.InitializeFinancialAccountAction => {
  return {
    type: INITIALIZE_FINANCIAL_ACCOUNT,
    payload: {
      accounts,
      accountTypes,
    },
  };
};

export const initializeFinancialBalance = (
  initialBalance: A.InitialBalance[],
): A.InitializeFinancialBalanceAction => {
  return {
    type: INITIALIZE_FINANCIAL_BALANCE,
    payload: {
      initialBalance,
    },
  };
};
