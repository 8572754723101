import createMuiTheme from "@material-ui/core/styles/createMuiTheme";
import {CustomTheme} from "../index";

const getTheme = () => {
  const theme = createMuiTheme({
    palette: {
      primary: {
        main: "#212121",
      },
      secondary: {
        main: "#616161",
      },
      text: {
        primary: "rgba(34,34,34,1)",
        secondary: "rgba(33,33,33,.6)",
      },
    },
    typography: {
      useNextVariants: true,
      body2: {
        fontSize: "inherit",
      },
    },
  }) as CustomTheme;
  
  theme.overrides = {
    MuiDrawer: {
      paper: {
        paddingTop: 16,
      },
    },
    MuiPaper: {
      rounded: {
        borderRadius: 2,
      },
    },
    MuiListItem: {
      root: {
        paddingTop: 4,
        paddingBottom: 4,
      },
    },
    MuiListItemText: {
      primary: {
        "fontWeight": theme.typography.fontWeightMedium,
        "color": "inherit",
        "fontSize": theme.typography.fontSize,
        "&$textDense": {
          fontSize: theme.typography.fontSize,
        },
      },
      textDense: {
      },
    },
    MuiListItemIcon: {
      root: {
        "color": "inherit",
        "& svg": {
          fontSize: 20,
        },
      },
    },
    MuiTableCell: {
      head: {
        fontSize: "0.75rem",
        fontWeight: 500,
      },
    },
    MuiTablePagination: {
      root: {
        fontSize: ".75rem",
      },
    },
    MuiTabs: {
      indicator: {
        height: 3,
      },
    },
  };
  
  theme.mixins = {
    ...theme.mixins,
    toolbar: {
      minHeight: 56,
    },
  };
  
  return theme;
};

export default getTheme;
