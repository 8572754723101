import {firebaseFunctions} from "../index";

interface IUidNameMap {
  [uid: string]: string;
}

const userNameCache: IUidNameMap = {};

const fbGetUserName = firebaseFunctions.httpsCallable("getUserName");

export async function getUserName(uid: string){
  if(typeof(userNameCache[uid]) === "string"){
    return userNameCache[uid];
  }
  
  const result = await fbGetUserName({uid}).catch(reason => {
    if(process.env.NODE_ENV === "development"){
      console.error("Error while executing Firebase function", reason);
    }
    
    return {
      data: {
        userName: uid,
        uid,
      },
    };
  });
  
  const {userName} = result.data;
  userNameCache[uid] = userName;
  
  return userName;
}
