import {connect} from "react-redux";
import {ThunkDispatch} from "redux-thunk";
import * as A from "../../../actions";
import App from "../";
import {RootState} from "../../../reducers";

const mapStateToProps = (rootReduxState: RootState) => {
  const {
    activity,
    acceptLicense,
    acceptPrivacyPolicy,
    account,
    theme,
  } = rootReduxState.App;
  
  return {
    activity,
    theme,
    acceptLicense,
    acceptPrivacyPolicy,
    account,
  };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<RootState, undefined, A.RootActions>) => {
  return {
    changeActivity: (activity: string) => dispatch(A.changeActivity(activity)),
  };
};

const AppContainer = connect(mapStateToProps, mapDispatchToProps)(App);

export default AppContainer;
