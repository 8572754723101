import {connect} from "react-redux";
import AppBar from "../AppBar";
import {changeActivity, changeLanguage, saveRootData} from "../../../actions/";
import {isSaving, isUnsaved} from "../../../util/saveState";
import {ThunkDispatch} from "redux-thunk";
import * as A from "../../../actions";
import {getCurrencyInState} from "../../../core/util/currency";
import {Lang} from "../../../reducers/App/state";
import {RootState} from "../../../reducers";

const mapStateToProps = (rootReduxState: RootState) => {
  const {lang, activity, account, sidebarOpen} = rootReduxState.App;
  const unsaved = isUnsaved(rootReduxState);
  const saving = isSaving(rootReduxState);
  const provider = account ? account.provider : undefined;
  const currency =  getCurrencyInState(rootReduxState.Finance);
  
  return {
    lang,
    activity,
    unsaved,
    saving,
    provider,
    currency,
    account,
  };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<RootState, undefined, A.RootActions>) => {
  return {
    saveData: () => dispatch(saveRootData()),
    changeActivity: (activity: string) => dispatch(changeActivity(activity)),
    changeLanguage: (lang: Lang) => dispatch(changeLanguage(lang)),
  };
};

const AppBarContainer = connect(mapStateToProps, mapDispatchToProps)(AppBar);

export default AppBarContainer;
