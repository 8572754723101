import * as types from "../../types/constant";
import * as A from "../../types/app/pageAnalysis";



export const changeAnalysisDate = (
  analysisType: string,
  startY: number | string | null,
  startM?: number | string | null,
  endY?: number | string | null,
  endM?: number | string | null,
): A.ChangeAnalysisDateAction => {
  return {
    type: types.CHANGE_ANALYSIS_DATE,
    payload: {
      analysisType,
      startY,
      startM,
      endY,
      endM,
    },
  };
};

export const changeAnalysisBSHistoryView = (
  view: "byAssetType" | "byAccountType" | "byAccount",
): A.ChangeAnalysisBSHistoryViewAction => {
  return {
    type: types.CHANGE_ANALYSIS_BS_HISTORY_VIEW,
    payload: {
      view,
    },
  };
};
