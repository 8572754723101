import React, {useEffect, useState} from "react";
import {getCurrentUser, getUserImageUrl} from "../backends/firebase/api";
import {getUserName} from "../backends/firebase/functions/getUserName";
import Avatar from "@material-ui/core/Avatar";
import {ClassNameMap} from "@material-ui/core/styles/withStyles";
import {PROVIDER_ICONS} from "./provider";
import {firebaseAuth} from "../backends/firebase";
import {Account} from "../core/reducers/App/state";

export function getCurrentUserName(account: Account): string {
  const user = getCurrentUser();
  if(user){
    return user.displayName || user.email || "No Name";
  }
  
  if(account.demo){
    if(typeof(account.demo) === "object"){
      return "Demo User";
    }
    else if(account.demo === "loadFromFile"){
      return "Loaded From File";
    }
  }
  
  return account.displayName || account.uid;
}

interface IUserNameProps {
  uid: string;
}

export function UserName(props: IUserNameProps){
  const {uid} = props;
  const [userName, setUserName] = useState(uid);
  
  useEffect(() => {
    if(uid !== userName){
      return;
    }
  
    getUserName(uid).then(name => {
      setUserName(name);
    });
    
    return;
  });
  
  return (
    <React.Fragment>
      {userName}
    </React.Fragment>
  );
}

export function getUserAvatar(classes: ClassNameMap, account: Account|null){
  const accountIcon = PROVIDER_ICONS[account ? account.provider : "memory"];
  const userImageUrl = getUserImageUrl();
  
  if(!account){
    return (
      <Avatar className={classes.activeAvatar}>
        {accountIcon}
      </Avatar>
    );
  }
  
  if(userImageUrl){
    return (
      <Avatar className={classes.activeAvatar} src={userImageUrl}/>
    );
  }
  
  return(
      <Avatar className={classes.activeAvatar}>{accountIcon}</Avatar>
    )
  ;
}

export function getAuthProviders(){
  const user = firebaseAuth.currentUser;
  if(!user){
    return [];
  }
  
  return user.providerData.map(d => {
    if(!d){
      return null;
    }
    
    return d.providerId;
  }).filter(d => d);
}
