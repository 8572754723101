import * as types from "../../types/constant";
import * as A from "../../types/app/pagePlanning";
import * as S from "../../../reducers/App/state";

export const gotoPlanningProject = (id: string): A.GotoPlanningProjectAction => {
  return {
    type: types.CHANGE_PLANNING_PROJECT,
    payload: {id},
  };
};

export const changePlanningPage = (page: string): A.ChangePlanningPageAction => {
  return {
    type: types.CHANGE_PLANNING_PAGE,
    payload: {page},
  };
};

export const switchPlanningFilter = (id: number): A.SwitchPlanningFilterAction => {
  return {
    type: types.SWITCH_PLANNING_FILTER,
    payload: {id},
  };
};

export const addPlanningFilter = (filter: S.PlanFilter): A.AddPlanningFilterAction => {
  return {
    type: types.ADD_PLANNING_FILTER,
    payload: {filter},
  };
};

export const changePlanningFilterOrder = (idListSorted: number[]): A.ChangePlanningFilterOrderAction => {
  return {
    type: types.CHANGE_PLANNING_FILTER_ORDER,
    payload: {orders: idListSorted},
  };
};

export const changePlanningFilterLabel = (id: number, newLabel: string): A.ChangePlanningFilterLabelAction => {
  return {
    type: types.CHANGE_PLANNING_FILTER_LABEL,
    payload: {
      id,
      label: newLabel,
    },
  };
};

export const changePlanningFilterRules = (
  id: number,
  newRules: Array<S.Filter<S.PlanFilterColumn>>,
): A.ChangePlanningFilterRulesAction => {
  return {
    type: types.CHANGE_PLANNING_FILTER_RULES,
    payload: {
      id,
      rules: newRules,
    },
  };
};

export const removePlanningFilter = (idList: number[]): A.RemovePlanningFilterAction => {
  return {
    type: types.REMOVE_PLANNING_FILTER,
    payload: {idList},
  };
};

export const updatePlanningSortRules = (rules: S.PlanSortRule[]): A.UpdatePlanningSortRulesAction => {
  return {
    type: types.UPDATE_PLANNING_SORT_RULES,
    payload: {rules},
  };
};

export const updatePlanningRowsPerPage = (rows_per_page: number): A.UpdatePlanningRowsPerPageAction => {
  return {
    type: types.UPDATE_PLANNING_ROWS_PER_PAGE,
    payload: {rows_per_page},
  };
};

export const updatePlanningResultPage = (page: number): A.UpdatePlanningResultPageAction => {
  return {
    type: types.UPDATE_PLANNING_RESULT_PAGE,
    payload: {page},
  };
};

export const changePlanningProjectViewType = (viewType: S.PlanViewType): A.ChangePlanningProjectViewTypeAction => {
  return {
    type: types.CHANGE_PLANNING_PROJECT_VIEWTYPE,
    payload: {viewType},
  };
};
