import React, {Fragment} from "react";
import {withTranslation} from "react-i18next";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import Select from "@material-ui/core/Select";
import {connect} from "react-redux";
import FormControl from "@material-ui/core/FormControl";
import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
import CustomizeIcon from "@material-ui/icons/Settings";
import RandomIcon from "mdi-react/QuestionMarkIcon";
import MenuItem from "@material-ui/core/MenuItem";
import LinearProgress from "@material-ui/core/LinearProgress";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import formatMoney from "accounting-js/lib/formatMoney";
import unformat from "accounting-js/lib/unformat";
import classNames from "classnames";
import {createAndLoadDemoData as __createAndLoadDemoData} from "../../actions/libs/app/account";
import {generateDemoData} from "../demoDataGenerator";
import {fromYen, pseudoGaussianRandom} from "../general";
import {getCurrentDate} from "../../core/util/finance";

import i18next from "i18next";
import * as R from "../../core/reducers/Finance/state";
import * as A from "../../actions";
import * as U from "../../core/util/dataGenerator";
import {Theme} from "@material-ui/core/styles/createMuiTheme";
import withStyles, {ClassNameMap, StyleRulesCallback} from "@material-ui/core/styles/withStyles";
import {ThunkDispatch} from "redux-thunk";
import {Lang} from "../../reducers/App/state";
import {RootState} from "../../reducers";
import {Omit, TReservedHocProps} from "../type";


interface IDemoDataDialogProps {
  classes: ClassNameMap;
  theme: Theme;
  i18n: i18next.i18n;
  lang: Lang;
  onCancel: () => any;
  onOK: () => any;
  open?: boolean;
  currencyId: R.CurrencyId;
  currencies: R.Currency[];
  params?: U.GeneratorParams;
  skipConfig?: boolean;
  createAndLoadDemoData: (items: R.ItemMaster[],
                          itemGroups: R.ItemGroupMaster[],
                          accounts: R.AccountMaster[],
                          accountTypes: R.AccountTypeMaster[],
                          flow: R.ActiveFlowData[],
                          balance: R.ActiveBalanceData[],
                          params: U.GeneratorParams) => void;
}

interface IDemoDataDialogStateWithoutDemoParams {
  scene: string;
  occupation: string;
  editingId: string;
  editingValue: string | null;
  progress: number | undefined;
  processing: {y: number, m: number};
  currencyId: R.CurrencyId;
}

type DemoDataDialogState = IDemoDataDialogStateWithoutDemoParams & U.GeneratorParams;

type TParamOptionFor<T> = {
  [P in keyof T]?: IParamOption;
};

interface IParamOption {
  formatter?: (v: string|number) => string;
  min?: number;
  max?: number;
  className?: string;
  nullable?: boolean;
  title?: string;
  key?: string | number;
}

class DemoDataDialog extends React.Component<IDemoDataDialogProps, DemoDataDialogState> {
  public paramOptions: TParamOptionFor<U.GeneratorParams> = {};
  public initiated: boolean = false;
  
  constructor(props: IDemoDataDialogProps){
    super(props);
  
    const {currencies, params, currencyId} = props;
    const currency = currencies.find(c => c.id === currencyId) as R.Currency;
    const getValueFromYen = (value: number) => fromYen(value, currency);
  
    const date = getCurrentDate();
    const initialStartY = date.getFullYear();
    const initialStartM = date.getMonth() + 1;
  
    const state: DemoDataDialogState = {
      scene: "init",
      occupation: "",
      editingId: "",
      editingValue: "",
      currencyId,
      progress: 0,
      processing: {y: 0, m: 0},
      
      years: 3,
      start_fund: getValueFromYen(1000000),
      start_debt: getValueFromYen(0),
      start_fixedAsset: getValueFromYen(0),
      debt_interest: getValueFromYen(0),
      debt_period: 35,
      repayment_method: "equalPayments", // ["equalPayments", "equalInstalments"]
      income_allowances: getValueFromYen(30000),
      income_basePay: getValueFromYen(210000),
      income_bonusAmount: getValueFromYen(800000),
      income_bonusCount: 2,
      income_commuting: getValueFromYen(120000),
      income_otherIncome: getValueFromYen(200000),
      income_overtime: 10,
      income_rfBonusAmount: 30,
      income_rfOtherIncome: 20,
      income_rfOvertime: 50,
      income_rfRiseRate: 30,
      income_riseRate: 2,
      income_workingHours: 8,
      income_overtimeRaiseRate: 25,
      income_pensionChargeRate: 9.15,
      income_healthInsuranceChargeRate: 4.4,
      income_employmentInsuranceChargeRate: 0.3,
      fixedAssetUsefulLives: 47,
      autoFixedAssetUsefulLives: true,
      income_feeding: 0,
      startY: initialStartY,
      startM: initialStartM,
      income_prevYearTotalIncome: null,
      income_prevYearTotalSocialInsurance: null,
      income_initialNormalizedPay: null,
      income_TotalIncome2YearsAgo: null,
      income_TotalSocialInsurance2YearsAgo: null,
      income_initialNormalizedPayLastTerm: null,
      spending_hobby: getValueFromYen(30000),
      spending_beauty: getValueFromYen(8000),
      spending_communication: getValueFromYen(13000),
      spending_commuting: getValueFromYen(20000),
      spending_education: getValueFromYen(2000),
      spending_electricity: getValueFromYen(3000),
      spending_food: getValueFromYen(60000),
      spending_gasWater: getValueFromYen(5000),
      spending_goods: getValueFromYen(20000),
      spending_house: getValueFromYen(60000),
      spending_medical: getValueFromYen(6000),
      spending_unclassified: getValueFromYen(10000),
      spending_trip_budget: getValueFromYen(200000),
      spending_trip_count: 2,
      spending_rf: 30,
      spending_debtBonusChargeRate: 20,
      currency,
    };
    
    if(params && typeof params === "object"){
      Object.keys(params).forEach((key) => {
        const paramKey = key as keyof U.GeneratorParams;
        state[paramKey] = params[paramKey];
      });
    }
    
    this.state = state;
    
    this.getScene_Init = this.getScene_Init.bind(this);
    this.getScene_CustomizeEmployee_Asset = this.getScene_CustomizeEmployee_Asset.bind(this);
    this.getScene_CustomizeEmployee_Income = this.getScene_CustomizeEmployee_Income.bind(this);
    this.getScene_CustomizeEmployee_Spend = this.getScene_CustomizeEmployee_Spend.bind(this);
    this.getScene_generateFinancialData = this.getScene_generateFinancialData.bind(this);
    this.onFinishEditingParams = this.onFinishEditingParams.bind(this);
    this.getScene_detail = this.getScene_detail.bind(this);
    this.getTextField = this.getTextField.bind(this);
    this.onClick = this.onClick.bind(this);
  }
  
  public componentDidMount(){
    window.addEventListener("click", this.onClick, false);
  }
  
  public componentWillUnmount(){
    window.removeEventListener("click", this.onClick);
  }
  
  public render(){
    const {classes, onCancel, skipConfig} = this.props;
    let {open} = this.props;
    const {scene} = this.state;
    
    open = open !== false;
    
    let content;
    if(skipConfig){
      content = this.getScene_generateFinancialData();
    }
    else if(scene === "init"){
      content = this.getScene_Init();
    }
    else if(scene === "customize_employee_asset"){
      content = this.getScene_CustomizeEmployee_Asset();
    }
    else if(scene === "customize_employee_income"){
      content = this.getScene_CustomizeEmployee_Income();
    }
    else if(scene === "customize_employee_spend"){
      content = this.getScene_CustomizeEmployee_Spend();
    }
    else if(scene === "detail"){
      content = this.getScene_detail();
    }
    else if(scene === "generating"){
      content = this.getScene_generateFinancialData();
    }
    
    return (
      <div>
        <Dialog
          open={open}
          onClose={onCancel}
          scroll="paper"
          aria-labelledby="scroll-dialog-title"
          classes={{paper: classes.dialogPaper, root: classes.dialogRoot}}
        >
          {content}
        </Dialog>
      </div>
    );
  }
  
  public getScene_Init(){
    const {classes, i18n, lang, onCancel} = this.props;
    const t = i18n.getFixedT(lang, "Demo");
    
    return (
      <Fragment>
        <DialogTitle id="scroll-dialog-title" classes={{root: classes.dialogTitle}}>
          {t<string>("dialogTitle")}
        </DialogTitle>
        <DialogContent classes={{root: classes.dialogContentRoot}}>
          <div style={{display: "flex", width: 400}}>
            <div style={{width: "50%", padding: 15}}>
              <div
                className={classes.dataCreateSelection}
                onClick={() => this.setState({scene: "generating"})}
              >
                <div>
                  <RandomIcon/>
                </div>
                <div>
                  {t<string>("random")}
                </div>
              </div>
            </div>
            <div style={{width: "50%", padding: 15}}>
              <div
                className={classes.dataCreateSelection}
                onClick={() => this.setState({scene: "customize_employee_asset"})}
              >
                <div>
                  <CustomizeIcon/>
                </div>
                <div>
                  {t<string>("customize")}
                </div>
              </div>
            </div>
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => onCancel()}>{t<string>("cancel")}</Button>
        </DialogActions>
      </Fragment>
    );
  }
  
  public getScene_CustomizeEmployee_Asset(){
    const {classes, i18n, lang, currencies} = this.props;
    const {
      repayment_method,
      currencyId,
      income_feeding,
      autoFixedAssetUsefulLives,
    } = this.state;
    const t = i18n.getFixedT(lang, "Demo");
    const currency = currencies.find(c => c.id === currencyId) as R.Currency;
  
    const getTextField = this.getTextField;
  
    const formatter_Money = (v: string|number): string => formatMoney(v, currency);
    const formatter_Hours = (v: string|number): string => t<string>("hours", {hours: v});
    const formatter_Times = (v: string|number): string => t<string>("times", {times: v});
    const formatter_Percent = (v: string|number): string => `${v} %`;
    const formatter_Years = (v: string|number): string => t<string>("years", {years: v});
    
    const getFXValue = (value: number) => fromYen(value, currency);
    
    const randomizeAssetParams = () => {
      const getCount = (min=0, max=9) => {
        return Math.floor(Math.random()*(max - min) + min);
      };
      
      const getValue = (
        min: number,
        max: number,
        mean: number,
        easingFunction: (min: number, max: number, mean: number) => number,
        fx: boolean,
      ) => {
        if(fx){
          min = getFXValue(min);
          max = getFXValue(max);
          if(mean){
            mean = getFXValue(mean);
          }
        }
        
        return easingFunction ? easingFunction(min, max, mean) : parseFloat(((Math.random() * (max - min + 1)) + min).toPrecision(2));
      };
      
      /**
       * Finding good curve function is fun.
       * 1. y=(x-2)^3 / 4 + 2     http://fooplot.com/#W3sidHlwZSI6MCwiZXEiOiIoeC0yKV4zLzQrMiIsImNvbG9yIjoiIzAwMDAwMCJ9LHsidHlwZSI6MTAwMCwid2luZG93IjpbIi0xIiwiNiIsIi0xIiwiNiJdfV0-
       */
      const easing = (min: number, max: number, mean: number) => {
        let rand = pseudoGaussianRandom(mean, min, max);
        const log10 = Math.floor(Math.log10(Math.abs(rand)||1));
        rand = rand / (10**log10);
        rand = Math.round(rand * 10)/ 10;
        return Math.round(rand * (10 ** log10));
      };
      
      this.setState({
        start_fund: getValue(0, 3000000, 1000000, easing, true),
      });
    };
  
    return (
      <Fragment>
        <DialogContent classes={{root: classes.dialogContentRoot}} style={{padding: "10px 40px"}}>
          <div style={{fontSize: ".8rem", marginBottom: 16, marginTop: 16, display: "flex"}}>
            <div style={{}}>
              <Button
                variant="contained"
                color="secondary"
                onClick={randomizeAssetParams}
                classes={{label: classes.buttonLabel}}
                style={{marginRight: 16}}
              >
                {t<string>("randomize")}
              </Button>
            </div>
            <div style={{paddingLeft: 8}}>
              {t<string>("description.asset")}
            </div>
          </div>
          <form noValidate={true} autoComplete="off" >
            <table>
              <tbody>
              <tr>
                <td>
                  {
                    getTextField(
                      "start_fund",
                      t<string>("startFund"),
                      getFXValue(1000000),
                      {formatter: formatter_Money, min: 0, className: classes.textFieldShort},
                    )
                  }
                </td>
                <td>
                  {
                    getTextField(
                      "years",
                      t<string>("period"),
                      0,
                      {formatter: formatter_Years, min: 0, className: classes.textFieldShort},
                    )
                  }
                </td>
                <td>
                  {
                    getTextField(
                      "startM",
                      t<string>("startM"),
                      0,
                      {min: 1, max: 12, className: classes.textFieldShort},
                    )
                  }
                </td>
                <td>
                  {
                    getTextField(
                      "startY",
                      t<string>("startY"),
                      0,
                      {min: 1900, max: 2200, className: classes.textFieldShort},
                    )
                  }
                </td>
                <td>
                  <FormControl style={{margin: 8, width: 190}}>
                    <InputLabel htmlFor="feeding">{t<string>("feeding")}</InputLabel>
                    <Select
                      value={income_feeding}
                      onChange={e => this.setState({income_feeding: (+e.target.value)})}
                      inputProps={{
                        name: "feeding",
                        id: "feeding",
                      }}
                      classes={{select: classes.select}}
                    >
                      {Array(9).fill(0).map((_, i) => (
                        <MenuItem key={i} value={i}>{i}</MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </td>
              </tr>
              <tr>
                <td>
                  {
                    getTextField(
                      "start_debt",
                      t<string>("startDebt"),
                      getFXValue(0),
                      {formatter: formatter_Money, min: 0, className: classes.textFieldShort},
                    )
                  }
                </td>
                <td>
                  {
                    getTextField(
                      "debt_interest",
                      t<string>("debtInterest"),
                      0,
                      {
                        formatter: formatter_Percent,
                        min: 0,
                        className: classes.textFieldShort,
                        title: t<string>("title.debtInterest"),
                      },
                    )
                  }
                </td>
                <td>
                  {
                    getTextField(
                      "debt_period",
                      t<string>("debtPeriod"),
                      0,
                      {formatter: formatter_Years, min: 0, className: classes.textFieldShort},
                    )
                  }
                </td>
                <td>
                  {
                    getTextField(
                      "spending_debtBonusChargeRate",
                      t<string>("spending_debtBonusChargeRate"),
                      20,
                      {formatter: formatter_Percent, min: 0, max: 100, className: classes.textFieldShort},
                    )
                  }
                </td>
                <td>
                  <FormControl style={{margin: 8, width: 190}} disabled={this.state.start_debt===0}>
                    <InputLabel htmlFor="repaymentMethod">{t<string>("repaymentMethod")}</InputLabel>
                    <Select
                      value={repayment_method}
                      onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                        this.setState({repayment_method: (e.target.value as U.RepaymentMethod)});
                      }}
                      inputProps={{
                        name: "repaymentMethod",
                        id: "repaymentMethod",
                      }}
                      classes={{select: classes.select}}
                    >
                      <MenuItem value="equalPayments">{t<string>("equalPayments")}</MenuItem>
                      <MenuItem value="equalInstalments">{t<string>("equalInstalments")}</MenuItem>
                    </Select>
                  </FormControl>
                </td>
              </tr>
              <tr>
                <td>
                  {
                    getTextField(
                      "start_fixedAsset",
                      t<string>("startFixedAsset"),
                      getFXValue(0),
                      {formatter: formatter_Money, min: 0, className: classes.textFieldShort},
                    )
                  }
                </td>
                <td colSpan={4}>
                  <div style={{display: "flex", justifyContent: "flex-start"}}>
                    {
                      getTextField(
                        "fixedAssetUsefulLives",
                        t<string>("fixedAssetUsefulLives"),
                        0,
                        {formatter: formatter_Years, min: 1, title: t<string>("title.fixedAssetUsefulLives")},
                      )
                    }
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={autoFixedAssetUsefulLives}
                          onChange={e => this.setState({autoFixedAssetUsefulLives: e.target.checked})}
                        />
                      }
                      label={t<string>("auto_fixedAssetUsefulLives")}
                    />
                  </div>
                </td>
              </tr>
              </tbody>
            </table>
          </form>
        </DialogContent>
        <DialogActions>
          <Button
            color="primary"
            onClick={() => this.setState({scene: "init"})}
            style={{marginRight: 32}}
          >
            {t<string>("back")}
          </Button>
          <Button
            color="primary"
            onClick={() => this.setState({scene: "customize_employee_asset"})}
            disabled={true}
          >
            {t<string>("customize_asset")}
          </Button>
          <Button
            color="primary"
            onClick={() => this.setState({scene: "customize_employee_income"})}
          >
            {t<string>("customize_income")}
          </Button>
          <Button
            color="primary"
            onClick={() => this.setState({scene: "customize_employee_spend"})}
          >
            {t<string>("customize_spend")}
          </Button>
          <Button
            color="primary"
            onClick={() => this.setState({scene: "detail"})}
          >
            {t<string>("detail")}
          </Button>
          <Button
            color="primary"
            onClick={() => this.setState({scene: "generating"})}
            style={{marginLeft: 32}}
          >
            {t<string>("OK")}
          </Button>
        </DialogActions>
      </Fragment>
    );
  }
  
  public getScene_CustomizeEmployee_Income(){
    const {classes, i18n, lang, currencies} = this.props;
    const {repayment_method, currencyId} = this.state;
    const t = i18n.getFixedT(lang, "Demo");
    const currency = currencies.find(c => c.id === currencyId) as R.Currency;
    
    const getTextField = this.getTextField;
    
    const formatter_Money = (v: string|number): string => formatMoney(v, currency);
    const formatter_Hours = (v: string|number): string => t<string>("hours", {hours: v});
    const formatter_Times = (v: string|number): string => t<string>("times", {times: v});
    const formatter_Percent = (v: string|number): string => `${v} %`;
    const formatter_Years = (v: string|number): string => t<string>("years", {years: v});
    
    const getFXValue = (value: number) => fromYen(value, currency);
    
    const randomize = () => {
      const getCount = (min=0, max=9) => {
        return Math.floor(Math.random()*(max - min) + min);
      };
      
      const getValue = (
        min: number,
        max: number,
        mean: number,
        easingFunction: (min: number, max: number, mean: number) => number,
        fx: boolean,
      ) => {
        if(fx){
          min = getFXValue(min);
          max = getFXValue(max);
          if(mean){
            mean = getFXValue(mean);
          }
        }
        return easingFunction ? easingFunction(min, max, mean) : parseFloat(((Math.random() * (max - min + 1)) + min).toPrecision(2));
      };
      
      /**
       * Finding good curve function is fun.
       * 1. y=(x-2)^3 / 4 + 2     http://fooplot.com/#W3sidHlwZSI6MCwiZXEiOiIoeC0yKV4zLzQrMiIsImNvbG9yIjoiIzAwMDAwMCJ9LHsidHlwZSI6MTAwMCwid2luZG93IjpbIi0xIiwiNiIsIi0xIiwiNiJdfV0-
       */
      const easing = (min: number, max: number, mean: number) => {
        let rand = pseudoGaussianRandom(mean, min, max);
        const log10 = Math.floor(Math.log10(Math.abs(rand)||1));
        rand = rand / (10**log10);
        rand = Math.round(rand * 10)/ 10;
        return Math.round(rand * (10 ** log10));
      };
      
      this.setState({
        income_allowances: getValue(1000, 99000, 10000, easing, true),
        income_basePay: getValue(100000, 800000, 300000, easing, true),
        income_bonusAmount: getValue(10000, 5000000, 600000, easing, true),
        income_bonusCount: getCount(0, 4),
        income_commuting: getValue(10000, 240000, 100000, easing, true),
        income_otherIncome: getValue(0, 500000, 50000, easing, true),
        income_overtime: getValue(0, 120, 30, easing, false),
        income_rfBonusAmount: getValue(0, 100, 50, easing, false),
        income_rfOtherIncome: getValue(0, 200, 100, easing, false),
        income_rfOvertime: getValue(0, 100, 150, easing, false),
        income_rfRiseRate: getValue(0, 200, 70, easing, false),
        income_riseRate: getValue(0, 15, 2, easing, false),
      });
    };
    
    return (
      <Fragment>
        <DialogContent classes={{root: classes.dialogContentRoot}} style={{padding: "10px 40px"}}>
          <div style={{fontSize: ".8rem", marginTop: 16, marginBottom: 16, display: "flex"}}>
            <div style={{}}>
              <Button
                variant="contained"
                color="secondary"
                onClick={randomize}
                classes={{label: classes.buttonLabel}}
                style={{marginRight: 16}}
              >
                {t<string>("randomize")}
              </Button>
            </div>
            <div style={{paddingLeft: 8}}>
              {t<string>("description.income")}
            </div>
          </div>
          <form noValidate={true} autoComplete="off" >
            <table>
              <tbody>
              <tr>
                <td>
                  {
                    getTextField(
                      "income_basePay",
                      t<string>("basePay"),
                      getFXValue(210000),
                      {formatter: formatter_Money, min: 0},
                    )
                  }
                </td>
                <td>
                  {
                    getTextField(
                      "income_overtime",
                      t<string>("overtime"),
                      10,
                      {formatter: formatter_Hours, min: 0, title: t<string>("title.overtime")},
                    )
                  },
                </td>
                <td>
                  {
                    getTextField(
                      "income_commuting",
                      t<string>("commutingAllowance"),
                      getFXValue(120000),
                      {formatter: formatter_Money, min: 0},
                    )
                  }
                </td>
                <td>
                  {
                    getTextField(
                      "income_allowances",
                      t<string>("allowances"),
                      getFXValue(30000),
                      {formatter: formatter_Money, min: 0},
                    )
                  }
                </td>
                <td />
              </tr>
              <tr>
                <td>
                  {
                    getTextField(
                      "income_bonusAmount",
                      t<string>("bonusAmount"),
                      getFXValue(1000000),
                      {formatter: formatter_Money, min: 0},
                    )
                  }
                </td>
                <td>
                  {
                    getTextField(
                      "income_bonusCount",
                      t<string>("bonusCount"),
                      2,
                      {formatter: formatter_Times, min: 0, max: 4},
                    )
                  }
                </td>
                <td>
                  {
                    getTextField(
                      "income_otherIncome",
                      t<string>("otherIncome"),
                      getFXValue(200000),
                      {formatter: formatter_Money, min: 0},
                    )
                  }
                </td>
                <td>
                  {
                    getTextField(
                      "income_workingHours",
                      t<string>("income_workingHours"),
                      8,
                      {formatter: formatter_Hours, min: 1, title: t<string>("title.workingHours")},
                    )
                  }
                </td>
                <td>
                  {
                    getTextField(
                      "income_overtimeRaiseRate",
                      t<string>("income_overtimeRaiseRate"),
                      25,
                      {formatter: formatter_Percent, min: 0, max: 100},
                    )
                  }
                </td>
              </tr>
              <tr>
                <td>
                  {
                    getTextField(
                      "income_riseRate",
                      t<string>("riseRate"),
                      2,
                      {formatter: formatter_Percent, min: 0, title: t<string>("title.riseRate")},
                    )
                  }
                </td>
                <td>
                  {
                    getTextField(
                      "income_rfOvertime",
                      t<string>("rfOvertime"),
                      50,
                      {formatter: formatter_Percent, min: 0, max: 1000},
                    )
                  }
                </td>
                <td>
                  {
                    getTextField(
                      "income_rfBonusAmount",
                      t<string>("rfBonusAmount"),
                      30,
                      {formatter: formatter_Percent, min: 0, max: 1000},
                    )
                  }
                </td>
                <td>
                  {
                    getTextField(
                      "income_rfOtherIncome",
                      t<string>("rfOtherIncome"),
                      50,
                      {formatter: formatter_Percent, min: 0, max: 1000},
                    )
                  }
                </td>
                <td>
                  {
                    getTextField(
                      "income_rfRiseRate",
                      t<string>("rfRiseRate"),
                      100,
                      {formatter: formatter_Percent, min: 0, max: 1000},
                    )
                  }
                </td>
              </tr>
              </tbody>
            </table>
          </form>
        </DialogContent>
        <DialogActions>
          <Button
            color="primary"
            onClick={() => this.setState({scene: "init"})}
            style={{marginRight: 32}}
          >
            {t<string>("back")}
          </Button>
          <Button
            color="primary"
            onClick={() => this.setState({scene: "customize_employee_asset"})}
          >
            {t<string>("customize_asset")}
          </Button>
          <Button
            color="primary"
            onClick={() => this.setState({scene: "customize_employee_income"})}
            disabled={true}
          >
            {t<string>("customize_income")}
          </Button>
          <Button
            color="primary"
            onClick={() => this.setState({scene: "customize_employee_spend"})}
          >
            {t<string>("customize_spend")}
          </Button>
          <Button
            color="primary"
            onClick={() => this.setState({scene: "detail"})}
          >
            {t<string>("detail")}
          </Button>
          <Button
            color="primary"
            onClick={() => this.setState({scene: "generating"})}
            style={{marginLeft: 32}}
          >
            {t<string>("OK")}
          </Button>
        </DialogActions>
      </Fragment>
    );
  }
  
  public getScene_CustomizeEmployee_Spend(){
    const {classes, i18n, lang, currencies} = this.props;
    const {repayment_method, currencyId} = this.state;
    const t = i18n.getFixedT(lang, "Demo");
    const currency = currencies.find(c => c.id === currencyId) as R.Currency;
    
    const getTextField = this.getTextField;
    
    const formatter_Money = (v: string|number): string => formatMoney(v, currency);
    const formatter_Hours = (v: string|number): string => t<string>("hours", {hours: v});
    const formatter_Times = (v: string|number): string => t<string>("times", {times: v});
    const formatter_Percent = (v: string|number): string => `${v} %`;
    const formatter_Years = (v: string|number): string => t<string>("years", {years: v});
    
    const getFXValue = (value: number) => fromYen(value, currency);
    
    const randomize = () => {
      const getCount = (min=0, max=9) => {
        return Math.floor(Math.random()*(max - min) + min);
      };
      
      const getValue = (
        min: number,
        max: number,
        mean: number,
        easingFunction: (min: number, max: number, mean: number) => number,
        fx: boolean,
      ) => {
        if(fx){
          min = getFXValue(min);
          max = getFXValue(max);
          if(mean){
            mean = getFXValue(mean);
          }
        }
        return easingFunction ? easingFunction(min, max, mean) : parseFloat(((Math.random() * (max - min + 1)) + min).toPrecision(2));
      };
      
      /**
       * Finding good curve function is fun.
       * 1. y=(x-2)^3 / 4 + 2     http://fooplot.com/#W3sidHlwZSI6MCwiZXEiOiIoeC0yKV4zLzQrMiIsImNvbG9yIjoiIzAwMDAwMCJ9LHsidHlwZSI6MTAwMCwid2luZG93IjpbIi0xIiwiNiIsIi0xIiwiNiJdfV0-
       */
      const easing = (min: number, max: number, mean: number) => {
        let rand = pseudoGaussianRandom(mean, min, max);
        const log10 = Math.floor(Math.log10(Math.abs(rand)||1));
        rand = rand / (10**log10);
        rand = Math.round(rand * 10)/ 10;
        return Math.round(rand * (10 ** log10));
      };
      
      this.setState({
        spending_hobby: getValue(0, 200000, 35000, easing, true),
        spending_beauty: getValue(0, 100000, 10000, easing, true),
        spending_communication: getValue(0, 30000, 15000, easing, true),
        spending_commuting: getValue(0, 60000, 20000, easing, true),
        spending_education: getValue(0, 100000, 10000, easing, true),
        spending_electricity: getValue(0, 24000, 5000, easing, true),
        spending_food: getValue(0, 200000, 60000, easing, true),
        spending_gasWater: getValue(0, 12000, 6000, easing, true),
        spending_goods: getValue(0, 200000, 10000, easing, true),
        spending_house: getValue(0, 300000, 85000, easing, true),
        spending_medical: getValue(0, 100000, 3000, easing, true),
        spending_unclassified: getValue(0, 100000, 20000, easing, true),
        spending_trip_budget: getValue(0, 400000, 100000, easing, true),
        spending_trip_count: getCount(0, 4),
        spending_rf: getValue(0, 50, 30, easing, false),
      });
    };
    
    return (
      <Fragment>
        <DialogContent classes={{root: classes.dialogContentRoot}} style={{padding: "10px 40px"}}>
          <div style={{fontSize: ".8rem", marginTop: 16, marginBottom: 16, display: "flex"}}>
            <div style={{}}>
              <Button
                variant="contained"
                color="secondary"
                onClick={randomize}
                style={{marginRight: 16}}
                classes={{label: classes.buttonLabel}}
              >
                {t<string>("randomize")}
              </Button>
            </div>
            <div style={{paddingLeft: 8}}>
              {t<string>("description.spend")}
            </div>
          </div>
          <form noValidate={true} autoComplete="off" >
            <table>
              <tbody>
              <tr>
                <td>
                  {getTextField("spending_food", t<string>("food"), getFXValue(60000), {formatter: formatter_Money, min: 0})}
                </td>
                <td>
                  {getTextField("spending_hobby", t<string>("hobby"), getFXValue(35000), {formatter: formatter_Money, min: 0})}
                </td>
                <td>
                  {getTextField("spending_goods", t<string>("goods"), getFXValue(20000), {formatter: formatter_Money, min: 0})}
                </td>
                <td>
                  {
                    getTextField(
                      "spending_communication",
                      t<string>("communication"),
                      getFXValue(13000),
                      {formatter: formatter_Money, min: 0},
                    )
                  }
                </td>
                <td>
                  {getTextField("spending_commuting", t<string>("commuting"), getFXValue(20000), {formatter: formatter_Money, min: 0})}
                </td>
              </tr>
              <tr>
                <td>
                  {getTextField("spending_medical", t<string>("medical"), getFXValue(6000), {formatter: formatter_Money, min: 0})}
                </td>
                <td>
                  {getTextField("spending_beauty", t<string>("beauty"), getFXValue(8000), {formatter: formatter_Money, min: 0})}
                </td>
                <td>
                  {getTextField("spending_education", t<string>("education"), getFXValue(2000), {formatter: formatter_Money, min: 0})}
                </td>
                <td>
                  {getTextField("spending_gasWater", t<string>("gasWater"), getFXValue(5000), {formatter: formatter_Money, min: 0})}
                </td>
                <td>
                  {getTextField("spending_electricity", t<string>("electricity"), getFXValue(3000), {formatter: formatter_Money, min: 0})}
                </td>
              </tr>
              <tr>
                <td>
                  {getTextField("spending_house", t<string>("house"), getFXValue(60000), {formatter: formatter_Money, min: 0})}
                </td>
                <td>
                  {
                    getTextField(
                      "spending_unclassified",
                      t<string>("unclassified"),
                      getFXValue(10000),
                      {formatter: formatter_Money, min: 0},
                    )
                  }
                </td>
                <td>
                  {getTextField("spending_trip_budget", t<string>("tripYearly"), getFXValue(10000), {formatter: formatter_Money, min: 0})}
                </td>
                <td>
                  {getTextField("spending_trip_count", t<string>("tripCount"), 2, {formatter: formatter_Times, min: 0})}
                </td>
                <td>
                  {getTextField("spending_rf", t<string>("rfSpending"), 30, {formatter: formatter_Percent, min: 0})}
                </td>
              </tr>
              </tbody>
            </table>
          </form>
        </DialogContent>
        <DialogActions>
          <Button
            color="primary"
            onClick={() => this.setState({scene: "init"})}
            style={{marginRight: 32}}
          >
            {t<string>("back")}
          </Button>
          <Button
            color="primary"
            onClick={() => this.setState({scene: "customize_employee_asset"})}
          >
            {t<string>("customize_asset")}
          </Button>
          <Button
            color="primary"
            onClick={() => this.setState({scene: "customize_employee_income"})}
          >
            {t<string>("customize_income")}
          </Button>
          <Button
            color="primary"
            onClick={() => this.setState({scene: "customize_employee_spend"})}
            disabled={true}
          >
            {t<string>("customize_spend")}
          </Button>
          <Button
            color="primary"
            onClick={() => this.setState({scene: "detail"})}
          >
            {t<string>("detail")}
          </Button>
          <Button
            color="primary"
            onClick={() => this.setState({scene: "generating"})}
            style={{marginLeft: 32}}
          >
            {t<string>("OK")}
          </Button>
        </DialogActions>
      </Fragment>
    );
  }
  
  public onFinishEditingParams(){
    let {editingValue} = this.state;
    const editingId = this.state.editingId as keyof U.GeneratorParams;
    const option = this.paramOptions[editingId];
    
    let min;
    let max;
    let nullable;
    if(option){
      ({min, max, nullable} = option);
    }
    
    let editingValueNum = null;
    
    if(!editingValue && nullable){
      editingValueNum = null;
    }
    else if(typeof(editingValue) === "string" && editingValue.startsWith("0") && !(unformat(editingValue) % 1)){ // check float
      editingValue = editingValue.replace(/^0/, "0.");
      editingValueNum = unformat(editingValue);
    }
    else{
      editingValueNum = unformat(editingValue);
    }
  
    if(typeof(min) === "number" && editingValueNum !== null && editingValueNum < min){
      return;
    }
    if(typeof(max) === "number" && editingValueNum !== null && editingValueNum > max){
      return;
    }
    
    const newState: Partial<DemoDataDialogState> = {
      editingId: "",
      editingValue: "",
    };
    newState[(editingId as keyof DemoDataDialogState)] = editingValueNum;
    
    this.setState((newState as DemoDataDialogState));
  }
  
  public getScene_detail(){
    const {i18n, lang, classes, currencies} = this.props;
    const {currencyId} = this.state;
    const currency = currencies.find(c => c.id === currencyId) as R.Currency;
    const {
      startM,
    } = this.state;
    const {getTextField} = this;
    const t = i18n.getFixedT(lang, "Demo");
    /*
    let formatter_Hours = v => t<string>("hours", {hours: v});
    let formatter_Times = v => t<string>("times", {times: v});
    */
    const formatter_Money = (v: string|number): string => formatMoney(v, currency);
    const formatter_Percent = (v: string|number): string => `${v} %`;
    const formatter_Years = (v: string|number): string => t<string>("years", {years: v});
    /*
    let fromYen = value => fromYen(value, currency.symbol);
    */
    
    let specialCase;
    if(startM < 6 || startM < 9){
      let inputs = [] as JSX.Element[];
      if(startM < 6){
        inputs = inputs.concat([
          getTextField(
            "income_TotalIncome2YearsAgo",
            t<string>("income_TotalIncome2YearsAgo"),
            null,
            {formatter: formatter_Money, min: 0, nullable: true, title: t<string>("title.income_TotalIncome2YearsAgo"), key: 0},
          ),
          getTextField(
            "income_TotalSocialInsurance2YearsAgo",
            t<string>("income_TotalSocialInsurance2YearsAgo"),
            null,
            {formatter: formatter_Money, min: 0, nullable: true, title: t<string>("title.income_TotalSocialInsurance2YearsAgo"), key: 1},
          ),
        ]);
      }
      if(startM < 9){
        inputs.push(
          getTextField(
            "income_initialNormalizedPayLastTerm",
            t<string>("initialNormalizedPayLastTerm"),
            null,
            {formatter: formatter_Money, min: 0, nullable: true, title: t<string>("title.initialNormalizedPayLastTerm"), key: 2},
          ),
        );
      }
      
      while(inputs.length < 3){
        inputs.push((<div style={{width: 170, display: "inline-block"}} key={`blank-${inputs.length}`} />));
      }
      
      specialCase = (
        <div style={{display: "flex", flexWrap: "wrap", justifyContent: "start"}}>
          {inputs}
        </div>
      );
    }
    
    return (
      <Fragment>
        <DialogContent classes={{root: classes.dialogContentRoot}} style={{padding: "10px 40px"}}>
          <div style={{marginBottom: 16, paddingTop: 16}}>
            <span style={{fontSize: "1.4rem"}}>{t<string>("detail")}</span>
          </div>
          <form noValidate={true} autoComplete="off" >
            <div>
              {specialCase}
              <div style={{display: "flex", flexWrap: "wrap", justifyContent: "start"}}>
                {
                  getTextField(
                    "income_prevYearTotalIncome",
                    t<string>("income_prevYearTotalIncome"),
                    null,
                    {formatter: formatter_Money, min: 0, nullable: true, title: t<string>("title.income_prevYearTotalIncome")},
                  )
                }
                {
                  getTextField(
                    "income_prevYearTotalSocialInsurance",
                    t<string>("income_prevYearTotalSocialInsurance"),
                    null,
                    {formatter: formatter_Money, min: 0, nullable: true, title: t<string>("title.income_prevYearTotalSocialInsurance")},
                  )
                }
                {
                  getTextField(
                    "income_initialNormalizedPay",
                    t<string>("initialNormalizedPay"),
                    null,
                    {formatter: formatter_Money, min: 0, nullable: true},
                  )
                }
              </div>
              <div style={{display: "flex", flexWrap: "wrap", justifyContent: "start"}}>
                {
                  getTextField(
                    "income_pensionChargeRate",
                    t<string>("income_pensionChargeRate"),
                    0,
                    {formatter: formatter_Percent, min: 0},
                  )
                }
                {
                  getTextField(
                    "income_healthInsuranceChargeRate",
                    t<string>("income_healthInsuranceChargeRate"),
                    0,
                    {formatter: formatter_Percent, min: 0},
                  )
                }
                {
                  getTextField(
                    "income_employmentInsuranceChargeRate",
                    t<string>("income_employmentInsuranceChargeRate"),
                    0,
                    {formatter: formatter_Percent, min: 0},
                  )
                }
              </div>
            </div>
          </form>
        </DialogContent>
        <DialogActions>
          <Button
            color="primary"
            onClick={() => this.setState({scene: "init"})}
            style={{marginRight: 32}}
          >
            {t<string>("back")}
          </Button>
          <Button
            color="primary"
            onClick={() => this.setState({scene: "customize_employee_asset"})}
          >
            {t<string>("customize_asset")}
          </Button>
          <Button
            color="primary"
            onClick={() => this.setState({scene: "customize_employee_income"})}
          >
            {t<string>("customize_income")}
          </Button>
          <Button
            color="primary"
            onClick={() => this.setState({scene: "customize_employee_spend"})}
          >
            {t<string>("customize_spend")}
          </Button>
          <Button
            color="primary"
            onClick={() => this.setState({scene: "detail"})}
            disabled={true}
          >
            {t<string>("detail")}
          </Button>
          <Button
            color="primary"
            onClick={() => this.setState({scene: "generating"})}
            style={{marginLeft: 32}}
          >
            {t<string>("OK")}
          </Button>
        </DialogActions>
      </Fragment>
    );
  }
  
  public getScene_generateFinancialData(){
    const {classes, i18n, lang, onOK, currencies, createAndLoadDemoData} = this.props;
    const {currencyId} = this.state;
    const currency = currencies.find(c => c.id === currencyId) as R.Currency;
    const {progress, processing} = this.state;
    const t = i18n.getFixedT(lang, "Demo");
    
    if(!this.initiated){
      this.initiated = true;
      
      const params: U.GeneratorParams = {
        years: this.state.years,
        start_fund: this.state.start_fund,
        start_debt: this.state.start_debt,
        start_fixedAsset: this.state.start_fixedAsset,
        debt_interest: this.state.debt_interest,
        debt_period: this.state.debt_period,
        repayment_method: this.state.repayment_method,
        income_allowances: this.state.income_allowances,
        income_basePay: this.state.income_basePay,
        income_bonusAmount: this.state.income_bonusAmount,
        income_bonusCount: this.state.income_bonusCount,
        income_commuting: this.state.income_commuting,
        income_otherIncome: this.state.income_otherIncome,
        income_overtime: this.state.income_overtime,
        income_rfBonusAmount: this.state.income_rfBonusAmount,
        income_rfOtherIncome: this.state.income_rfOtherIncome,
        income_rfOvertime: this.state.income_rfOvertime,
        income_rfRiseRate: this.state.income_rfRiseRate,
        income_riseRate: this.state.income_riseRate,
        income_workingHours: this.state.income_workingHours,
        income_overtimeRaiseRate: this.state.income_overtimeRaiseRate,
        income_pensionChargeRate: this.state.income_pensionChargeRate,
        income_healthInsuranceChargeRate: this.state.income_healthInsuranceChargeRate,
        income_employmentInsuranceChargeRate: this.state.income_employmentInsuranceChargeRate,
        fixedAssetUsefulLives: this.state.fixedAssetUsefulLives,
        autoFixedAssetUsefulLives: this.state.autoFixedAssetUsefulLives,
        income_feeding: this.state.income_feeding,
        startY: this.state.startY,
        startM: this.state.startM,
        income_prevYearTotalIncome: this.state.income_prevYearTotalIncome,
        income_prevYearTotalSocialInsurance: this.state.income_prevYearTotalSocialInsurance,
        income_initialNormalizedPay: this.state.income_initialNormalizedPay,
        income_TotalIncome2YearsAgo: this.state.income_TotalIncome2YearsAgo,
        income_TotalSocialInsurance2YearsAgo: this.state.income_TotalSocialInsurance2YearsAgo,
        income_initialNormalizedPayLastTerm: this.state.income_initialNormalizedPayLastTerm,
        spending_hobby: this.state.spending_hobby,
        spending_beauty: this.state.spending_beauty,
        spending_communication: this.state.spending_communication,
        spending_commuting: this.state.spending_commuting,
        spending_education: this.state.spending_education,
        spending_electricity: this.state.spending_electricity,
        spending_food: this.state.spending_food,
        spending_gasWater: this.state.spending_gasWater,
        spending_goods: this.state.spending_goods,
        spending_house: this.state.spending_house,
        spending_medical: this.state.spending_medical,
        spending_unclassified: this.state.spending_unclassified,
        spending_trip_budget: this.state.spending_trip_budget,
        spending_trip_count: this.state.spending_trip_count,
        spending_rf: this.state.spending_rf,
        spending_debtBonusChargeRate: this.state.spending_debtBonusChargeRate,
        currency,
      };
      
      let now = Date.now();
      const reporter = (prg: number, prc: {y: number, m: number}) => {
        if(Date.now() - now > 66){
          now = Date.now();
          this.setState({
            processing: prc,
            progress: prg,
          });
        }
      };
      
      generateDemoData(params, t, reporter)
        .then((result) => {
          const {
            data_flow,
            data_balance,
            master_items,
            master_itemGroups,
            master_accounts,
            master_accountTypes,
          } = result;
          
          data_flow.sort((a, b) => {
            if(a.date !== b.date){
              return a.date - b.date;
            }
            if(a.item === 24){ // start_fund
              if(b.item === 24){
                return a.account - b.account;
              }
              return -1;
            }
            if(a.account !== b.account){
              return a.account - b.account;
            }
            if(a.item !== b.item){
              return a.item - b.item;
            }
            if(a.value !== b.value){
              return a.value - b.value;
            }
            return 0;
          });
          
          data_balance.sort((a, b) => {
            if(a.date !== b.date){
              return a.date - b.date;
            }
            if(a.account !== b.account){
              return a.account - b.account;
            }
            return 0;
          });
          
          createAndLoadDemoData(master_items, master_itemGroups, master_accounts, master_accountTypes, data_flow, data_balance, params);
          onOK();
        })
        .catch(reason => console.error(reason))
      ;
    }
  
    return (
      <Fragment>
        <DialogTitle id="scroll-dialog-title">{t<string>("generating")}</DialogTitle>
        <DialogContent classes={{root: classes.dialogContentRoot}} style={{padding: "10px 40px"}}>
          <div>
            <LinearProgress variant="determinate" value={progress}/>
            <br/>
            {`${t<string>("processing")}: ${processing.y}/${processing.m.toString().padStart(2, "0")}`}
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => this.setState({scene: "customize_employee_asset"})} color="primary">
            {t<string>("cancel")}
          </Button>
        </DialogActions>
      </Fragment>
    );
  }
  
  public getTextField(id: keyof U.GeneratorParams, label: string, defaultValue?: number|null, option?: IParamOption){
    const {classes, i18n, lang} = this.props;
    const {start_debt, editingValue, editingId} = this.state;
    const t = i18n.getFixedT(lang, "Demo");
  
    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      this.setState({editingValue: e.target.value});
    };
  
    let formatter;
    let min: number | undefined;
    let max: number | undefined;
    let className;
    let title;
    let key;
    if(option){
      ({formatter, min, max, className, title, key} = option);
    }
    this.paramOptions[id] = option;
    
    let value = defaultValue || 0;
    const stateValue = this.state[id];
    if(typeof(stateValue) === "number"){
      value = stateValue;
    }
    
    let input;
    if(["debt_interest", "debt_period", "spending_debtBonusChargeRate"].includes(id) && start_debt === 0){
      input = (
        <Input
          id={id}
          value={formatter ? formatter(value) : value}
          disabled={true}
        />
      );
    }
    else if(id === "fixedAssetUsefulLives" && this.state.autoFixedAssetUsefulLives){
      input = (
        <Input
          id={id}
          value={formatter ? formatter(value) : value}
          disabled={true}
        />
      );
    }
    else if(editingId === id){
      input = (
        <Input
          id={id}
          value={editingValue === null ? undefined : editingValue}
          onChange={handleChange}
          onClick={e => e.stopPropagation()}
          onBlur={() => this.onFinishEditingParams()}
          onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => {
            if(e.key === "Enter"){
              this.onFinishEditingParams();
              return;
            }
            if(["ArrowUp", "Up", "ArrowDown", "Down"].includes(e.key)){
              let v = (+(e.target as HTMLInputElement).value); // To number
    
              if(e.key === "ArrowUp" || e.key === "Up"){
                if(["startY", "startM"].includes(id)){
                  v += 1;
                }
                else if(v.toString().length < 2){
                  v += 1;
                }
                else{
                  v += 10 ** Math.floor(Math.log10(Math.abs(v) || 1) - 1);
                }
              }
              else if(e.key === "ArrowDown" || e.key === "Down"){
                if(["startY", "startM"].includes(id)){
                  v -= 1;
                }
                else if(v.toString().length < 2){
                  v -= 1;
                }
                else{
                  v -= 10 ** Math.floor(Math.log10(Math.abs(v) || 1) - 1);
                }
              }
    
              if(typeof(min) === "number" && (v < min || isNaN(v))){
                return;
              }
              if(typeof(max) === "number" && (v > max || isNaN(v))){
                return;
              }
    
              this.setState({editingValue: v.toString()});
            }
          }}
        />
      );
    }
    else{
      let inputValue;
      let editingValue2: string = "";
      
      if(typeof(value) === "number"){
        inputValue = formatter ? formatter(value) : value;
        editingValue2 = value.toString();
      }
      else{
        inputValue = t<string>("unknown");
        editingValue2 = "";
      }
      
      input = (
        <Input
          id={id}
          value={inputValue}
          onClick={e => {
            e.stopPropagation();
            this.setState({editingId: id, editingValue: editingValue2});
          }}
          onFocus={e => {
            e.stopPropagation();
            this.setState({editingId: id, editingValue: editingValue2});
          }}
          readOnly={true}
        />
      );
    }
    
    return (
      <FormControl className={classNames(classes.textField, className)} title={title} key={key}>
        <InputLabel htmlFor={id}>{label}</InputLabel>
        {input}
      </FormControl>
    );
  }
  
  public onClick(){
    if(this.state.editingId){
      this.onFinishEditingParams();
    }
  }
}





const styles: StyleRulesCallback = (theme) => ({
  dialogTitle: {
    textAlign: "center",
  },
  dialogRoot: {
    "display": "flex",
    "alignItems": "center",
    "justifyContent": "center",
    "& *": {
      boxSizing: "border-box",
    },
  },
  dialogPaper: {
    maxWidth: 1100,
    boxSizing: "border-box",
  },
  dialogContentRoot: {
    boxSizing: "border-box",
    flex: "1 1 auto",
    padding: "0 24px",
    overflowY: "auto",
  },
  dataCreateSelection: {
    "borderRadius": 4,
    "padding": 15,
    "width": "100%",
    "display": "flex",
    "flexDirection": "column",
    "alignItems": "center",
    "transition": "all ease .3s",
    "cursor": "pointer",
    "border": "1px solid rgba(33,33,33,.2)",
    "&:hover": {
      backgroundColor: theme.palette.secondary.main,
      color: theme.palette.secondary.contrastText,
    },
  },
  textField: {
    margin: theme.spacing.unit,
    width: 170,
  },
  textFieldShort: {
    width: 140,
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: "33.33%",
    flexShrink: 0,
  },
  paperRoot: {
    "padding": theme.spacing.unit,
    "& + div": {
      marginTop: theme.spacing.unit * 2,
    },
  },
  select: {
    height: "auto",
  },
  buttonLabel: {
    whiteSpace: "nowrap",
  },
});

let _Component: React.ComponentType<any> = DemoDataDialog;
_Component = withTranslation("Auth")(_Component);
_Component = withStyles(styles, {withTheme: true})(_Component);

const ComponentWithHoc = _Component as React.ComponentType<Omit<IDemoDataDialogProps, TReservedHocProps>>;



const mapStateToProps = (rootReduxState: RootState) => {
  const {lang} = rootReduxState.App;
  const {
    currencyId,
    currencies,
  } = rootReduxState.Finance;
  
  return {
    lang,
    currencyId,
    currencies,
  };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<RootState, undefined, A.RootActions>) => {
  return {
    createAndLoadDemoData: (
      items: R.ItemMaster[],
      itemGroups: R.ItemGroupMaster[],
      accounts: R.AccountMaster[],
      accountTypes: R.AccountTypeMaster[],
      flow: R.ActiveFlowData[],
      balance: R.ActiveBalanceData[],
      params: U.GeneratorParams,
    ) => dispatch(__createAndLoadDemoData(items, itemGroups, accounts, accountTypes, flow, balance, params)),
  };
};

const LoginDialogContainer = connect(mapStateToProps, mapDispatchToProps)(ComponentWithHoc);

export default LoginDialogContainer;
