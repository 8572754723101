import React from "react";
import Loader from "react-spinners/FadeLoader";
import {withTheme} from "@material-ui/core/styles";
import {CustomTheme} from "../../util/theme";
import {Omit, TReservedHocProps} from "../../util/type";

interface IFallbackProps {
  theme: CustomTheme;
}

class Fallback extends React.Component<IFallbackProps> {
  public render(){
    const {theme} = this.props;
    
    return (
      <div
        style={{
          position: "relative",
          height: "100%",
          width: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <div>
          <span style={{color: theme.palette.text.primary}}>
            Loading ...
          </span>
          <Loader
            sizeUnit="px"
            color={theme.palette.primary.main}
            height={15}
            width={5}
            margin="2px"
            radius={2}
            loading={true}
          />
        </div>
      </div>
    );
  }
}



let _Component: React.ComponentType<any> = Fallback;
_Component = withTheme()(_Component);

export default _Component as React.ComponentType<Omit<IFallbackProps, TReservedHocProps>>;
