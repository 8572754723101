export * from "./app";
export * from "../../core/actions/types/finance";
export * from "../../core/actions/types/finance/input";
export * from "../../core/actions/types/finance/planning";

import {AppActions} from "./app";
import {
  FinanceIndexActions,
  FinanceInputActions,
  FinancePlanningActions,
} from "../../core/actions/types";

export type RootActions = AppActions |
  FinanceIndexActions |
  FinanceInputActions |
  FinancePlanningActions
;

export * from "./constant";
