import React from "react";
import withStyles, {ClassNameMap, StyleRules} from "@material-ui/core/styles/withStyles";
import classNames from "classnames";
import {Omit, TWithStyleProps} from "../../util/type";

interface IInitialLoaderProps {
  classes: ClassNameMap;
  dismiss?: boolean;
  onDismissed?: () => void;
}

const transitionDuration = .8; // In seconds

class InitialLoader extends React.Component<IInitialLoaderProps> {
  public componentDidUpdate(prevProps: Readonly<IInitialLoaderProps>, prevState: Readonly<{}>, snapshot?: any): void {
    const {dismiss, onDismissed} = this.props;
    
    if(dismiss && typeof(onDismissed) === "function"){
      window.setTimeout(() => {
        onDismissed();
      }, transitionDuration * 1000);
    }
  }
  
  public render(){
    const {classes, dismiss} = this.props;
    
    return (
      <div className={classNames(classes.root, dismiss && classes.dismiss)}>
        <div style={{fontSize: "2rem"}}>
          OpenFP
        </div>
        <div>
          <img src={`${window.location.origin}/static/images/initialLoader.svg`} alt="Loading ..." />
        </div>
      </div>
    );
  }
}

const styles: StyleRules = {
  root: {
    zIndex: 9999,
    opacity: 1,
    transition: `all ${transitionDuration}s ease`,
    position: "fixed",
    top: 0,
    bottom: 0,
    right: 0,
    left: 0,
    backgroundColor: "#fff",
    color: "#333",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  dismiss: {
    opacity: 0,
  },
};

let _Component: React.ComponentType<any> = InitialLoader;
_Component = withStyles(styles)(_Component);

export default _Component as React.ComponentType<Omit<IInitialLoaderProps, TWithStyleProps>>;
