import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import en from "./locales/en.json";
import ja from "./locales/ja.json";

(async () => {
  i18n.languages = ["en", "ja"];
  
  await i18n
    .use(initReactI18next)
    .init({
      resources: {...{en}, ...{ja}}, // Object.assign({}, {en}, {ja}),
      fallbackLng: "en",
      debug: false,
      
      interpolation: {
        escapeValue: false, // not needed for react!!
      },
      
      defaultNS: "general",
      
      // react i18next special options (optional)
      react: {
        wait: true,
        bindI18n: "languageChanged loaded",
        bindStore: "added removed",
        nsMode: "default",
      },
    });
})();

export default i18n;
