import * as S from "../reducers/Finance/state";
import {
  ACCOUNT_TYPE__CASH,
  ACCOUNT_TYPE__LOAN,
  ITEM__START_AMOUNT,
  ITEM__UNACCOUNTED,
  ITEM_GROUP__GENERAL,
  ITEM_GROUP__UNCOUNTING,
} from "./constants";

type IsFixedItemFunc = (item: S.ItemMaster) => boolean;
/**
 * -1: Unaccounted
 * -2: Start amount
 *
 * @param {Object} item
 * @returns {boolean}
 */
export const isFixedItem: IsFixedItemFunc = (item) => {
  return [
    ITEM__UNACCOUNTED,
    ITEM__START_AMOUNT,
  ].includes(item.id);
};



type IsFixedItemGroupFunc = (itemGroup: S.ItemGroupMaster) => boolean;
/**
 * -1: Uncounting
 * -2: General
 *
 * @param {Object} itemGroup
 * @returns {boolean}
 */
export const isFixedItemGroup: IsFixedItemGroupFunc = (itemGroup) => {
  return [
    ITEM_GROUP__UNCOUNTING,
    ITEM_GROUP__GENERAL,
  ].includes(itemGroup.id);
};



type IsFixedAccountTypeFunc = (accountType: S.AccountTypeMaster) => boolean;
/**
 * -1: cash
 * -2: loan
 *
 * @param {Object} accountType
 * @returns {boolean}
 */
export const isFixedAccountType: IsFixedAccountTypeFunc = (accountType) => {
  return [
    ACCOUNT_TYPE__CASH,
    ACCOUNT_TYPE__LOAN,
  ].includes(accountType.id);
};



type MergeAccountsFunc = (account1: ReadonlyArray<S.AccountMaster>, account2: ReadonlyArray<S.AccountMaster>) => S.AccountMaster[];
/**
 * @param {Array<object>} account1
 * @param {Array<object>} account2
 * @returns {Array<object>}
 */
export const mergeAccounts: MergeAccountsFunc = (account1, account2) => {
  let merged = account1.concat(account2);
  merged.reverse();
  merged = merged.filter((account, i) => {
    return merged.findIndex(a => a.id === account.id) === i;
  });
  
  merged.reverse();
  return merged;
};



type MergeAccountTypesFunc = (
  accountType1: ReadonlyArray<S.AccountTypeMaster>,
  accountType2: ReadonlyArray<S.AccountTypeMaster>,
) => S.AccountTypeMaster[];
/**
 * @param {Array<object>} accountType1
 * @param {Array<object>} accountType2
 * @returns {Array<object>}
 */
export const mergeAccountTypes: MergeAccountTypesFunc = (accountType1, accountType2) => {
  let merged = accountType1.concat(accountType2);
  merged = merged.reverse();
  merged = merged.filter((accountType, i) => {
    return merged.findIndex(a => a.id === accountType.id) === i;
  });
  
  merged.reverse();
  return merged;
};



type GetFlowDataSortedByDateWithSortMapFunc =
  (flow: ReadonlyArray<S.FlowData>) => {
    sorted_flows: S.FlowData[],
    mapToSortedIndices: number[],
    mapToOriginalIndices: number[],
  };
/**
 * @param {Array.<Object>} flow
 * @returns {{sorted_flows: Array.<Object>, mapToSortedIndex: Array.<number>, mapToOriginalIndex: Array.<number>}}
 */
export const getFlowDataSortedByDateWithSortMap: GetFlowDataSortedByDateWithSortMapFunc = (flow) => {
  const flowWithIndex = flow.map((f, i) => {
    return {...f, index: i}; // Create shallow copy for not affecting original object
  });
  
  flowWithIndex.sort((a, b) => {
    if(a.date === null || b.date === null){
      if(a.date === null && b.date !== null){
        return 1;
      }
      else if(a.date !== null && b.date === null){
        return -1;
      }
      return 0;
    }
    
    if(a.date > b.date){
      return -1;
    }
    if(a.date < b.date){
      return +1;
    }
    
    if(a.account === null || b.account === null){
      if(a.account === null && b.account !== null){
        return 1;
      }
      else if(a.account !== null && b.account === null){
        return -1;
      }
      return 0;
    }
    
    if(a.account > b.account){
      return -1;
    }
    if(a.account < b.account){
      return +1;
    }
    
    if(a.item === null || b.item === null){
      if(a.item === null && b.item !== null){
        return 1;
      }
      else if(a.item !== null && b.item === null){
        return -1;
      }
      return 0;
    }
    
    if(a.item === -1 && b.item !== -1){
      return -1;
    }
    if(a.item !== -1 && b.item === -1){
      return +1;
    }
    if(a.item > b.item){
      return -1;
    }
    if(a.item < b.item){
      return +1;
    }
    return 0;
  });
  
  const mapToOriginalIndices = flowWithIndex.map(f => f.index);
  const mapToSortedIndices = mapToOriginalIndices
    .map((f, i) => [i, f])
    .sort((a, b) => {
      return a[1] - b[1];
    })
    .map(f => f[0])
  ;
  
  return {
    sorted_flows: flowWithIndex,
    mapToSortedIndices,
    mapToOriginalIndices,
  };
};



type GetSortedFlowDataFunc = <T extends S.FlowData|S.ActiveFlowData = S.FlowData>(
  flow: ReadonlyArray<T>,
  option?: {readonly items: S.ItemMaster[], readonly accounts?: S.AccountMaster[]},
) => T[];

/**
 * @param {Array.<Object>} flow
 * @param {Object=} option
 * @return {Array.<Object>}
 */
export const getSortedFlowData: GetSortedFlowDataFunc = (flow, option) => {
  const sortedFlow = [...flow];
  
  if(!option || (!option.items && !option.accounts)){
    sortedFlow.sort((a, b) => {
      if(a.date === null || b.date === null){
        if(a.date === null && b.date !== null){
          return 1;
        }
        else if(a.date !== null && b.date === null){
          return -1;
        }
      }
      else{
        if(a.date > b.date){
          return -1;
        }
        else if(a.date < b.date){
          return +1;
        }
      }
      
      if(a.item === -1 && b.item !== -1){
        return -1;
      }
      else if(a.item !== -1 && b.item === -1){
        return +1;
      }
      return 0;
    });
  
    return sortedFlow;
  }
  else{
    const {items, accounts} = option;
    
    if(accounts){
      sortedFlow.sort((a, b) => {
        if(a.date === null || b.date === null){
          if(a.date === null && b.date !== null){
            return 1;
          }
          else if(a.date !== null && b.date === null){
            return -1;
          }
        }
        else{
          if(a.date > b.date){
            return -1;
          }
          else if(a.date < b.date){
            return +1;
          }
        }
  
        const a_AccountOrder = (accounts).find(ac => ac.id === a.account);
        const b_AccountOrder = (accounts).find(ac => ac.id === b.account);
        if(!a_AccountOrder || !b_AccountOrder){
          if(!a_AccountOrder && b_AccountOrder){
            return 1;
          }
          else if(a_AccountOrder && !b_AccountOrder){
            return -1;
          }
        }
        else{
          if(a_AccountOrder.order < b_AccountOrder.order){
            return +1;
          }
          if(a_AccountOrder.order > b_AccountOrder.order){
            return -1;
          }
        }
        
        if(!a.item === null || b.item === null){
          if(a.item == null && b.item !== null){
            return 1;
          }
          else if(a.item !== null && b.item === null){
            return -1;
          }
        }
        else{
          if(a.item === -1 && b.item !== -1){
            return -1;
          }
          if(a.item !== -1 && b.item === -1){
            return +1;
          }
  
          const a_ItemOrder = items.find(i => i.id === a.item);
          const b_ItemOrder = items.find(i => i.id === b.item);
          if(!a_ItemOrder || !b_ItemOrder){
            if(!a_ItemOrder && b_ItemOrder){
              return 1;
            }
            else if(a_ItemOrder && !b_ItemOrder){
              return -1;
            }
          }
          else{
            if(a_ItemOrder.order < b_ItemOrder.order){
              return +1;
            }
            if(a_ItemOrder.order > b_ItemOrder.order){
              return -1;
            }
          }
        }
  
        if(a.value === null || b.value === null){
          if(a.value === null && b.value !== null){
            return 1;
          }
          else if(a.value !== null && b.value === null){
            return -1;
          }
        }
        else{
          if(a.value < b.value){
            return +1;
          }
          if(a.value > b.value){
            return -1;
          }
        }
  
        if(a.notes === null || b.notes === null){
          if(a.notes === null && b.notes !== null){
            return 1;
          }
          else if(a.notes !== null && b.notes === null){
            return -1;
          }
        }
        else{
          return a.notes > b.notes ? -1 : 1;
        }
        
        return 0;
      });
      
      return sortedFlow;
    }
    else if(items){
      sortedFlow.sort((a, b) => {
        if(a.date === null || b.date === null){
          if(a.date === null && b.date !== null){
            return  1;
          }
          else if(a.date !== null && b.date === null){
            return  -1;
          }
        }
        else{
          if(a.date > b.date){
            return  -1;
          }
          if(a.date < b.date){
            return  +1;
          }
        }
  
        if(a.item === null || b.item === null){
          if(a.item === null && b.item !== null){
            return  1;
          }
          else if(a.item !== null && b.item === null){
            return  -1;
          }
        }
        else{
          if(a.item === -1 && b.item !== -1){
            return  -1;
          }
          if(a.item !== -1 && b.item === -1){
            return  +1;
          }
    
          const a_order = items.find(i => i.id === a.item);
          const b_order = items.find(i => i.id === b.item);
          if(!a_order || !b_order){
            if(!a_order && b_order){
              return  1;
            }
            else if(a_order && !b_order){
              return  -1;
            }
          }
          else{
            if(a_order.order < b_order.order){
              return  +1;
            }
            if(a_order.order > b_order.order){
              return  -1;
            }
          }
        }
  
        if(a.value === null || b.value === null){
          if(a.value === null && b.value !== null){
            return  1;
          }
          else if(a.value !== null && b.value === null){
            return  -1;
          }
        }
        else{
          if(a.value < b.value){
            return  +1;
          }
          if(a.value > b.value){
            return  -1;
          }
        }
  
        if(a.notes === null || b.notes === null){
          if(a.notes === null && b.notes !== null){
            return  1;
          }
          else if(a.notes !== null && b.notes === null){
            return  -1;
          }
        }
        else{
          return a.notes > b.notes ? -1 : 1;
        }
  
        return 0;
      });
  
      return sortedFlow;
    }
  }
  
  return sortedFlow;
};



type GetSortedBalanceData = <T extends S.BalanceData|S.ActiveBalanceData = S.BalanceData> (
  balance: ReadonlyArray<T>,
  option?: {
    readonly accounts?: S.AccountMaster[],
  },
) => T[];
/**
 * @param {Array.<Object>} balance
 * @param {Object=} option
 * @return {Array.<Object>}
 */
export const getSortedBalanceData: GetSortedBalanceData = (balance, option) => {
  const sortedBalance = [...balance];
  
  if(!option || !option.accounts){
    sortedBalance.sort((a, b) => {
      if(a.date === null || b.date === null){
        if(a.date === null && b.date !== null){
          {
            return 1;
          }
        }
        else if(a.date !== null && b.date === null){
          return -1;
        }
        {
          return 0;
        }
      }
  
      if(a.date > b.date){
        return -1;
      }
      if(a.date < b.date){
        return +1;
      }
      {
        return 0;
      }
    });
  
    {
      return sortedBalance;
    }
  }
  else{
    const {accounts} = option;
  
    sortedBalance.sort((a, b) => {
      if(a.date === null || b.date === null){
        if(a.date === null && b.date !== null){
          return 1;
        }
        else if(a.date !== null && b.date === null){
          return -1;
        }
      }
      else{
        if(a.date > b.date){
          return -1;
        }
        if(a.date < b.date){
          return +1;
        }
      }
    
      if(a.account === null || b.account === null){
        if(a.account === null && b.account !== null){
          return 1;
        }
        if(a.account !== null && b.account === null){
          return -1;
        }
      }
      else{
        const a_order = accounts.find(ac => ac.id === a.account);
        const b_order = accounts.find(ac => ac.id === b.account);
      
        if(!a_order || !b_order){
          if(!a_order && b_order){
            return +1;
          }
          if(a_order && !b_order){
            return -1;
          }
        }
        else{
          if(a_order.order < b_order.order){
            return +1;
          }
          if(a_order.order > b_order.order){
            return -1;
          }
        }
      }
    
      {
        return 0;
      }
    });
  
    return sortedBalance;
  }
};



type IsBalanceActiveFunc = (balance: unknown) => balance is S.ActiveBalanceData;
/**
 * @param {Object} balance
 * @returns {boolean}
 */
export const isBalanceActive: IsBalanceActiveFunc = (balance): balance is S.ActiveBalanceData => {
  if(!balance || typeof(balance) !== "object"){
    return false;
  }
  
  return typeof((balance as S.ActiveBalanceData).date) === "number"
    && typeof((balance as S.ActiveBalanceData).account) === "number"
    && typeof((balance as S.ActiveBalanceData).balance) === "number";
};



type IsFlowActiveFunc = (flow: unknown) => flow is S.ActiveFlowData;
/**
 * @param {Object} flow
 * @returns {boolean}
 */
export const isFlowActive: IsFlowActiveFunc = (flow): flow is S.ActiveFlowData => {
  if(!flow || typeof(flow) !== "object"){
    return false;
  }
  
  return typeof((flow as S.ActiveFlowData).date) === "number"
    && typeof((flow as S.ActiveFlowData).account) === "number"
    && typeof((flow as S.ActiveFlowData).value) === "number";
};



type GetFlowInPeriodFunc = <T extends S.FlowData|S.ActiveFlowData = S.FlowData>(
  sorted_flow: ReadonlyArray<T>,
  periodStart: S.CustomDate,
  periodEnd: S.CustomDate,
) => T[];
/**
 * @param {Array<object>} sorted_flow
 * @param {number} periodStart
 * @param {number} periodEnd
 * @returns {Array<object>}
 */
export const getFlowInPeriod: GetFlowInPeriodFunc = (sorted_flow, periodStart, periodEnd) => {
  let startIndex = null;
  let endIndex = null;
  
  for(let i=0;i<sorted_flow.length;i++){
    const {date} = sorted_flow[i];
    if(startIndex === null && typeof(date) === "number" && date <= periodEnd){
      startIndex = i;
    }
    if(endIndex === null && typeof(date) === "number" && date < periodStart){
      endIndex = i;
    }
    
    if(startIndex !== null && endIndex !== null){
      break;
    }
  }
  
  startIndex = startIndex === null ? sorted_flow.length - 1 : startIndex;
  endIndex = endIndex === null ? sorted_flow.length : endIndex;
  return sorted_flow.slice(startIndex, endIndex);
};



type GetRecentBalanceInTheAccountFunc = (
  sorted_balance: ReadonlyArray<S.BalanceData>,
  account: number,
  date: S.CustomDate,
) => {balance: number|null, date: S.CustomDate|null};
/**
 * Get the most recent account balance. The argument date is excluded from counting.
 *
 * @param {Array.<Object>} sorted_balance - Must be sorted by date desc
 * @param {number} account
 * @param {number} date
 * @returns {Object}
 */
export const getRecentBalanceInTheAccount: GetRecentBalanceInTheAccountFunc = (sorted_balance, account, date) => {
  let _balance = null;
  let _date = null;
  
  sorted_balance.some(b => {
    if(!isBalanceActive(b)){
      return false;
    }
    
    if(b.account === account && typeof(b.date) === "number" && b.date < date){
      _balance = b.balance;
      _date = b.date;
      return true;
    }
    
    return false;
  });
  
  return {balance: _balance, date: _date};
};



type GetSumOfFlowInPeriodFunc = (
  sorted_flow: ReadonlyArray<S.FlowData>,
  account: number,
  startDate?: S.CustomDate|null,
  endDate?: S.CustomDate|null,
) => number;
/**
 *
 * @param {Array.<Object>} sorted_flow - Must be sorted by date desc
 * @param {number} account
 * @param {number=} startDate
 * @param {number=} endDate
 * @returns {number}
 */
export const getSumOfFlowInPeriod: GetSumOfFlowInPeriodFunc = (sorted_flow, account, startDate, endDate) => {
  const hasStartDate = typeof(startDate) === "number";
  const hasEndDate = typeof(endDate) === "number";
  
  if(!hasStartDate && !hasEndDate){
    let changeSumOfFlow = 0;
    
    sorted_flow.forEach(f => {
      if(isFlowActive(f) && f.account === account){
        changeSumOfFlow += f.value;
      }
    });
    
    return changeSumOfFlow;
  }
  else if(!hasStartDate && hasEndDate){
    let changeSumOfFlow = 0;
    
    for(let i=sorted_flow.length-1;i>=0;i--){
      const f = sorted_flow[i];
      
      if(typeof(f.date) === "number" && f.date > (endDate as number)){
        break;
      }
      
      if(isFlowActive(f) && f.account === account){
        changeSumOfFlow += f.value;
      }
    }
    
    return changeSumOfFlow;
  }
  else if(hasStartDate && !hasEndDate){
    let changeSumOfFlow = 0;
    
    for(let i = 0; i < sorted_flow.length; i++){
      const f = sorted_flow[i];
      
      if(typeof(f.date) === "number" && f.date < (startDate as number)){
        break;
      }
      
      if(isFlowActive(f) && f.account === account){
        changeSumOfFlow += f.value;
      }
    }
    
    return changeSumOfFlow;
  }
  else{
    let changeSumOfFlow = 0;
    
    sorted_flow.some(f => {
      if(!isFlowActive(f)){
        return false;
      }
      
      if(f.account === account && (startDate as number) <= f.date && f.date <= (endDate as number)){
        changeSumOfFlow += f.value;
      }
      else if(f.date < (startDate as number)){
        return true;
      }
      
      return false;
    });
    
    return changeSumOfFlow;
  }
};



type PopulateUnaccountedAssetFlowFunc = (
  sorted_flows: S.FlowData[],
  unaccounted_flow: S.UnaccountedFlowData,
) => S.ChangeRequest | null;
/**
 * Data change is in place.
 * @param {Array.<Object>} sorted_flows
 * @param {{date: number, account: number, value: number}} unaccounted_flow
 * @returns {{action: string, index: number, data: Object}|null}
 */
export const populateUnaccountedAssetFlow: PopulateUnaccountedAssetFlowFunc = (sorted_flows, unaccounted_flow) => {
  const {date, account, value} = unaccounted_flow;
  
  let index = null;
  let index2 = null;
  
  const round = (val: number|null|undefined) => typeof val === "number" ? Math.floor(Math.abs(val)) * (val < 0 ? -1 : 1) : null;
  const roundedValue = round(value);
  
  for(let i=0;i<sorted_flows.length;i++){
    const f = sorted_flows[i];
    
    if(typeof(f.date) !== "number" || f.date > date){
      continue;
    }
    
    if(f.account === account && f.date === date){
      if(f.item === ITEM__UNACCOUNTED){
        const modifiedValue = (f.value||0) + (roundedValue||0);
        
        if(roundedValue === null || modifiedValue === 0){
          const [removedData] = sorted_flows.splice(i, 1);
          return {action: "remove", index: i, data: removedData};
        }
        else{
          sorted_flows[i].value = modifiedValue;
          return {action: "edit", index: i, data: sorted_flows[i]};
        }
      }
      
      // Set the row index of the target date and the account we first see.
      if(index === null){
        index = i; // Maybe we will insert new Flow to this index
      }
    }
    else if(f.date === date){
      if(index2 === null){
        index2 = i; // Maybe we will insert new Flow to this index if index === null
      }
    }
    else if(f.date < date){
      if(roundedValue === null || roundedValue === 0){
        return null;
      }
      
      // Insert to the row followed by row with the same date and account
      if(index !== null){
        sorted_flows.splice(index, 0, unaccounted_flow);
        return {action: "insert", index, data: unaccounted_flow};
      }
      // Insert to the row followed by row with the same date
      else{
        index2 = index2 !== null ? index2 : i;
        sorted_flows.splice(index2, 0, unaccounted_flow);
        return {action: "insert", index: index2, data: unaccounted_flow};
      }
    }
  }
  
  if(roundedValue !== null && roundedValue !== 0){
    sorted_flows.push(unaccounted_flow);
    return {action: "insert", index: sorted_flows.length - 1, data: unaccounted_flow};
  }
  
  return null;
};


type RemoveUnaccountedAssetFlowFunc = (sorted_flows: S.FlowData[], date: S.CustomDate, account: number) => void;
/**
 * @param {Array.<Object>} sorted_flows
 * @param {number} date
 * @param {number} account
 */
export const removeUnaccountedAssetFlow: RemoveUnaccountedAssetFlowFunc = (sorted_flows, date, account) => {
  for(let i=0;i<sorted_flows.length;i++){
    const f = sorted_flows[i];
    if(f.date !== null && f.date < date){
      break;
    }
    
    if(f.date === date && f.account === account){
      if(f.item === ITEM__UNACCOUNTED){
        sorted_flows.splice(i, 1);
        i--;
        break;
      }
    }
  }
};



type GetDiffForBalanceChangeAndFlowSumFunc = (
  sorted_balances: ReadonlyArray<S.BalanceData>,
  sorted_flows: ReadonlyArray<S.FlowData>,
  balance: S.ActiveBalanceData,
) => number;
/**
 * @param {Array.<Object>} sorted_balances
 * @param {Array.<Object>} sorted_flows
 * @param {Object} balance
 * @returns {number}
 */
export const getDiffForBalanceChangeAndFlowSum: GetDiffForBalanceChangeAndFlowSumFunc = (sorted_balances, sorted_flows, balance) => {
  const recentBalanceData = getRecentBalanceInTheAccount(sorted_balances, balance.account, balance.date);
  const lastConfirmedBalance = typeof(recentBalanceData.balance) === "number" ? recentBalanceData.balance : 0;
  const changeOfBalance = balance.balance - lastConfirmedBalance;
  
  // Start date is next day of last confirmed balance change
  let sumStartDate;
  if(typeof(recentBalanceData.date) === "number"){
    const date = getDateFromCustomDate(recentBalanceData.date);
    date.setDate(date.getDate() + 1);
    sumStartDate = getCustomDateFromDate(date);
  }
  else{
    sumStartDate = 0;
  }
  
  const changeSumOfFlow = getSumOfFlowInPeriod(sorted_flows, balance.account, sumStartDate, balance.date);
  
  return changeOfBalance - changeSumOfFlow;
};



type FindIndexOfNextUnaccountedItemFunc = (sorted_flows: ReadonlyArray<S.FlowData>, date: S.CustomDate, account: number) => number;
/**
 * @param {Array.<Object>} sorted_flows
 * @param {number} date
 * @param {number} account
 * @returns {number}
 */
export const findIndexOfNextUnaccountedItem: FindIndexOfNextUnaccountedItemFunc = (sorted_flows, date, account) => {
  let index = -1;
  
  for(let i=0;i<sorted_flows.length;i++){
    const f = sorted_flows[i];
    
    if(f.date === null){
      continue;
    }
    
    if(f.date <= date){
      break;
    }
    
    if(f.account === account && f.date > date && f.item === -1){
      index = i;
    }
  }
  
  return index;
};



type FindNextUnaccountedItemFunc = (
  sorted_flows: ReadonlyArray<S.FlowData>,
  date: S.CustomDate,
  account: number,
) => S.UnaccountedFlowData | null;
/**
 * @param {Array<Object>} sorted_flows
 * @param {number} date
 * @param {number} account
 * @returns {Object}
 */
export const findNextUnaccountedItem: FindNextUnaccountedItemFunc = (sorted_flows, date, account) => {
  let flow = null;
  
  for(let i=0;i<sorted_flows.length;i++){
    const f = sorted_flows[i];
    
    if(f.date === null){
      continue;
    }
    
    if(f.date <= date){
      break;
    }
    
    if(f.account === account && f.date > date && f.item === ITEM__UNACCOUNTED){
      flow = f;
    }
  }
  
  return flow as S.UnaccountedFlowData;
};



type FindBalanceAtDayFunc = (
  sorted_balances: ReadonlyArray<S.BalanceData>,
  date: S.CustomDate,
  account: number,
) => S.ActiveBalanceData;
/**
 * @param {Array.<Object>} sorted_balances
 * @param {number} date
 * @param {number} account
 * @returns {*}
 */
export const findBalanceAtDay: FindBalanceAtDayFunc = (sorted_balances, date, account) => {
  let balance: S.ActiveBalanceData = {date, account, balance: 0, note: null};
  
  for(let i=sorted_balances.length-1;i>=0;i--){
    const b = sorted_balances[i];
    
    if(!isBalanceActive(b)){
      continue;
    }
    
    if(b.date > date){
      return balance;
    }
    else if(b.account === account){
      balance = b;
      
      if(b.date === date){
        return balance;
      }
    }
  }
  
  return balance;
};



type ReCalculateNextUnaccountedItemFunc = (
  sorted_flows: S.FlowData[],
  sorted_balances: ReadonlyArray<S.BalanceData>,
  date: S.CustomDate,
  account: number,
) => void;
/**
 * @param {Array.<Object>} sorted_flows
 * @param {Array.<Object>} sorted_balances
 * @param {number} date
 * @param {number} account
 */
export const reCalculateNextUnaccountedItem: ReCalculateNextUnaccountedItemFunc = (sorted_flows, sorted_balances, date, account) => {
  // Search for and update next unaccounted item
  const flow_next = findNextUnaccountedItem(sorted_flows, date, account);
  
  if(flow_next !== null){
    const balance_next = findBalanceAtDay(sorted_balances, flow_next.date, flow_next.account);
    const diff = getDiffForBalanceChangeAndFlowSum(sorted_balances, sorted_flows, balance_next);
    
    if(diff !== 0){
      const new_flow = {date: balance_next.date, account: balance_next.account, item: -1, value: diff, notes: ""};
      populateUnaccountedAssetFlow(sorted_flows, new_flow);
    }
  }
};



type InsertBalanceAtFunc = (
  index: number,
  balance: S.BalanceData,
  allBalances: S.BalanceData[],
  sorted_flows: S.FlowData[],
  doRebalance: boolean,
) => void;
/**
 * @param {number} index
 * @param {Object} balance
 * @param {Array.<Object>} allBalances
 * @param {Array.<Object>} sorted_flows
 * @param {boolean} doRebalance
 */
export const insertBalanceAt: InsertBalanceAtFunc = (index, balance, allBalances, sorted_flows, doRebalance) => {
  // Do not make duplicate balance
  for(let i=0;i<allBalances.length;i++){
    const b = allBalances[i];
    if(b.date === balance.date && b.account === balance.account && balance.account !== null)
    {
      return;
    }
  }
  
  allBalances.splice(index, 0, balance);
  
  if(!doRebalance){
    return;
  }
  
  if(!isBalanceActive(balance))
  {
    return;
  }
  
  const sorted_balances = getSortedBalanceData(allBalances);
  
  addUnaccountedItem(sorted_flows, sorted_balances, balance);
};



type RemoveBalanceAtFunc = (
  index: number,
  allBalances: S.BalanceData[],
  sorted_flows: S.FlowData[],
  doRebalance: boolean,
) => void;
/**
 * @param {number} index
 * @param {Array.<Object>} allBalances
 * @param {Array.<Object>} sorted_flows
 * @param {boolean} doRebalance
 */
export const removeBalanceAt: RemoveBalanceAtFunc = (index, allBalances, sorted_flows, doRebalance) => {
  const [removedBalance] = allBalances.splice(index, 1);
  
  if(!doRebalance){
    return;
  }
  
  if(!isBalanceActive(removedBalance)){
    return;
  }
  
  const sorted_balances = getSortedBalanceData(allBalances);
  
  removeUnaccountedItem(sorted_flows, sorted_balances, removedBalance.date, removedBalance.account);
};



type AddUnaccountedItemFunc = (
  sorted_flows: S.FlowData[],
  sorted_balances: S.BalanceData[],
  balance: S.ActiveBalanceData,
) => void;
/**
 * @param {Array.<Object>} sorted_flows
 * @param {Array.<Object>} sorted_balances
 * @param {Object} balance
 */
export const addUnaccountedItem: AddUnaccountedItemFunc = (sorted_flows, sorted_balances, balance) => {
  const diff = getDiffForBalanceChangeAndFlowSum(sorted_balances, sorted_flows, balance);
  if(diff !== 0){
    const new_flow = {date: balance.date, account: balance.account, item: -1, value: diff, notes: ""};
    populateUnaccountedAssetFlow(sorted_flows, new_flow);
  }
  
  reCalculateNextUnaccountedItem(sorted_flows, sorted_balances, balance.date, balance.account);
};



type RemoveUnaccountedItemFunc = (
  sorted_flows: S.FlowData[],
  sorted_balances: S.BalanceData[],
  date: S.CustomDate,
  account: number,
) => void;
/**
 * @param {Array.<Object>} sorted_flows
 * @param {Array.<Object>} sorted_balances
 * @param {number} date
 * @param {number} account
 */
export const removeUnaccountedItem: RemoveUnaccountedItemFunc = (sorted_flows, sorted_balances, date, account) => {
  removeUnaccountedAssetFlow(sorted_flows, date, account);
  reCalculateNextUnaccountedItem(sorted_flows, sorted_balances, date, account);
};



type ReBalanceAllFlowFunc = (
  sorted_flows: S.FlowData[],
  sorted_balances: S.BalanceData[],
) => void;
/**
 * Changes will be made in-place.
 * @param {Array.<Object>} sorted_flows
 * @param {Array.<Object>} sorted_balances
 */
export const reBalanceAllFlow: ReBalanceAllFlowFunc = (sorted_flows, sorted_balances) => {
  // Clear all unaccounted flow first
  for(let i=0;i<sorted_flows.length;i++){
    if(sorted_flows[i].item === ITEM__UNACCOUNTED){
      sorted_flows.splice(i, 1);
      i--;
    }
  }
  
  for(let i=sorted_balances.length-1;i>=0;i--){
    const b = sorted_balances[i];
    
    if(!isBalanceActive(b)){
      continue;
    }
    
    // @todo I don't think the code below is necessary.
    // removeUnaccountedAssetFlow(sorted_flows, b.date, b.account);
    
    const diff = getDiffForBalanceChangeAndFlowSum(sorted_balances, sorted_flows, b);
    if(diff !== 0){
      const new_flow = {date: b.date, account: b.account, item: ITEM__UNACCOUNTED, value: diff, notes: ""};
      populateUnaccountedAssetFlow(sorted_flows, new_flow);
    }
  }
};



type ReorderIndexMapsFunc = (
  mapToSortedIndices: number[],
  mapToOriginalIndices: number[],
  originalIndexToDo: number,
  SortedIndexToDo?: number,
) => {mapToOriginalIndices: number[], mapToSortedIndices: number[]};
/**
 * @param {Array} mapToSortedIndices
 * @param {Array} mapToOriginalIndices
 * @param {number} deletingOriginalIndex
 * @param {number=} deletingSortedIndex
 */
export const reorderIndexMapsAfterDeleting: ReorderIndexMapsFunc =
  (mapToSortedIndices, mapToOriginalIndices, deletingOriginalIndex, deletingSortedIndex) => {
    if(typeof(deletingSortedIndex) !== "number"){
      deletingSortedIndex = mapToSortedIndices[deletingOriginalIndex];
    }
  
    mapToSortedIndices = mapToSortedIndices.map(sortedIndex => {
      if(sortedIndex > (deletingSortedIndex as number)){
        return sortedIndex - 1;
      }
      return sortedIndex;
    });
  
    mapToOriginalIndices = mapToOriginalIndices.map(originalIndex => {
      if(originalIndex > deletingOriginalIndex){
        return originalIndex - 1;
      }
      return originalIndex;
    });
  
    mapToOriginalIndices.splice(deletingSortedIndex, 1);
    mapToSortedIndices.splice(deletingOriginalIndex, 1);
  
    return {mapToOriginalIndices, mapToSortedIndices};
  };

/**
 * @param {Array} mapToSortedIndices
 * @param {Array} mapToOriginalIndices
 * @param {number} insertingOriginalIndex
 * @param {number=} insertingSortedIndex
 */
export const reorderIndexMapsAfterInserting: ReorderIndexMapsFunc =
  (mapToSortedIndices, mapToOriginalIndices, insertingOriginalIndex, insertingSortedIndex) => {
    if(typeof(insertingSortedIndex) !== "number"){
      insertingSortedIndex = mapToSortedIndices[insertingOriginalIndex];
    }
    
    mapToSortedIndices = mapToSortedIndices.map(sortedIndex => {
      if(sortedIndex >= (insertingSortedIndex as number)){
        return sortedIndex + 1;
      }
      return sortedIndex;
    });
    
    mapToOriginalIndices = mapToOriginalIndices.map(originalIndex => {
      if(originalIndex >= insertingOriginalIndex){
        return originalIndex + 1;
      }
      return originalIndex;
    });
    
    mapToSortedIndices.splice(insertingOriginalIndex, 0, insertingSortedIndex);
    mapToOriginalIndices.splice(insertingSortedIndex, 0, insertingOriginalIndex);
    
    return {mapToSortedIndices, mapToOriginalIndices};
  };



type ProvisionToUnsortedFlowDataFunc = (
  unsorted_flows: S.FlowData[],
  mapToSortedIndices: number[],
  mapToOriginalIndices: number[],
  sorted_flows: S.FlowData[],
  sorted_balances: S.BalanceData[],
  date: S.CustomDate,
  account: number,
) => {
  mapToOriginalIndices: number[],
  mapToSortedIndices: number[],
  change?: S.ChangeRequest,
};
/**
 * @param {Array.<Object>} unsorted_flows
 * @param {Array} mapToSortedIndices
 * @param {Array} mapToOriginalIndices
 * @param {Array.<Object>} sorted_flows
 * @param {Array.<Object>} sorted_balances
 * @param {number} date
 * @param {number} account
 * @returns {{mapToSortedIndices: Array, mapToOriginalIndices: Array, change: Object=}}
 */
export const provisionToUnsortedFlowData: ProvisionToUnsortedFlowDataFunc =
  (unsorted_flows, mapToSortedIndices, mapToOriginalIndices, sorted_flows, sorted_balances, date, account) => {
    const b = findBalanceAtDay(sorted_balances, date, account);
    if(!b){
      return {mapToSortedIndices, mapToOriginalIndices};
    }
    
    const diff = getDiffForBalanceChangeAndFlowSum(sorted_balances, sorted_flows, b);
  
    const new_flow = {date: b.date, account: b.account, item: -1, value: diff, notes: ""};
    const change = populateUnaccountedAssetFlow(sorted_flows, new_flow);
    
    if(!change){
      return {mapToSortedIndices, mapToOriginalIndices};
    }
    
    if(change.action === "insert"){
      const {date: d, account: act} = change.data;
      let indexToInsert = 0;
      for(let i=0;i<unsorted_flows.length;i++){
        const f = unsorted_flows[i];
        
        if(f.date === null){
          continue;
        }
        
        if(f.date < d){
          indexToInsert = i;
        }
        else if(f.date === d && f.account === act){
          indexToInsert = i;
        }
        else if(f.date > d){
          indexToInsert = i;
          break;
        }
      }
      
      unsorted_flows.splice(indexToInsert, 0, change.data);
      
      ({mapToSortedIndices, mapToOriginalIndices} = reorderIndexMapsAfterInserting(
        mapToSortedIndices,
        mapToOriginalIndices,
        indexToInsert,
        change.index,
      ));
    }
    else if(change.action === "edit"){
      const {value} = change.data;
      const index = mapToOriginalIndices[change.index];
      
      unsorted_flows[index].value = value;
    }
    else if(change.action === "remove"){
      const indexToRemove = mapToOriginalIndices[change.index];
      unsorted_flows.splice(indexToRemove, 1);
      ({mapToSortedIndices, mapToOriginalIndices} = reorderIndexMapsAfterDeleting(
        mapToSortedIndices,
        mapToOriginalIndices,
        indexToRemove,
        change.index,
      ));
    }
    
    return {mapToSortedIndices, mapToOriginalIndices, change};
  };



type ModifyFutureIndexInChangeListFunc = (
  changesToModify: S.ChangeRequest[],
  startIndex: number,
  mod: S.ChangeRequest | undefined,
  mapToOriginalIndices: number[],
) => void;
/**
 * @param {Array} changesToModify
 * @param {number} startIndex
 * @param {{action: string, index: number}} mod - i.e. {action: "insert", index: 3}
 * @param {Array} mapToOriginalIndices
 */
export const ModifyFutureIndexInChangeList: ModifyFutureIndexInChangeListFunc =
  (changesToModify, startIndex, mod, mapToOriginalIndices) => {
    if(!mod){
      return;
    }
    
    for(let i=startIndex;i<changesToModify.length;i++){
      if(mapToOriginalIndices[mod.index] > changesToModify[i].index){
        continue;
      }
      
      if(mod.action === "insert"){
        changesToModify[i].index++;
      }
      else if(mod.action === "remove"){
        changesToModify[i].index--;
      }
    }
  };



type GetAccountInfoFunc = (
  accountId: number,
  master_accounts: ReadonlyArray<S.AccountMaster>,
  master_accountTypes: ReadonlyArray<S.AccountTypeMaster>,
) => {
  accountName: string,
  accountOrder: number,
  accountTypeId: number,
  accountTypeName: string,
  accountTypeOrder: number,
  assetType: S.AccountTypeCategory,
} | null;
/**
 *
 * @param {Number} accountId
 * @param {Array<Object>} master_accounts
 * @param {Array<Object>} master_accountTypes
 * @returns {{accountName: string, accountOrder: Number, accountTypeId: Number,
 * accountTypeName: string, accountTypeOrder: Number, assetType: string}}
 */
export const getAccountInfo: GetAccountInfoFunc = (accountId, master_accounts, master_accountTypes) => {
  const account = master_accounts.find(a => a.id === accountId);
  if(!account){
    return null;
  }
  
  const accountType = master_accountTypes.find(at => at.id === account.type);
  if(!accountType){
    return null;
  }
  
  return {
    accountName: account.name,
    accountOrder: account.order,
    accountTypeId: accountType.id,
    accountTypeName: accountType.name,
    accountTypeOrder: accountType.order,
    assetType: accountType.type,
  };
};



type ParseCustomDateFunc = (customDate: S.CustomDate) => {y: number, m: number, d: number};
/**
 * @param {number} customDate - i.e. 20181231
 * @returns {{y: number, m: number, d: number}}
 */
export const parseCustomDate: ParseCustomDateFunc = (customDate) => {
  const y = Math.floor(customDate/10000);
  const remainder = customDate - y * 10000;
  const m = Math.floor(remainder/100);
  const d = remainder - m * 100;
  return {y, m, d};
};



type GetCustomDateDayFunc = (customDate: S.CustomDate) => number;
/**
 * @param {number} customDate
 * @returns {number}
 */
export const getCustomDateDay: GetCustomDateDayFunc = (customDate) => {
  const date = getDateFromCustomDate(customDate);
  return date.getDay();
};



type CreateCustomDateFunc = (year: number, month: number, day: number) => S.CustomDate;
/**
 * @param {number} year
 * @param {number} month
 * @param {number} day
 * @returns {number}
 */
export const createCustomDate: CreateCustomDateFunc = (year, month, day) => {
  return year*10000 + month*100 + day;
};



type GetDateFromCustomDateFunc = (customDate: S.CustomDate) => Date;
/**
 * @param {number} customDate - i.e. 20151231
 * @returns {Date}
 */
export const getDateFromCustomDate: GetDateFromCustomDateFunc = (customDate) => {
  const {y, m, d} = parseCustomDate(customDate);
  return new Date(y, m-1, d);
};



type GetCustomDateFromDateFunc = (date: Date) => S.CustomDate;
/**
 * @param {Date} date
 * @returns {number}
 */
export const getCustomDateFromDate: GetCustomDateFromDateFunc = (date) => {
  const year = date.getFullYear();
  const month = date.getMonth() + 1;
  const day = date.getDate();
  return year*10000 + month*100 + day;
};



type GetNowInCustomDateFunc = () => S.CustomDate;
/**
 * @returns {number}
 */
export const getNowInCustomDate: GetNowInCustomDateFunc = () => {
  if(process.env.REACT_APP_ENV === "development"){
    const date = Number(process.env.REACT_APP_DATE);
    if(!isNaN(date) && process.env.REACT_APP_DATE){
      return date;
    }
  }
  
  return getCustomDateFromDate(new Date());
};



type GetNowFunc = () => number;
/**
 * @returns {number}
 */
export const getNow: GetNowFunc = () => {
  if(process.env.REACT_APP_ENV === "development"){
    const date = Number(process.env.REACT_APP_DATE);
    if(!isNaN(date) && process.env.REACT_APP_DATE){
      return getDateFromCustomDate(date).getTime();
    }
  }
  
  return Date.now();
};



type GetCurrentDateFunc = () => Date;
/**
 * @returns {Date}
 */
export const getCurrentDate: GetCurrentDateFunc = () => {
  if(process.env.REACT_APP_ENV === "development"){
    const date = Number(process.env.REACT_APP_DATE);
    if(!isNaN(date) && process.env.REACT_APP_DATE){
      return getDateFromCustomDate(date);
    }
  }
  
  return new Date();
};



type CustomDateToUnixEpochFunc = (customDate: S.CustomDate) => number;
/**
 * @param {number} customDate
 * @returns {number}
 */
export const customDateToUnixEpoch: CustomDateToUnixEpochFunc = (customDate) => {
  const {y, m, d} = parseCustomDate(customDate);
  return new Date(y, m-1, d).getTime() / 1000;
};



type AddDaysToCustomDateFunc = (customDate: S.CustomDate, days: number) => S.CustomDate;
/**
 * @param {number} customDate
 * @param {number} days
 * @returns {number}
 */
export const addDaysToCustomDate: AddDaysToCustomDateFunc = (customDate, days) => {
  const date = getDateFromCustomDate(customDate);
  date.setDate(date.getDate() + days);
  return getCustomDateFromDate(date);
};



type AddMonthsToCustomDateFunc = (customDate: S.CustomDate, months: number) => S.CustomDate;
/**
 * @param {number} customDate
 * @param {number} months
 * @returns {number}
 */
export const addMonthsToCustomDate: AddMonthsToCustomDateFunc = (customDate, months) => {
  const date = getDateFromCustomDate(customDate);
  date.setMonth(date.getMonth() + months);
  return getCustomDateFromDate(date);
};



type GetDaysFunc = (customDateFrom: S.CustomDate, customDateTo: S.CustomDate) => number;
/**
 * @param {number} customDateFrom
 * @param {number} customDateTo
 * @returns {number}
 */
export const getDays: GetDaysFunc = (customDateFrom, customDateTo) => {
  const date1 = getDateFromCustomDate(customDateFrom);
  const date2 = getDateFromCustomDate(customDateTo);
  
  const elapsed = date2.getTime() - date1.getTime();
  return Math.floor(Math.abs(elapsed) / 86400000) * (elapsed >= 0 ? 1 : -1);
};



type GetMonthsFunc = (customDate1: S.CustomDate, customDate2: S.CustomDate) => number;
/**
 * @param {number} customDate1
 * @param {number} customDate2
 * @returns {number}
 */
export const getMonths: GetMonthsFunc = (customDate1, customDate2) => {
  const date1 = parseCustomDate(Math.min(customDate1, customDate2));
  const date2 = parseCustomDate(Math.max(customDate1, customDate2));
  
  let elapsedY = date2.y - date1.y;
  let elapsedM = date2.m - date1.m;
  if(elapsedM < 0){
    elapsedY -= 1;
    elapsedM = 12 + elapsedM;
  }
  return elapsedY*12 + elapsedM;
};



type CustomDateToDateStrFunc = (customDate: S.CustomDate, dateStringMapper: string[]) => string;
/**
 * @param {number} customDate
 * @param {Array} dateStringMapper
 * @returns {string|null}
 */
export const customDateToDateStr: CustomDateToDateStrFunc = (customDate, dateStringMapper) => {
  const date = getDateFromCustomDate(customDate);
  
  const year = date.getFullYear();
  const month = (date.getMonth() + 1).toString().padStart(2, "0");
  const days = date.getDate().toString().padStart(2, "0");
  const dayOfWeek = date.getDay();
  return `${year}/${month}/${days} (${dateStringMapper[dayOfWeek]})`;
};




type GetFlowChangeByDateAndAccountFunc = (
  sorted_data_flow: ReadonlyArray<S.FlowData>,
  startDate: S.CustomDate,
  endDate: S.CustomDate,
) => Array<{date: S.CustomDate, account: number, value: number}>;
/**
 * @param {Array} sorted_data_flow
 * @param {number} startDate
 * @param {number} endDate
 * @returns {Array<{date: number, account: number, value: number}>}
 */
export const getFlowChangeByDateAndAccount: GetFlowChangeByDateAndAccountFunc =
  (sorted_data_flow, startDate, endDate) => {
    let sliced_flow = sorted_data_flow.filter(f => {
      return isFlowActive(f);
    }) as S.ActiveFlowData[];
    
    if(typeof(startDate) === "number"){
      const index = sliced_flow.findIndex(f => f.date < startDate);
      sliced_flow = sliced_flow.slice(0, index);
    }
    if(typeof(endDate) === "number"){
      const index = sliced_flow.findIndex(f => f.date <= endDate);
      sliced_flow = sliced_flow.slice(index);
    }
  
    const changes: {[key: string]: number} = {};
    for(let i=0;i<sliced_flow.length;i++){
      const {date, account, value} = sliced_flow[i];
  
      const key = `${date}-${account}`;
      if(typeof(changes[key]) !== "number"){
        changes[key] = value;
      }
      else{
        changes[key] += value;
      }
    }
  
    return Object.keys(changes).reduce((acc: Array<{date: S.CustomDate, account: number, value: number}>, key) => {
      const splitKey = key.split("-");
      const value = changes[key];
      if(value === 0){
        return acc;
      }
  
      const date = +splitKey[0];
      const account = +splitKey[1];
      acc.push({date, account, value});
      return acc;
    }, []);
  };



type GetBalanceChangeByDateAndAccountFunc = (
  sorted_data_balance: S.BalanceData[],
  startDate: S.CustomDate,
  endDate: S.CustomDate,
  master_accounts: S.AccountMaster[],
) => Array<{date: S.CustomDate, account: number, value: number}>;
/**
 * @param {Array} sorted_data_balance
 * @param {number} startDate
 * @param {number} endDate
 * @param {Array} master_accounts
 * @returns {Array<{date: number, account: number, value: number}>}
 */
export const getBalanceChangeByDateAndAccount: GetBalanceChangeByDateAndAccountFunc = (
  sorted_data_balance,
  startDate,
  endDate,
  master_accounts,
) => {
  let filtered_balance = sorted_data_balance.filter(b => isBalanceActive(b)) as S.ActiveBalanceData[];
  
  if(typeof(startDate) === "number"){
    const index = filtered_balance.findIndex(b => b.date < startDate);
    filtered_balance = filtered_balance.slice(0, index);
  }
  if(typeof(endDate) === "number"){
    const index = filtered_balance.findIndex(b => b.date <= endDate);
    filtered_balance = filtered_balance.slice(index);
  }
  
  const latestBalanceAtAccount: {[key: string]: number} = {};
  master_accounts.forEach(account => {
    latestBalanceAtAccount[account.id] = findBalanceAtDay(sorted_data_balance, startDate, account.id).balance;
  });
  
  const changes: {[key: string]: number} = {};
  for(let i=filtered_balance.length-1;i>=0;i--){
    const {date, account, balance} = filtered_balance[i];
    const key = `${date}-${account}`;
    const latestBalance = (latestBalanceAtAccount[account] ? latestBalanceAtAccount[account] : 0) || 0;
    const change = balance - latestBalance;
    if(change === 0){
      continue;
    }
    
    changes[key] = change;
    latestBalanceAtAccount[account] = balance;
  }
  
  return Object.keys(changes).reduce((acc: Array<{date: S.CustomDate, account: number, value: number}>, key) => {
    const splitKey = key.split("-");
    const date = +splitKey[0];
    const account = +splitKey[1];
    const value = changes[key];
    acc.push({date, account, value});
    return acc;
  }, []);
};


export interface FlowAndBalanceDifference {
  extra_flow?: Array<{date: number, account: number, value: number}>;
  extra_balance?: Array<{date: number, account: number, value: number}>;
}

type GetDifferenceBetweenFlowAndBalanceFunc = (
  sorted_data_flow: S.FlowData[],
  sorted_data_balance: S.BalanceData[],
  startDate: S.CustomDate,
  endDate: S.CustomDate,
  master_accounts: S.AccountMaster[],
  options?: {onlyFlow: boolean, onlyBalance: boolean, both: boolean},
) => FlowAndBalanceDifference;
/**
 * @param {Array} sorted_data_flow
 * @param {Array} sorted_data_balance
 * @param {number} startDate
 * @param {number} endDate
 * @param {Array} master_accounts
 * @param {Object} options
 * @returns {*}
 */
export const getDifferenceBetweenFlowAndBalance: GetDifferenceBetweenFlowAndBalanceFunc = (
  sorted_data_flow,
  sorted_data_balance,
  startDate,
  endDate,
  master_accounts,
  options,
) => {
  const flowChanges = getFlowChangeByDateAndAccount(sorted_data_flow, startDate, endDate);
  const balanceChanges = getBalanceChangeByDateAndAccount(sorted_data_balance, startDate, endDate, master_accounts);
  const keys = ["date", "account", "value"] as Array<keyof typeof getBalanceChangeByDateAndAccount>;
  
  if(options){
    if(options.onlyFlow){
      return {extra_flow: differenceOfArraysOfObject(flowChanges, balanceChanges, keys)};
    }
    else if(options.onlyBalance){
      return {extra_balance: differenceOfArraysOfObject(balanceChanges, flowChanges, keys)};
    }
    else if(options.both){
      return {
        extra_flow: differenceOfArraysOfObject(flowChanges, balanceChanges, keys),
        extra_balance: differenceOfArraysOfObject(balanceChanges, flowChanges, keys),
      };
    }
  }
  
  return {
    extra_flow: differenceOfArraysOfObject(flowChanges, balanceChanges, keys),
    extra_balance: differenceOfArraysOfObject(balanceChanges, flowChanges, keys),
  };
};



type DifferenceOfArraysOfObjectFunc = <E, K extends keyof E>(a1: E[], a2: E[], keys: K[]) => E[];
/**
 * @param {Array} a1
 * @param {Array} a2
 * @param {Array} keys
 * @returns {Array}
 */
export const differenceOfArraysOfObject: DifferenceOfArraysOfObjectFunc = (a1, a2, keys) => {
  return a1.filter(x1 => a2.findIndex(x2 => keys.every(key => x1[key] === x2[key])) < 0);
};



type FindBalanceIndexFunc = (data_balance: S.BalanceData[], date: S.CustomDate, account: number, isSorted?: boolean) => number | null;
/**
 * @param {Array} data_balance
 * @param {number} date
 * @param {number} account
 * @param {boolean=} isSorted
 * @returns {number|null}
 */
export const findBalanceIndex: FindBalanceIndexFunc = (data_balance, date, account, isSorted) => {
  if(isSorted){
    for(let i=0;i<data_balance.length;i++){
      const b = data_balance[i];
      if(b.date === date && b.account === account){
        return i;
      }
      
      if(typeof(b.date) === "number" && b.date < date){
        return null;
      }
    }
    
    return null;
  }
  
  for(let i=0;i<data_balance.length;i++){
    const b = data_balance[i];
    if(b.date === date && b.account === account){
      return i;
    }
  }
  
  return null;
};



type FindBalanceIndexForInsertFunc = (data_balance: S.BalanceData[], date: S.CustomDate) => number;

export const findBalanceIndexForInsert: FindBalanceIndexForInsertFunc = (data_balance, date) => {
  let index = data_balance.length;
  
  if(data_balance.length === 0){
    return 0;
  }
  
  // Judge whether data_balance is sorted and which direction being sorted
  const active_balance = data_balance.filter(b => isBalanceActive(b)) as S.ActiveBalanceData[];
  const maxCheckIndex = Math.min(active_balance.length, 20);
  
  if(active_balance.length === 0){
    return 0;
  }
  
  const prevDate = active_balance[0].date;
  let direction = 0;
  for(let i=0;i<maxCheckIndex;i++){
    const {date: d} = active_balance[i];
    
    if(d > prevDate){
      direction += 1;
    }
    else if(d < prevDate){
      direction -= 1;
    }
  }
  
  if(direction > 0){
    for(let i=0;i<data_balance.length;i++){
      const b = data_balance[i];
      
      if(b.date === null){
        continue;
      }
    
      if(b.date <= date){
        index = i;
      }
      else if(b.date > date && typeof(index) === "number"){
        return index + 1;
      }
    }
    
    return index;
  }
  else if(direction < 0){
    for(let i=0;i<data_balance.length;i++){
      const b = data_balance[i];
  
      if(b.date === null){
        continue;
      }
      
      if(b.date >= date){
        index = i;
      }
      else if(b.date < date && typeof(index) === "number"){
        return index;
      }
    }
    
    return index;
  }
  
  return data_balance.length;
};



type GetChangeListForBalanceFunc = (
  extra_flow: Array<{date: number, account: number, value: number}>,
  data_balance: S.BalanceData[],
  master_accounts: S.AccountMaster[],
) => S.ChangeRequest[];

export const getChangeListForBalance: GetChangeListForBalanceFunc = (extra_flow, data_balance, master_accounts) => {
  const flow = [...extra_flow];
  flow.sort((a, b) => a.date - b.date);
  const latestBalance: {[key: string]: number} = {};
  const sorted_data_balance = getSortedBalanceData(data_balance);
  
  const changeList = flow.map(p => {
    const {date, account: accountId, value} = p;
    const account = master_accounts.find(a => a.id === accountId);
    const balanceAtDay = findBalanceAtDay(sorted_data_balance, date, accountId);
    let currentBalance = 0;
    let fixedBalance;
    
    if(balanceAtDay){
      currentBalance = balanceAtDay.balance;
    }
    else{
      currentBalance = 0;
    }
  
    fixedBalance = (typeof(latestBalance[accountId]) === "number" ?
      latestBalance[accountId] : currentBalance) + value;
    latestBalance[accountId] = fixedBalance;
    
    const index = account ? findBalanceIndex(data_balance, date, account.id) : null;
    
    return {
      date,
      account,
      action: typeof(index) === "number" ? "edit" : "insert",
      index,
      currentBalance,
      fixedBalance,
    };
  }).filter(row => row.currentBalance !== row.fixedBalance);
  
  const editChangeList = changeList.filter(c => c.action === "edit");
  const insertChangeList = changeList.filter(c => c.action === "insert");
  const changes: S.ChangeRequest[] = [];
  
  editChangeList.forEach(c => {
    const {
      date,
      account,
      index,
      fixedBalance,
    } = c;
  
    changes.push({
      action: "edit",
      index: index as number,
      data: {date, account: account ? account.id : null, balance: fixedBalance, note: ""},
    });
  });
  
  const indexMap: {[key: string]: number} = {};
  data_balance.forEach((_, index) => {
    indexMap[index] = index;
  });
  
  insertChangeList.forEach(c => {
    const {
      date,
      account,
      fixedBalance,
    } = c;
  
    const index = findBalanceIndexForInsert(data_balance, date);
    const indexToInsert = indexMap[index];
    
    Object.keys(indexMap).forEach(key => {
      const numericKey = +key;
      if(numericKey >= index){
        indexMap[numericKey] += 1;
      }
    });
    
    changes.push({
      index: indexToInsert,
      action: "insert",
      data: {date, account: account? account.id : null, balance: fixedBalance, note: ""},
    });
  });
  
  return changes;
};



type ToLocaleDateStringFunc = (customDate: S.CustomDate, lang: string) => string;

export const toLocaleDateString: ToLocaleDateStringFunc = (customDate, lang) => {
  const date = parseCustomDate(customDate);
  
  const m = date.m.toString().padStart(2, "0");
  const d = date.d.toString().padStart(2, "0");
  
  if(lang === "ja"){
    return `${date.y}/${m}/${d}`;
  }
  else{
    return `${m}/${d}/${date.y}`;
  }
};



type GetTaggablePlansFunc = (plan: S.PlanProject[]) => S.PlanProject[];
/**
 * @param {Array<Object>} planning_projects
 * @returns {Array<Object>}
 */
export const getTaggablePlans: GetTaggablePlansFunc = (planning_projects) => {
  return planning_projects.filter(p => {
    return ["InProgress", "RePlanning"].includes(p.status) && p.countTaggedFlowOnly;
  });
};



type MakeReverseChangeFunc = (change: S.ChangeRequest) => S.ChangeRequest | null;
/**
 * @param {{action: string, index: number, data: object, prevData: object=}} change
 * @returns {{action: string, index: number, data: object, prevData: object=}|{}}
 */
export const makeReverseChange: MakeReverseChangeFunc = (change) => {
  const {action, index, data, prevData} = change;
  
  if(action === "insert"){
    return {
      action: "remove",
      index,
      data,
    };
  }
  else if(action === "edit"){
    return {
      action: "edit",
      index,
      data: prevData,
      prevData: data,
    };
  }
  else if(action === "remove"){
    return {
      action: "insert",
      index,
      data,
    };
  }
  
  return null;
};

export default {
  ModifyFutureIndexInChangeList,
  addUnaccountedItem,
  findBalanceAtDay,
  findIndexOfNextUnaccountedItem,
  findNextUnaccountedItem,
  getDiffForBalanceChangeAndFlowSum,
  getFlowDataSortedByDateWithSortMap,
  getRecentBalanceInTheAccount,
  getSortedBalanceData,
  getSortedFlowData,
  getSumOfFlowInPeriod,
  insertBalanceAt,
  isBalanceActive,
  isFlowActive,
  populateUnaccountedAssetFlow,
  provisionToUnsortedFlowData,
  reBalanceAllFlow,
  reCalculateNextUnaccountedItem,
  removeBalanceAt,
  removeUnaccountedAssetFlow,
  removeUnaccountedItem,
  reorderIndexMapsAfterDeleting,
  reorderIndexMapsAfterInserting,
  getAccountInfo,
  parseCustomDate,
  createCustomDate,
  getNowInCustomDate,
  getNow,
  getCurrentDate,
  getDateFromCustomDate,
  getCustomDateFromDate,
  addDaysToCustomDate,
  addMonthsToCustomDate,
  getDays,
  getMonths,
  getFlowChangeByDateAndAccount,
  getBalanceChangeByDateAndAccount,
  getDifferenceBetweenFlowAndBalance,
  differenceOfArraysOfObject,
  findBalanceIndex,
  findBalanceIndexForInsert,
  isFixedItemGroup,
  isFixedItem,
};
