import * as S from "../reducers/Finance/state";
import {
  getCustomDateFromDate,
  getDateFromCustomDate,
  getDays,
  getFlowInPeriod,
  isFlowActive,
  parseCustomDate,
} from "./finance";
import {getFXValue} from "./currency";

type ShouldLogHistoryFunc = (project: S.PlanProject) => boolean;
/**
 * @param {Object} project
 * @returns {boolean}
 */
export const shouldLogHistory: ShouldLogHistoryFunc = (project) => {
  return [
    "InProgress",
    "RePlanning",
    "Done",
    "Canceled",
  ].includes(project.status);
};

type IsCustomPeriodGroupFunc = (periodGroup: S.PlanPeriodGroup) => periodGroup is S.PlanPeriodGroupCustom;
export const isCustomPeriodGroup: IsCustomPeriodGroupFunc = (periodGroup): periodGroup is S.PlanPeriodGroupCustom => {
  return periodGroup.type === "custom";
};

type IsNonCustomPeriodGroupFunc = (periodGroup: S.PlanPeriodGroup) => periodGroup is S.PlanPeriodGroupNonCustom;
export const isNonCustomPeriodGroup: IsNonCustomPeriodGroupFunc = (periodGroup): periodGroup is S.PlanPeriodGroupNonCustom => {
  return periodGroup.type !== "custom";
};


type GetPeriodGroupFunc = (periodGroups: S.PlanPeriodGroup[], id: string) => {
  index: number;
  periodGroup: S.PlanPeriodGroup | null;
};
/**
 * @param {Array} periodGroups
 * @param {string} id
 * @returns {{index: number, periodGroup: Object}}
 */
export const getPeriodGroup: GetPeriodGroupFunc = (periodGroups, id) => {
  const index = periodGroups.findIndex(pg => pg.id === id);
  if(index < 0){
    return {index, periodGroup: null};
  }
  
  return {index, periodGroup: periodGroups[index]};
};



type GetPeriodGroupForProjectFunc = (periodGroups: S.PlanPeriodGroup[], project: S.DraftPlanProject) => S.PlanPeriodGroup | null;
/**
 * @param {array} periodGroups
 * @param {Object} project
 * @returns {Object}
 */
export const getPeriodGroupForProject: GetPeriodGroupForProjectFunc = (periodGroups, project) => {
  const {periodGroup} = getPeriodGroup(periodGroups, project.periodGroupId);
  return periodGroup;
};



type GetPeriodStartEndForProjectFunc = (periodGroup: S.PlanPeriodGroup, project: S.DraftPlanProject) => {
  periodStart: number|null;
  periodEnd: number|null;
};
/**
 * @param {Object} periodGroup
 * @param {Object} project
 * @returns {{periodStart: number|null, periodEnd: number|null}}
 */
export const getPeriodStartEndForProject: GetPeriodStartEndForProjectFunc = (periodGroup, project) => {
  return getPeriod(periodGroup, project.periodValue);
};



type GetProjectFunc = (planning_projects: S.PlanProject[], id: string) => {
  index: number;
  project: S.PlanProject | null;
};
/**
 * @param {array} planning_projects
 * @param {string} id
 * @returns {{index: number, project: Object}}
 */
export const getProject: GetProjectFunc = (planning_projects, id) => {
  const index = planning_projects.findIndex(p => p.id === id);
  if(index < 0){
    return {index, project: null};
  }
  
  return {index, project: planning_projects[index]};
};



type ResetPeriodStartFunc = (
  periodGroupType: S.PlanPeriodGroupType,
  periodStart: number,
  periodValue: S.CustomDate,
) => S.CustomDate;
/**
 * @param {object} periodGroupType
 * @param {number} periodStart
 * @param {number} periodValue
 * @returns {number}
 */
export const resetPeriodStart: ResetPeriodStartFunc = (periodGroupType, periodStart, periodValue) => {
  if(periodGroupType === "custom"){
    return periodStart;
  }
  
  const ps = periodStart;
  const min = getDateFromCustomDate(periodValue);
  
  if(periodGroupType === "yearly"){
    min.setMonth(ps - 1);
    min.setDate(1);
  }
  else if(periodGroupType === "interim"){
    min.setDate(1);
  }
  else if(periodGroupType === "quarterly"){
    min.setDate(1);
  }
  else if(periodGroupType === "monthly"){
    min.setDate(ps);
  }
  /*
  else if(periodGroupType === "weekly"){
    
  }
  */
  
  return getCustomDateFromDate(min);
};



type ResetPeriodEnd = (
  periodGroupType: S.PlanPeriodGroupType,
  periodStart: S.CustomDate,
) => S.CustomDate;
/**
 * @param {Object} periodGroupType
 * @param {number} periodStart
 * @returns {number}
 */
export const resetPeriodEnd: ResetPeriodEnd = (periodGroupType, periodStart) => {
  if(periodGroupType === "custom"){
    return periodStart;
  }
  
  const min = getDateFromCustomDate(periodStart);
  const max = new Date(min);
  
  if(periodGroupType === "yearly"){
    max.setDate(1);
    max.setFullYear(max.getFullYear() + 1);
    max.setDate(0);
  }
  else if(periodGroupType === "interim"){
    max.setDate(1);
    max.setMonth(max.getMonth() + 6);
    max.setDate(0);
  }
  else if(periodGroupType === "quarterly"){
    max.setDate(1);
    max.setMonth(max.getMonth() + 3);
    max.setDate(0);
  }
  else if(periodGroupType === "monthly"){
    max.setMonth(max.getMonth() + 1);
    max.setDate(max.getDate() - 1);
  }
  else if(periodGroupType === "weekly"){
    max.setDate(max.getDate() + 6);
  }
  
  return getCustomDateFromDate(max);
};



type GetPeriodFunc = (
  periodGroup?: S.PlanPeriodGroup | null,
  periodValue?: S.CustomDate | null,
) => {
  periodStart: number | null;
  periodEnd: number | null;
};
/**
 * @param {Object} periodGroup
 * @param {number} periodValue
 * @returns {{periodStart: number|null, periodEnd: number|null}}
 */
export const getPeriod: GetPeriodFunc = (periodGroup, periodValue) => {
  if(!periodGroup){
    return {periodStart: null, periodEnd: null};
  }
  
  const periodGroupType = periodGroup.type;
  
  if(isCustomPeriodGroup(periodGroup)){
    return {periodStart: periodGroup.periodStart, periodEnd: periodGroup.periodEnd};
  }
  else if(periodGroupType === "yearly"){
    const startMonth = periodGroup.periodStart;
    if(typeof(startMonth) !== "number" || startMonth < 0 || startMonth > 12){
      return {periodStart: null, periodEnd: null};
    }
    
    if(periodValue){
      const date = getDateFromCustomDate(periodValue);
      date.setMonth(startMonth - 1);
      date.setDate(1);
      const periodStart = getCustomDateFromDate(date);
      const periodEndDate = new Date(date);
      
      periodEndDate.setFullYear(periodEndDate.getFullYear() + 1);
      
      periodEndDate.setDate(periodEndDate.getDate() - 1);
      const periodEnd = getCustomDateFromDate(periodEndDate);
      return {periodStart, periodEnd};
    }
  }
  else if(periodGroupType === "interim"){
    let startMonth = periodGroup.periodStart;
    if(typeof(startMonth) !== "number" || startMonth < 0 || startMonth > 12){
      return {periodStart: null, periodEnd: null};
    }
    
    if(periodValue){
      const date = getDateFromCustomDate(periodValue);
      const inputMonth = date.getMonth() + 1;
      const monthCandidates = [startMonth, (startMonth+6)%12];
      monthCandidates.sort((a, b) => a - b);
      
      if(monthCandidates[0] <= inputMonth && inputMonth < monthCandidates[1]){
        startMonth = monthCandidates[0];
      }
      else{
        startMonth = monthCandidates[1];
      }
      
      date.setMonth(startMonth - 1);
      date.setDate(1);
      const periodStart = getCustomDateFromDate(date);
      const periodEndDate = new Date(date);
      
      periodEndDate.setMonth(periodEndDate.getMonth() + 6);
      periodEndDate.setDate(periodEndDate.getDate() - 1);
      
      const periodEnd = getCustomDateFromDate(periodEndDate);
      return {periodStart, periodEnd};
    }
  }
  else if(periodGroupType === "quarterly"){
    let startMonth = periodGroup.periodStart;
    if(typeof(startMonth) !== "number" || startMonth < 0 || startMonth > 12){
      return {periodStart: null, periodEnd: null};
    }
    
    if(periodValue){
      const date = getDateFromCustomDate(periodValue);
      const inputMonth = date.getMonth() + 1;
      const monthCandidates = [startMonth, (startMonth+3)%12, (startMonth+6)%12, (startMonth+9)%12];
      monthCandidates.sort((a, b) => a - b);
      
      if(monthCandidates[0] <= inputMonth && inputMonth < monthCandidates[1]){
        startMonth = monthCandidates[0];
      }
      else if(monthCandidates[1] <= inputMonth && inputMonth < monthCandidates[2]){
        startMonth = monthCandidates[1];
      }
      else if(monthCandidates[2] <= inputMonth && inputMonth < monthCandidates[3]){
        startMonth = monthCandidates[2];
      }
      else{
        startMonth = monthCandidates[3];
      }
      
      date.setMonth(startMonth - 1);
      date.setDate(1);
      const periodStart = getCustomDateFromDate(date);
      const periodEndDate = new Date(date);
      
      periodEndDate.setMonth(periodEndDate.getMonth() + 3);
      periodEndDate.setDate(periodEndDate.getDate() - 1);
      
      const periodEnd = getCustomDateFromDate(periodEndDate);
      return {periodStart, periodEnd};
    }
  }
  else if(periodGroupType === "monthly"){
    if(periodValue){
      const date = getDateFromCustomDate(periodValue);
      date.setDate(periodGroup.periodStart);
      const periodStart = getCustomDateFromDate(date);
      const periodEndDate = new Date(date);
      
      periodEndDate.setMonth(periodEndDate.getMonth() + 1);
      periodEndDate.setDate(periodEndDate.getDate() - 1);
      const periodEnd = getCustomDateFromDate(periodEndDate);
      return {periodStart, periodEnd};
    }
  }
  else if(periodGroupType === "weekly"){
    if(periodValue){
      const date = getDateFromCustomDate(periodValue);
      const day_project = date.getDay();
      const day_periodGroup = periodGroup.periodStart;
      
      if(day_project === day_periodGroup){
        const periodStart = getCustomDateFromDate(date);
        const periodEndDate = new Date(date);
        periodEndDate.setDate(periodEndDate.getDate() + 6);
        const periodEnd = getCustomDateFromDate(periodEndDate);
        return {periodStart, periodEnd};
      }
      else if(day_project < day_periodGroup){
        const diff = day_periodGroup - day_project;
        date.setDate(date.getDate() + diff - 7);
        const periodStart = getCustomDateFromDate(date);
        const periodEndDate = new Date(date);
        
        periodEndDate.setDate(periodEndDate.getDate() + 6);
        const periodEnd = getCustomDateFromDate(periodEndDate);
        return {periodStart, periodEnd};
      }
      else if(day_project > day_periodGroup){
        const diff = day_project - day_periodGroup;
        date.setDate(date.getDate() - diff);
        const periodStart = getCustomDateFromDate(date);
        
        const periodEndDate = new Date(date);
        periodEndDate.setDate(periodEndDate.getDate() + 6);
        const periodEnd = getCustomDateFromDate(periodEndDate);
        return {periodStart, periodEnd};
      }
    }
  }
  
  return {periodStart: null, periodEnd: null};
};



type GetLastPeriodForCustomProjectsFunc =  (
  periodGroup: S.PlanPeriodGroupCustom,
) => {
  periodStart: number | null;
  periodEnd: number | null;
};
/**
 * @param {object} periodGroup
 * @returns {object}
 */
export const getLastPeriodForCustomProjects: GetLastPeriodForCustomProjectsFunc = (periodGroup) => {
  const {periodStart: ps, periodEnd: pe} = periodGroup;
  const days = getDays(ps, pe);
  
  const pe_date = getDateFromCustomDate(ps);
  pe_date.setDate(pe_date.getDate() - 1);
  const ps_date = new Date(pe_date);
  ps_date.setDate(ps_date.getDate() - days + 1);
  
  return {
    periodStart: getCustomDateFromDate(ps_date),
    periodEnd: getCustomDateFromDate(pe_date),
  };
};



interface ISummarizedFlow {
  item: {
    [itemId: number]: number;
  };
  itemGroup: {
    [itemGroupId: number]: number;
  };
}

type SummarizeFlowFunc = (
  data_flow: S.FlowData[],
  items: S.ItemMaster[],
  itemGroups: S.ItemGroupMaster[],
  accounts: S.AccountMaster[],
  currencyId: number,
) => ISummarizedFlow;
/**
 * @param {Array<object>} data_flow
 * @param {Array<object>} items
 * @param {Array<object>} itemGroups
 * @param {Array<object>} accounts
 * @param {number} currencyId
 * @returns {{item: object, itemGroup: object}}
 */
export const summarizeFlow: SummarizeFlowFunc = (data_flow, items, itemGroups, accounts, currencyId) => {
  const currencyIdForAccount: {[accountId: number]: number} = {};
  
  return data_flow.reduce((acc: ISummarizedFlow, f) => {
    if(!isFlowActive(f)){
      return acc;
    }
    
    const item = items.find(i => i.id === f.item);
    if(!item){
      return acc;
    }
    
    const itemGroup = itemGroups.find(ig => ig.id === item.group);
    if(!itemGroup){
      return acc;
    }
    
    if(typeof(currencyIdForAccount[f.account]) !== "number"){
      const account = accounts.find(a => a.id === f.account);
      if(!account){
        throw new Error("Account not found");
      }
  
      currencyIdForAccount[f.account] = account.currency;
    }
    
    const currencyOfFlow = currencyIdForAccount[f.account];
    const adjustedValue = getFXValue(f.value, currencyOfFlow, currencyId, f.date);
    
    acc.item[item.id] = adjustedValue + (acc.item[item.id] || 0);
    acc.itemGroup[itemGroup.id] = adjustedValue + (acc.itemGroup[itemGroup.id] || 0);
    return acc;
  }, {item: {}, itemGroup: {}});
};



type GetYoYPeriodFunc = (periodStart: S.CustomDate | null, periodEnd: S.CustomDate | null) => {
  periodStart: number | null;
  periodEnd: number | null;
};
/**
 * @param {number} periodStart
 * @param {number} periodEnd
 * @returns {{periodStart: number, periodEnd: number}}
 */
export const getYoYPeriod: GetYoYPeriodFunc = (periodStart, periodEnd) => {
  if(typeof(periodStart) !== "number" || typeof(periodEnd) !== "number"){
    return {periodStart, periodEnd};
  }
  
  const ps = parseCustomDate(periodStart);
  const pe = parseCustomDate(periodEnd);
  
  if(ps.m === 2 && (ps.d === 28 || ps.d === 29)){ // Treat as month-end
    const ps_date = getDateFromCustomDate(periodStart);
    ps_date.setFullYear(ps_date.getFullYear() - 1);
    ps_date.setMonth(2); // Set to May
    ps_date.setDate(0); // And go 1day back to have end of February
    periodStart = getCustomDateFromDate(ps_date);
  }
  else{
    periodStart = periodStart - 10000;
  }
  
  if(pe.m === 2 && (pe.d === 28 || pe.d === 29)){ // Treat as month-end
    const pe_date = getDateFromCustomDate(periodEnd);
    pe_date.setFullYear(pe_date.getFullYear() - 1);
    pe_date.setMonth(2); // Set to May
    pe_date.setDate(0); // And go 1day back to have end of February
    periodEnd = getCustomDateFromDate(pe_date);
  }
  else{
    periodEnd = periodEnd - 10000;
  }
  
  return {periodStart, periodEnd};
};



type GetYoYResultsFunc = (
  project: S.PlanProject,
  periodGroup: S.PlanPeriodGroup,
  sorted_flow: S.FlowData[],
  items: S.ItemMaster[],
  itemGroups: S.ItemGroupMaster[],
  accounts: S.AccountMaster[],
  currencyId: number,
) => ISummarizedFlow;
/**
 * @param {object} project
 * @param {object} periodGroup
 * @param {Array<object>} sorted_flow
 * @param {Array<object>} items
 * @param {Array<object>} itemGroups
 * @param {Array<object>} accounts
 * @param {number} currencyId
 * @returns {{item: object, itemGroup: object}}
 */
export const getYoYResults: GetYoYResultsFunc = (project, periodGroup, sorted_flow, items, itemGroups, accounts, currencyId) => {
  const {periodStart: ps, periodEnd: pe} = getPeriodStartEndForProject(periodGroup, project);
  const {periodStart, periodEnd} = getYoYPeriod(ps, pe);
  
  if(!periodStart || !periodEnd){
    return {item: {}, itemGroup: {}};
  }
  
  const filtered_flow = getFlowInPeriod(sorted_flow, periodStart, periodEnd);
  
  return summarizeFlow(filtered_flow, items, itemGroups, accounts, currencyId);
};



type GetLastPeriodFunc = (
  project: S.PlanProject,
  periodGroup: S.PlanPeriodGroup,
) => {periodStart: number | null, periodEnd: number | null};
/**
 * @param {object} project
 * @param {object} periodGroup
 * @returns {{periodStart: number, periodEnd: number}}
 */
export const getLastPeriod: GetLastPeriodFunc = (project, periodGroup) => {
  if(isNonCustomPeriodGroup(periodGroup)){
    const {type: periodType} = periodGroup;
    const {periodValue} = project;
    
    if(typeof(periodValue) !== "number"){
      return {periodStart: null, periodEnd: null};
    }
    
    if(periodType === "yearly"){
      const newPeriodValue = periodValue - 10000; // Set 1 year back
      return getPeriodStartEndForProject(periodGroup, {...project, periodValue: newPeriodValue});
    }
    else if(periodType === "interim"){
      const date = getDateFromCustomDate(periodValue);
      date.setMonth(date.getMonth() - 6);
      const newPeriodValue = getCustomDateFromDate(date);
  
      return getPeriodStartEndForProject(periodGroup, {...project, periodValue: newPeriodValue});
    }
    else if(periodType === "quarterly"){
      const date = getDateFromCustomDate(periodValue);
      date.setMonth(date.getMonth() - 3);
      const newPeriodValue = getCustomDateFromDate(date);
  
      return getPeriodStartEndForProject(periodGroup, {...project, periodValue: newPeriodValue});
    }
    else if(periodType === "monthly"){
      const date = getDateFromCustomDate(periodValue);
      date.setMonth(date.getMonth() - 1);
      const newPeriodValue = getCustomDateFromDate(date);
  
      return getPeriodStartEndForProject(periodGroup, {...project, periodValue: newPeriodValue});
    }
    else if(periodType === "weekly"){
      const date = getDateFromCustomDate(periodValue);
      date.setDate(date.getDate() - 7);
      const newPeriodValue = getCustomDateFromDate(date);
  
      return getPeriodStartEndForProject(periodGroup, {...project, periodValue: newPeriodValue});
    }
  }
  else{
    return getLastPeriodForCustomProjects((periodGroup as S.PlanPeriodGroupCustom));
  }
  
  return {periodStart: null, periodEnd: null};
};



type GetLastPeriodResultsFunc = (
  project: S.PlanProject,
  periodGroup: S.PlanPeriodGroup,
  sorted_flow: S.FlowData[],
  items: S.ItemMaster[],
  itemGroups: S.ItemGroupMaster[],
  accounts: S.AccountMaster[],
  currencyId: number,
) => ISummarizedFlow;
/**
 * @param {object} project
 * @param {object} periodGroup
 * @param {Array<object>} sorted_flow
 * @param {Array<object>} items
 * @param {Array<object>} itemGroups
 * @param {Array<object>} accounts
 * @param {number} currencyId
 * @returns {{item: object, itemGroup: object}}
 */
export const getLastPeriodResults: GetLastPeriodResultsFunc = (
  project,
  periodGroup,
  sorted_flow,
  items,
  itemGroups,
  accounts,
  currencyId,
) => {
  const {periodStart, periodEnd} = getLastPeriod(project, periodGroup);
  if(periodStart === null || periodEnd === null){
    return {item: {}, itemGroup: {}};
  }
  
  const filtered_flow = getFlowInPeriod(sorted_flow, periodStart, periodEnd);
  
  return summarizeFlow(filtered_flow, items, itemGroups, accounts, currencyId);
};



type IsItemBasedSpendingFunc = (spending: S.PlannedSpending) => spending is S.PlannedSpendingForItem;
export const isItemBasedSpending: IsItemBasedSpendingFunc = (spending): spending is S.PlannedSpendingForItem  => {
  return typeof((spending as S.PlannedSpendingForItem).item) === "number";
};

type IsItemGroupBasedSpendingFunc = (spending: S.PlannedSpending) => spending is S.PlannedSpendingForItemGroup;
export const isItemGroupBasedSpending: IsItemGroupBasedSpendingFunc = (spending): spending is S.PlannedSpendingForItemGroup  => {
  return typeof((spending as S.PlannedSpendingForItemGroup).itemGroup) === "number";
};


type IsSpendingActiveFunc = (spending: S.PlannedSpending, itemType: S.PlanProjectItemType) => spending is S.PlannedActiveSpending;
/**
 * @param {object} spending
 * @param {string} itemType
 * @returns {boolean}
 */
export const isSpendingActive: IsSpendingActiveFunc = (spending, itemType): spending is S.PlannedActiveSpending  => {
  if(itemType === "item" && isItemBasedSpending(spending)){
    return typeof(spending.item) === "number" && typeof(spending.value) === "number";
  }
  else if(itemType === "itemGroup" && isItemGroupBasedSpending(spending)){
    return typeof(spending.itemGroup) === "number" && typeof(spending.value) === "number";
  }
  
  return false;
};



type GetActiveSpendingsFunc = (spendings: S.PlannedSpending[], itemType: S.PlanProjectItemType) => S.PlannedActiveSpending[];
/**
 * @param {Array<object>} spendingArray
 * @param {string} itemType
 * @returns {Array<object>}
 */
export const getActiveSpendings: GetActiveSpendingsFunc = (spendingArray, itemType) => {
  return spendingArray.filter(s => isSpendingActive(s, itemType)) as S.PlannedActiveSpending[];
};
