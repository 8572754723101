import React, {ErrorInfo} from "react";
import Paper from "@material-ui/core/Paper";
import {withTranslation} from "react-i18next";

import i18next from "i18next";
import withStyles, {ClassNameMap, StyleRulesCallback} from "@material-ui/core/styles/withStyles";
import {Lang} from "../../reducers/App/state";
import {Omit, TReservedHocProps} from "../../util/type";

interface IErrorBoundaryProps {
  classes: ClassNameMap;
  i18n: i18next.i18n;
  lang: Lang;
}

interface IErrorBoundaryState {
  hasError: boolean;
}

class ErrorBoundary extends React.PureComponent<IErrorBoundaryProps, IErrorBoundaryState> {
  constructor(props: IErrorBoundaryProps){
    super(props);
    this.state = {
      hasError: false,
    };
  }
  
  public static getDerivedStateFromError(error: any){
    return {
      hasError: true,
    };
  }
  
  public componentDidCatch(error: Error, errorInfo: ErrorInfo){
    logError(error, errorInfo)
      .catch(reason => {
        if(process.env.REACT_APP_ENV === "development"){
          console.error("Error while logging error");
          console.error(reason);
        }
      });
  }
  
  public render(){
    const {classes, i18n, lang, children} = this.props;
    const {hasError} = this.state;
    
    if(hasError){
      const t = i18n.getFixedT(lang, "App");
      
      return (
        <div className={classes.root}>
          <Paper className={classes.paper}>
            <span className={classes.message}>
              {t("unknownError")}
            </span>
          </Paper>
        </div>
      );
    }
    
    return children;
  }
}

const styles: StyleRulesCallback = (theme) => ({
  root: {
    margin: 16,
  },
  paper: {
    padding: 32,
  },
  message: {
    fontSize: "1.1rem",
    color: "red",
  },
});

let _Component: React.ComponentType<any> = ErrorBoundary;
_Component = withTranslation("App")(_Component);
_Component = withStyles(styles, {withTheme: true})(_Component);

export default _Component as React.ComponentType<Omit<IErrorBoundaryProps, TReservedHocProps>>;



async function logError(error: Error, info: ErrorInfo){
  if(process.env.REACT_APP_ENV === "development"){
    console.error("Error has been caught on ErrorBoundary");
    console.error(error, info);
  }
}
