import {Reducer} from "redux";
import * as S from "./state";
import * as T from "../../actions/types";

import getInitialFinanceState from "./initialState";
import inputReducer from "./Input";
import planningReducer from "./Planning";
import * as C from "../../util/constants";
import * as AC from "../../actions/types/constant";
import * as AT from "../../actions/types/finance";
import * as AA from "../../actions/types/app/language";
import {getNowInCustomDate} from "../../util/finance";

type RootActions = T.RootActions<{Finance: S.FinanceState}>;

type TReducer = Reducer<S.FinanceState, RootActions>;

const financeReducer: TReducer = (state = getInitialFinanceState(), action) => {
  switch(action.type){
    case AC.SAVING_FINANCE_DATA: {
      return {...state, saving: true};
    }
    case AC.FAILED_SAVE_FINANCE_DATA: {
      return {...state, saving: false};
    }
    case AC.SAVE_FINANCE_DATA: {
      return {...state, saving: false, unsaved: false};
    }
    case AC.CHANGE_CURRENCY: {
      const {currencyId} = action.payload as AT.ChangeCurrencyPayload;
    
      return {...state, currencyId, unsaved: true};
    }
    case AC.MODIFY_CURRENCY: {
      const {currencyId, currency} = action.payload as AT.ModifyCurrencyPayload;
      const currencies = state.currencies.map(c => {
        if(c.id !== currencyId){
          return c;
        }
      
        return currency;
      });
    
      return {
        ...state,
        currencyId,
        currencies,
        unsaved: true,
      };
    }
    case AC.INITIALIZE_FINANCIAL_ITEM: {
      const {items, itemGroups} = (action as AT.InitializeFinancialItemAction).payload;
    
      if(Array.isArray(items) && items.length > 0){
        let master_items = state.master_items.concat(items);
        master_items.reverse();
        master_items = master_items.filter((item, i) => {
          return state.master_items.findIndex(itm => itm.id === item.id) === i;
        });
        master_items.reverse();
      
        state = {
          ...state,
          unsaved: true,
          master_items,
        };
      }
    
      if(Array.isArray(itemGroups) && itemGroups.length > 0){
        let master_itemGroups = state.master_itemGroups.concat(itemGroups);
        master_itemGroups.reverse();
        master_itemGroups = state.master_itemGroups.filter((itemGroup, i) => {
          return state.master_itemGroups.findIndex(ig => ig.id === itemGroup.id) === i;
        });
        master_itemGroups.reverse();
      
        state = {
          ...state,
          unsaved: true,
          master_itemGroups,
        };
      }
    
      return state;
    }
    case AC.INITIALIZE_FINANCIAL_ACCOUNT: {
      const{
        accounts,
        accountTypes,
      } = (action as AT.InitializeFinancialAccountAction).payload;
    
      return {
        ...state,
        unsaved: true,
        master_accounts: accounts,
        master_accountTypes: accountTypes,
      };
    }
    case AC.INITIALIZE_FINANCIAL_BALANCE: {
      let {
        initialBalance,
      } = (action as AT.InitializeFinancialBalanceAction).payload;
    
      let {
        data_flow,
        data_balance,
      } = state;
      const {
        master_accounts: accounts,
      } = state;
    
      if(Array.isArray(initialBalance) && initialBalance.length > 0){
        initialBalance = [...initialBalance];
        initialBalance.sort((a, b) => {
          if(a.date === null || b.date === null){
            if(a.date === null && b.date !== null){
              return 1;
            }
            if(a.date !== null && b.date === null){
              return -1;
            }
            return 0;
          }
        
          return b.date - a.date;
        });
      
        data_flow = [...data_flow];
        data_balance = [...data_balance];
      
        initialBalance.forEach(b => {
          const {id, value} = b;
          let {date} = b;
        
          if(typeof(id) !== "number" && typeof(value) !== "number"){
            return;
          }
        
          const account = accounts.find(a => a.id === b.id);
        
          if(!account){
            return;
          }
        
          if(typeof(date) !== "number"){
            date = getNowInCustomDate();
          }
        
          data_balance.unshift({
            date,
            account: account.id,
            balance: value,
            note: null,
          });
        
          data_flow.unshift({
            date,
            item: C.ITEM__START_AMOUNT,
            account: account.id,
            value,
            notes: null,
          });
        });
      
        return {
          ...state,
          unsaved: true,
          data_flow,
          data_balance,
        };
      }
    
      return state;
    }
    case AC.LOAD_ENTIRE_STATE: {
      const {state: stateInAction} = action.payload;
      return stateInAction.Finance as S.FinanceState;
    }
    case AC.SWITCH_ACCOUNT: {
      const {state: stateInAction} = action.payload;
      return stateInAction.Finance as S.FinanceState;
    }
    case AC.LOGOUT: {
      return getInitialFinanceState();
    }
    case AC.DELETE_USER_ACCOUNT: {
      return getInitialFinanceState();
    }
    case AC.CHANGE_LANGUAGE: {
      const {lang} = action.payload as AA.IChangeLanguagePayload;
      if(lang){
        return {
          ...state,
          unsaved: true,
          currencyId: lang === "ja" ? C.CURRENCY_JPY.id : C.CURRENCY_USD.id,
        };
      }
    
      return state;
    }
    default: {
      let _state = inputReducer(state, action as any);
      if(_state === state){
        _state = planningReducer(state, action as any);
      }
      state = _state;
    }
  }
  
  return state;
};

export default financeReducer;
