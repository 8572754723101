import firebase from "firebase/app";
import "firebase/auth";
import "firebase/database";
import "firebase/functions";
import {firebaseConfig} from "./config";

export const firebaseApp = firebase.initializeApp(firebaseConfig);
export const firebaseAuth = firebaseApp.auth();
export const firebaseDb = firebaseApp.database();
export const firebaseFunctions = firebaseApp.functions();

export type AuthProvider = firebase.auth.AuthProvider;
export const TwitterAuthProvider = firebase.auth.TwitterAuthProvider;
export const FacebookAuthProvider = firebase.auth.FacebookAuthProvider;
export const GoogleAuthProvider = firebase.auth.GoogleAuthProvider;
export const GithubAuthProvider = firebase.auth.GithubAuthProvider;
