import {connect} from "react-redux";
import NotFound from "../NotFound";
import {ThunkDispatch} from "redux-thunk";
import * as A from "../../../actions";
import {Lang} from "../../../reducers/App/state";
import {RootState} from "../../../reducers";

const mapStateToProps = (rootReduxState: RootState) => {
  const {lang} = rootReduxState.App;
  return {lang};
};

const mapDispatchToProps = (dispatch: ThunkDispatch<RootState, undefined, A.RootActions>) => {
  return {
    changeActivity: (activity: string) => dispatch(A.changeActivity(activity)),
    changeLanguage: (lang: Lang) => dispatch(A.changeLanguage(lang)),
  };
};

const AppContainer = connect(mapStateToProps, mapDispatchToProps)(NotFound);

export default AppContainer;
