import * as React from "react";
import * as ReactDOM from "react-dom";
import { Provider } from "react-redux";
import {Store} from "redux";
import ReactGA from "react-ga";
import "./i18n";
import "./style/index.css";
import * as serviceWorker from "./serviceWorker";

import {initializeFXTable} from "./core/util/currency";
import initializeState from "./store";
import InitialLoader from "./module/Activity/InitialLoader";
import App from "./module/App/__container__";

type TUnmountLoadingScreen = () => void;



function renderInitialLoadingScreen(): Promise<TUnmountLoadingScreen> {
  return new Promise((resolve) => {
    const nodeToMount = document.getElementById("initialLoad");
    if(!nodeToMount){
      throw new Error("Node to mount does not exist");
    }
    
    const element = (
      <InitialLoader />
    );
  
    ReactDOM.render(element, nodeToMount, () => {
      const unmountLoadingScreen: TUnmountLoadingScreen = () => {
        const onDismissed = () => {
          ReactDOM.unmountComponentAtNode(nodeToMount);
        };
    
        const dismissingLoadingScreen = (
          <InitialLoader
            dismiss={true}
            onDismissed={onDismissed}
          />
        );
    
        ReactDOM.render(dismissingLoadingScreen, nodeToMount);
      };
  
      resolve(unmountLoadingScreen);
    });
  });
}



function renderMainApp(store: Store){
  const nodeToMount = document.getElementById("root");
  if(!nodeToMount){
    throw new Error("Node to mount does not exist");
  }
  
  const app = (
    <Provider store={store}>
      <App />
    </Provider>
  );
  
  ReactDOM.render(app, nodeToMount);

  // If you want your app to work offline and load faster, you can change
  // unregister() to register() below. Note this comes with some pitfalls.
  // Learn more about service workers: http://bit.ly/CRA-PWA
  serviceWorker.unregister();
}



async function main(){
  const taskRenderingLoadingScreen = renderInitialLoadingScreen();
  
  ReactGA.initialize("UA-91245492-6", {
    debug: process.env.REACT_APP_DEBUG_GA === "enable",
  });
  
  if(process.env.NODE_ENV === "development" && process.env.REACT_APP_ENABLE_WHY_DID_YOU_UPDATE === "true"){
    const {whyDidYouUpdate} = require("why-did-you-update");
    whyDidYouUpdate(React);
  }
  
  const taskInitializingFXTable = initializeFXTable();
  const taskInitializingState = initializeState();
  
  await taskInitializingFXTable;
  const {store} = await taskInitializingState;
  
  renderMainApp(store);
  
  const dismissLoadingScreen = await taskRenderingLoadingScreen;
  dismissLoadingScreen();
}

(async () => {
  await main();
})();
