import React from "react";
import {withTranslation} from "react-i18next";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";

import i18next from "i18next";
import {Lang} from "../../reducers/App/state";
import {Omit, TWithTranslateProps} from "../type";

interface IClasses {
  [key: string]: string;
}

interface IConfirmDialogProps {
  i18n: i18next.i18n;
  lang: Lang;
  open?: boolean;
  title?: string;
  description?: string | React.ReactNode;
  onClickOK: () => void;
  onClickCancel?: () => void;
  alert?: boolean;
  scroll?: string;
  disabled?: boolean|"both"; // `disabled=true` only disables OK button 
  okButtonLabel?: string;
  cancelButtonLabel?: string;
  classes?: IClasses;
}


class ConfirmDialog extends React.Component<IConfirmDialogProps> {
  public render(){
    const {
      onClickCancel,
      onClickOK,
      lang,
      i18n,
      alert,
      children,
      title,
      classes,
    } = this.props;
    let {
      okButtonLabel,
      cancelButtonLabel,
    } = this.props;
    let {
      open,
      disabled,
      description,
      scroll,
    } = this.props;
    const t = i18n.getFixedT(lang, "general");
    
    if(typeof(open) !== "boolean"){
      open = true;
    }
  
    if(typeof(disabled) !== "boolean" && disabled !== "both"){
      disabled = false;
    }
    
    scroll = scroll || "paper";
    description = children || description;
    
    const cancelButton = (() => {
      if(!alert){
        cancelButtonLabel = cancelButtonLabel ? cancelButtonLabel : t<string>("dialogCancel");
        
        return (
          <Button
            onClick={onClickCancel}
            color="primary"
            disabled={disabled === "both"}
          >
            {cancelButtonLabel}
          </Button>
        );
      }
      
      return null;
    })();
    
    if(!["paper", "body"].includes(scroll)){
      scroll = "paper";
    }
    
    okButtonLabel = okButtonLabel ? okButtonLabel : t<string>("dialogOK");
    
    return (
      <Dialog
        open={open}
        onClose={onClickCancel}
        scroll={(scroll as "paper" | "body" | undefined)}
        aria-labelledby="scroll-dialog-title"
        disableBackdropClick={true}
        disableEscapeKeyDown={true}
        classes={classes}
      >
        {
          title && (
            <DialogTitle id="scroll-dialog-title">{title}</DialogTitle>
          )
        }
        <DialogContent>
          {description}
        </DialogContent>
        <DialogActions>
          {cancelButton}
          <Button onClick={onClickOK} color="primary" disabled={!!disabled}>
            {okButtonLabel}
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

let Confirm: React.ComponentType<any> = ConfirmDialog;
Confirm = withTranslation("general")(Confirm);

export default Confirm as React.ComponentType<Omit<IConfirmDialogProps, TWithTranslateProps>>;
