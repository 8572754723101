import {Action} from "redux";
import {ThunkAction} from "redux-thunk";
import * as A from "../../../core/actions/types/finance/input";
import * as S from "../../../core/reducers/Finance/state";
import * as T from "../../../core/actions/types/constant";

interface IRootState {
  Finance: S.FinanceState;
}

type TAsyncAction<U extends Action> = ThunkAction<void, IRootState, undefined, U>;

export const replaceFlowData = (flow: S.FlowData[]): A.IReplaceFlowDataAction => {
  return {
    type: T.REPLACE_FINANCE_FLOW_DATA,
    payload: {flow},
  };
};

export const replaceBalanceData = (balance: S.BalanceData[]): A.IReplaceBalanceDataAction => {
  return {
    type: T.REPLACE_FINANCE_BALANCE_DATA,
    payload: {balance},
  };
};

export const changeFlowData = (changes: S.ChangeRequest[]): TAsyncAction<A.IChangeFlowDataAction> => {
  return (dispatch, getState) => {
    const {currencyId} = getState().Finance;
    
    dispatch({
      type: T.CHANGE_FINANCE_FLOW_DATA,
      payload: {
        changes,
        currencyId,
      },
    });
  };
};

export const changeBalanceData = (changes: S.ChangeRequest[], doSort?: boolean): TAsyncAction<A.IChangeBalanceDataAction> => {
  return (dispatch, getState) => {
    const {currencyId} = getState().Finance;
    
    dispatch({
      type: T.CHANGE_FINANCE_BALANCE_DATA,
      payload: {
        changes,
        currencyId,
        doSort,
      },
    });
  };
};

export const reBalanceAllFlowData = (): A.IReBalanceAllFlowDataAction => {
  return {
    type: T.REBALANCE_FINANCE_FLOW_DATA,
  };
};

export const sortBalance = (): A.ISortBalanceAction => {
  return {
    type: T.SORT_BALANCE,
  };
};

export const addItems = (items: Array<{id: number, name: string, order: number}>): A.IAddItemsAction => {
  return {
    type: T.ADD_ITEM,
    payload: {items},
  };
};

export const addItemGroups = (itemGroups: Array<{id: number, name: string, order: number}>): A.IAddItemGroupsAction => {
  return {
    type: T.ADD_ITEM_GROUP,
    payload: {itemGroups},
  };
};

export const addAccounts = (
  accounts: Array<{id: number, name: string, type: number, order: number, currency: number}>,
): A.IAddAccountsAction => {
  return {
    type: T.ADD_ACCOUNT,
    payload: {accounts},
  };
};

export const addAccountTypes = (accountTypes: Array<{id: number, name: string, type: string}>): A.IAddAccountTypesAction => {
  return {
    type: T.ADD_ACCOUNT_TYPE,
    payload: {accountTypes},
  };
};

export const editItem = (item: S.ItemMaster): A.IEditItemAction => {
  return {
    type: T.EDIT_ITEM,
    payload: {item},
  };
};

export const editItemGroup = (itemGroup: S.ItemGroupMaster): A.IEditItemGroupAction => {
  return {
    type: T.EDIT_ITEM_GROUP,
    payload: {itemGroup},
  };
};

export const editAccount = (account: S.AccountMaster): A.IEditAccountAction => {
  return {
    type: T.EDIT_ACCOUNT,
    payload: {account},
  };
};

export const editAccountType = (accountType: S.AccountTypeMaster): A.IEditAccountTypeAction => {
  return {
    type: T.EDIT_ACCOUNT_TYPE,
    payload: {accountType},
  };
};

export const removeItems = (items: Array<{id: number}>): A.IRemoveItemsAction => {
  return {
    type: T.REMOVE_ITEM,
    payload: {items},
  };
};

export const removeItemGroups = (itemGroups: Array<{id: number}>): A.IRemoveItemGroupsAction => {
  return {
    type: T.REMOVE_ITEM_GROUP,
    payload: {itemGroups},
  };
};

export const removeAccounts = (accounts: Array<{id: number}>): A.IRemoveAccountsAction => {
  return {
    type: T.REMOVE_ACCOUNT,
    payload: {accounts},
  };
};

export const removeAccountTypes = (accountTypes: Array<{id: number}>): A.IRemoveAccountTypesAction => {
  return {
    type: T.REMOVE_ACCOUNT_TYPE,
    payload: {accountTypes},
  };
};

export const replaceItems = (items: S.ItemMaster[]): A.IReplaceItemsAction => {
  return {
    type: T.REPLACE_ITEM,
    payload: {items},
  };
};

export const replaceItemGroups = (itemGroups: S.ItemGroupMaster[]): A.IReplaceItemGroupsAction => {
  return {
    type: T.REPLACE_ITEM_GROUP,
    payload: {itemGroups},
  };
};

export const replaceAccounts = (accounts: S.AccountMaster[]): A.IReplaceAccountsAction => {
  return {
    type: T.REPLACE_ACCOUNT,
    payload: {accounts},
  };
};

export const replaceAccountTypes = (accountTypes: S.AccountTypeMaster[]): A.IReplaceAccountTypesAction => {
  return {
    type: T.REPLACE_ACCOUNT_TYPE,
    payload: {accountTypes},
  };
};

/**
 * @param {number} index
 * @param {array<number>} tags
 * @returns {{type: string, index: number, tags: array<number>}}
 */
export const changePlanTagForFlowData = (index: number, tags: string[]): A.IChangePlanTagForFlowDataAction => {
  return {
    type: T.CHANGE_PLAN_TAG_FOR_FLOW_DATA,
    payload: {
      index,
      tags,
    },
  };
};

/**
 * @param {array<number>} tags
 * @returns {{type: string, tags: *}}
 */
export const deletePlanTagsInAllFlowData = (tags: string[]): A.IDeletePlanTagsInAllFlowDataAction => {
  return {
    type: T.DELETE_PLAN_TAG_IN_ALL_FLOW_DATA,
    payload: {tags},
  };
};
