import React from "react";
import {Omit} from "../../util/type";

const AvailableContentSize = React.createContext({availableWidth: -1, availableHeight: -1});

interface IContentSize {
  availableHeight: number;
  availableWidth: number;
}

type IProvider = (arg: IContentSize) => ((children: React.ReactNode) => React.ReactElement<any>);

const Provider: IProvider = ({availableHeight, availableWidth}) => {
  return (children) => (
    <AvailableContentSize.Provider value={{availableHeight, availableWidth}}>
      {children}
    </AvailableContentSize.Provider>
  );
};

type IWithContentSize =
  <P extends IContentSize>(ComponentClass: React.ComponentType<P>) => React.ComponentType<Omit<P, keyof IContentSize>>;

const withContentSize: IWithContentSize = (ComponentClass) => {
  return (props) => {
    const ComponentWithSize = ({availableHeight, availableWidth}: IContentSize) => {
      const extendedProps = {...props, availableHeight, availableWidth} as any;
      return <ComponentClass {...extendedProps}/>;
    };
  
    return (
      <AvailableContentSize.Consumer>
        {ComponentWithSize}
      </AvailableContentSize.Consumer>
    );
  };
};

export {Provider, withContentSize};
