import {Action} from "redux";
import {ThunkAction} from "redux-thunk";
import uuidv4 from "uuidv4";
import * as T from "../../../core/actions/types/constant";
import * as A from "../../../core/actions/types/finance/planning";
import * as S from "../../../core/reducers/Finance/state";
import * as T2 from "../../types/constant";
import {AppState} from "../../../reducers/App/state";
import {getNow, getNowInCustomDate} from "../../../core/util/finance";

interface IRootState {
  App: AppState;
  Finance: S.FinanceState;
}

type TAsyncAction<U extends Action, R = void> = ThunkAction<R, IRootState, undefined, U>;

export const addPlanningPeriodGroup = (
  periodGroup: S.PlanPeriodGroupDraft,
): TAsyncAction<A.AddPlanningPeriodGroupAction, string> => {
  return (dispatch, getState) => {
    const {App} = getState();
    const created_by = App.account ? App.account.uid : "";
    const created_at = getNow();
  
    const id = uuidv4();
  
    dispatch({
      type: T.ADD_PLANNING_PERIOD_GROUP,
      payload: {
        id,
        periodGroup,
        created_at,
        created_by,
      },
    });
  
    return id;
  };
};

export const modifyPlanningPeriodGroup = (
  periodGroupId: string,
  periodGroup: S.PlanPeriodGroup,
): TAsyncAction<A.ModifyPlanningPeriodGroupAction> => {
  return (dispatch, getState) => {
    const {App} = getState();
    const updated_by = App.account ? App.account.uid : "";
    const updated_at = getNow();
  
    dispatch({
      type: T.MODIFY_PLANNING_PERIOD_GROUP,
      payload: {
        id: periodGroupId,
        periodGroup,
        updated_at,
        updated_by,
      },
    });
  };
};

export const deletePlanningPeriodGroup = (periodGroupIds: string[]): TAsyncAction<A.DeletePlanningPeriodGroupAction> => {
  return (dispatch, getState) => {
    const {App} = getState();
    const updated_by = App.account ? App.account.uid : "";
    const updated_at = getNow();
  
    dispatch({
      type: T.DELETE_PLANNING_PERIOD_GROUP,
      payload: {
        ids: periodGroupIds,
        updated_at,
        updated_by,
      },
    });
  };
};

export const renamePlanningPeriodGroup = (periodGroupId: string, name: string): TAsyncAction<A.RenamePlanningPeriodGroupAction> => {
  return (dispatch, getState) => {
    const {App} = getState();
    const updated_by = App.account ? App.account.uid : "";
    const updated_at = getNow();
  
    dispatch({
      type: T.RENAME_PLANNING_PERIOD_GROUP,
      payload: {
        id: periodGroupId,
        name,
        updated_at,
        updated_by,
      },
    });
  };
};

export const addPlanningProject = (project: S.DraftPlanProject): TAsyncAction<A.AddPlanningProjectAction, string> => {
  return (dispatch, getState) => {
    const {App} = getState();
    const created_by = App.account ? App.account.uid : "";
    const created_at = getNow();
  
    const id = uuidv4();
    project.id = id;
  
    dispatch({
      type: T.ADD_PLANNING_PROJECT,
      payload: {
        project: (project as S.PlanProject),
        created_at,
        created_by,
      },
    });
  
    return id;
  };
};

export const modifyPlanningProject = (id: string, project: S.PlanProject): TAsyncAction<A.ModifyPlanningProjectAction> => {
  return (dispatch, getState) => {
    const {App} = getState();
    const updated_by = App.account ? App.account.uid : "";
    const updated_at = getNow();
  
    dispatch({
      type: T.MODIFY_PLANNING_PROJECT,
      payload: {
        id,
        project,
        updated_at,
        updated_by,
      },
    });
  };
};

export const renamePlanningProject = (id: string, name: string): TAsyncAction<A.RenamePlanningProjectAction> => {
  return (dispatch, getState) => {
    const {App} = getState();
    const updated_by = App.account ? App.account.uid : "";
    const updated_at = getNow();
  
    dispatch({
      type: T.RENAME_PLANNING_PROJECT,
      payload: {
        id,
        name,
        updated_at,
        updated_by,
      },
    });
  };
};

export const deletePlanningProject = (id: string): TAsyncAction<A.DeletePlanningProjectAction> => {
  return (dispatch, getState) => {
    const {App} = getState();
    const updated_by = App.account ? App.account.uid : "";
    const updated_at = getNow();
  
    dispatch({
      type: T.DELETE_PLANNING_PROJECT,
      payload: {
        id,
        updated_at,
        updated_by,
      },
    });
  };
};

export const deletePlanningProjects = (idList: string[]): TAsyncAction<A.DeletePlanningProjectsAction> => {
  return (dispatch, getState) => {
    const {App} = getState();
    const updated_by = App.account ? App.account.uid : "";
    const updated_at = getNow();
  
    dispatch({
      type: T.DELETE_PLANNING_PROJECTS,
      payload: {
        idList,
        updated_at,
        updated_by,
      },
    });
  };
};

export const updatePlanningStatus = (
  id: string,
  status: S.PlanStatus,
  fixDate?: number | null,
): TAsyncAction</*UpdatePlanningStatusAction*/any> => {
  return (dispatch, getState) => {
    const {App} = getState();
    const updated_by = App.account ? App.account.uid : "";
    const updated_at = getNow();
    
    if(typeof(fixDate) !== "number" && ["Canceled", "Done"].includes(status)){
      fixDate = getNowInCustomDate();
    }
    
    dispatch({
      type: T.UPDATE_PLANNING_STATUS,
      payload: {
        id,
        status,
        fixDate,
        updated_at,
        updated_by,
      },
    });
    
    if(!["Done", "Canceled"].includes(status)){
      return;
    }
    
    const filters = App.page_Input_flow_filters.filter(f => f.column === "planTags");
    
    if(filters.length !== 1){
      return;
    }
    
    const planFilter = filters[0];
    if(!Array.isArray(planFilter.values) || !planFilter.values.includes(id)){
      return;
    }
  
    planFilter.values = planFilter.values.filter(v => v !== id);
    
    dispatch({
      type: T2.UPDATE_FINANCE_FILTER_TAG,
      payload: {
        rule: planFilter.rule,
        values: planFilter.values,
      },
    });
  };
};

export const updatePlanningBudget = (id: string, budget: number): TAsyncAction<A.UpdatePlanningBudgetAction> => {
  return (dispatch, getState) => {
    const {App} = getState();
    const updated_by = App.account ? App.account.uid : "";
    const updated_at = getNow();
  
    dispatch({
      type: T.UPDATE_PLANNING_BUDGET,
      payload: {
        id,
        budget,
        updated_at,
        updated_by,
      },
    });
  };
};

export const updatePlanningCurrency = (id: string, currencyId: number): TAsyncAction<A.UpdatePlanningCurrencyAction> => {
  return (dispatch, getState) => {
    const {App} = getState();
    const updated_by = App.account ? App.account.uid : "";
    const updated_at = getNow();
    
    dispatch({
      type: T.UPDATE_PLANNING_CURRENCY,
      payload: {
        id,
        currencyId,
        updated_at,
        updated_by,
      },
    });
  };
};

export const updatePlanningProtect = (id: string, protect: boolean): TAsyncAction<A.UpdatePlanningProtectAction> => {
  return (dispatch, getState) => {
    const {App} = getState();
    const updated_by = App.account ? App.account.uid : "";
    const updated_at = getNow();
  
    dispatch({
      type: T.UPDATE_PLANNING_PROTECT,
      payload: {
        id,
        protect,
        updated_at,
        updated_by,
      },
    });
  };
};

export const updatePlanningTagOnly = (id: string, countTaggedFlowOnly: boolean): TAsyncAction<A.UpdatePlanningTagOnlyAction> => {
  return (dispatch, getState) => {
    const {App} = getState();
    const updated_by = App.account ? App.account.uid : "";
    const updated_at = getNow();
  
    dispatch({
      type: T.UPDATE_PLANNING_TAG_ONLY,
      payload: {
        id,
        countTaggedFlowOnly,
        updated_at,
        updated_by,
      },
    });
  };
};

export const updatePlanningPeriodGroup = (planId: string, periodGroupId: string): TAsyncAction<A.UpdatePlanningPeriodGroupAction> => {
  return (dispatch, getState) => {
    const {App} = getState();
    const updated_by = App.account ? App.account.uid : "";
    const updated_at = getNow();
  
    dispatch({
      type: T.UPDATE_PLANNING_PERIOD_GROUP,
      payload: {
        planId,
        periodGroupId,
        updated_at,
        updated_by,
      },
    });
  };
};

export const changePlannedSpending = (id: string, changes: S.ChangeRequest[]): TAsyncAction<A.ChangePlannedSpendingAction> => {
  return (dispatch, getState) => {
    const {App} = getState();
    const updated_by = App.account ? App.account.uid : "";
    const updated_at = getNow();
  
    dispatch({
      type: T.CHANGE_PLANNED_SPENDING,
      payload: {
        id,
        changes,
        updated_at,
        updated_by,
      },
    });
  };
};

export const addReviewForAPlan = (id: string, review: S.PlanReview): TAsyncAction<A.AddReviewForAPlanAction> => {
  return (dispatch, getState) => {
    const {App} = getState();
    const updated_by = App.account ? App.account.uid : "";
    const updated_at = getNow();
  
    dispatch({
      type: T.ADD_REVIEW_FOR_A_PLAN,
      payload: {
        id,
        review,
        updated_at,
        updated_by,
      },
    });
  };
};

export const removeReviewForAPlan = (projectId: string, reviewId: number): TAsyncAction<A.RemoveReviewForAPlanAction> => {
  return (dispatch, getState) => {
    const {App} = getState();
    const updated_by = App.account ? App.account.uid : "";
    const updated_at = getNow();
  
    dispatch({
      type: T.REMOVE_REVIEW_FOR_A_PLAN,
      payload: {
        projectId,
        reviewId,
        updated_at,
        updated_by,
      },
    });
  };
};

export const modifyReviewForAPlan = (
  projectId: string,
  reviewId: number,
  review: S.PlanReview,
): TAsyncAction<A.ModifyReviewForAPlanAction> => {
  return (dispatch, getState) => {
    const {App} = getState();
    const updated_by = App.account ? App.account.uid : "";
    const updated_at = getNow();
  
    dispatch({
      type: T.MODIFY_REVIEW_FOR_A_PLAN,
      payload: {
        projectId,
        reviewId,
        review,
        updated_at,
        updated_by,
      },
    });
  };
};

export const updateStatusOfReviewForPlan = (
  projectId: string,
  reviewId: number,
  status: S.PlanReviewStatus,
): TAsyncAction<A.UpdateStatusOfReviewForPlanAction> => {
  return (dispatch, getState) => {
    const {App} = getState();
    const updated_by = App.account ? App.account.uid : "";
    const updated_at = getNow();
  
    dispatch({
      type: T.UPDATE_STATUS_OF_REVIEW_FOR_A_PLAN,
      payload: {
        projectId,
        reviewId,
        status,
        updated_at,
        updated_by,
      },
    });
  };
};

