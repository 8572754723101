import {
  ITEM__UNACCOUNTED,
  ITEM__START_AMOUNT,
  ITEM_GROUP__UNCOUNTING,
  ITEM_GROUP__GENERAL,
  ACCOUNT_TYPE__CASH,
  ACCOUNT_TYPE__LOAN,
} from "../core/util/constants";
import * as S from "../core/reducers/Finance/state";

type IFGetPopularItems = () => {items: S.ItemMaster[], itemGroups: S.ItemGroupMaster[]};

/**
 * This is based on Statistics by Japanese government.
 * https://www.stat.go.jp/data/kakei/koumoku/bunrui27.html
 *
 * @return {{items: Array<object>, itemGroups: Array<object>}}
 */
export const getPopularItems: IFGetPopularItems = () => {
  const popularItems = {
    items: [
      {id: 0, name: "food", group: 2, order: 0}, // 食費
      {id: 1, name: "dailySupply", group: 2, order: 0}, // 日用品費
      {id: 2, name: "hobby", group: 4, order: 0}, // 趣味・娯楽費
      {id: 3, name: "mobilePhone", group: 3, order: 0},  // 携帯電話代
      {id: 4, name: "transport", group: 2, order: 0}, // 交通費
      {id: 5, name: "medical", group: 2, order: 0}, // 医療費
      {id: 6, name: "cloth", group: 4, order: 0}, // 衣服・装飾品
      {id: 7, name: "book", group: 5, order: 0}, // 書籍代
      {id: 8, name: "gas", group: 3, order: 0}, // ガス代
      {id: 9, name: "electricity", group: 3, order: 0}, // 電気代
      {id: 10, name: "house", group: 3, order: 0}, // 家賃・住宅費
      {id: 29, name: "generalTrip", group: 6, order: 0}, // 旅費全般
      {id: 11, name: "specialExpenditure", group: ITEM_GROUP__GENERAL, order: 0}, // 特別支出(一ヶ月)
      {id: 12, name: "basePay", group: 1, order: 0}, // 基本給
      {id: 13, name: "overtimePay", group: 1, order: 0}, // 残業代
      {id: 14, name: "bonus", group: 1, order: 0}, // 賞与
      {id: 15, name: "allowance", group: 1, order: 0}, // 手当
      {id: 16, name: "commutingAllowance", group: 1, order: 0}, // 通勤手当
      {id: 17, name: "otherIncome", group: ITEM_GROUP__GENERAL, order: 0}, // その他収入(非課税)
      {id: 18, name: "pension", group: 1, order: 0}, // 厚生年金
      {id: 19, name: "healthInsurance", group: 1, order: 0}, // 健康保険料
      {id: 20, name: "employmentInsurance", group: 1, order: 0}, // 雇用保険料
      {id: 21, name: "incomeTax", group: 1, order: 0}, // 所得税
      {id: 22, name: "inhabitantTax", group: 1, order: 0}, // 住民税
      {id: 23, name: "otherDeduction", group: 1, order: 0}, // その他控除
      {id: ITEM__START_AMOUNT, name: "startAmount", group: ITEM_GROUP__UNCOUNTING, order: 0}, // 開始残高
      {id: 25, name: "transfer", group: ITEM_GROUP__UNCOUNTING, order: 0}, // 入出金
      {id: 26, name: "clearDebt", group: ITEM_GROUP__GENERAL, order: 0}, // 負債償却
      {id: 27, name: "borrowing", group: ITEM_GROUP__UNCOUNTING, order: 0}, // 借入
      {id: 28, name: "repayment", group: ITEM_GROUP__GENERAL, order: 0}, // 元金返済
      {id: 30, name: "interestPayment", group: ITEM_GROUP__GENERAL, order: 0}, // 金利支払
      {id: 31, name: "depreciation", group: ITEM_GROUP__GENERAL, order: 0}, // 減価償却
      {id: 32, name: "yearEndAdjustment", group: 1, order: 0}, // 年末調整
  
      {id: 33, name: "homeElectronicsPC", group: 8, order: 0}, // 家電・PC
      {id: 34, name: "softwareITService", group: 8, order: 0}, // ソフトウェア・ITサービス
      {id: 35, name: "otherService", group: ITEM_GROUP__GENERAL, order: 0}, // その他サービス
      {id: 36, name: "furniture", group: 3, order: 0}, // 家具
      {id: 37, name: "gasoline", group: 6, order: 0}, // ガソリン代
      {id: 38, name: "taxi", group: 6, order: 0}, // タクシー代
      {id: 39, name: "flight", group: 6, order: 0}, // 航空機代
      {id: 40, name: "accommodation", group: 6, order: 0}, // 宿泊費
      {id: 41, name: "cosmeticsBeauty", group: 4, order: 0}, // 化粧品・美容
      {id: 42, name: "wiredCommunication", group: 3, order: 0}, // 有線通信代
      {id: 43, name: "highway", group: 6, order: 0}, // 有料道路代
      {id: 44, name: "waterSupply", group: 3, order: 0}, // 水道料金
      {id: 45, name: "parking", group: 6, order: 0}, // 駐車場料金
      {id: 46, name: "carRental", group: 6, order: 0}, // レンタカー代
      {id: 47, name: "carInsurance", group: 7, order: 0}, // 自動車保険料
      {id: 48, name: "lifeInsurance", group: 7, order: 0}, // 生命保険料
      {id: 49, name: "propertyInsurance", group: 7, order: 0}, // 損害保険料
      {id: 51, name: "generalInsurance", group: 7, order: 0}, // その他保険料
      {id: 52, name: "memberDues", group: ITEM_GROUP__GENERAL, order: 0}, // 年会費・月謝
      {id: 53, name: "atmFee", group: ITEM_GROUP__GENERAL, order: 0}, // ATM手数料
      {id: 54, name: "bankTransferFee", group: ITEM_GROUP__GENERAL, order: 0}, // 銀行振込手数料
      {id: 55, name: "interest", group: ITEM_GROUP__GENERAL, order: 0}, // 利息
      {id: 56, name: "shipping", group: ITEM_GROUP__GENERAL, order: 0}, // 郵送料
      {id: 57, name: "exerciseEquipment", group: 5, order: 0}, // 運動用具
      {id: 58, name: "preciousMetalJewelry", group: ITEM_GROUP__GENERAL, order: 0}, // 貴金属・宝飾類
      {id: 59, name: "pocketMoney", group: ITEM_GROUP__GENERAL, order: 0}, // こづかい・仕送り
      {id: 60, name: "tuition", group: 5, order: 0}, // 学費
      {id: 61, name: "carBike", group: 9, order: 0}, // 車・バイク
      {id: 62, name: "ceremonialFuneral", group: ITEM_GROUP__GENERAL, order: 0}, // 冠婚葬祭費
      {id: 63, name: "securitiesTrade", group: 9, order: 0}, // 有価証券売買
      {id: 64, name: "realEstateTrade", group: 9, order: 0}, // 不動産売買
      {id: 65, name: "generalAssetTrade", group: 9, order: 0}, // その他資産売買
      {id: 66, name: "housingLoan", group: 3, order: 0}, // 住宅ローン
      {id: 67, name: "carLoan", group: 6, order: 0}, // 自動車ローン
      {id: 68, name: "generalLoan", group: ITEM_GROUP__GENERAL, order: 0}, // その他ローン
      {id: 69, name: "generalIncome", group: ITEM_GROUP__GENERAL, order: 0}, // その他収入
      {id: 70, name: "generalSpending", group: ITEM_GROUP__GENERAL, order: 0}, // その他支出
      {id: 71, name: "gameApp", group: 4, order: 0}, // ゲーム・アプリ
      {id: 72, name: "show", group: 4, order: 0}, // 観戦・鑑賞
      {id: 73, name: "generalDevelopment", group: 5, order: 0}, // 教養・啓発費
      {id: 74, name: "gymLesson", group: 5, order: 0}, // ジム・レッスン
  
      {id: ITEM__UNACCOUNTED, name: "unaccounted", group: -1, order: 0}, // 未記帳金
    ],
    itemGroups: [
      {id: ITEM_GROUP__UNCOUNTING, name: "notCounted", order: 0}, // 集計対象外
      {id: ITEM_GROUP__GENERAL, name: "generalItems", order: 0}, // 一般科目
      {id: 1, name: "netIncome", order: 0}, // 手取り給与
      {id: 2, name: "generalLivingCost", order: 0}, // 一般生活費
      {id: 3, name: "livingInfra", order: 0}, // 住居インフラ
      {id: 4, name: "entertainment", order: 0}, // 遊興関連費
      {id: 5, name: "educationDevelopment", order: 0}, // 教育・能力開発
      {id: 6, name: "generalTrip", order: 0}, // 旅費
      {id: 7, name: "insurances", order: 0}, // 保険料
      {id: 8, name: "electricHardwareService", order: 0}, // 電子製品・電子サービス
      {id: 9, name: "assetTrade", order: 0}, // 資産取引
    ],
  };
  
  popularItems.items = popularItems.items.map((item, i) => {
    return {
      ...item,
      order: i,
    };
  });
  
  popularItems.itemGroups = popularItems.itemGroups.map((itemGroup, i) => {
    return {
      ...itemGroup,
      order: i,
    };
  });
  
  return popularItems;
};



type IFGetInitialAccounts = (currency: S.CurrencyId) => {accounts: S.AccountMaster[], accountTypes: S.AccountTypeMaster[]};

/**
 *
 * @return {{accounts: Array<object>, accountTypes: Array<object>}}
 */
export const getInitialAccounts: IFGetInitialAccounts = (currency: S.CurrencyId) => {
  const initialAccounts = {
    accounts: [
      {id: 0, name: "wallet", type: ACCOUNT_TYPE__CASH, order: 0, currency}, // 財布
      {id: 1, name: "bank", type: ACCOUNT_TYPE__CASH, order: 0, currency}, // 銀行A
      {id: 2, name: "card", type: ACCOUNT_TYPE__LOAN, order: 0, currency}, // クレジットカードA
    ],
    accountTypes: [
      {id: ACCOUNT_TYPE__CASH, name: "cash", type: "totalAsset" as S.AccountTypeCategory, order: 0}, // 現金
      {id: ACCOUNT_TYPE__LOAN, name: "loan", type: "liability" as S.AccountTypeCategory, order: 0}, // 借金
    ],
  };
  
  initialAccounts.accounts = initialAccounts.accounts.map((account, i) => {
    return {
      ...account,
      order: i,
    };
  });
  
  initialAccounts.accountTypes = initialAccounts.accountTypes.map((accountType, i) => {
    return {
      ...accountType,
      order: i,
    };
  });
  
  return initialAccounts;
};
