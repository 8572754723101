export const ITEM__UNACCOUNTED = -1;
export const ITEM__START_AMOUNT = -2;
export const ITEM_GROUP__UNCOUNTING = -1;
export const ITEM_GROUP__GENERAL = -2;
export const ACCOUNT_TYPE__CASH = -1;
export const ACCOUNT_TYPE__LOAN = -2;

export const Demo_defaultParams = {
  repayment_method: "equalPayments",
  income_employmentInsuranceChargeRate: 0.3,
  income_feeding: 0,
  income_healthInsuranceChargeRate: 4.4,
  income_overtimeRaiseRate: 25,
  income_payday: 25,
  income_pensionChargeRate: 9.15,
  income_workingHours: 8,
  spending_debtBonusChargeRate: 20,
};

export const CURRENCY_JPY = {
  id: -1, label: "JPY", symbol: "円", format: {pos: "%v %s", neg: "-%v %s", zero: "0 %s"}, precision: 0, thousand: ",", decimal: ".",
};

export const CURRENCY_USD = {
  id: -2, label: "USD", symbol: "$", format: {pos: "%s %v", neg: "(%s %v)", zero: "%s 0"}, precision: 2, thousand: ",", decimal: "."};

export const CURRENCY_EUR = {
  id: -3, label: "EUR", symbol: "€", format: {pos: "%s %v", neg: "-%s %v", zero: "%s 0"}, precision: 2, thousand: ".", decimal: ",",
};

export const CURRENCY_CNY = {
  id: -4, label: "CNY", symbol: "元", format: {pos: "%v %s", neg: "-%v %s", zero: "0 %s"}, precision: 0, thousand: ",", decimal: ".",
};
