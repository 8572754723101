import React, {useEffect} from "react";
import CircularProgress from "@material-ui/core/CircularProgress";
import {withTranslation} from "react-i18next";
import {ThunkDispatch} from "redux-thunk";
import {connect} from "react-redux";
import i18next from "i18next";

import {getCurrentUser} from "../../backends/firebase/api";
import ConfirmDialog from "./ConfirmDialog";
import * as A from "../../actions";
import withStyles, {StyleRulesCallback} from "@material-ui/core/styles/withStyles";
import {Theme} from "@material-ui/core";
import {Lang} from "../../reducers/App/state";
import {RootState} from "../../reducers";
import {Omit, TReservedHocProps} from "../type";

interface IEmailVerificationListenerProps {
  interval?: number;
  onVerified: () => any;
}

function EmailVerificationListener(props: IEmailVerificationListenerProps){
  useEffect(() => {
    const {onVerified, interval} = props;
    
    const checkVerified = async () => {
      const user = getCurrentUser();
      if(!user){
        return;
      }
      
      await user.reload();
      return user.emailVerified;
    };
    
    let timer: number|undefined = window.setInterval(async () => {
      const verified = await checkVerified();
      if(verified){
        window.clearInterval(timer);
        timer = undefined;
        
        onVerified();
      }
    }, typeof(interval) === "number" ? interval : 3000);
    
    return function cancelToCheckVerification(){
      if(typeof(timer) === "number"){
        window.clearInterval(timer);
        timer = undefined;
      }
    };
  });
  
  return null;
}


interface IEmailVerificationMonitorProps {
  i18n: i18next.i18n;
  lang: Lang;
  theme: Theme;
  onClose: () => any;
  onVerified: () => any;
}



class EmailVerificationMonitor extends React.Component<IEmailVerificationMonitorProps> {
  public render(){
    const {theme, lang, i18n, onClose, onVerified} = this.props;
    const t = i18n.getFixedT(lang, "Auth");
    
    return (
      <ConfirmDialog
        alert={true}
        lang={lang}
        onClickOK={onClose}
        okButtonLabel={t("general:closeDialog")}
        title={t("addAccountDialog.verificationEmailSent")}
      >
        <EmailVerificationListener
          onVerified={onVerified}
        />
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
          }}
        >
          <div>
            <CircularProgress color="secondary"/>
          </div>
          <div style={{padding: theme.spacing.unit*2}}>
            {t("addAccountDialog.keepDialogToAutoLogin")}
          </div>
        </div>
      </ConfirmDialog>
    );
  }
}



const styles: StyleRulesCallback = (theme) => ({
});


let _Component: React.ComponentType<any> = EmailVerificationMonitor;
_Component = withTranslation("Auth")(_Component);
_Component = withStyles(styles, {withTheme: true})(_Component);

const ComponentWithHoc = _Component as React.ComponentType<Omit<IEmailVerificationMonitorProps, TReservedHocProps>>;





const mapStateToProps = (rootReduxState: RootState) => {
  const {lang} = rootReduxState.App;
  
  return {
    lang,
  };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<RootState, undefined, A.RootActions>) => {
  return {
  };
};

const AddAccountDialogContainer = connect(mapStateToProps, mapDispatchToProps)(ComponentWithHoc);

export default AddAccountDialogContainer;
