import React from "react";
import {withTranslation} from "react-i18next";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import InputLabel from "@material-ui/core/InputLabel";
import Input from "@material-ui/core/Input";
import FormControl from "@material-ui/core/FormControl";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import IconButton from "@material-ui/core/IconButton";
import {ERROR_reason} from "../provider";
import {connect} from "react-redux";
import AddAccountIcon from "@material-ui/icons/PersonAdd";

import i18next from "i18next";
import * as A from "../../actions";
import {Theme} from "@material-ui/core/styles/createMuiTheme";
import withStyles, {ClassNameMap, StyleRulesCallback} from "@material-ui/core/styles/withStyles";
import {ThunkDispatch} from "redux-thunk";
import firebase from "firebase/app";
import {getCurrentUser, setLanguageForAuth} from "../../backends/firebase/api";
import EmailVerificationMonitor from "./EmailVerificationMonitor";
import ConfirmDialog from "./ConfirmDialog";
import {Lang} from "../../reducers/App/state";
import {RootState} from "../../reducers";
import {Omit, TReservedHocProps} from "../type";


interface ICreateAccountDialogProps {
  classes: ClassNameMap;
  theme: Theme;
  i18n: i18next.i18n;
  lang: Lang;
  onCancel: () => void;
  onOK: () => void;
  createUserAccount: (
    user: string,
    password: string,
  ) => Promise<firebase.auth.UserCredential>;
  initializeUserAccountAfterEmailIsVerified: (
    user: firebase.User,
  ) => Promise<void>;
  isLoggedIn: boolean;
}

interface ICreateAccountDialogState {
  user: string;
  password: string;
  passwordConfirm: string;
  showPassword: boolean;
  showPasswordConfirm: boolean;
  addingError: boolean | string;
  addingAccount: boolean;
  dialogOpen: string;
}


class CreateAccountDialog extends React.Component<ICreateAccountDialogProps, ICreateAccountDialogState> {
  constructor(props: ICreateAccountDialogProps){
    super(props);
    
    const {isLoggedIn} = props;
    
    this.createForm = this.createForm.bind(this);
    this.onClickOK = this.onClickOK.bind(this);
    this.onChangeUsername = this.onChangeUsername.bind(this);
    this.onChangePassword = this.onChangePassword.bind(this);
    this.onClickShowPassword = this.onClickShowPassword.bind(this);
    this.onMouseDownPassword = this.onMouseDownPassword.bind(this);
    this.onChangePasswordConfirm = this.onChangePasswordConfirm.bind(this);
    this.onClickShowPasswordConfirm = this.onClickShowPasswordConfirm.bind(this);
    this.onMouseDownPasswordConfirm = this.onMouseDownPasswordConfirm.bind(this);
    
    this.state = {
      user: "",
      password: "",
      passwordConfirm: "",
      showPassword: false,
      showPasswordConfirm: false,
      addingError: false,
      addingAccount: false,
      dialogOpen: "",
    };
  }
  
  public render(){
    const {
      classes,
      theme,
      i18n,
      lang,
      onOK,
      onCancel,
      isLoggedIn,
      initializeUserAccountAfterEmailIsVerified,
    } = this.props;
    const {dialogOpen, addingAccount} = this.state;
    const t = i18n.getFixedT(lang, "Auth");
    
    let providerForm;
    const providerFormStyle: React.CSSProperties = {};
    let error = null;
  
    const ret = this.createForm();
    if(ret){
      ({providerForm, error} = ret);
    
      providerFormStyle.maxHeight = 400;
    }
    
    const disableOKButton = Boolean(error || addingAccount);
    
    const dialogTitle = t<string>("addAccountDialog.createAccount");
    
    const dialog = (() => {
      if(dialogOpen === "addingAccount"){
        return (
          <ConfirmDialog
            alert={true}
            lang={lang}
            title={t("addAccountDialog.sendingVerificationEmail")}
            okButtonLabel={t("general:closeDialog")}
            onClickOK={() => {
              this.setState({
                dialogOpen: "",
              });
            }}
          />
        );
      }
      else if(dialogOpen === "verifyingEmail"){
        return (
          <EmailVerificationMonitor
            onClose={() => {
              this.setState({
                dialogOpen: "",
              }, () => {
                if(typeof(onCancel) === "function"){
                  onCancel();
                }
              });
            }}
            onVerified={async () => {
              if(process.env.REACT_APP_ENV === "development"){
                console.log("Email verification confirmed!");
              }
  
              const user = getCurrentUser();
              if(!user){
                return this.setState({
                  dialogOpen: "",
                  addingError: t<string>("addAccountDialog.loginStateExpired"),
                });
              }
  
              await initializeUserAccountAfterEmailIsVerified(user);
            }}
          />
        );
      }
      
      return null;
    })();
    
    return (
      <div>
        <Dialog
          open={true}
          onClose={onCancel}
          scroll="paper"
          aria-labelledby="scroll-dialog-title"
          classes={{paper: classes.dialogPaper, root: classes.dialogRoot}}
          disableBackdropClick={true}
          disableEscapeKeyDown={true}
        >
          <DialogContent classes={{root: classes.dialogContentRoot}}>
            <div className={classes.iconContainer}>
              <div className={classes.icon}>
                <AddAccountIcon fontSize="large" color="primary"/>
              </div>
              <div>{dialogTitle}</div>
            </div>
            <div className={classes.providerForm} style={providerFormStyle}>
              {providerForm}
            </div>
          </DialogContent>
          <DialogActions>
            <Button onClick={onCancel} color="primary" disabled={addingAccount}>
              {t<string>("addAccountDialog.dialogCancel")}
            </Button>
            <Button
              color="primary"
              disabled={disableOKButton}
              onClick={this.onClickOK}
            >
              {t<string>("addAccountDialog.dialogOK")}
            </Button>
          </DialogActions>
        </Dialog>
        {dialog}
      </div>
    );
  }
  
  public createForm(){
    const {
      classes,
      i18n,
      lang,
      isLoggedIn,
    } = this.props;
    const {
      user,
      password,
      passwordConfirm,
      showPassword,
      showPasswordConfirm,
      addingAccount,
    } = this.state;
    let {
      addingError,
    } = this.state;
    const t = i18n.getFixedT(lang, "Auth");
    
    let error = null;
    let providerForm = null;
    
    if(user.length < 1){
      error = t<string>("addAccountDialog.emptyUsername");
    }
    else if(password.length < 1){
      error = t<string>("addAccountDialog.emptyPassword");
    }
    else if(password !== passwordConfirm){
      error = t<string>("addAccountDialog.passwordNotMatch");
    }
    
    if(addingError && typeof(addingError) !== "string"){
      addingError = t<string>("addAccountDialog.failedToAdd");
    }
  
    providerForm = (
      <form>
        <FormControl className={classes.accountFormControl}>
          <TextField
            required={true}
            fullWidth={true}
            id="addProvider-username"
            label={t<string>("addAccountDialog.username")}
            value={user}
            onChange={this.onChangeUsername}
            autoComplete="off"
            autoFocus={true}
            disabled={addingAccount}
          />
        </FormControl>
        <FormControl className={classes.accountFormControl}>
          <InputLabel htmlFor="addProvider-adornment-password">{t<string>("addAccountDialog.password")} *</InputLabel>
          <Input
            fullWidth={true}
            required={true}
            id="addProvider-adornment-password"
            type={showPassword ? "text" : "password"}
            value={password}
            onChange={this.onChangePassword}
            autoComplete="new-password"
            disabled={addingAccount}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="Toggle password visibility"
                  onClick={this.onClickShowPassword}
                  onMouseDown={this.onMouseDownPassword}
                >
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            }
          />
        </FormControl>
        <FormControl className={classes.accountFormControl}>
          <InputLabel htmlFor="addProvider-adornment-password2">{t<string>("addAccountDialog.passwordConfirm")} *</InputLabel>
          <Input
            fullWidth={true}
            required={true}
            id="addProvider-adornment-password2"
            type={showPasswordConfirm ? "text" : "password"}
            value={passwordConfirm}
            onChange={this.onChangePasswordConfirm}
            autoComplete="new-password"
            disabled={addingAccount}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="Toggle password visibility"
                  onClick={this.onClickShowPasswordConfirm}
                  onMouseDown={this.onMouseDownPasswordConfirm}
                >
                  {showPasswordConfirm ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            }
          />
        </FormControl>
        <div className={classes.inputError}>
          {addingError || error}
        </div>
      </form>
    );
    
    return {
      providerForm,
      error,
      addingError,
    };
  }
  
  public onClickOK(){
    const {
      createUserAccount,
      lang,
    } = this.props;
    const {
      user,
      password,
      passwordConfirm,
    } = this.state;
  
    if(user.length < 1){
      return;
    }
    else if(password.length < 1){
      return;
    }
    else if(password !== passwordConfirm){
      return;
    }
  
    this.setState({
      addingAccount: true,
      dialogOpen: "addingAccount",
    }, async () => {
      let error;
  
      setLanguageForAuth(lang);
      await createUserAccount(user, password)
        .catch(reason => {
          const {
            i18n,
          } = this.props;
          const t = i18n.getFixedT(lang, "Auth");
          error = true;
  
          let addingError: string|boolean = true;
  
          if(reason.code === "auth/email-already-in-use"){
            addingError = t<string>("addAccountDialog.emailAlreadyInUse");
          }
          else if(reason.code === "auth/invalid-email"){
            addingError = t<string>("addAccountDialog.invalidEmail");
          }
          else if(reason.code === "auth/operation-not-allowed"){
            addingError = t<string>("addAccountDialog.operationNotAllowed");
          }
          else if(reason.code === "auth/weak-password"){
            addingError = t<string>("addAccountDialog.weakPassword");
          }
          else if(reason.name === "account-already-exists"){
            addingError = t<string>("addAccountDialog.accountAlreadyExists");
          }
          else if(reason.message === ERROR_reason.failedToDecryptData){
            addingError = t<string>("addAccountDialog.dataAlreadySaveWithDifferentPassword");
          }
          else if(reason.name === "email-verification-error"){
            addingError = t<string>("addAccountDialog.failedToSendVerificationEmail");
          }
  
          this.setState({
            addingError,
            addingAccount: false,
            dialogOpen: "",
          });
        });
      
      if(error){
        return;
      }
      
      this.setState({
        dialogOpen: "verifyingEmail",
      });
    });
  }
  
  public onChangeUsername(e: React.ChangeEvent<HTMLTextAreaElement>){
    this.setState({
      user: e.target.value,
    });
  }
  
  public onChangePassword(e: React.ChangeEvent<HTMLInputElement>){
    this.setState({
      password: e.target.value,
    });
  }
  
  public onClickShowPassword(e: React.MouseEvent<HTMLElement>){
    this.setState(prevState => ({
      showPassword: !prevState.showPassword,
    }));
  }
  
  public onMouseDownPassword(e: React.MouseEvent){
    e.preventDefault();
  }
  
  public onChangePasswordConfirm(e: React.ChangeEvent<HTMLInputElement>){
    this.setState({
      passwordConfirm: e.target.value,
    });
  }
  
  public onClickShowPasswordConfirm(e: React.MouseEvent){
    this.setState(prevState => ({
      showPasswordConfirm: !prevState.showPasswordConfirm,
    }));
  }
  
  public onMouseDownPasswordConfirm(e: React.MouseEvent){
    e.preventDefault();
  }
}






const styles: StyleRulesCallback = (theme) => ({
  dialogRoot: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  dialogPaper: {
    minWidth: 320,
    width: 440,
  },
  dialogContentRoot: {
    flex: "1 1 auto",
    padding: "0 24px",
    overflowY: "auto",
  },
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
  formControl: {
    margin: theme.spacing.unit,
    minWidth: 120,
    width: "100%",
  },
  pleaseSelect: {
    fontSize: theme.typography.fontSize * .9,
  },
  providerForm: {
    transition: "all ease .3s",
    maxHeight: 0,
    overflow: "hidden",
    marginTop: theme.spacing.unit * 2,
    margin: theme.spacing.unit,
  },
  accountFormControl: {
    display: "block",
    marginBottom: theme.spacing.unit,
  },
  inputError: {
    color: theme.palette.error.main,
    fontSize: theme.typography.fontSize * .9,
    marginTop: theme.spacing.unit,
  },
  iconContainer: {
    marginBottom: theme.spacing.unit*3,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    fontSize: "1.4rem",
  },
});

let _Component: React.ComponentType<any> = CreateAccountDialog;
_Component = withTranslation("Auth")(_Component);
_Component = withStyles(styles, {withTheme: true})(_Component);

const ComponentWithHoc = _Component as React.ComponentType<Omit<ICreateAccountDialogProps, TReservedHocProps>>;





const mapStateToProps = (rootReduxState: RootState) => {
  const {lang, account} = rootReduxState.App;
  
  return {
    lang,
    isLoggedIn: Boolean(account),
  };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<RootState, undefined, A.RootActions>) => {
  return {
    createUserAccount: (
      uid: string,
      password: string,
    ) => dispatch<Promise<firebase.auth.UserCredential>>(A.createUserAccount(uid, password)),
    initializeUserAccountAfterEmailIsVerified: (
      user: firebase.User,
    ) => dispatch<Promise<void>>(A.initializeUserAccountAfterEmailIsVerified(user)),
  };
};

const AddAccountDialogContainer = connect(mapStateToProps, mapDispatchToProps)(ComponentWithHoc);

export default AddAccountDialogContainer;
