import {connect} from "react-redux";
import Home from "../";
import {ThunkDispatch} from "redux-thunk";
import * as A from "../../../../actions";
import {getCurrencyInState} from "../../../../core/util/currency";
import {RootState} from "../../../../reducers";


const mapStateToProps = (rootReduxState: RootState) => {
  const {lang, account, theme: currentTheme} = rootReduxState.App;
  const currency =  getCurrencyInState(rootReduxState.Finance);
  
  const isLoggedIn = Boolean(account);
  
  return {isLoggedIn, lang, currency, currentTheme};
};

const mapDispatchToProps = (dispatch: ThunkDispatch<RootState, undefined, A.RootActions>) => {
  return {
  };
};

const HomeContainer = connect(mapStateToProps, mapDispatchToProps)(Home);

export default HomeContainer;
