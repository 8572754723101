import AbstractStorage from "./abstractStorage";
import {StorageProvider} from "../../reducers/App/state";

export const type: StorageProvider = "memory";

export default class MemoryStorage extends AbstractStorage {
  protected __namespace = "variableStorage";
  
  constructor(id: string | undefined){
    super(type);
    
    if(typeof(id) === "string"){
      this.__namespace = `${this.__namespace}/${id}`;
    }
  
    // @ts-ignore
    if(!window[this.__namespace]){
      // @ts-ignore
      window[this.__namespace] = {};
    }
  }
  
  /**
   * @param {string} name
   * @returns {Promise<any>}
   */
  public async get(name: string): Promise<any>{
    try{
      // @ts-ignore
      return window[this.__namespace][name];
    }
    catch(e){
      return null;
    }
  }
  
  /**
   * @param {string} name
   * @param {*} value
   * @returns {Promise<any>}
   */
  public async save(name: string, value: any): Promise<{name: string, value: any}> {
    // @ts-ignore
    if(!window[this.__namespace]){
      // @ts-ignore
      window[this.__namespace] = {};
    }
  
    // @ts-ignore
    window[this.__namespace][name] = value;
    
    return {
      name,
      // @ts-ignore
      value: window[this.__namespace][name],
    };
  }
  
  /**
   * @param {string} name
   * @returns {Promise<any>}
   */
  public async remove(name: string): Promise<string> {
    // @ts-ignore
    if(!window[this.__namespace]){
      return name;
    }
  
    // @ts-ignore
    delete window[this.__namespace][name];
    return name;
  }
}
