import React, {ReactElement} from "react";
import {withTranslation} from "react-i18next";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import InputLabel from "@material-ui/core/InputLabel";
import Input from "@material-ui/core/Input";
import FormControl from "@material-ui/core/FormControl";
import InputAdornment from "@material-ui/core/InputAdornment";
import CircularProgress from "@material-ui/core/CircularProgress";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import IconButton from "@material-ui/core/IconButton";
import {connect} from "react-redux";
import DropZone, {ImageFile} from "react-dropzone";

import i18next from "i18next";
import * as A from "../../actions";
import {Theme} from "@material-ui/core/styles/createMuiTheme";
import withStyles, {ClassNameMap, StyleRulesCallback} from "@material-ui/core/styles/withStyles";
import {ThunkDispatch} from "redux-thunk";
import {Lang} from "../../reducers/App/state";
import {EncryptedRootState, RootState} from "../../reducers";
import {Omit, TReservedHocProps} from "../type";


const dropZoneStylesTemplate = {
  height: 120,
  opacity: 1,
  margin: "auto",
  border: "1px dashed rgba(33,33,33,.3)",
  overflow: "hidden",
};

interface ILoadDialogProps {
  classes: ClassNameMap;
  theme: Theme;
  i18n: i18next.i18n;
  lang: Lang;
  onCancel: () => any;
  onOK: () => any;
  open?: boolean;
  loadAccount: (
    data: RootState | EncryptedRootState,
    password: string,
  ) => Promise<void>;
}

interface ILoadDialogState {
  loading: boolean;
  failedCount: number;
  file: RootState | EncryptedRootState | null;
  encrypted: boolean | null;
  loadFailed: string | null;
  showPassword: boolean;
  password: string;
}


class LoadDialog extends React.Component<ILoadDialogProps, ILoadDialogState> {
  constructor(props: ILoadDialogProps){
    super(props);
    
    this.dragMessage = this.dragMessage.bind(this);
    this.onDropFile = this.onDropFile.bind(this);
    this.handleMouseDownPassword = this.handleMouseDownPassword.bind(this);
    this.handleClickShowPassword = this.handleClickShowPassword.bind(this);
    this.onClickOK = this.onClickOK.bind(this);
    this.onClickClear = this.onClickClear.bind(this);
    this.onKeyDown = this.onKeyDown.bind(this);
    
    this.state = {
      loading: false,
      failedCount: 0,
      file: null,
      encrypted: null,
      loadFailed: "",
      showPassword: false,
      password: "",
    };
  }
  
  public render(){
    const {classes, i18n, lang, onCancel, theme} = this.props;
    let {open} = this.props;
    const {file, encrypted, loadFailed, showPassword, password, loading} = this.state;
    const t = i18n.getFixedT(lang, "Auth");
    let loadFailureComponent: ReactElement<{}> | string | null = loadFailed;
    
    open = open !== false;
    
    const disableOKButton = (file === null);
  
    let dropZoneStyles = dropZoneStylesTemplate;
    if(file !== null){
      dropZoneStyles = {
        ...dropZoneStyles,
        height: 0,
        opacity: 0,
      };
    }
    
    if(loading){
      loadFailureComponent = (
        <CircularProgress size={theme.typography.fontSize} color="primary"/>
      );
    }
    
    
    let additionalContent;
    if((loading || loadFailed) && file === null){
      additionalContent = (
        <div style={{color: "red", marginTop: 16, textAlign: "center"}}>
          {loadFailureComponent}
        </div>
      );
    }
    else if(file !== null && encrypted){
      additionalContent = (
        <div style={{marginTop: 16, textAlign: "center"}}>
          <div style={{marginBottom: 8}}>{t<string>("loadDialog.fileEncrypted")}</div>
          <form>
            <FormControl>
              <InputLabel htmlFor="adornment-password">Password</InputLabel>
              <Input
                id="adornment-password"
                type={showPassword ? "text" : "password"}
                value={password}
                onChange={e => this.setState({password: e.target.value})}
                onKeyDown={this.onKeyDown}
                autoFocus={true}
                autoComplete="current-password"
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="Toggle password visibility"
                      onClick={this.handleClickShowPassword}
                      onMouseDown={this.handleMouseDownPassword}
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                }
              />
            </FormControl>
          </form>
          <div style={{marginTop: 8, color: "red"}}>
            {loadFailureComponent}
          </div>
        </div>
      );
    }
    else if(file !== null){
      additionalContent = (
        <div>
          {t<string>("loadDialog.proceed")}
        </div>
      );
    }
    
    return (
      <div>
        <Dialog
          open={open}
          onClose={() => onCancel()}
          scroll="body"
          aria-labelledby="scroll-dialog-title"
          classes={{paper: classes.dialogRoot}}
        >
          <DialogTitle id="scroll-dialog-title">{t<string>(`loadDialog.title`)}</DialogTitle>
          <DialogContent>
            <DropZone
              onDrop={this.onDropFile}
              inputProps={{style: {width: "100%"}}}
              multiple={false}
              style={dropZoneStyles}
              activeClassName={classes.dropping}
            >
              {this.dragMessage}
            </DropZone>
            <div>
              {additionalContent}
            </div>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.onClickClear} color="primary" disabled={file===null}>
              {t<string>("loadDialog.clear")}
            </Button>
            <Button onClick={this.onClickOK} color="primary" disabled={disableOKButton}>
              {t<string>("loadDialog.OK")}
            </Button>
            <Button onClick={() => onCancel()} color="primary">
              {t<string>("loadDialog.Cancel")}
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
  
  public dragMessage({isDragActive}: {isDragActive: boolean}){
    const {classes, i18n, lang} = this.props;
    const t = i18n.getFixedT(lang, "Finance");
    
    if(isDragActive){
      return (
        <div className={classes.dropHere}>
          {t<string>("input.flow.dropHere")}
        </div>
      );
    }
    else{
      return (
        <div className={classes.dropHere}>
          {t<string>("input.flow.dropOver")}
        </div>
      );
    }
  }
  
  public onDropFile(acceptedFiles: ImageFile[]){
    const {i18n, lang} = this.props;
    const t = i18n.getFixedT(lang, "Finance");
    
    this.setState({
      file: null,
    });
    
    if(!acceptedFiles || !acceptedFiles[0]){
      this.setState({loadFailed: t<string>("input.flow.dropAborted"), file: null});
    }
    
    const file = acceptedFiles[0];
    
    const reader = new FileReader();
    reader.onabort = () => this.setState({loadFailed: t<string>("input.flow.dropAborted"), file: null});
    reader.onerror = () => this.setState({loadFailed: t<string>("input.flow.dropError"), file: null});
    reader.onload = () => {
      const fileAsText = reader.result;
      
      try{
        if(typeof(fileAsText) !== "string"){
          this.setState({
            file: null,
            encrypted: null,
            loadFailed: t<string>("Auth:loadDialog.invalidFormat"),
          });
          return;
        }
        
        const fileData = JSON.parse(fileAsText);
  
        const encrypted = typeof(fileData.App) === "string";
  
        this.setState({
          file: fileData,
          encrypted,
          password: "",
          loadFailed: "",
        });
      }
      catch(e){
        this.setState({
          file: null,
          encrypted: null,
          loadFailed: t<string>("Auth:loadDialog.invalidFormat"),
        });
      }
    };
    
    reader.readAsText(file);
  }
  
  public handleMouseDownPassword(event: React.MouseEvent){
    event.preventDefault();
  }
  
  public handleClickShowPassword(){
    this.setState(state => ({ showPassword: !state.showPassword }));
  }
  
  public onClickOK(){
    const {loadAccount, i18n, lang, onOK} = this.props;
    const {file, password} = this.state;
    const t = i18n.getFixedT(lang, "Auth");
    
    const tryLoadAccount = () => {
      if(file === null){
        return;
      }
  
      loadAccount(file, password)
        .then(() => {
          onOK();
        })
        .catch((reason) => {
          this.setState(prevState => ({
            loading: true,
            failedCount: prevState.failedCount + 1,
            loadFailed: t<string>("loadDialog.loadAccountFailed"),
          }));
          
          setTimeout(() => {
            this.setState({
              loading: false,
            });
          }, 1000 * 2 ** (this.state.failedCount - 1));
        });
    };
  
    tryLoadAccount();
  }
  
  public onClickClear(){
    this.setState({
      file: null,
      loadFailed: null,
      encrypted: null,
      password: "",
    });
  }
  
  public onKeyDown(e: React.KeyboardEvent){
    if(e.key === "Enter"){
      this.onClickOK();
      e.stopPropagation();
      e.preventDefault();
    }
  }
}




const styles: StyleRulesCallback = (theme) => ({
  dropHere: {
    height: 120,
    lineHeight: "120px",
    textAlign: "center",
    fontSize: ".8rem",
  },
  dropping: {
    backgroundColor: "rgba(33,33,33,.05)",
  },
});

let _Component: React.ComponentType<any> = LoadDialog;
_Component = withTranslation("Auth")(_Component);
_Component = withStyles(styles, {withTheme: true})(_Component);

const ComponentWithHoc = _Component as React.ComponentType<Omit<ILoadDialogProps, TReservedHocProps>>;



const mapStateToProps = (rootReduxState: RootState) => {
  const AppState = rootReduxState.App;
  const {lang} = AppState;
  
  return {lang};
};

const mapDispatchToProps = (dispatch: ThunkDispatch<RootState, undefined, A.RootActions>) => {
  return {
    loadAccount: (
      data: RootState | EncryptedRootState,
      password: string,
    ) => dispatch<Promise<void>>(A.loadAccountFromData(data, password)),
  };
};

const LoadDialogContainer = connect(mapStateToProps, mapDispatchToProps)(ComponentWithHoc);

export default LoadDialogContainer;
