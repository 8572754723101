import {combineReducers} from "redux";
import appReducer from "./App";
import {AppState} from "./App/state";
import financeReducer from "../core/reducers/Finance";
import {FinanceState} from "../core/reducers/Finance/state";

import getInitialAppState from "./App/initialState";
import getInitialFinanceState from "../core/reducers/Finance/initialState";

export type StateType = "App" | "Finance";
export type SubState = AppState | FinanceState;

export interface RootState {
  App: AppState;
  Finance: FinanceState;
}

export type GetInitialState = () => RootState;

export interface EncryptedRootState {
  App: string;
  Finance: string;
}

export const getInitialState = (): RootState => ({
  App: getInitialAppState(),
  Finance: getInitialFinanceState(),
});
export const getInitialState_App = () => getInitialAppState();
export const getInitialState_Finance = () => getInitialFinanceState();


export default combineReducers({
  App: appReducer, // This reducer manages state under `state.App`
  Finance: financeReducer,
});

