import * as A from "../../../core/actions/types/finance";
import * as S from "../../../core/reducers/Finance/state";
import * as T from "../../../core/actions/types/constant";

export const changeCurrency = (currencyId: number): A.ChangeCurrencyAction => {
  return {
    type: T.CHANGE_CURRENCY,
    payload: {currencyId},
  };
};

export const modifyCurrency = (currencyId: number, currency: S.Currency): A.ModifyCurrencyAction => {
  return {
    type: T.MODIFY_CURRENCY,
    payload: {currencyId, currency},
  };
};
