import "setimmediate";
import {Buffer as _Buffer} from "buffer/";
import aesjs from "aes-js";
import scrypt from "scrypt-js";
import pako from "pako";
import sha256 from "fast-sha256";

interface IGenerateKeyFuncOption {
  salt?: string;
  N?: number;
  r?: number;
  p?: number;
  dkLen?: number;
}
type GenerateKeyFunc = (password: string, option?: IGenerateKeyFuncOption) => Promise<ReadonlyArray<number>>;
/**
 * @param {string} password
 * @param {Object} option
 * @returns {Promise<string>}
 */
const generateKey: GenerateKeyFunc = (password, option) => {
  const passwordBuffer = new _Buffer(password.normalize("NFKC"));
  let salt: any;
  let N = 1024;
  let r = 8;
  let p = 1;
  let dkLen = 32;
  
  if(option){
    if(typeof(option.salt) === "string"){
      salt = new _Buffer(option.salt.normalize("NFKC"));
    }
    if(typeof(option.N) === "number"){
      N = option.N;
    }
    if(typeof(option.r) === "number"){
      r = option.r;
    }
    if(typeof(option.p) === "number"){
      p = option.p;
    }
    if(typeof(option.dkLen) === "number"){
      dkLen = option.dkLen;
    }
  }
  
  if(!salt){
    salt = new _Buffer("salt".normalize("NFKC"));
  }
  
  let isFinished = false;
  
  return new Promise((resolve, reject) => {
    scrypt((passwordBuffer as any), (salt as any), N, r, p, dkLen, (error, progress, key) => {
      if(isFinished){
        throw new Error("Callback called after function finished");
      }
      
      if(error){
        isFinished = true;
        return reject(error);
      }
      else if(key){
        isFinished = true;
        return resolve(key);
      }
    });
  });
};



type EncryptDataFunc = (password: string, data: any, option?: IGenerateKeyFuncOption) => Promise<string>;
/**
 * @param {string} password
 * @param {*} data
 * @param {Object=} option
 * @returns {Promise<string>}
 */
export const encryptData: EncryptDataFunc = (password, data, option) => {
  if(data === void 0){
    return Promise.reject("data is undefined");
  }
  
  return new Promise((resolve, reject) => {
    generateKey(password, option)
      .then(key => {
        const dataStr = JSON.stringify(data);
        const dataBytes = aesjs.utils.utf8.toBytes(dataStr);
        const dataSize = dataBytes.length;
        const dataBytesCompressed = pako.deflate(dataBytes);
        const compressedSize = dataBytesCompressed.length;
        
        console.debug(
          `Compressed data for ${dataSize} >>> ${compressedSize} bytes. Compressed: ${Math.round(compressedSize/dataSize*100*10)/10}%`,
        );
        
        const aesCtr = new aesjs.ModeOfOperation.ctr((key as number[]), new aesjs.Counter(5));
        const encryptedBytes = aesCtr.encrypt(dataBytesCompressed);
        
        const encryptedHex = aesjs.utils.hex.fromBytes(encryptedBytes);
        resolve(encryptedHex);
      })
      .catch(reason => {
        reject(reason);
      });
  });
};



type decryptDataFunc = (password: string, encryptedHexData: string, option?: IGenerateKeyFuncOption) => Promise<any>;
/**
 * @param {string} password
 * @param {string} encryptedHexData
 * @param {Object=} option
 * @returns {Promise<*>}
 */
export const decryptData: decryptDataFunc = (password, encryptedHexData, option) => {
  if(typeof(encryptedHexData) !== "string"){
    return Promise.reject("data to decrypted must be string hex form");
  }
  
  return new Promise((resolve, reject) => {
    generateKey(password, option)
      .then(key => {
        const encryptedBytes = aesjs.utils.hex.toBytes(encryptedHexData);
        
        const aesCtr = new aesjs.ModeOfOperation.ctr((key as number[]), new aesjs.Counter(5));
        const decryptedBytes = aesCtr.decrypt(encryptedBytes);
        let uncompressedBytes: any;
        
        try{
          uncompressedBytes = pako.inflate(decryptedBytes);
        }
        catch(e){
          return resolve(null);
        }
        
        const decryptedStr = aesjs.utils.utf8.fromBytes(uncompressedBytes);
        try{
          const decryptedObj = JSON.parse(decryptedStr);
          resolve(decryptedObj);
        }
        catch(e){
          return resolve(null);
        }
      })
      .catch(reason => {
        reject(reason);
      });
  });
};



type hashFunc = (text: string) => string;
export const hash: hashFunc = (text) => {
  const textBuffer = new _Buffer(text.normalize("NFKC"));
  const hashedTextUint8Array = sha256(textBuffer);
  const hashedTextBuffer = _Buffer.from(hashedTextUint8Array);
  return hashedTextBuffer.toString("hex");
};
