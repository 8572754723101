import {applyMiddleware, createStore, Middleware, Store} from "redux";
import thunkMiddleware from "redux-thunk";
import {createLogger} from "redux-logger";
import {composeWithDevTools} from "redux-devtools-extension/developmentOnly";
import reducer, {getInitialState, RootState} from "./reducers";
import {Lang} from "./reducers/App/state";
import * as C from "./core/util/constants";
import {checkUserSignedIn, getCurrentUser, signOut} from "./backends/firebase/api";
import {getAll} from "./util/provider";
import {firebaseStorageType} from "./backends/storage";
import {updateRootState} from "./reducers/version";

type InitializeStoreFunc = () => Promise<{
  store: Store;
}>;

async function detectInitialState(): Promise<RootState> {
  const initialState = getInitialState();
  const url = new URL(window.location.href);
  const search = url.search;
  const queryString = search ? search.replace(/^\?/, "") : null;
  const searchParams = (queryString && window.URLSearchParams) ? new URLSearchParams(queryString) : null;
  
  const lang = (() => {
    if (searchParams && searchParams.has("lang")) {
      const l = searchParams.get("lang");
      if (l === "ja") {
        return (l as Lang);
      } else if (l === "en") {
        return (l as Lang);
      }
    }
    
    if (window.navigator.language === "ja") {
      return "ja" as Lang;
    } else if (window.navigator.language === "en") {
      return "en" as Lang;
    }
    
    return null;
  })();
  
  const currencyId = (() => {
    if (lang === "ja") {
      return C.CURRENCY_JPY.id;
    } else if (lang === "en") {
      return C.CURRENCY_USD.id;
    }
    
    return null;
  })();
  
  const theme = (() => {
    if (searchParams && searchParams.has("theme")) {
      const t = searchParams.get("theme");
      if (t && ["dark", "light_grey", "light_green"].includes(t)) {
        return t;
      }
    }
    
    return null;
  })();
  
  const activity = (() => {
    if (searchParams && searchParams.has("activity")) {
      const a = searchParams.get("activity");
      if (a && ["about"].includes(a)) {
        return a;
      }
    }
    
    return null;
  })();
  
  const modifyStateForBrowserParams = (s: RootState) => {
    if (lang !== null) {
      s.App = {
        ...s.App,
        lang,
      };
    }
    
    if (currencyId !== null) {
      s.Finance = {
        ...s.Finance,
        currencyId,
      };
    }
    
    if (theme !== null) {
      s.App = {
        ...s.App,
        theme,
      };
    }
    
    if (activity !== null) {
      s.App = {
        ...s.App,
        activity,
      };
    }
  };
  
  const user = await checkUserSignedIn();
  
  // If user is logging in with firebase, load data from the firebase account.
  if (user) {
    const state = await getAll(firebaseStorageType, "", "").catch(reason => {
      if (process.env.REACT_APP_ENV === "development") {
        console.debug("Failed to load initial state for user", getCurrentUser(), reason);
      }
      
      return null;
    });
    
    if (state) {
      const updatedState = updateRootState(state);
      modifyStateForBrowserParams(updatedState);
      return updatedState;
    } else {
      if (process.env.REACT_APP_ENV === "development") {
        console.log("Signed out from firebase account");
      }
      
      await signOut();
    }
  }
  
  modifyStateForBrowserParams(initialState);
  return initialState;
}

const initializeStore: InitializeStoreFunc = async () => {
  const middlewares = [thunkMiddleware] as Middleware[];
  
  if(process.env.NODE_ENV === "development"){
    const loggerMiddleware = createLogger();
    middlewares.push(loggerMiddleware);
    
    if(process.env.REACT_APP_ENABLE_REDUX_IMMUTABLE_STATE_INVARIANT === "true"){
      middlewares.push(require("redux-immutable-state-invariant").default());
    }
  }
  
  // Override initialState if told to do so.
  const initialState = await detectInitialState();
  
  const store = createStore(
    reducer,
    initialState,
    composeWithDevTools(applyMiddleware(...middlewares)),
  );
  
  // let unsubscribe = store.subscribe(function(){});
  
  return {
    store,
  };
};

export const stateItemName = "localState";
export default initializeStore;
