import {Action} from "redux";
import {ThunkAction} from "redux-thunk";
import firebase from "firebase/app";
import * as types from "../../types/constant";
import * as A from "../../types";
import * as DP from "../../../core/util/dataGenerator";
import {updateRootState} from "../../../reducers/version";
import getStorage, {firebaseStorageType, localStorageType, memoryStorageType} from "../../../backends/storage";
import {EncryptedRootState, getInitialState, RootState, SubState} from "../../../reducers";
import {getAll, remove, saveAll} from "../../../util/provider";
import {decryptData} from "../../../util/crypto";
import {
  checkUserHasContract,
  createAccountWithPassword,
  getCurrentUser,
  initUserAccountData as initializeUserDataInFirebase,
  signInByPassword,
  signInBySocial,
  signOut,
} from "../../../backends/firebase/api";
import {closeAccount} from "../../../backends/firebase/functions/closeAccount";
import * as SF from "../../../core/reducers/Finance/state";
import {
  DELETE_USER_ACCOUNT,
  FAILED_SWITCH_USER_ACCOUNT,
  LOAD_ENTIRE_STATE,
  LOGOUT,
  SWITCH_ACCOUNT,
  SWITCHING_ACCOUNT,
} from "../../../core/actions/types/constant";
import {
  DeleteUserAccountAction,
  LoadAccountFromDataAction,
  LogoutAction,
  SwitchAccountActions,
} from "../../../core/actions/types";
import {Account} from "../../../core/reducers/App/state";

type TAsyncAction<U extends Action, R = Promise<void>> = ThunkAction<R, RootState, undefined, U>;

type TDispatchError = (e: any) => void;
type TDispatchSuccess = (account: Account, state: RootState) => void;

async function __loadDataFromLocalStorage(
  uid: string,
  password: string,
  dispatchSuccess: TDispatchSuccess,
  dispatchError: TDispatchError,
){
  let error2 = "Failed to switch user account";
  const state = await getAll(localStorageType, uid, password)
    .catch(reason => {
      error2 = reason;
      return;
    });
  
  if(!state){
    if(process.env.REACT_APP_ENV === "development"){
      console.error("localStorage login error", error2);
    }
    
    dispatchError("no-data-in-localStorage");
    return;
  }
  
  const fbUser = getCurrentUser();
  
  const account: Account = {
    provider: localStorageType,
    uid,
    displayName: (fbUser && fbUser.displayName) || "",
    isEncrypted: Boolean(password),
  };
  
  dispatchSuccess(account, state);
  
  return;
}

async function __loadDataFromCloudStorage(
  uid: string,
  password: string,
  dispatchSuccess: TDispatchSuccess,
  dispatchError: TDispatchError,
){
  let error2;
  const state = await getAll(firebaseStorageType, uid, password)
    .catch(reason => {
      error2 = reason;
      return;
    });
  
  if(!state || error2){
    dispatchError(new Error(error2));
    return;
  }
  
  const fbUser = getCurrentUser();
  
  const account: Account = {
    provider: firebaseStorageType,
    uid,
    displayName: (fbUser && (fbUser.displayName || fbUser.email)) || "",
    isEncrypted: Boolean(password),
  };
  
  dispatchSuccess(account, state);
  
  return;
}

async function __loadDataFromStorageAvailable(
  uid: string,
  password: string,
  dispatchSuccess: TDispatchSuccess,
  dispatchError: TDispatchError,
){
  const hasValidContract = await checkUserHasContract(uid);
  if(!hasValidContract){
    if(process.env.REACT_APP_ENV === "development"){
      console.log("Since no contract for using network storage, switching to fetch data from localStorage");
    }
    
    await __loadDataFromLocalStorage(uid, password, dispatchSuccess, dispatchError);
    return;
  }
  
  await __loadDataFromCloudStorage(uid, password, dispatchSuccess, dispatchError);
  return;
}

export const loadDataFromLocalStorage = (
  uid: string,
  password: string,
): TAsyncAction<SwitchAccountActions<Account, RootState>> => {
  return async (dispatch, getState) => {
    dispatch({
      type: SWITCHING_ACCOUNT,
    });
  
    const dispatchError: TDispatchError = (e) => {
      dispatch({
        type: FAILED_SWITCH_USER_ACCOUNT,
      });
    
      throw e || new Error("login error");
    };
  
    const dispatchSuccess: TDispatchSuccess = (account, state) => {
      dispatch({
        type: SWITCH_ACCOUNT,
        payload: {
          account,
          state,
        },
      });
    };
    
    await __loadDataFromLocalStorage(uid, password, dispatchSuccess, dispatchError);
    return;
  };
};

export const login = (email: string, password: string): TAsyncAction<SwitchAccountActions<Account, RootState>> => {
  return async (dispatch, getState) => {
    dispatch({
      type: SWITCHING_ACCOUNT,
    });
    
    const dispatchError: TDispatchError = (e) => {
      dispatch({
        type: FAILED_SWITCH_USER_ACCOUNT,
      });
  
      throw e || new Error("login error");
    };
  
    const dispatchSuccess: TDispatchSuccess = (account, state) => {
      dispatch({
        type: SWITCH_ACCOUNT,
        payload: {
          account,
          state,
        },
      });
    };
    
    let error: any;
    const credential = await signInByPassword(email, password)
      .catch(e => {
        error = e;
        return null;
      });
    
    if(error){
      return dispatchError(error.code);
    }
  
    if(!credential){
      return dispatchError("Empty Credential");
    }
    
    const {user} = credential;
    if(!user || !user.uid){
      return dispatchError("User not exists in credential");
    }
    
    if(!user.emailVerified){
      return dispatchError("email-not-verified");
    }
    
    await __loadDataFromStorageAvailable(user.uid, user.uid, dispatchSuccess, dispatchError);
  };
};

export const socialLogin = (
  provider: firebase.auth.AuthProvider,
): TAsyncAction<SwitchAccountActions<Account, RootState>> => {
  return async (dispatch, getState) => {
    dispatch({
      type: SWITCHING_ACCOUNT,
    });
  
    const dispatchError: TDispatchError = (e) => {
      dispatch({
        type: FAILED_SWITCH_USER_ACCOUNT,
      });
    
      throw e || new Error("login error");
    };
  
    const dispatchSuccess: TDispatchSuccess = (account, state) => {
      dispatch({
        type: SWITCH_ACCOUNT,
        payload: {
          account,
          state,
        },
      });
    };
  
    let error;
    const result = await signInBySocial(provider).catch(e => {
      error = e;
    });
  
    if(error || !result || !result.user){
      if(process.env.REACT_APP_ENV === "development"){
        console.error("Sign in error");
        console.error(error);
      }
      
      dispatchError(error);
      return;
    }
  
    await __loadDataFromStorageAvailable(result.user.uid, result.user.uid, dispatchSuccess, dispatchError);
  };
};

export const logout = (): TAsyncAction<LogoutAction> => {
  return async (dispatch, getState) => {
    const {App} = getState();
    
    if(App.account && App.account.provider === memoryStorageType){
      await remove(memoryStorageType, App.account.uid);
    }
    
    if(getCurrentUser()){
      await signOut();
    }
    
    dispatch({
      type: LOGOUT,
    });
  
    return;
  };
};

export const initializeUserAccount = async (
  uid: string,
  initialState: RootState,
): Promise<boolean> => {
  const error = await initializeUserDataInFirebase(uid, initialState);
  
  if(error){
    if(process.env.REACT_APP_ENV === "development"){
      console.error("Error while initializing user data", error);
    }
    return false;
  }
  
  return true;
};

export function createInitialState(uid: string, displayName: string, password: string|false): RootState {
  const state = {...getInitialState()};
  state.App = {
    ...state.App,
    account: {
      provider: localStorageType,
      uid,
      displayName,
      isEncrypted: Boolean(password),
    },
    activity: "welcome",
    welcoming: true,
    acceptLicense: false,
    acceptPrivacyPolicy: false,
    unsaved: true,
  };
  
  return state;
}


export async function createUserDataToLocalStorage(
  uid: string,
  appState: RootState,
  userPassword: string|false,
){
  const provider = localStorageType;
  
  const Storage = getStorage(provider);
  const storage = new Storage(uid);
  
  const onError = (reason: any): never => {
    throw new Error(reason);
  };
  
  const randomVal = Math.random();
  
  await storage.save("check", randomVal).catch(onError);
  const value = await storage.get("check");
  
  if(value !== randomVal){
    return onError("invalidCheckerValue");
  }
  
  const state = await getAll(provider, uid, userPassword).catch(onError);
  if(state){
    return "alreadyExist";
  }
  else{
    await saveAll(appState, provider, uid, userPassword).catch(onError);
    return "succeeded";
  }
}

export const createUserAccount = (
  email: string,
  password: string|false,
): TAsyncAction<A.CreateUserAccountActions, Promise<firebase.auth.UserCredential>> => {
  return async (dispatch, getState) => {
    let error;
    const credential = await createAccountWithPassword(email, password || "")
      .catch(e => {
        error = e;
        return null;
      });
    
    if(!credential || error){
      throw error || new Error("User credential is empty");
    }
    
    const {user} = credential;
    if(!user){
      throw new Error("User data is empty");
    }
    
    await user.sendEmailVerification()
      .catch(reason => {
        if(process.env.REACT_APP_ENV === "development"){
          console.error("Error while sending email verification");
          console.error(reason);
        }
        
        const e = new Error("Email Verification Error");
        e.name = "email-verification-error";
        throw e;
      });
    
    return credential;
  };
};

export const initializeUserAccountAfterEmailIsVerified = (
  user: firebase.User,
): TAsyncAction<A.CreateUserAccountActions, Promise<void>> => {
  return async (dispatch, getState) => {
    // Use firebase uid as a password for `localStorage` (Not for firebase authentication password).
    // Yes, this is un-secure, but I decided to implement like this.
    // When an user tries to login via social login, this app can never know user password.
    // Data in localStorage cannot be encrypted by password for those users.
    //
    // But I don't think this change would significantly lower security level for localStorage.
    // Because when thief can steal the data in localStorage, it means user can steal every data on browser.
    // I mean at this case security is already broken.
    const uidAsPassword = user.uid;
    
    const initialState = createInitialState(user.uid, user.displayName || user.email || "", uidAsPassword);
  
    const successInitialization = await initializeUserAccount(user.uid, initialState);
    if(!successInitialization){
      throw new Error("User data initialization failed");
    }
  
    const result = await createUserDataToLocalStorage(user.uid, initialState, uidAsPassword)
      .catch(reason => {
        dispatch({
          type: types.FAILED_CREATE_USER_ACCOUNT,
        });
      
        throw new Error(reason);
      });
  
    if(result === "alreadyExist"){
      dispatch({
        type: types.FAILED_CREATE_USER_ACCOUNT,
      });
    
      const err = new Error("Account already exists");
      err.name = "account-already-exists";
      throw err;
    }
  
    dispatch({
      type: SWITCHING_ACCOUNT,
    });
  
    const {account} = initialState.App;
    if(!account){
      dispatch({
        type: FAILED_SWITCH_USER_ACCOUNT,
      });
      return;
    }
    
    dispatch({
      type: SWITCH_ACCOUNT,
      payload: {
        account,
        state: initialState,
      },
    });
  
    return;
  };
};

export const deleteUserAccount = (
  provider: string,
  uid: string,
): TAsyncAction<DeleteUserAccountAction, Promise<boolean>> => {
  return async (dispatch, getState) => {
    if(provider !== firebaseStorageType){
      let error;
      await remove(provider, uid)
        .catch(reason => {
          error = reason;
        });
  
      if(error){
        throw error;
      }
    }
    else{
      const asyncTasks = [memoryStorageType, localStorageType].map(async (p) => {
        await remove(p, uid)
          .catch(reason => {
            if(process.env.REACT_APP_ENV === "development"){
              console.error("Error while deleting account data");
              console.error(reason);
            }
          });
        
        return;
      });
      
      await Promise.all(asyncTasks);
    }
    
    await closeAccount();
  
    dispatch({
      type: DELETE_USER_ACCOUNT,
    });
    
    return true;
  };
};

export const loadAccountFromData = (
  data: RootState | EncryptedRootState,
  password: string|false,
): TAsyncAction<LoadAccountFromDataAction<RootState>> => {
  return async (dispatch, getState) => {
    const isPasswordSet = typeof(password) === "string" && password.length > 0;
    
    if(!isPasswordSet){
      const isStateEncrypted = typeof(data.App) !== "object" || typeof(data.Finance) !== "object";
      
      if(isStateEncrypted){
        throw new Error("Password is not set but state is likely encrypted");
      }
      
      const rootState = data as RootState;
      if(!rootState.App || !rootState.Finance){
        throw new Error("Missing state property");
      }
      
      const rootStateOfLatestVersion = updateRootState(rootState);
      
      const {Finance} = rootStateOfLatestVersion;
      let {App} = rootStateOfLatestVersion;
    
      if(App.account){
        App = {
          ...App,
          account: {
            ...App.account,
            uid: "File",
            provider: memoryStorageType,
            demo: "loadFromFile",
          },
        };
      }
      
      const rootStateToLoad: RootState = {
        App,
        Finance,
      };
    
      dispatch({
        type: LOAD_ENTIRE_STATE,
        payload: {
          state: rootStateToLoad,
        },
      });
      
      return;
    }
  
    // When password is set but type of state is not string
    if(typeof(data.App) !== "string" || typeof(data.Finance) !== "string"){
      throw new Error("Password is set but state not seems encrypted");
    }
  
    const decrypt = [
      decryptData(password || "", data.App),
      decryptData(password || "", data.Finance),
    ];
    
    const nameMap: string[] = [];
    const taskMap: Array<Promise<SubState>> = [];
    Object.entries(data).forEach(([name, value]) => {
      nameMap.push(name);
      taskMap.push(decryptData(password || "", value));
    });
    
    let error;
    const resolvedTasks = await Promise.all(taskMap)
      .catch(reason => {
        error = new Error(reason);
        return;
      });
    
    if(error){
      throw error;
    }
    
    if(!Array.isArray(resolvedTasks) || resolvedTasks.some(t => !t)){
      throw new Error("Some of State is empty");
    }
    
    type TPartialState<T> = {
      [P in keyof T]?: T[P];
    };
    
    const workingState: TPartialState<RootState> = {};
    for(let i=0;i<resolvedTasks.length;i++){
      const stateName = nameMap[i] as keyof RootState;
      workingState[stateName] = resolvedTasks[i];
    }
    
    const decryptedState = workingState as RootState;
    const latestState = updateRootState(decryptedState);
    
    if(latestState.App.account){
      const account: Account = {
        ...latestState.App.account,
        uid: "File",
        provider: memoryStorageType,
        demo: "loadFromFile",
      };
      
      latestState.App = {
        ...latestState.App,
        account,
      };
    }
  
    dispatch({
      type: LOAD_ENTIRE_STATE,
      payload: {
        state: latestState,
      },
    });
    
    return;
  };
};

export const initializeAccount = (): TAsyncAction<A.InitializeAccountAction, void> => {
  return (dispatch, getState) => {
    const state = {...getState()};
    const {App: {account}} = state;
    
    if(!account){
      return;
    }
  
    dispatch({
      type: types.INITIALIZE_ACCOUNT,
    });
  };
};

export const acceptLicense = (doesConsent: boolean): A.AcceptLicenseAction => {
  return {
    type: types.ACCEPT_LICENSE,
    payload: {accept: Boolean(doesConsent)},
  };
};

export const acceptPrivacyPolicy = (doesConsent: boolean): A.AcceptLicenseAction => {
  return {
    type: types.ACCEPT_PRIVACY_POLICY,
    payload: {accept: Boolean(doesConsent)},
  };
};

export const createAndLoadDemoData = (
  items: SF.ItemMaster[],
  itemGroups: SF.ItemGroupMaster[],
  accounts: SF.AccountMaster[],
  accountTypes: SF.AccountTypeMaster[],
  flow: SF.FlowData[],
  balance: SF.BalanceData[],
  params: DP.GeneratorParams,
): TAsyncAction<A.CreateAndLoadDemoDataAction> => {
  return async (dispatch, getState) => {
    const provider = memoryStorageType;
    const uid = "demo";
    
    let state = getState();
    
    const newApp = {...state.App};
    newApp.account = {
      provider,
      uid,
      displayName: "",
      isEncrypted: false,
      demo: params,
    };
    newApp.welcoming = false;
    newApp.activity = "dashboard";
    newApp.acceptLicense = true;
    newApp.acceptPrivacyPolicy = true;
    newApp.page_Input_balance_viewType = "date";
    
    const newFinance = {...state.Finance};
    newFinance.master_items = items;
    newFinance.master_itemGroups = itemGroups;
    newFinance.master_accounts = accounts;
    newFinance.master_accountTypes = accountTypes;
    newFinance.data_flow = flow;
    newFinance.data_balance = balance;
    
    state = {...state, App: newApp, Finance: newFinance};
    
    await saveAll(state, provider, uid, false);
    
    dispatch({
      type: LOAD_ENTIRE_STATE,
      payload: {state},
    });
    
    return;
  };
};
