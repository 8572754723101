import {
  closest,
  events,
  getEdgeOffset,
  getElementMargin,
  getLockPixelOffset,
  getPosition, hitTest,
  isTouchEvent, limit,
  vendorPrefix,
} from "./utils";
import React from "react";
import {findDOMNode} from "react-dom";
import {This, SortableItemRef} from "./types";
import SortableItem from "./SortableItem";

export const handleStart: EventListener = function(this: This, event: Event) {
  const {classes} = this.props;
  
  if((event as MouseEvent).button === 2 || this.shouldCancelStart(event)){
    return;
  }
  
  this.vars._touched = true;
  this.vars._pos = getPosition(event);
  
  if(!closest((event.target as Node), (el) => el.nodeType === 1 && (el as HTMLElement).classList.contains(classes.grabber))){
    return;
  }
  
  const node = closest(
    (event.target as Node),
    (el) => Boolean((el as HTMLElement).id && (el as HTMLElement).id.startsWith("item-")),
  ) as HTMLElement;
  
  if(node && node.id && node.id.startsWith("item-") && !this.manager.active){
    const itemId = +node.id.replace("item-", "");
    
    if(isNaN(itemId)){
      return;
    }
    
    this.manager.active = {itemId, groupId: null, insertAt: itemId};
    
    /*
     * Fixes a bug in Firefox where the :active state of anchor tags
     * prevent subsequent 'mousemove' events from being fired
     * (see https://github.com/clauderic/react-sortable-hoc/issues/118)
     */
    if (
      !isTouchEvent(event) &&
      event.target !== null &&
      (event.target as HTMLElement).tagName.toLowerCase() === "a"
    ) {
      event.preventDefault();
    }
    
    this.handlePress(event);
    return;
  }
};

export const handleMove: EventListener = function(this: This, event: Event){
  if(this.manager && !this.manager.active && this.vars._touched){
    clearTimeout(this.vars.cancelTimer);
    this.vars.cancelTimer = window.setTimeout(this.cancel, 0);
  }
};

export const handleEnd: EventListener = function(this: This, event: Event){
  this.vars._touched = false;
  this.cancel();
};

export const handlePress: EventListener = function(this: This, event: Event){
  const {active} = this.manager;
  
  if(active !== null && typeof(active) === "object"){
    const {
      useWindowAsScrollContainer,
      classes,
      scrollAPI,
    } = this.props;
    const {
      sortableItemRefs,
      getHelperDimensions,
      hideSortableGhost,
    } = this;
    
    if(this.containers.container === null
      || this.containers.document === null
      || this.containers.contentWindow === null
    ){
      return;
    }
    
    const {itemId} = active;
    const refCandidate = sortableItemRefs.find((r: SortableItemRef) => r.item.id === itemId);
    
    if(refCandidate === undefined){
      return;
    }
    
    const ref = refCandidate.ref.current;
    
    if(ref === null || ref.node === undefined || ref.node === null){
      return;
    }
    
    const node = ref.node as HTMLElement;
    
    const margin = getElementMargin(node);
    
    const containerBoundingRect = this.containers.container.getBoundingClientRect();
    const dimensions = getHelperDimensions({node});
    
    this.vars.node = node;
    this.vars.margin = margin;
    this.vars.width = dimensions.width;
    this.vars.height = dimensions.height;
    this.vars.marginOffset = {
      x: this.vars.margin.left + this.vars.margin.right,
      y: Math.max(this.vars.margin.top, this.vars.margin.bottom),
    };
    this.vars.boundingClientRect = node.getBoundingClientRect();
    this.vars.containerBoundingRect = containerBoundingRect;
    
    this.vars.offsetEdge = getEdgeOffset(node);
    this.vars.initialOffset = getPosition(event);
    this.vars.initialScroll = {
      top: this.containers.container.scrollTop,
      left: this.containers.container.scrollLeft,
    };
    
    if(useWindowAsScrollContainer){
      this.vars.initialWindowScroll = {
        top: scrollAPI.getScrollTop(),
        left: scrollAPI.getScrollLeft(),
      };
    }
    else{
      this.vars.initialWindowScroll = {
        top: window.pageYOffset,
        left: window.pageXOffset,
      };
    }
    
    const fields = node.querySelectorAll("input, textarea, select");
    const clonedNode = node.cloneNode(true) as HTMLElement;
    const clonedFields: HTMLElement[] = [];
    clonedNode.querySelectorAll("input, textarea, select").forEach((el) => {
      clonedFields.push((el as HTMLElement));
    });
    
    clonedFields.forEach((field, i) => {
      if ((field as HTMLInputElement).type !== "file") {
        (field as HTMLInputElement).value = (fields[i] as HTMLInputElement).value;
      }
    });
    
    this.vars.helper = this.containers.document.body.appendChild(clonedNode);
    
    this.vars.helper.style.position = "fixed";
    this.vars.helper.style.top = `${this.vars.boundingClientRect.top - margin.top}px`;
    this.vars.helper.style.left = `${this.vars.boundingClientRect.left - margin.left}px`;
    this.vars.helper.style.width = `${this.vars.width}px`;
    this.vars.helper.style.height = `${this.vars.height}px`;
    this.vars.helper.style.boxSizing = "border-box";
    
    if (hideSortableGhost) {
      this.vars.sortableGhost = node as HTMLElement;
      node.style.width = "0";
      node.style.padding = "0";
      node.style.border = "none";
    }
    
    this.vars.minTranslate = {x: 0, y: 0};
    this.vars.maxTranslate = {x: 0, y: 0};
    
    const {contentWindow} = this.containers;
    const {boundingClientRect, width, height} = this.vars;
    
    if(useWindowAsScrollContainer){
      this.vars.minTranslate.x = - boundingClientRect.left - width / 2;
      this.vars.maxTranslate.x = contentWindow.innerWidth - boundingClientRect.left - width / 2;
      this.vars.minTranslate.y = - boundingClientRect.top - height / 2;
      this.vars.maxTranslate.y = contentWindow.innerHeight - boundingClientRect.top - height / 2;
    }
    else{
      this.vars.minTranslate.x = containerBoundingRect.left - boundingClientRect.left - width / 2;
      this.vars.maxTranslate.x = containerBoundingRect.left + containerBoundingRect.width - boundingClientRect.left - width / 2;
      this.vars.minTranslate.y = containerBoundingRect.top - boundingClientRect.top - height / 2;
      this.vars.maxTranslate.y = containerBoundingRect.top + containerBoundingRect.height - boundingClientRect.top - height / 2;
    }
    
    if (typeof(classes.helper) === "string") {
      this.vars.helper.classList.add(...(classes.helper as string).split(" "));
    }
    
    this.vars.listenerNode = (event as TouchEvent).touches ? (node as HTMLElement) : this.containers.contentWindow;
    events.move.forEach((eventName) =>{
      if(this.vars.listenerNode === null){
        return;
      }
  
      this.vars.listenerNode.addEventListener(
        eventName,
        this.handleSortMove,
        false,
      );
    });
    events.end.forEach((eventName) => {
      if(this.vars.listenerNode === null){
        return;
      }
      
      this.vars.listenerNode.addEventListener(
        eventName,
        this.handleSortEnd,
        false,
      );
    });
  }
};

export const handleSortMove: EventListener = function(this: This, event: Event){
  // Prevent scrolling on mobile
  event.preventDefault();
  
  this.updatePosition(event);
  
  // For performance boost, only animate every 150ms
  if(!this.vars.animateTimer){
    this.vars.animateTimer = window.setTimeout(() => {
      if(this.manager.active){
        this.animateNodes();
      }
      clearTimeout(this.vars.animateTimer);
      this.vars.animateTimer = null;
    }, 150);
  }
  
  this.autoScroll();
};

export const handleSortEnd: EventListener = function(this: This, event: Event){
  const {theme} = this.props;
  const {hideSortableGhost} = this;
  
  // Remove the event listeners if the node is still in the DOM
  if(this.vars.listenerNode){
    events.move.forEach((eventName) =>
      this.vars.listenerNode.removeEventListener(eventName, this.handleSortMove),
    );
    events.end.forEach((eventName) =>
      this.vars.listenerNode.removeEventListener(eventName, this.handleSortEnd),
    );
  }
  
  // Remove the helper from the DOM
  this.vars.helper.parentNode.removeChild(this.vars.helper);
  
  if(hideSortableGhost && this.vars.sortableGhost){
    this.vars.sortableGhost.style.width = "";
    this.vars.sortableGhost.style.border = `1px solid ${theme.palette.text.secondary}`;
    this.vars.sortableGhost.style.padding = "3px 7px";
  }
  
  const nodes = this.sortableItemRefs.map((r: SortableItemRef) => r.ref.current);
  for(let i = 0, len = nodes.length; i < len; i++){
    const node = nodes[i];
    const el = node.node;
    
    // Clear the cached offsetTop / offsetLeft value
    node.edgeOffset = null;
    
    // Remove the transforms / transitions
    el.style[`${vendorPrefix}Transform`] = "";
    el.style[`${vendorPrefix}TransitionDuration`] = "";
  }
  
  // Stop autoScroll
  clearInterval(this.vars.autoScrollInterval);
  this.vars.autoScrollInterval = null;
  
  // Update state
  const {itemId, groupId, insertAt} = this.manager.active;
  this.manager.active = null;
  
  if(typeof(itemId) === "number" && typeof(groupId) === "number"){
    this.manager.changeGroupOfItem(
      itemId,
      groupId,
      insertAt,
    );
  }
  
  const groups = this.manager.getGroupsWithChildren();
  
  this.vars._touched = false;
  this.removeHighlightFromGroups();
  
  this.setState({groups}, () => {
    this.props.onChange(this.manager.getItems(), this.manager.getGroups());
  });
};

export function cancel(this: This){
  const {active} = this.manager;
  this.removeHighlightFromGroups();
  
  if (!active) {
    clearTimeout(this.pressTimer);
    this.manager.active = null;
  }
}

export function getLockPixelOffsets(this: This) {
  const {width, height} = this.vars;
  
  if(width === null || height === null){
    throw new Error("width or height is null");
  }
  
  const {lockOffset} = this.props;
  const offsets = Array.isArray(lockOffset)
    ? lockOffset
    : [lockOffset, lockOffset];
  
  const [minLockOffset, maxLockOffset] = offsets;
  
  return [
    getLockPixelOffset({lockOffset: minLockOffset, width, height}),
    getLockPixelOffset({lockOffset: maxLockOffset, width, height}),
  ];
}

export const updatePosition = function(this: This, event: Event): void {
  const {lockToContainerEdges, useWindowAsScrollContainer, scrollAPI} = this.props;
  
  if(!this.vars.initialOffset
    || !this.vars.initialWindowScroll
    || typeof(this.vars.width) !== "number"
    || typeof(this.vars.height) !== "number"
    || !this.vars.helper
  ){
    return;
  }
  
  const offset = getPosition(event);
  const translate = {
    x: offset.x - this.vars.initialOffset.x,
    y: offset.y - this.vars.initialOffset.y,
  };
  
  this.vars.translate = translate;
  
  if (lockToContainerEdges) {
    // Adjust for window scroll
    if(useWindowAsScrollContainer){
      translate.y -= scrollAPI.getScrollTop() - this.vars.initialWindowScroll.top;
      translate.x -= scrollAPI.getScrollLeft() - this.vars.initialWindowScroll.left;
    }
    else{
      translate.y -= window.pageYOffset - this.vars.initialWindowScroll.top;
      translate.x -= window.pageXOffset - this.vars.initialWindowScroll.left;
    }
    
    const [minLockOffset, maxLockOffset] = this.getLockPixelOffsets();
    const minOffset = {
      x: this.vars.width / 2 - minLockOffset.x,
      y: this.vars.height / 2 - minLockOffset.y,
    };
    const maxOffset = {
      x: this.vars.width / 2 - maxLockOffset.x,
      y: this.vars.height / 2 - maxLockOffset.y,
    };
    
    translate.x = limit(
      this.vars.minTranslate.x + minOffset.x,
      this.vars.maxTranslate.x - maxOffset.x,
      translate.x,
    );
    translate.y = limit(
      this.vars.minTranslate.y + minOffset.y,
      this.vars.maxTranslate.y - maxOffset.y,
      translate.y,
    );
  }
  
  const transformStyleName = `${vendorPrefix}Transform`;
  
  this.vars.helper.style[(transformStyleName as "transform")] = `translate3d(${translate.x}px,${translate.y}px, 0)`;
};

export const animateNodes = function(this: This){
  const {
    transitionDuration,
    classes,
    bindPropChild,
    useWindowAsScrollContainer,
    scrollAPI,
  } = this.props;
  const {hideSortableGhost} = this;
  
  const containerScrollDelta = {
    left: this.containers.container.scrollLeft - this.vars.initialScroll.left,
    top: this.containers.container.scrollTop - this.vars.initialScroll.top,
  };
  
  const sortingOffset = {
    left: this.vars.offsetEdge.left + this.vars.translate.x + containerScrollDelta.left,
    top: this.vars.offsetEdge.top + this.vars.translate.y + containerScrollDelta.top,
  };
  
  let windowScrollDelta = {top: 0, left: 0};
  if(useWindowAsScrollContainer){
    windowScrollDelta = {
      top: scrollAPI.getScrollTop() - this.vars.initialWindowScroll.top,
      left: scrollAPI.getScrollLeft() - this.vars.initialWindowScroll.left,
    };
  }
  else{
    windowScrollDelta = {
      top: window.pageYOffset - this.vars.initialWindowScroll.top,
      left: window.pageXOffset - this.vars.initialWindowScroll.left,
    };
  }
  
  const movingItemOffset = {
    top: sortingOffset.top + windowScrollDelta.top,
    left: sortingOffset.left + windowScrollDelta.left,
  };
  
  // Update active info
  const groupId = this.getGroupIdAt({
    top: movingItemOffset.top,
    left: movingItemOffset.left,
  }, this.vars.height);
  
  const oldGroupId = this.manager.active.groupId;
  
  if(this.manager.active.groupId !== groupId){
    this.manager.active.groupId = groupId;
    this.manager.active.insertAt = null;
    
    this.removeHighlightFromGroups();
    
    // Highlight group container
    if(typeof(groupId) === "number"){
      const container = this.getGroupContainerById(groupId);
      if(container){
        container.classList.add(classes.highlightedGroup);
        
        container.querySelectorAll(`.${classes.addIcon}`).forEach((el: HTMLElement) => {
          el.style.visibility = "hidden";
        });
      }
    }
  }
  
  // Clear translation in all node in the old groupId
  if(typeof(oldGroupId) === "number"){
    const nodeList = this.sortableItemRefs
      .filter((r: SortableItemRef) => r.item[bindPropChild] === oldGroupId)
      .map((r: SortableItemRef) => r.ref.current);
    
    nodeList.forEach((ref: SortableItem) => {
      const node: HTMLElement = ref.node as HTMLElement;
      const transformStyleName = `${vendorPrefix}Transform` as "transform";
      node.style[transformStyleName] = "";
    });
  }
  
  if(typeof(groupId) !== "number"){
    return;
  }
  
  let nodes = this.sortableItemRefs.filter((r: SortableItemRef) => r.item[bindPropChild] === groupId);
  nodes.sort((a: SortableItemRef, b: SortableItemRef) => a.item.order - b.item.order);
  nodes = nodes.map((r: SortableItemRef) => r.ref.current);
  
  let encounterHitItem = false;
  let firstColItem;
  let prevItem;
  let marginAbsorbed = false;
  let insertAt = null;
  let additionalContainerHeight = 0;
  
  const targetGroupContainer = this.containers.container.querySelector(`#itemGroup-${groupId}`);
  
  for(let i=0, len=nodes.length; i<len; i++){
    const {node, id} = nodes[i];
    let {edgeOffset} = nodes[i];
    
    const parentOffset = getEdgeOffset(node.parentNode);
    
    // If we haven't cached the node's offsetTop / offsetLeft value
    if (!edgeOffset) {
      edgeOffset = getEdgeOffset(node);
      nodes[i].edgeOffset = edgeOffset;
    }
    
    // Get a reference to the next and previous node
    const nextNode = i < nodes.length - 1 && nodes[i + 1];
    
    // Also cache the next node's edge offset if needed.
    // We need this for calculating the animation in a grid setup
    if (nextNode && !nextNode.edgeOffset) {
      nextNode.edgeOffset = getEdgeOffset(nextNode.node);
    }
    
    // For the first time on looping, add height equal to one row, for inserting an item to the tail.
    if(!targetGroupContainer.style.height){
      additionalContainerHeight = edgeOffset.height + this.vars.marginOffset.y;
      targetGroupContainer.style.height = targetGroupContainer.offsetHeight + additionalContainerHeight + "px";
    }
    
    // If the node is the one we're currently animating, skip it
    if(id === this.manager.active.itemId){
      if (hideSortableGhost) {
        // With windowing libraries such as `react-virtualized`, the sortableGhost
        // node may change while scrolling down and then back up (or vice-versa),
        // so we need to update the reference to the new node just to be safe.
        this.vars.sortableGhost = node;
        node.style.width = 0;
        node.style.padding = 0;
        node.style.border = "none";
      }
      continue;
    }
  
    const {
      offsetWidth: width,
      offsetHeight: height,
    } = node;
    
    const translate = {
      x: 0,
      y: 0,
    };
  
    if(!encounterHitItem){
      const hitZone = {
        top: edgeOffset.top - this.vars.height / 2,
        left: edgeOffset.left - this.vars.width / 2,
        height: height + this.vars.height / 2,
        width: width + this.vars.width / 2,
      };
  
      // When pointer did not hit the item, skip the item.
      if (!hitTest(hitZone, movingItemOffset)) {
        continue;
      }
  
      encounterHitItem = true;
  
      // If the current node is to the left on the same row, or above the node that's being dragged
      // then move it to the right
      translate.x = this.vars.width + this.vars.marginOffset.x;
  
      // If it moves passed the right bounds, then animate it to the first position of the next row.
      // We just use the offset of the next node to calculate where to move, because that node's original position
      // is exactly where we want to go
      if (edgeOffset.left + width + this.vars.margin.right + translate.x > this.vars.containerBoundingRect.width) {
        translate.x = nodes[0].edgeOffset.left - edgeOffset.left;
        translate.y = parentOffset.height;
    
        firstColItem = {
          ...nodes[i],
          translate,
          i,
        };
      }
  
      insertAt = id;
    }
    else{
      // Already pushed and moved by dragging item but before and current items are not yet wrapped.
      if(!firstColItem){
        translate.x = this.vars.width + this.vars.marginOffset.x;
      }
      // Before and current items are already wrapped, and the first col item comes down from upper row.
      else if(firstColItem.edgeOffset.top !== edgeOffset.top){
        translate.x = firstColItem.edgeOffset.left
          + firstColItem.edgeOffset.width
          + this.vars.margin.right
          + firstColItem.translate.x;
        
        if(edgeOffset.left === nodes[0].edgeOffset.left){
          if(prevItem.i !== firstColItem.i){
            translate.x = 0;
            marginAbsorbed = true;
          }
        }
        else if(marginAbsorbed){
          translate.x = 0;
        }
      }
      // Before and current items are already wrapped, and this item comes down from upper row together with firstColItem.
      else{
        translate.x = firstColItem.edgeOffset.left
          + firstColItem.edgeOffset.width
          + this.vars.margin.right
          + firstColItem.translate.x
          - edgeOffset.left;
        translate.y = parentOffset.height;
    
        firstColItem = {
          ...nodes[i],
          translate,
          i,
        };
      }
  
      // If it moves passed the right bounds, then animate it to the first position of the next row.
      // We just use the offset of the next node to calculate where to move, because that node's original position
      // is exactly where we want to go
      if(edgeOffset.left + width + this.vars.margin.right + translate.x > this.vars.containerBoundingRect.width){
        translate.x = nodes[0].edgeOffset.left - edgeOffset.left;
        translate.y = parentOffset.height;
    
        firstColItem = {
          ...nodes[i],
          translate,
          i,
        };
      }
  
      if(edgeOffset.top + height + translate.y > targetGroupContainer.offsetTop + targetGroupContainer.offsetHeight){
        additionalContainerHeight = edgeOffset.height + this.vars.marginOffset.y;
        targetGroupContainer.style.height = targetGroupContainer.offsetHeight + additionalContainerHeight + "px";
      }
    }
    
    if (transitionDuration) {
      node.style[`${vendorPrefix}TransitionDuration`] = `${transitionDuration}ms`;
    }
    
    node.style[`${vendorPrefix}Transform`] = `translate3d(${translate.x}px,${translate.y}px, 0)`;
    
    prevItem = {
      ...nodes[i],
      translate,
      i,
    };
  }
  
  this.manager.active.insertAt = insertAt;
};

export function autoScroll(this: This){
  const {useWindowAsScrollContainer, scrollAPI} = this.props;
  let {headerHeight} = this.props;
  const {
    translate,
    maxTranslate,
    minTranslate,
    height,
    width,
    autoScrollInterval,
  } = this.vars;
  
  headerHeight = (typeof(headerHeight) === "number" ? headerHeight : 0);
  
  const direction = {
    x: 0,
    y: 0,
  };
  const speed = {
    x: 1,
    y: 1,
  };
  const acceleration = {
    x: 10,
    y: 10,
  };
  
  if (translate.y >= maxTranslate.y - height / 2) {
    // Scroll Down
    direction.y = 1;
    speed.y =
      acceleration.y *
      Math.abs(
        (maxTranslate.y - height / 2 - translate.y) / height,
      );
  } else if (translate.x >= maxTranslate.x - width / 2) {
    // Scroll Right
    direction.x = 1;
    speed.x =
      acceleration.x *
      Math.abs(
        (maxTranslate.x - width / 2 - translate.x) / width,
      );
  } else if (translate.y <= minTranslate.y + height / 2 + headerHeight) {
    // Scroll Up
    direction.y = -1;
    speed.y =
      acceleration.y *
      Math.abs(
        (translate.y - height / 2 - minTranslate.y - headerHeight) / height,
      );
  } else if (translate.x <= minTranslate.x + width / 2) {
    // Scroll Left
    direction.x = -1;
    speed.x =
      acceleration.x *
      Math.abs(
        (translate.x - width / 2 - minTranslate.x) / width,
      );
  }
  
  if (autoScrollInterval) {
    clearInterval(autoScrollInterval);
    this.vars.autoScrollInterval = null;
    this.vars.isAutoScrolling = false;
  }
  
  if (direction.x !== 0 || direction.y !== 0) {
    this.vars.autoScrollInterval = setInterval(() => {
      this.vars.isAutoScrolling = true;
      const offset = {
        left: speed.x * direction.x,
        top: speed.y * direction.y,
      };
      
      if(useWindowAsScrollContainer){
        const scrollTop = scrollAPI.getScrollTop();
        const scrollLeft = scrollAPI.getScrollLeft();
        scrollAPI.scrollTop(scrollTop + offset.top);
        scrollAPI.scrollLeft(scrollLeft + offset.left);
      }
      else{
        this.containers.scrollContainer.scrollTop += offset.top;
        this.containers.scrollContainer.scrollLeft += offset.left;
      }
      
      this.vars.translate.x += offset.left;
      this.vars.translate.y += offset.top;
      
      this.animateNodes();
    }, 5);
  }
}

export function getContainer(this: React.Component<any>){
  return findDOMNode(this) as Element | null;
}
