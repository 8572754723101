import React from "react";
import * as S from "../core/reducers/Finance/state";
import {updateRootState} from "../reducers/version";
import getStorage, {firebaseStorageType, localStorageType, memoryStorageType} from "../backends/storage";
import {checkUserHasContract, checkUserPasswordForFirebase, getCurrentUser} from "../backends/firebase/api";
import {decryptData, encryptData} from "./crypto";
import {FaLaptop as LocalStorageIcon} from "react-icons/fa";
import MemoryIcon from "mdi-react/AccountOffIcon";
import FirebaseIcon from "mdi-react/SignalVariantIcon";
import {AppState} from "../reducers/App/state";
import {RootState, StateType, SubState} from "../reducers";

export const PROVIDER_ICONS: {[storageProvider: string]: React.ReactElement} = Object.freeze({
  localStorage: <LocalStorageIcon/>,
  memory: <MemoryIcon/>,
  firebase: <FirebaseIcon/>,
});

const LocalStorage = getStorage(localStorageType);
const MemoryStorage = getStorage(memoryStorageType);
const FirebaseStorage = getStorage(firebaseStorageType);

export const ERROR_reason = Object.freeze({
  failedToDecryptData: "failedToDecryptData",
  unexpectedFormat: "unexpectedFormat",
  failedToGet: "failedToGet",
  failedToEncryptData: "failedToEncryptData",
  failedToSave: "failedToSave",
});

const keyFuncMap = {
  get: {
    all: {
      [localStorageType]: getAllFromLocalStorage,
      [memoryStorageType]: getAllFromMemoryStorage,
      [firebaseStorageType]: getAllFromFirebaseStorage,
    },
    x: {
      [localStorageType]: getStateFromLocalStorage,
      [memoryStorageType]: getStateFromMemoryStorage,
      [firebaseStorageType]: getStateFromFirebaseStorage,
    },
  },
  save: {
    all: {
      [localStorageType]: saveAllToLocalStorage,
      [memoryStorageType]: saveAllToMemoryStorage,
      [firebaseStorageType]: saveAllToFirebaseStorage,
    },
    x: {
      [localStorageType]: saveStateToLocalStorage,
      [memoryStorageType]: saveStateToMemoryStorage,
      [firebaseStorageType]: saveStateToFirebaseStorage,
    },
  },
  remove: {
    [localStorageType]: removeFromLocalStorage,
    [memoryStorageType]: removeFromMemoryStorage,
    [firebaseStorageType]: removeFromFirebaseStorage,
  },
};

function isSupportedProvider(provider: string){
  return provider === localStorageType
    || provider === memoryStorageType
    || provider === firebaseStorageType
  ;
}

export async function checkPasswordCorrect(
  provider: string,
  uid: string,
  password: string,
): Promise<boolean> {
  return checkUserPasswordForFirebase(password);
}



//#region save methods
export function saveAll(
  state: RootState,
  provider: string,
  uid: string,
  password: string|false,
): Promise<any> {
  if(!isSupportedProvider(provider)){
    return Promise.reject();
  }
  
  return keyFuncMap.save.all[provider](state, uid, password);
}

export function saveState(
  stateName: StateType,
  state: RootState,
  provider: string,
  uid: string,
  password: string|false,
): Promise<any> {
  if(!isSupportedProvider(provider)){
    return saveStateToMemoryStorage(stateName, state, uid, password);
  }
  
  return keyFuncMap.save.x[provider](stateName, state, uid, password);
}

function saveAllToLocalStorage(
  state: RootState,
  uid: string,
  password?: string|false,
): Promise<any> {
  const pApp = saveStateToLocalStorage("App", state, uid, password);
  const pFin = saveStateToLocalStorage("Finance", state, uid, password);
  
  return Promise.all([pApp, pFin])
    .then(results => {
      return Promise.resolve();
    });
}

function saveStateToLocalStorage<T extends StateType = StateType>(
  X: T,
  state: RootState,
  uid: string,
  password?: string|false,
): Promise<any> {
  return new Promise((resolve, reject) => {
    const onError = (reason: any) => {
      reject(reason);
    };
  
    const storage = new LocalStorage(uid);
    const X_in_state = {...state[X], unsaved: false, saving: false};
    
    if(typeof(password) === "string"){
      encryptData(password, X_in_state)
        .then(encryptHex => {
          storage.save(X, encryptHex)
            .then(() => resolve())
            .catch(reason => onError(ERROR_reason.failedToSave));
        })
        .catch(reason => onError(ERROR_reason.failedToEncryptData));
    }
    else{
      storage.save(X, X_in_state)
        .then(() => resolve())
        .catch(reason => onError(ERROR_reason.failedToSave));
    }
  });
}

function saveAllToMemoryStorage(
  state: RootState,
  uid: string,
  password?: string|false,
): Promise<any> {
  const pApp = saveStateToMemoryStorage("App", state, uid, password);
  const pFin = saveStateToMemoryStorage("Finance", state, uid, password);
  
  return Promise.all([pApp, pFin])
    .then(results => {
      return Promise.resolve();
    });
}

function saveStateToMemoryStorage<T extends StateType = StateType>(
  X: T,
  state: RootState,
  user: string,
  password?: string|false,
): Promise<any> {
  return new Promise((resolve, reject) => {
    const onError = (reason: any) => {
      reject(reason);
    };
  
    const storage = new MemoryStorage(user);
    const X_in_state = {...state[X], unsaved: false, saving: false};
    
    if(typeof(password) === "string"){
      encryptData(password, X_in_state)
        .then((encryptedHex) => {
          storage.save(X, encryptedHex)
            .then(() => resolve())
            .catch(reason => onError(ERROR_reason.failedToSave));
        })
        .catch(reason => onError(ERROR_reason.failedToEncryptData));
    }
    else{
      storage.save(X, X_in_state)
        .then(() => resolve())
        .catch(reason => onError(ERROR_reason.failedToSave));
    }
  });
}

function saveAllToFirebaseStorage(
  state: RootState,
  uid: string,
  password?: string|false,
): Promise<any> {
  return new Promise((resolve, reject) => {
    const onError = (reason: any) => {
      reject(reason);
    };
  
    const user = getCurrentUser();
    if(!user){
      onError("User not logged in to firebase");
      return;
    }
  
    checkUserHasContract(user.uid).then(hasValidContract => {
      if (!hasValidContract) {
        reject("no-valid-contract");
      }
  
      state = {
        App: {
          ...state.App,
          unsaved: false,
          saving: false,
        },
        Finance: {
          ...state.Finance,
          unsaved: false,
          saving: false,
        },
      };
  
      const storage = new FirebaseStorage(user.uid);
      storage.save("", state).then(() => {
        resolve();
      });
    });
  });
}

function saveStateToFirebaseStorage<T extends StateType = StateType>(
  X: T,
  state: RootState,
  _uid: string,
  _password?: string|false,
): Promise<any> {
  return new Promise((resolve, reject) => {
    const onError = (reason: any) => {
      reject(reason);
    };
    
    const user = getCurrentUser();
    if(!user){
      onError("User not logged in to firebase");
      return;
    }
    
    checkUserHasContract(user.uid).then(hasValidContract => {
      if(!hasValidContract){
        return reject("no-valid-contract");
      }
  
      const storage = new FirebaseStorage(user.uid);
      const X_in_state = {...state[X], unsaved: false, saving: false};
  
      storage.save(X, X_in_state)
        .then(() => {
          resolve();
        })
        .catch(reason => {
          onError(reason);
        });
    });
  });
}
//#endregion

//#region get methods
export async function getAll(
  provider: string,
  uid: string,
  password?: string|false,
): Promise<RootState|null> {
  if(!isSupportedProvider(provider)){
    throw new Error("Unsupported provider");
  }
  
  const rootState = await keyFuncMap.get.all[provider](uid, password);
  if(!rootState){
    return rootState;
  }
  
  return updateRootState(rootState);
}

/*
 * This method is commented out because of version management.
 * When loading state from storage, the state must be checked its version every time.
 * In order to update state, all state data is required. This is a dependency.
 * So we cannot afford to let user get part of state because if so we cannot perform state updating.
 * 
function getState<S extends S.SubState = S.SubState>(
  stateName: S.StateType,
  provider: string,
  uid: string,
  password?: string|false,
): Promise<S.SubState|null> {
  if(!isSupportedProvider(provider)){
    return getStateFromMemoryStorage(stateName, uid, password);
  }
  
  return keyFuncMap.get.x[provider](stateName, uid, password);
}
 */

/**
 * Resolves if data is found in expected format or data is not found.
 * Rejects if whether data is found is unknown.
 */
async function getAllFromLocalStorage(
  uid: string,
  password?: string|false,
): Promise<RootState|null> {
  const pApp = getStateFromLocalStorage<AppState>("App", uid, password);
  const pFin = getStateFromLocalStorage<S.FinanceState>("Finance", uid, password);
  
  const results = await Promise.all([pApp, pFin]);
  
  const [App, Finance] = results as [AppState, S.FinanceState];
  
  if(!App || !Finance){
    return null;
  }
  
  // Before version 7, there was Settings state
  if(App.version < 7){
    const Settings = await getStateFromLocalStorage<any>(("Settings" as any), uid, password);
    if(Settings){
      return {
        App,
        Finance,
        // @ts-ignore
        Settings,
      };
    }
  }
  
  return {App, Finance};
}

/**
 * 0. Data not found: resolves null
 * 1. Data found, With password, Decrypt succeeded: resolves data
 * 2. Data found, With password, Decrypt succeeded but empty: rejects with error
 * 3. Data found, With password, Decrypt failed: rejects with error
 * 4. Data found, Without password, Data is object: resolves data
 * 5. Data found, Without password, Data is not object: rejects with error
 */
// Do not export. This must be a private function
function getStateFromLocalStorage<SS extends SubState = SubState, ST extends StateType = StateType>(
  X: ST,
  uid: string,
  password?: string|false,
): Promise<SS|null>{
  return new Promise((resolve, reject) => {
    const storage = new LocalStorage(uid);
  
    const onError = (reason: any) => {
      reject(reason);
    };
    
    storage.get(X)
      .then(encryptedHex => {
        if(encryptedHex === null){
          return resolve(null);
        }
        
        if(typeof(password) === "string"){
          decryptData(password, encryptedHex)
            .then((decryptedData: any) => {
              if(!decryptedData){
                return onError(ERROR_reason.failedToDecryptData);
              }
              resolve(decryptedData);
            })
            .catch(reason => onError(ERROR_reason.failedToDecryptData));
        }
        else if(!password && typeof(encryptedHex) === "object" && encryptedHex){
          resolve(encryptedHex);
        }
        else{
          onError(ERROR_reason.failedToDecryptData);
        }
      })
      .catch(reason => onError(ERROR_reason.failedToGet));
  });
}

async function getAllFromMemoryStorage(
  uid: string,
  password?: string|false,
): Promise<RootState|null>{
  const pApp = getStateFromMemoryStorage<AppState>("App", uid, password);
  const pFin = getStateFromMemoryStorage<S.FinanceState>("Finance", uid, password);
  
  const results = await Promise.all([pApp, pFin]);
  
  const [App, Finance] = results as [AppState, S.FinanceState];
  
  if(!App || !Finance){
    return null;
  }
  
  // Before version 7, there was Settings state
  if(App.version < 7){
    const Settings = await getStateFromMemoryStorage<any>(("Settings" as any), uid, password);
    if(Settings){
      return {
        App,
        Finance,
        // @ts-ignore
        Settings,
      };
    }
  }
  
  return {App, Finance};
}

// Do not export. This must be a private function
function getStateFromMemoryStorage<SS extends SubState = SubState, ST extends StateType = StateType>(
  X: ST,
  uid: string,
  password?: string|false,
): Promise<SS|null> {
  return new Promise((resolve, reject) => {
    const storage = new MemoryStorage(uid);
    
    const onError = (reason: any) => {
      reject(reason);
    };
    
    storage.get(X)
      .then(encryptedHex => {
        if(encryptedHex === null){
          return resolve(null);
        }
        
        if(typeof(password) === "string"){
          decryptData(password, encryptedHex)
            .then(decryptedData => {
              if(!decryptedData){
                return onError(ERROR_reason.failedToDecryptData);
              }
              resolve(decryptedData);
            })
            .catch(reason => onError(ERROR_reason.failedToDecryptData));
        }
        else{
          if(!password && typeof(encryptedHex) === "object" && encryptedHex){
            resolve(encryptedHex);
          }
          else{
            onError(ERROR_reason.failedToDecryptData);
          }
        }
      })
      .catch(reason => onError(ERROR_reason.failedToGet))
    ;
  });
}

/**
 * ** WARNING **
 * Do not modify this function to fetch all user state data(App, Finance, Settings) by specifying
 * root reference.
 * Firebase realtime database may hold user data other than state data.
 */
function getAllFromFirebaseStorage(
  uid: string,
  password?: string|false,
): Promise<RootState|null>{
  return new Promise((resolve, reject) => {
    const onError = (reason: any) => {
      reject(reason);
    };
    
    const user = getCurrentUser();
    if(!user){
      onError("User not logged in to firebase");
      return;
    }
    
    checkUserHasContract(user.uid).then(hasValidContract => {
      if(!hasValidContract){
        reject("no-valid-contract");
        return;
      }
  
      const storage = new FirebaseStorage(user.uid);
      storage.get("")
        .then((stateData: RootState) => {
          resolve(stateData);
        })
        .catch(reason => onError(ERROR_reason.failedToGet))
      ;
    });
  });
}

// Do not export. This must be a private function
function getStateFromFirebaseStorage<SS extends SubState = SubState, ST extends StateType = StateType>(
  X: ST,
  _uid: string,
  _password?: string|false,
): Promise<SS|null> {
  return new Promise((resolve, reject) => {
    const onError = (reason: any) => {
      reject(reason);
    };
  
    const user = getCurrentUser();
    if(!user){
      onError("User not logged in to firebase");
      return;
    }
    
    checkUserHasContract(user.uid).then(hasValidContract => {
      if(!hasValidContract){
        reject("no-valid-contract");
      }
      
      const storage = new FirebaseStorage(user.uid);
      storage.get(X)
        .then(stateData => {
          resolve(stateData);
        })
        .catch(reason => onError(ERROR_reason.failedToGet))
      ;
    });
  });
}
//#endregion


export function remove(
  provider: string,
  uid: string,
): Promise<void> {
  if(!isSupportedProvider(provider)){
    return Promise.reject();
  }
  
  return keyFuncMap.remove[provider](uid);
}

async function removeFromLocalStorage(uid: string): Promise<any> {
  const storage = new LocalStorage(uid);
  
  const removeApp = storage.remove("App");
  const removeFinance = storage.remove("Finance");
  const removeSettings = storage.remove("Settings");
  
  return Promise.all([removeApp, removeFinance, removeSettings]);
}

async function removeFromMemoryStorage(uid: string): Promise<any> {
  const storage = new MemoryStorage(uid);
  
  const removeApp = storage.remove("App");
  const removeFinance = storage.remove("Finance");
  const removeSettings = storage.remove("Settings");
  
  return Promise.all([removeApp, removeFinance, removeSettings]);
}

async function removeFromFirebaseStorage(_uid: string): Promise<any> {
  const user = getCurrentUser();
  if(!user){
    throw new Error("User not logged in to firebase");
  }
  
  const hasValidContract = await checkUserHasContract(user.uid);
  if(!hasValidContract){
    return;
  }
  
  const storage = new FirebaseStorage(user.uid);
  return storage.remove("");
}
