import React from "react";
import {findDOMNode} from "react-dom";
import {closest} from "./utils";

interface INameEditorProps {
  item: {id: number, name: string};
  onChange: (itemId: number, itemName: string) => any;
  maxLength?: number;
}

interface INameEditorState {
  editing: boolean;
  editingValue: string;
}

class NameEditor extends React.Component<INameEditorProps, INameEditorState> {
  protected unmounting: boolean = false;
  
  constructor(props: INameEditorProps){
    super(props);
    
    this.onChangeName = this.onChangeName.bind(this);
    this.onKeyDown = this.onKeyDown.bind(this);
    this.onClickItemName = this.onClickItemName.bind(this);
    this.onClickOutside = this.onClickOutside.bind(this);
    
    this.state = {
      editing: false,
      editingValue: props.item.name,
    };
  }
  
  public render(){
    const {item} = this.props;
    const {editing, editingValue} = this.state;
    
    let itemName;
    if(!editing){
      itemName = (
        <div onClick={this.onClickItemName}>
          {item.name}
        </div>
      );
    }
    else{
      itemName = (
        <input
          value={editingValue}
          onChange={this.onChangeName}
          onKeyDown={this.onKeyDown}
          autoFocus={true}
        />
      );
    }
    
    return itemName;
  }
  
  public componentDidMount(){
    window.addEventListener("mousedown", this.onClickOutside, true);
  }
  
  public componentWillUnmount(){
    this.unmounting = true;
    window.removeEventListener("mousedown", this.onClickOutside);
  }
  
  public onChangeName(e: React.ChangeEvent<HTMLInputElement>){
    if(this.unmounting){
      return;
    }
    
    const {maxLength} = this.props;
    let editingValue = e.target.value;
    
    if(typeof(maxLength) === "number"){
      editingValue = editingValue.substr(0, maxLength);
    }
    
    this.setState({
      editingValue,
    });
  }
  
  public onKeyDown(e: React.KeyboardEvent<HTMLInputElement>){
    if(this.unmounting){
      return;
    }
    
    const {onChange} = this.props;
    
    if(e.key === "Enter"){
      this.setState({editing: false});
      
      if(this.state.editingValue !== this.props.item.name && this.state.editingValue.length > 0){
        onChange(this.props.item.id, this.state.editingValue);
      }
    }
  }
  
  public onClickItemName(){
    if(this.unmounting){
      return;
    }
    
    this.setState({editing: true});
  }
  
  public onClickOutside(e: MouseEvent){
    const {onChange, item} = this.props;
    
    if(this.unmounting || !this.state.editing){
      return;
    }
    
    const node = findDOMNode(this);
    if(!node){
      return;
    }
    
    const target = e.target as HTMLElement;
    
    if(!closest(target, (n) => node.contains(n))){
      this.setState({editing: false});
      
      if(this.state.editingValue !== item.name && this.state.editingValue.length > 0){
        onChange(item.id, this.state.editingValue);
      }
    }
  }
}

export default NameEditor;
