import React from "react";
import {withTranslation} from "react-i18next";
import Scrollbars from "react-custom-scrollbars";
import memoize from "memoize-one";
import {Provider} from "../ContentSizeProvider";
import {Provider as ScrollProvider} from "../ContentSizeProvider/ScrollProvider";
import AppBar from "./__container__/AppBar";
import Drawer from "./Drawer";
import {IGetActivities} from "../Activity";

import i18next from "i18next";
import * as R from "../../core/reducers/Finance/state";
import withStyles, {ClassNameMap, StyleRulesCallback} from "@material-ui/core/styles/withStyles";
import {shouldSidebarClosed} from "../../util/general";
import {Lang} from "../../reducers/App/state";
import {CustomTheme} from "../../util/theme";
import {Account} from "../../core/reducers/App/state";
import {Omit, TReservedHocProps} from "../../util/type";


type IMemoizeProvider = (h: number, w: number) => (children: React.ReactNode) => React.ReactElement<any>;

const Provider_memoized = memoize<IMemoizeProvider>((availableHeight, availableWidth) => Provider({availableHeight, availableWidth}));


const drawerWidth = 240;
const OptimizedResizeEvent = generateOptimizedResizeEvent();


interface IAppFrameProps {
  classes: ClassNameMap;
  theme: CustomTheme;
  i18n: i18next.i18n;
  lang: Lang;
  account: Account | null;
  activity: string;
  getActivities: IGetActivities;
  changeLanguage: (lang: Lang) => void;
  changeActivity: (activity: string) => void;
  toggleSidebar: () => void;
  unsaved: boolean;
  saving: boolean;
  provider?: string;
  sidebarOpen: boolean;
  currency: R.Currency;
}

interface IAppFrameState {
  availableHeight: number;
  availableWidth: number;
}


class AppFrame extends React.PureComponent<IAppFrameProps, IAppFrameState> {
  public scrollRef: React.RefObject<Scrollbars>;
  
  constructor(props: IAppFrameProps){
    super(props);
  
    this.state = {
      availableHeight: -1,
      availableWidth: -1,
    };
    
    this.onActivityMenuClickedHandler = this.onActivityMenuClickedHandler.bind(this);
    this.getAvailableContentSize = this.getAvailableContentSize.bind(this);
    this.handleDrawerToggle = this.handleDrawerToggle.bind(this);
    this.resizeListener = this.resizeListener.bind(this);
    
    this.scrollRef = React.createRef<Scrollbars>();
  }
  
  public render(){
    const {
      classes,
      children,
      lang,
      changeActivity,
      activity,
      getActivities,
      changeLanguage,
      unsaved,
      saving,
      provider,
      sidebarOpen,
      currency,
      account,
    } = this.props;
    /* 
     * We want to handle sidebar open/close state for calculating width/height.
     * So we calculate content size in every render
     */
    // let {availableHeight, availableWidth} = this.state;
    const {width, height} = this.getAvailableContentSize();
  
    const childrenWithSize = Provider_memoized(height, width)(children);
  
    const scrollBarsProps = {
      style: {width, height},
      autoHide: true,
      hideTracksWhenNotNeeded: true,
    };
  
    const childrenWithScrollAPI = ScrollProvider({scrollAPI: this.scrollRef.current})(childrenWithSize);
    
    return (
      <div className={classes.root}>
        <AppBar
          handleDrawerToggle={this.handleDrawerToggle}
        />
        <Drawer
          lang={lang}
          activity={activity}
          getActivities={getActivities}
          account={account}
          changeActivity={changeActivity}
          open={sidebarOpen}
          height={height}
        />
        <div className={classes.content}>
          <div className={classes.toolbar} />
          <main style={{position: "relative"}}>
            <Scrollbars {...scrollBarsProps} ref={this.scrollRef}>
              {childrenWithScrollAPI}
            </Scrollbars>
          </main>
        </div>
      </div>
    );
  }
  
  public componentDidMount(){
    OptimizedResizeEvent.addListener(this.resizeListener);
    this.resizeListener();
  }
  
  public componentDidUpdate(){
    const {current: scrollbar} = this.scrollRef;
    if(scrollbar && typeof(scrollbar.scrollTop) === "function"){
      scrollbar.scrollToTop();
    }
  }
  
  public componentWillUnmount(){
    if(typeof(this.resizeListener) === "function"){
      OptimizedResizeEvent.removeListener(this.resizeListener);
    }
  }
  
  public onActivityMenuClickedHandler(activity: string){
    const {changeActivity} = this.props;
    return (event: Event) => {
      changeActivity(activity);
    };
  }
  
  public getAvailableContentSize(){
    const {theme, sidebarOpen, activity, account} = this.props;
    const {screen} = window;
    // @ts-ignore
    const orientation = screen.msOrientation || (screen.orientation || screen.mozOrientation || {}).type;
    const windowHeight = window.innerHeight || window.document.documentElement.clientHeight || document.body.clientHeight;
    let windowWidth = window.innerWidth || window.document.documentElement.clientWidth || document.body.clientWidth;
    let toolbarHeight;
  
    // We set minWidth:1400px for <body> in styles/index.styl
    // So the minimum width is 1400.
    windowWidth = Math.max(windowWidth, 1400);
    
    toolbarHeight = theme.mixins.toolbar.minHeight || 64;
  
    if(orientation.startsWith("landscape") && windowWidth > 600 && theme.mixins.toolbar
      && theme.mixins.toolbar["@media (min-width:600px)"])
    {
      // @todo This is kind of a hack. Fix this if it could be.
      // @ts-ignore
      toolbarHeight = theme.mixins.toolbar["@media (min-width:600px)"].minHeight;
    }
    else if(orientation.startsWith("landscape")
      && theme.mixins.toolbar["@media (min-width:0px) and (orientation: landscape)"])
    {
      // @todo This is kind of a hack. Fix this if it could be.
      // @ts-ignore
      toolbarHeight = theme.mixins.toolbar["@media (min-width:0px) and (orientation: landscape)"].minHeight;
    }
    else if(theme.mixins.toolbar["@media (min-width:600px)"]){
      // @todo This is kind of a hack. Fix this if it could be.
      // @ts-ignore
      toolbarHeight = theme.mixins.toolbar["@media (min-width:600px)"].minHeight;
    }
  
    const availableHeight = windowHeight - toolbarHeight;
    let availableWidth = windowWidth;
    if(sidebarOpen){
      availableWidth -= drawerWidth;
    }
    else{
      availableWidth -= theme.spacing.unit * 9;
    }
    
    if(shouldSidebarClosed(activity, Boolean(account))){
      availableWidth = windowWidth;
    }
    
    return {width: availableWidth, height: availableHeight};
  }
  
  public handleDrawerToggle(){
    const {theme, toggleSidebar} = this.props;
    const duration = theme.transitions.duration.enteringScreen;
    
    toggleSidebar();
    
    setTimeout(() => {
      this.resizeListener();
    }, duration * 2);
  }
  
  public resizeListener(){
    const {height: availableHeight, width: availableWidth} = this.getAvailableContentSize();
    this.setState({availableHeight, availableWidth});
  }
}





const styles: StyleRulesCallback = (theme: CustomTheme) => ({
  root: {
    flexGrow: 1,
    zIndex: 1,
    overflow: "hidden",
    position: "relative",
    display: "flex",
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
  },
  navIconHide: {
    /*
    [theme.breakpoints.up("md")]: {
      display: "none",
    }
    //*/
  },
  flex: {
    flex: 1,
    [theme.breakpoints.down("sm")]: {
      flex: "auto",
      overflow: "hidden",
      opacity: 0,
    },
  },
  drawerPaper: {
    position: "relative",
    width: drawerWidth,
    whiteSpace: "nowrap",
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerPaperClose: {
    overflowX: "hidden",
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing.unit * 7,
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing.unit * 9,
    },
  },
  save: {
    margin: theme.spacing.unit / 2,
  },
  flag: {
    margin: theme.spacing.unit / 2,
  },
  flagEnd: {
    margin: theme.spacing.unit / 2,
    marginRight: theme.spacing.unit * 2,
  },
  content: {
    flexGrow: 1,
    backgroundColor: (theme.custom && theme.custom.backgroundColor) || "#eee",
    minWidth: 0, // So the Typography noWrap works
  },
  toolbar: theme.mixins.toolbar,
  nested: {
    paddingLeft: theme.spacing.unit * 4,
  },
  nestedOnClosedSidebar: {
    paddingLeft: theme.spacing.unit * 3,
  },
  saveIcon: {
    color: "rgba(255,255,255,.7)",
  },
});


interface IGenerateOptimizedResizeEventReturnValue {
  addListener: (cb: () => any) => void;
  removeListener: (cb: () => any) => void;
}

function generateOptimizedResizeEvent(): IGenerateOptimizedResizeEventReturnValue {
  let callbacks: Array<(() => any)> = [];
  let running = false;
  
  // fired on resize event
  function resize(){
    if (!running) {
      running = true;
      
      if (window.requestAnimationFrame){
        window.requestAnimationFrame(runCallbacks);
      }
      else {
        setTimeout(runCallbacks, 66);
      }
    }
  }
  
  // run the actual callbacks
  function runCallbacks(){
    callbacks.forEach((callback) => {
      callback();
    });
    
    running = false;
  }
  
  // adds callback to loop
  function addCallback(callback: () => any){
    if (callback) {
      callbacks.push(callback);
    }
  }
  
  function removeCallback(callback: () => any){
    callbacks = callbacks.filter(cb => {
      return cb !== callback;
    });
  }
  
  return {
    // public method to add additional callback
    addListener: (callback: () => any) => {
      if(!callbacks.length){
        window.addEventListener("resize", resize);
      }
      addCallback(callback);
    },
    removeListener: (callback: () => any) => {
      if(typeof(callback) === "function"){
        removeCallback(callback);
      }
    },
  };
}



let _Component: React.ComponentType<any> = AppFrame;
_Component = withStyles(styles, {withTheme: true})(_Component);
_Component = withTranslation("App")(_Component);

export default _Component as React.ComponentType<Omit<IAppFrameProps, TReservedHocProps>>;
