import React from "react";
import {Omit} from "../../util/type";

type TScrollAPI = React.Component<any, any>|null;

interface IScrollContext {
  scrollAPI: TScrollAPI;
}

const Scroll = React.createContext<IScrollContext>({
  scrollAPI: null,
});

type IProvider = (arg: {scrollAPI: TScrollAPI}) => ((children: React.ReactElement<any>) => React.ReactElement<any>);

const Provider: IProvider = ({scrollAPI}) => {
  return (children) => (
    <Scroll.Provider value={{scrollAPI}}>
      {children}
    </Scroll.Provider>
  );
};

type IWithScrollAPI = <P extends IScrollContext>(c: React.ComponentType<any>) => React.FunctionComponent<Omit<P, keyof IScrollContext>>;

const withScrollAPI: IWithScrollAPI = (ComponentClass) => {
  return (props) => {
    const ComponentWithScrollAPI = ({scrollAPI}: {scrollAPI: TScrollAPI}) => {
      const extraProps = {...props, scrollAPI};
    
      return <ComponentClass {...extraProps}/>;
    };
  
    return (
      <Scroll.Consumer>
        {ComponentWithScrollAPI}
      </Scroll.Consumer>
    );
  };
};

export {Provider, withScrollAPI};
