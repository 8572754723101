import React from "react";
import FormControl from "@material-ui/core/FormControl";
import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
import unformat from "accounting-js/lib/unformat";
import { ClassNameMap } from "@material-ui/core/styles/withStyles";

interface ITextFieldProps {
  classes?: ClassNameMap;
  disabled?: boolean;
  id?: string;
  type?: string;
  label?: string;
  onEdited: (value: number|undefined) => any;
  width?: number;
  option?: {
    formatter?: (value: string|number) => string;
    min?: number;
    max?: number;
    title?: string;
    key?: string;
    defaultValue?: number;
    step?: number;
    nullable?: boolean;
  };
}

interface ITextFieldState {
  editing: boolean;
  value: string | undefined;
}


class TextField extends React.Component<ITextFieldProps, ITextFieldState> {
  constructor(props: ITextFieldProps){
    super(props);
    
    this.state = {
      editing: false,
      value: undefined,
    };
  
    this.onEndEdit = this.onEndEdit.bind(this);
  }
  
  public render(){
    const {classes, option, disabled, label, width} = this.props;
    let {id, type} = this.props;
    const {editing} = this.state;
    let {value} = this.state;
    
    if(typeof(label) === "string"){
      if(id === null || id === undefined){
        id = Math.round(Math.random() * 100000).toString();
      }
    }
    
    if(typeof(type) !== "string"){
      type = "text";
    }
    
    let rootClassName = "";
    let inputClassName = "";
    if(classes){
      ({root: rootClassName, input: inputClassName} = classes);
    }
  
    let formatter;
    let min: number | undefined;
    let max: number | undefined;
    let title;
    let key;
    let defaultValue;
    let step: number | undefined;
    if(option){
      ({formatter, min, max, title, key, defaultValue, step} = option);
    }
    
    if(!option || (!option.step && typeof(option.step) !== "number")){
      step = 1;
    }
  
    if(value === undefined && typeof(defaultValue) === "number"){
      value = defaultValue.toString();
    }
    else if(value === undefined){
      value = "";
    }
  
    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      this.setState({value: e.target.value});
    };
  
    let input;
    if(disabled){
      input = (
        <Input
          id={id}
          type={type}
          value={(formatter && value !== undefined) ? formatter(value) : value}
          style={typeof(width) === "number" ? {width} : undefined}
          className={inputClassName}
          disabled={true}
        />
      );
    }
    else if(editing){
      input = (
        <Input
          id={id}
          type={type}
          value={value}
          style={typeof(width) === "number" ? {width} : undefined}
          className={inputClassName}
          onChange={handleChange}
          onClick={e => e.stopPropagation()}
          onBlur={() => this.onEndEdit()}
          onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => {
            if(e.key === "Enter"){
              this.onEndEdit();
              return;
            }
            if(["ArrowUp", "Up", "ArrowDown", "Down"].includes(e.key)){
              let v = (+(e.target as HTMLInputElement).value); // To number
    
              if(e.key === "ArrowUp" || e.key === "Up"){
                if(typeof(step) === "number"){
                  v += step;
                }
                else{
                  v += 10 ** Math.floor(Math.log10(Math.abs(v) || 1) - 1);
                }
              }
              else if(e.key === "ArrowDown" || e.key === "Down"){
                if(typeof(step) === "number"){
                  v -= step;
                }
                else{
                  v -= 10 ** Math.floor(Math.log10(Math.abs(v) || 1) - 1);
                }
              }
    
              if(typeof(min) === "number" && (v < min || isNaN(v))){
                return;
              }
              if(typeof(max) === "number" && (v > max || isNaN(v))){
                return;
              }
    
              this.setState({value: v.toString()});
            }
          }}
        />
      );
    }
    else{
      let inputValue;
      let valueStr: string;
      
      if(typeof(value) === "number"){
        inputValue = formatter ? formatter(value) : value;
        valueStr = (value as number).toString();
      }
      else{
        inputValue = "";
        valueStr = "";
      }
    
      input = (
        <Input
          id={id}
          type={type}
          value={inputValue}
          style={typeof(width) === "number" ? {width} : undefined}
          className={inputClassName}
          onClick={e => {
            e.stopPropagation();
            this.setState({editing: true, value: valueStr});
          }}
          onFocus={e => {
            e.stopPropagation();
            this.setState({editing: true, value: valueStr});
          }}
          readOnly={true}
        />
      );
    }
  
    return (
      <FormControl className={rootClassName} title={title} key={key}>
        {label ? <InputLabel htmlFor={id}>{label}</InputLabel> : null}
        {input}
      </FormControl>
    );
  }
  
  public onEndEdit(){
    let {value} = this.state;
    const {option, onEdited} = this.props;
  
    let min;
    let max;
    let nullable;
    if(option){
      ({min, max, nullable} = option);
    }
  
    if(!value && nullable){
      value = undefined;
    }
    else if(typeof(value) === "string" && value.startsWith("0") && !(unformat(value) % 1)){ // check float
      value = value.replace(/^0/, "0.");
      value = unformat(value);
    }
    else{
      value = unformat(value);
    }
  
    if(typeof(value) === "number" && typeof(min) === "number" && value < min){
      return;
    }
    if(typeof(value) === "number" && typeof(max) === "number" && value > max){
      return;
    }
  
    this.setState({
      editing: false,
      value,
    }, () => {
      if(typeof(onEdited) === "function"){
        onEdited(value !== undefined ? +value : undefined);
      }
    });
  }
}



export default TextField;
