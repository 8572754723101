import React, {Component, Fragment} from "react";
import {withTranslation} from "react-i18next";
import Paper from "@material-ui/core/Paper";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Button from "@material-ui/core/Button";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import DatePicker from "react-datepicker";
import moment from "moment";
import "moment/locale/ja";
import "react-datepicker/dist/react-datepicker.css";
import ConfirmDialog from "../../../util/component/ConfirmDialog";
import {withContentSize} from "../../ContentSizeProvider";
import {getInitialAccounts, getPopularItems} from "../../../util/initialUserData";
import GroupEditor from "../../../util/component/GroupEditor";
import * as T from "../../../util/component/GroupEditor/Manager";
import {
  getCurrentDate,
  getCustomDateFromDate,
  getDateFromCustomDate,
  isFixedAccountType,
  isFixedItem,
  isFixedItemGroup,
  mergeAccounts,
  mergeAccountTypes,
} from "../../../core/util/finance";
import TextField from "../../../util/component/TextField";
import formatMoney from "accounting-js/lib/formatMoney";
import {getCurrentUserName} from "../../../util/user";

import withStyles, {ClassNameMap, StyleRulesCallback} from "@material-ui/core/styles/withStyles";
import * as R from "../../../core/reducers/Finance/state";
import i18next from "i18next";
import {InitialBalance} from "../../../core/actions/types/finance";
import {Lang} from "../../../reducers/App/state";
import {CustomTheme} from "../../../util/theme";
import {Account} from "../../../core/reducers/App/state";
import {Omit, TReservedHocProps} from "../../../util/type";

interface Item extends T.GeneralItem {
  group: number;
}

interface ItemGroup extends T.GeneralGroup {
  
}

interface IAccount extends T.GeneralItem {
  type: number;
  currency: number;
}

interface IAccountType extends T.GeneralGroup {
  type: R.AccountTypeCategory;
}

interface IAccountTypeWithAccount extends R.AccountTypeMaster {
  accounts: R.AccountMaster[];
}



interface IWelcomeProps {
  classes: ClassNameMap;
  theme: CustomTheme;
  i18n: i18next.i18n;
  lang: Lang;
  availableHeight: number;
  availableWidth: number;
  currency: R.Currency;
  initializeAccount: () => void;
  initializeFinancialItem: (items: R.ItemMaster[], itemGroups: R.ItemGroupMaster[]) => void;
  initializeFinancialAccount: (accounts: R.AccountMaster[], accountTypes: R.AccountTypeMaster[]) => void;
  initializeFinancialBalance: (initialBalance: InitialBalance[]) => void;
  account: Account;
  licenseAgreement: boolean;
  privacyPolicyAgreement: boolean;
  agreeTerms: (accept: boolean) => Promise<void>;
  disagreeTerms: () => Promise<void>;
  activity: string;
  items: R.ItemMaster[];
  itemGroups: R.ItemGroupMaster[];
  accounts: R.AccountMaster[];
  accountTypes: R.AccountTypeMaster[];
  currencies: R.Currency[];
  saveDataAll: () => Promise<any>;
  saveDataApp: () => Promise<any>;
}

interface IWelcomeState {
  page: string;
  licenseAgreement: boolean;
  privacyPolicyAgreement: boolean;
  createInitialItems: boolean;
  error: string;
  dialog: string;
  initialBalance: InitialBalance[];
  currency: R.CurrencyId;
}


class Welcome extends Component<IWelcomeProps, IWelcomeState> {
  public items: Item[]|null = null;
  public groups: ItemGroup[]|null = null;
  public accounts: IAccount[]|null = null;
  public accountTypes: IAccountType[]|null = null;
  
  constructor(props: IWelcomeProps){
    super(props);
    
    const {licenseAgreement, privacyPolicyAgreement} = props;
    
    this.getStepper = this.getStepper.bind(this);
    this.getLicenseAgreementPage = this.getLicenseAgreementPage.bind(this);
    this.getItemSettingPage = this.getItemSettingPage.bind(this);
    this.getAccountPage = this.getAccountPage.bind(this);
    this.getAccountBalancePage = this.getAccountBalancePage.bind(this);
    this.reasonForNotRemovingItems = this.reasonForNotRemovingItems.bind(this);
    this.reasonForNotRemovingGroups = this.reasonForNotRemovingGroups.bind(this);
    this.generateItemTemplate = this.generateItemTemplate.bind(this);
    this.generateGroupTemplate = this.generateGroupTemplate.bind(this);
    this.onClickToInitialAccountPage = this.onClickToInitialAccountPage.bind(this);
    this.onChangeItem = this.onChangeItem.bind(this);
    this.onChangeAccount = this.onChangeAccount.bind(this);
    this.onRemoveAccount = this.onRemoveAccount.bind(this);
    this.onRemoveAccountType = this.onRemoveAccountType.bind(this);
    this.reasonForNotRemovingAccounts = this.reasonForNotRemovingAccounts.bind(this);
    this.reasonForNotRemovingAccountTypes = this.reasonForNotRemovingAccountTypes.bind(this);
    this.generateAccountTemplate = this.generateAccountTemplate.bind(this);
    this.generateAccountTypeTemplate = this.generateAccountTypeTemplate.bind(this);
    this.onClickToAccountBalancePage = this.onClickToAccountBalancePage.bind(this);
    this.getInitialBalanceEditor = this.getInitialBalanceEditor.bind(this);
    this.onComplete = this.onComplete.bind(this);
    
    this.state = {
      page: (licenseAgreement && privacyPolicyAgreement) ? "itemSettings" : "licenseAgreement",
      licenseAgreement,
      privacyPolicyAgreement,
      createInitialItems: false,
      error: "",
      dialog: "",
      initialBalance: [],
      currency: -1,
    };
    
    this.items = null;
    this.groups = null;
    this.accounts = null;
    this.accountTypes = null;
  }
  
  public render(){
    const {
      classes,
      i18n,
      lang,
    } = this.props;
    const {
      page,
      error,
    } = this.state;
    const t = i18n.getFixedT(lang, "Home");
    
    if(page === "licenseAgreement"){
      return this.getLicenseAgreementPage();
    }
    else if(page === "itemSettings"){
      return this.getItemSettingPage();
    }
    else if(page === "initialAccounts"){
      return this.getAccountPage();
    }
    else if(page === "initialBalance"){
      return this.getAccountBalancePage();
    }
    
    return (
      <div className={classes.root}>
        <Paper classes={{root: classes.paperRoot}}>
          {error ? t<string>(`welcome.${error}`) : "404 Sorry, page not found."}
        </Paper>
      </div>
    );
  }
  
  public getStepper(){
    const {i18n, lang} = this.props;
    const {page} = this.state;
    const t = i18n.getFixedT(lang, "Home");
    
    let step = 0;
    const stepProps = {
      itemSettings: {completed: false},
      initialAccounts: {completed: false},
      initialBalance: {completed: false},
    };
    
    if(page === "itemSettings"){
      step = 0;
    }
    if(page === "initialAccounts"){
      step = 1;
      stepProps.itemSettings.completed = true;
    }
    if(page === "initialBalance"){
      step = 2;
      stepProps.itemSettings.completed = true;
      stepProps.initialAccounts.completed = true;
    }
    
    return (
      <Stepper activeStep={step}>
        <Step {...stepProps.itemSettings}>
          <StepLabel>{t<string>("welcome.initializeItem")}</StepLabel>
        </Step>
        <Step {...stepProps.initialAccounts}>
          <StepLabel>{t<string>("welcome.initializeAccount")}</StepLabel>
        </Step>
        <Step {...stepProps.initialBalance}>
          <StepLabel>{t<string>("welcome.initializeBalance")}</StepLabel>
        </Step>
      </Stepper>
    );
  }
  
  public getLicenseAgreementPage(){
    const {
      classes,
      account,
      i18n,
      lang,
      agreeTerms,
      disagreeTerms,
      theme,
      activity,
      saveDataApp,
    } = this.props;
    const {
      licenseAgreement,
      privacyPolicyAgreement,
    } = this.state;
    const t = i18n.getFixedT(lang, "Home");
    const t_about = i18n.getFixedT(lang, "About");
  
    return (
      <div className={classes.root}>
        <Paper classes={{root: classes.paperRoot}}>
          <h1>
            {t<string>("welcome.title", {user: getCurrentUserName(account)})}
          </h1>
          <p className={classes.titleDesc} dangerouslySetInnerHTML={{__html: t<string>("welcome.titleDesc")}} />
          
          <div className={classes.licenseTitle}>
            {t_about<string>("licenseTitle")}
          </div>
          <div className={classes.licenseContent} dangerouslySetInnerHTML={{__html: t_about<string>("licenseDescription")}} />
          <div className={classes.checkbox}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={licenseAgreement}
                  onChange={e => this.setState({licenseAgreement: e.target.checked})}
                />
              }
              label={t<string>("welcome.agreeLicenseTerm")}
            />
          </div>
  
          <div className={classes.licenseTitle}>
            {t_about<string>("privacyPolicyTitle")}
          </div>
          <div className={classes.licenseContent} dangerouslySetInnerHTML={{__html: t_about<string>("privacyPolicyDescription")}} />
          <div className={classes.checkbox}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={privacyPolicyAgreement}
                  onChange={e => this.setState({privacyPolicyAgreement: e.target.checked})}
                />
              }
              label={t<string>("welcome.agreePrivacyPolicy")}
            />
          </div>
          
          <div className={classes.agreementButtons}>
            <Button
              variant="contained"
              onClick={async () => {
                if(licenseAgreement && privacyPolicyAgreement){
                  if(activity === "welcome"){
                    
                    agreeTerms(licenseAgreement && privacyPolicyAgreement)
                      .then(async () => {
                        this.setState({page: "itemSettings"});
                        await saveDataApp();
                      })
                      .catch(reason => {
                        this.setState({error: "failedToSaveAgreement"});
                      });
                  }
                  else{
                    await agreeTerms(licenseAgreement && privacyPolicyAgreement).catch(reason => {
                      // Do nothing
                    });
                    await saveDataApp();
                  }
                }
              }}
              disabled={!licenseAgreement || !privacyPolicyAgreement}
            >
              {t<string>("welcome.agreeAndGoNextPage")}
            </Button>
            <Button
              variant="contained"
              onClick={disagreeTerms}
              style={{marginLeft: theme.spacing.unit*2}}
            >
              {t<string>("welcome.disagree")}
            </Button>
          </div>
        </Paper>
      </div>
    );
  }
  
  public getItemSettingPage(){
    const {classes, i18n, lang, theme, availableHeight} = this.props;
    const {createInitialItems} = this.state;
    const t = i18n.getFixedT(lang, "Home");
    const t_pop = i18n.getFixedT(lang, "PopularItems");
    const t_fin = i18n.getFixedT(lang, "Finance");
    
    const windowHeight = window.innerHeight || window.document.documentElement.clientHeight || document.body.clientHeight;
    const headerHeight = windowHeight - availableHeight;
    
    let groupEditor;
    if(createInitialItems){
      let items;
      let itemGroups;
      
      if(this.items === null && this.groups === null){
        ({items, itemGroups} = getPopularItems());
  
        items = items.map(item => {
          return {
            ...item,
            name: t_pop(`items.${item.name}`),
          };
        });
        
        itemGroups = itemGroups.map(itemGroup => {
          return {
            ...itemGroup,
            name: t_pop(`itemGroups.${itemGroup.name}`),
          };
        });
      }
      else{
        items = this.items as R.ItemMaster[];
        itemGroups = this.groups as R.ItemGroupMaster[];
      }
  
      groupEditor = (
        <Fragment>
          <div style={{marginBottom: theme.spacing.unit*4}}>
            {t<string>("welcome.initialItemsAreHere")}
          </div>
    
          <GroupEditor
            items={items}
            groups={itemGroups}
            bindPropParent="id"
            bindPropChild="group"
            width={1000 - theme.spacing.unit*8}
            onChange={this.onChangeItem}
            headerHeight={headerHeight}
            removeConfirmText={t_fin("input.items.removeConfirmTitle")}
            removeGroupConfirmText={t_fin("input.items.removeGroupConfirmTitle")}
            onRemoveItem={this.onRemoveItem}
            onRemoveGroup={this.onRemoveItemGroup}
            reasonForNotRemovingItems={this.reasonForNotRemovingItems}
            reasonForNotRemovingGroups={this.reasonForNotRemovingGroups}
            generateItemTemplate={this.generateItemTemplate}
            generateGroupTemplate={this.generateGroupTemplate}
            maxItemNameLength={30}
            maxGroupNameLength={30}
            addGroupLabel={t_fin("input.items.newItemGroupName")}
          />
        </Fragment>
      );
    }
    else{
      groupEditor = (
        <Fragment>
          <div style={{marginBottom: theme.spacing.unit*4}}>
            {t<string>("welcome.noItemsAreAdded")}
          </div>
        </Fragment>
      );
    }
    
    return (
      <div className={classes.root}>
        <Paper classes={{root: classes.paperRoot}} style={{width: 1000}}>
          {this.getStepper()}
  
          <div className={classes.checkboxContainer}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={createInitialItems}
                  onChange={e => this.setState({createInitialItems: e.target.checked})}
                />
              }
              label={t<string>("welcome.createInitialItems")}
            />
          </div>
          
          {groupEditor}
          
          <div className={classes.agreementButtons}>
            <Button
              variant="contained"
              onClick={this.onClickToInitialAccountPage}
            >
              {t<string>("welcome.goNext")}
            </Button>
          </div>
        </Paper>
      </div>
    );
  }
  
  public getAccountPage(){
    const {classes, i18n, lang, theme, availableHeight, currencies} = this.props;
    const {currency} = this.state;
    const t = i18n.getFixedT(lang, "Home");
    const t_pop = i18n.getFixedT(lang, "PopularItems");
    const t_fin = i18n.getFixedT(lang, "Finance");
  
    const windowHeight = window.innerHeight || window.document.documentElement.clientHeight || document.body.clientHeight;
    const headerHeight = windowHeight - availableHeight;
  
    let groupEditor;
    let accounts;
    let accountTypes;
  
    if(this.accounts === null && this.accountTypes === null){
      ({accounts, accountTypes} = getInitialAccounts(currency));
  
      accounts = accounts.map(account => {
        return {
          ...account,
          name: t_pop(`accounts.${account.name}`),
        };
      });
      
      accounts = mergeAccounts(this.props.accounts, accounts);
      this.accounts = accounts as IAccount[];
  
      accountTypes = accountTypes.map(accountType => {
        return {
          ...accountType,
          name: t_pop(`accountTypes.${accountType.name}`),
        };
      });
      
      accountTypes = mergeAccountTypes(this.props.accountTypes, accountTypes);
      this.accountTypes = accountTypes as IAccountType[];
    }
    else{
      accounts = this.accounts as IAccount[];
      accountTypes = this.accountTypes as IAccountType[];
    }
    
    const assetTypes = [
      {id: "totalAsset", label: t_fin("book.totalAsset")},
      {id: "liability", label: t_fin("book.liability")},
    ];
  
    groupEditor = (
      <Fragment>
        <div
          className={classes.description}
          dangerouslySetInnerHTML={{__html: t<string>("welcome.initialAccountsAreHere")}}
        />
      
        <GroupEditor
          items={accounts}
          groups={accountTypes}
          bindPropParent="id"
          bindPropChild="type"
          bindPropCategory="type"
          categories={assetTypes}
          width={1000 - theme.spacing.unit*8}
          onChange={this.onChangeAccount}
          headerHeight={headerHeight}
          removeConfirmText={t_fin("input.items.removeAccountConfirmTitle")}
          removeGroupConfirmText={t_fin("input.items.removeAccountTypeConfirmTitle")}
          onRemoveItem={this.onRemoveAccount}
          onRemoveGroup={this.onRemoveAccountType}
          reasonForNotRemovingItems={this.reasonForNotRemovingAccounts}
          reasonForNotRemovingGroups={this.reasonForNotRemovingAccountTypes}
          generateItemTemplate={this.generateAccountTemplate}
          generateGroupTemplate={this.generateAccountTypeTemplate}
          maxItemNameLength={30}
          maxGroupNameLength={30}
          addGroupLabel={t_fin("input.items.newAccountTypeName")}
          currencies={currencies}
        />
      </Fragment>
    );
  
    return (
      <div className={classes.root}>
        <Paper classes={{root: classes.paperRoot}} style={{width: 1000}}>
          {this.getStepper()}
        
          {groupEditor}
        
          <div className={classes.agreementButtons}>
            <Button
              variant="contained"
              onClick={() => this.setState({page: "itemSettings"})}
            >
              {t<string>("welcome.goBack")}
            </Button>
            <Button
              variant="contained"
              onClick={this.onClickToAccountBalancePage}
              style={{marginLeft: theme.spacing.unit*2}}
            >
              {t<string>("welcome.goNext")}
            </Button>
          </div>
        </Paper>
      </div>
    );
 }
  
  public getAccountBalancePage(){
    const {
      classes,
      i18n,
      lang,
      theme,
      accounts,
      accountTypes,
    } = this.props;
    const {dialog} = this.state;
    const t = i18n.getFixedT(lang, "Home");
    
    const data = accountTypes.map(at => {
      const associatedAccounts = accounts.filter(a => a.type === at.id);
      associatedAccounts.sort((a, b) => a.order - b.order);
      
      return {
        ...at,
        accounts: associatedAccounts,
      };
    }) as IAccountTypeWithAccount[];
    
    data.sort((a, b) => {
      const a_order = ["totalAsset", "liability", "netAsset"].findIndex(type => a.type === type);
      const b_order = ["totalAsset", "liability", "netAsset"].findIndex(type => b.type === type);
      
      if(a_order !== b_order){
        return a_order - b_order;
      }
      
      return a.order - b.order;
    });
    
    const initialBalanceContainer = this.getInitialBalanceEditor(data);
    
    let dialogContainer;
    if(dialog === "complete"){
      dialogContainer = (
        <ConfirmDialog
          lang={lang}
          onClickCancel={() => this.setState({dialog: ""})}
          onClickOK={this.onComplete}
        >
          {t<string>("welcome.areYouSureToComplete")}
        </ConfirmDialog>
      );
    }
    
    return (
      <div className={classes.root}>
        <Paper className={classes.paperRoot} style={{width: 1000}}>
          {this.getStepper()}
          
          {initialBalanceContainer}
          
          <div className={classes.agreementButtons}>
            <Button
              variant="contained"
              onClick={() => this.setState({page: "initialAccounts"})}
            >
              {t<string>("welcome.goBack")}
            </Button>
            <Button
              variant="contained"
              onClick={() => {
                this.setState({dialog: "complete"});
              }}
              style={{marginLeft: theme.spacing.unit*2}}
            >
              {t<string>("welcome.complete")}
            </Button>
          </div>
        </Paper>
        {dialogContainer}
      </div>
    );
  }
  
  public onRemoveItem(item: T.ItemBase){
    return !isFixedItem((item as R.ItemMaster)); // If false, stop removing
  }
  
  public onRemoveItemGroup(itemGroupId: number, items: T.ItemBase[], groups: T.ItemBase[]){
    const itemGroup = groups.find(g => g.id === itemGroupId) as R.ItemGroupMaster;
    if(isFixedItemGroup(itemGroup)){
      return false;
    }
    
    return items.every(item => (item as R.ItemMaster).group !== itemGroupId); // if false, stop removing
  }
  
  public reasonForNotRemovingItems(removingItems: T.ItemBase[]){
    const {i18n, lang, classes} = this.props;
    const t = i18n.getFixedT(lang, "Finance");
    
    return (
      <div>
        <p>
          {t<string>("input.items.cannotRemoveFixedItemsDesc")}
        </p>
        
        <div className={classes.removingItemContainer}>
          {removingItems.map(item => {
            return (
              <div className={classes.removingItems} key={item.id}>
                {item.name}
              </div>
            );
          })}
        </div>
      </div>
    );
  }
  
  public reasonForNotRemovingGroups(removingGroups: T.ItemBase[], items: T.ItemBase[]){
    const {i18n, lang, classes} = this.props;
    const t = i18n.getFixedT(lang, "Finance");
  
    const content = removingGroups.map(group => {
      if(isFixedItemGroup(group)){
        return (
          <React.Fragment key={group.id}>
            <p>
              {t<string>("input.items.cannotRemoveFixedItemGroupsDesc")}
            </p>
            <div className={classes.removingItemContainer} style={{justifyContent: "center"}}>
              <div className={classes.removingItems}>
                {group.name}
              </div>
            </div>
          </React.Fragment>
        );
      }
      else{
        return (
          <React.Fragment key={group.id}>
            <p>
              {t<string>("input.items.cannotRemoveItemGroupDesc")}
            </p>
            <div className={classes.removingItemContainer}>
              {items.filter(item => removingGroups.find(g => g.id === (item as R.ItemMaster).group)).map(item => {
                return (
                  <div className={classes.removingItems} key={item.id}>
                    {item.name}
                  </div>
                );
              })}
            </div>
          </React.Fragment>
        );
      }
    });
    
    return (
      <div>
        {content}
      </div>
    );
  }
  
  public generateItemTemplate(){
    return {
      id: null,
      name: null,
      order: null,
      group: null,
    };
  }
  
  public generateGroupTemplate(){
    const {i18n, lang} = this.props;
    const t = i18n.getFixedT(lang, "Finance");
    
    return {
      id: null,
      name: t<string>("input.items.newItemGroupName"),
      order: null,
    };
  }
  
  public onClickToInitialAccountPage(){
    const {createInitialItems} = this.state;
    
    this.setState({
      page: "initialAccounts",
    }, () => {
      const {initializeFinancialItem} = this.props;
      let items;
      let groups;
      if(createInitialItems){
        items = this.items;
        groups = this.groups;
        
        if(items !== null && groups !== null){
          initializeFinancialItem(items, groups);
        }
      }
    });
  }
  
  public onChangeItem(items: T.ItemBase[], groups: T.ItemBase[]){
    this.items = items as Item[];
    this.groups = groups as ItemGroup[];
  }
  
  public onChangeAccount(accounts: T.ItemBase[], accountTypes: T.ItemBase[]){
    this.accounts = accounts as IAccount[];
    this.accountTypes = accountTypes as IAccountType[];
  }
  
  public onRemoveAccount(account: T.ItemBase){
    return true;
  }
  
  public onRemoveAccountType(accountTypeId: number, accounts: T.ItemBase[], accountTypes: T.ItemBase[]){
    const accountType = accountTypes.find(at => at.id === accountTypeId) as R.AccountTypeMaster;
    if(isFixedAccountType(accountType)){
      return false;
    }
  
    return accounts.every(account => (account as R.AccountMaster).type !== accountTypeId); // if false, stop removing
  }
  
  public reasonForNotRemovingAccounts(removingAccounts: T.ItemBase[]){
    const {i18n, lang, classes} = this.props;
    const t = i18n.getFixedT(lang, "Finance");
  
    return (
      <div>
        <p>
          {t<string>("input.items.cannotRemoveFixedItemsDesc")}
        </p>
      
        <div className={classes.removingItemContainer}>
          {removingAccounts.map(account => {
            return (
              <div className={classes.removingItems} key={account.id}>
                {account.name}
              </div>
            );
          })}
        </div>
      </div>
    );
  }
  
  public reasonForNotRemovingAccountTypes(removingAccountTypes: T.ItemBase[], accounts: T.ItemBase[]){
    const {i18n, lang, classes} = this.props;
    const t = i18n.getFixedT(lang, "Finance");
  
    const content = removingAccountTypes.map((accountType) => {
      if(isFixedAccountType((accountType as R.AccountTypeMaster))){
        return (
          <React.Fragment key={accountType.id}>
            <p>
              {t<string>("input.items.cannotRemoveFixedOnesDesc")}
            </p>
            <div className={classes.removingItemContainer} style={{justifyContent: "center"}}>
              <div className={classes.removingItems}>
                {accountType.name}
              </div>
            </div>
          </React.Fragment>
        );
      }
      else{
        return (
          <React.Fragment key={accountType.id}>
            <p>
              {t<string>("input.items.cannotRemoveAccountTypeDesc")}
            </p>
            <div className={classes.removingItemContainer}>
              {accounts.filter(account => removingAccountTypes.find(at => at.id === (account as R.AccountMaster).type)).map(account => {
                return (
                  <div className={classes.removingItems} key={account.id}>
                    {account.name}
                  </div>
                );
              })}
            </div>
          </React.Fragment>
        );
      }
    });
  
    return (
      <div>
        {content}
      </div>
    );
  }
  
  public generateAccountTemplate(){
    return {
      id: null,
      name: null,
      order: null,
      type: null,
    };
  }
  
  public generateAccountTypeTemplate(){
    const {i18n, lang} = this.props;
    const t = i18n.getFixedT(lang, "Finance");
  
    return {
      id: null,
      name: t<string>("input.items.newAccountTypeName"),
      type: "totalAsset",
      order: null,
    };
  }
  
  public onClickToAccountBalancePage(){
    this.setState({
      page: "initialBalance",
    }, () => {
      const {initializeFinancialAccount} = this.props;
      
      if(this.accounts !== null && this.accountTypes !== null){
        initializeFinancialAccount(this.accounts, this.accountTypes);
      }
    });
  }
  
  public getInitialBalanceEditor(data: IAccountTypeWithAccount[]){
    const {classes, i18n, lang, currencies} = this.props;
    const t = i18n.getFixedT(lang, "Home");
    const t_fin = i18n.getFixedT(lang, "Finance");
  
    const table = data.map((accountType, i) => {
      const {accounts, type} = accountType;
      
      return (
        accounts.map((account, i2) => {
          const onEdited = (value: number|undefined) => {
            let {initialBalance} = this.state;
  
            const index = initialBalance.findIndex(b => b.id === account.id);
            if(index > -1 && initialBalance[index].value === value){
              return;
            }
            else if(index === -1){
              initialBalance.push({
                id: account.id,
                value: typeof(value) === "number" ? value : null,
                date: null,
              });
            }
            else{
              initialBalance = initialBalance.map((b, i3) => {
                if(i3 === index){
                  return {
                    ...initialBalance[index],
                    value: typeof(value) === "number" ? value : null,
                  };
                }
                
                return b;
              });
            }
            
            this.setState({initialBalance});
          };
          
          const currency = currencies.find(c => c.id === account.currency);
          
          const textField = (
            <TextField
              onEdited={onEdited}
              option={{
                formatter: (value: React.ReactText) => formatMoney(value, currency),
                nullable: true,
              }}
              classes={{input: classes.input}}
            />
          );
          
          let datePicker;
          const selectedValue = (() => {
            const {initialBalance} = this.state;
  
            const index = initialBalance.findIndex(b => b.id === account.id);
            if(index > -1){
              const {date} = initialBalance[index];
              if(typeof(date) === "number"){
                return moment(getDateFromCustomDate(date));
              }
              return moment(getCurrentDate());
            }
            else{
              return moment(getCurrentDate());
            }
          })();
          
          const handleDateChange = (date: moment.Moment) => {
            let {initialBalance} = this.state;
            const index = initialBalance.findIndex(b => b.id === account.id);
            
            if(index === -1){
              initialBalance.push({
                id: account.id,
                value: null,
                date: date ? getCustomDateFromDate(new Date(date.unix()*1000)) : null,
              });
            }
            else{
              initialBalance = [...initialBalance];
              initialBalance[index] = {
                ...initialBalance[index],
                date: date ? getCustomDateFromDate(new Date(date.unix()*1000)) : null,
              };
            }
            
            this.setState({initialBalance});
          };
          
          const datePickerProps1 = {
            onChange: handleDateChange,
            selectsStart: false,
            selected: selectedValue,
            showMonthDropdown: true,
            showYearDropdown: true,
            dropdownMode: "select" as "select",
            locale: lang,
            className: classes.datePicker,
            placeholderText: t<string>("date.selectStartDate"),
            dateFormat: lang === "ja" ? "YYYY/MM/DD(ddd)" : "MM/DD/YYYY",
            highlightDates: [moment(getCurrentDate())],
            popperPlacement: "bottom-start",
            calendarClassName: classes.calendar,
            popperModifiers: {
              offset: {
                enabled: true,
                offset: "40px, 0px",
              },
            },
            dayClassName: (date: moment.Moment) => {
              if(date.day() === 0){
                return classes.sunday;
              }
              if(date.day() === 6){
                return classes.saturday;
              }
              return "";
            },
          };
          datePicker = (
            <DatePicker {...datePickerProps1} />
          );
  
          if(i2 === 0){
            let accountsWithSameAccountType = 0;
            
            if(data.filter((d, j) => j < i && d.type === type).length === 0){
              data.filter((d, j) => j >= i && d.type === type).forEach(d => {
                accountsWithSameAccountType += d.accounts.length + 1;
              });
            }
            
            if(accountsWithSameAccountType > 0){
              accountsWithSameAccountType--;
            }
            
            return (
              <React.Fragment key={account.id}>
                <tr>
                  <td colSpan={7} className={classes.divider}>
                    <div />
                  </td>
                </tr>
                <tr className={classes.accountHead}>
                  {
                    accountsWithSameAccountType > 0
                    && <td rowSpan={accountsWithSameAccountType} style={{verticalAlign: "top"}}>{t_fin(`book.${accountType.type}`)}</td>
                  }
                  <td rowSpan={accounts.length} style={{verticalAlign: "top"}}>{accountType.name}</td>
                  <td>{account.name}</td>
                  <td>{t_fin("input.balance.value")}</td>
                  <td className="inputTd" style={{verticalAlign: "top"}}>{textField}</td>
                  <td>{t_fin("input.balance.date")}</td>
                  <td className="inputTd" style={{verticalAlign: "top"}}>{datePicker}</td>
                </tr>
              </React.Fragment>
            );
          }
          
          return (
            <tr key={account.id}>
              <td>{account.name}</td>
              <td>{t_fin("input.balance.value")}</td>
              <td className="inputTd">{textField}</td>
              <td>{t_fin("input.balance.date")}</td>
              <td className="inputTd" style={{verticalAlign: "top"}}>{datePicker}</td>
            </tr>
          );
        })
      );
    });
    
    return (
      <div className={classes.balanceTableContainer}>
        <div className={classes.description} dangerouslySetInnerHTML={{__html: t<string>("welcome.initialBalanceDescription")}}/>
        
        <table className={classes.balanceTable}>
          <tbody>
          {table}
          </tbody>
        </table>
      </div>
    );
  }
  
  public async onComplete(){
    const {
      initializeAccount,
      initializeFinancialBalance,
      saveDataAll,
    } = this.props;
    const {initialBalance} = this.state;
    
    initializeFinancialBalance(initialBalance);
    initializeAccount();
    await saveDataAll();
  }
}





const styles: StyleRulesCallback = (theme) => ({
  root: {
    padding: theme.spacing.unit*2,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  paperRoot: {
    maxWidth: 1000,
    padding: theme.spacing.unit*4,
  },
  titleDesc: {
  },
  licenseTitle: {
    fontSize: "1.3rem",
    marginTop: theme.spacing.unit*5,
    marginBottom: theme.spacing.unit,
  },
  licenseContent: {
    maxHeight: 200,
    overflowY: "auto",
    whiteSpace: "normal",
    fontSize: ".85rem",
    border: `1px solid ${theme.palette.text.secondary}`,
    padding: `0px ${theme.spacing.unit*2}px`,
  },
  agreementButtons: {
    marginTop: theme.spacing.unit*2,
  },
  itemGroupContainer: {
    marginBottom: theme.spacing.unit*4,
  },
  itemGroup: {
    fontSize: "1.3rem",
  },
  itemsContainer: {
    marginTop: theme.spacing.unit,
    display: "flex",
    flexWrap: "wrap",
  },
  itemContainer: {
    padding: theme.spacing.unit/2,
  },
  item: {
    border: `1px solid ${theme.palette.text.secondary}`,
    fontSize: ".85rem",
    padding: "2px 5px",
    cursor: "move",
  },
  checkboxContainer: {
    marginTop: theme.spacing.unit*2,
    marginBottom: theme.spacing.unit*2,
    padding: `${theme.spacing.unit}px ${theme.spacing.unit*2}px`,
    border: `3px solid ${theme.palette.text.secondary}`,
    borderRadius: 5,
  },
  removingItemContainer: {
    display: "flex",
    alignItems: "center",
    flexWrap: "wrap",
    fontSize: ".85rem",
    justifyContent: "center",
  },
  removingItems: {
    padding: "4px 8px",
    margin: 4,
    border: `1px solid ${theme.palette.text.primary}`,
    borderRadius: 3,
  },
  description: {
    marginBottom: theme.spacing.unit*4,
    fontSize: ".85rem",
  },
  balanceTableContainer: {
    marginTop: theme.spacing.unit*4,
    marginBottom: theme.spacing.unit*4,
  },
  balanceTable: {
    "fontSize": ".85rem",
    "borderCollapse": "collapse",
    "& td:not([colspan='7']):not(.inputTd)": {
      padding: `6px 12px`,
      verticalAlign: "top",
    },
  },
  divider: {
    "paddingTop": theme.spacing.unit*3,
    "paddingBottom": theme.spacing.unit*3,
    "paddingLeft": 0,
    "paddingRight": 0,
    "& div": {
      borderTop: `1px solid ${theme.palette.text.secondary}`,
    },
  },
  accountHead: {
  },
  input: {
    "& input": {
      padding: "6px 10px",
      fontSize: ".85rem",
    },
  },
  datePicker: {
    width: 140,
    padding: "7px 16px",
    borderBottom: "1px solid rgba(200,200,200,1)",
    border: "none",
    textAlign: "center",
    background: "transparent",
    color: theme.palette.text ? theme.palette.text.primary : undefined,
  },
  datePickerPopper: {
    boxShadow: "3px 3px 8px rgba(33,33,33,.2)",
  },
  calendar: {
    "boxShadow": "3px 3px 8px rgba(33,33,33,.2)",
    "border": "1px solid rgba(33,33,33,.2)",
    "& .react-datepicker__header__dropdown--select": {
      display: "flex",
      justifyContent: "space-around",
    },
    "& .react-datepicker__month-select": {
      border: "none",
      borderBottom: "1px solid rgba(33,33,33,.2)",
      padding: "1px 4px",
    },
    "& .react-datepicker__year-select": {
      border: "none",
      borderBottom: "1px solid rgba(33,33,33,.2)",
      padding: "1px 4px",
    },
  },
  sunday: {
    color: "red",
  },
  saturday: {
    color: "blue",
  },
});



let _Component: React.ComponentType<any> = Welcome;
_Component = withContentSize(_Component);
_Component = withStyles(styles, { withTheme: true })(_Component);
_Component = withTranslation("Home")(_Component);

export default _Component as React.ComponentType<Omit<IWelcomeProps, TReservedHocProps>>;
