import React from "react";
import MuiThemeProvider from "@material-ui/core/styles/MuiThemeProvider";
import CSSBaseline from "@material-ui/core/CssBaseline";
import memoize from "memoize-one";

import {getActivities, getActivity} from "../Activity";
import Fallback from "../Activity/Fallback";
import AppFrame from "../AppFrame/__container__";
import ErrorBoundary from "./__container__/ErrorBoundary";
import {getTheme} from "../../util/theme";
import {Account} from "../../core/reducers/App/state";

const createMuiTheme_memoized = memoize(theme => getTheme(theme));

interface IAppProps {
  activity: string;
  changeActivity: (activity: string) => any;
  theme: string;
  acceptLicense: boolean;
  acceptPrivacyPolicy: boolean;
  account: Account|null;
}

class App extends React.PureComponent<IAppProps> {
  public render() {
    const {
      activity,
      theme: themeName,
      acceptLicense,
      acceptPrivacyPolicy,
      account,
    } = this.props;
    
    const adjustedActivity = (() => {
      // When user does not consent with license terms nor privacy policy,
      // Jump to welcome page to make user agree with the terms
      if(!["home", "about"].includes(activity)
        && (!acceptLicense || !acceptPrivacyPolicy)
      ){
        return "welcome";
      }
      else if(activity === "home" && account){
        return "dashboard";
      }
      else{
        return activity;
      }
    })();
    
    const Activity = getActivity(adjustedActivity);
    const theme = createMuiTheme_memoized(themeName);
  
    const defaultStyle: React.CSSProperties = {};
  
    if(theme.palette.text && theme.palette.text.primary){
      defaultStyle.color = theme.palette.text.primary;
    }
    else{
      defaultStyle.color = undefined;
    }
    
    return (
      <MuiThemeProvider theme={theme}>
        <CSSBaseline/>
        <div style={defaultStyle}>
          <AppFrame getActivities={getActivities}>
            <ErrorBoundary key={adjustedActivity}>
              <React.Suspense fallback={<Fallback />}>
                <Activity/>
              </React.Suspense>
            </ErrorBoundary>
          </AppFrame>
        </div>
      </MuiThemeProvider>
    );
  }
}


export default App;

