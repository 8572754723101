import React from "react";
import {withTranslation} from "react-i18next";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import InputLabel from "@material-ui/core/InputLabel";
import Input from "@material-ui/core/Input";
import FormControl from "@material-ui/core/FormControl";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import IconButton from "@material-ui/core/IconButton";
import CircularProgress from "@material-ui/core/CircularProgress";
import LoginIcon from "@material-ui/icons/AccountCircle";
import {localStorageType} from "../../backends/storage/index";
import {ERROR_reason} from "../provider";
import {connect} from "react-redux";
import ConfirmDialog from "./ConfirmDialog";
import {
  AuthProvider,
  FacebookAuthProvider,
  GithubAuthProvider,
  GoogleAuthProvider,
  TwitterAuthProvider,
} from "../../backends/firebase";
import {
  getCurrentUser,
  getSignInMethod,
  sendPasswordResetEmail,
  setLanguageForAuth,
  signOut,
} from "../../backends/firebase/api";
import firebase from "firebase/app";

import i18next from "i18next";
import * as A from "../../actions";
import {Theme} from "@material-ui/core/styles/createMuiTheme";
import withStyles, {ClassNameMap, StyleRulesCallback} from "@material-ui/core/styles/withStyles";
import {ThunkDispatch} from "redux-thunk";
import EmailVerificationMonitor from "./EmailVerificationMonitor";
import {Lang} from "../../reducers/App/state";
import {RootState} from "../../reducers";
import {Omit, TReservedHocProps} from "../type";


interface ILoginDialogProps {
  classes: ClassNameMap;
  i18n: i18next.i18n;
  lang: Lang;
  onCancel?: () => any;
  onOK: () => any;
  open?: boolean;
  login: (uid: string, password: string) => Promise<void>;
  theme: Theme;
  social?: string;
  socialLogin: (provider: firebase.auth.AuthProvider) => void;
  loadDataFromLocalStorage: (uid: string, password: string) => void;
}

interface ILoginDialogState {
  email: string;
  password: string;
  showPassword: boolean;
  loggingIn: boolean;
  loginError: string;
  lastLoginFailure: {provider: string, uid: string, date: number, count: number} | {};
  dialogOpen: string;
  linkingInfo: {
    providers: string[];
    credential: firebase.auth.AuthCredential;
  } | null;
  resetError: string;
}

function isLastLoginFailureNotEmpty(
  lastLoginFailure: {provider: string, uid: string, date: number, count: number} | {},
): lastLoginFailure is {provider: string, uid: string, date: number, count: number} {
  return Object.keys(lastLoginFailure).length === 4;
}

class LoginDialog extends React.Component<ILoginDialogProps, ILoginDialogState> {
  constructor(props: ILoginDialogProps){
    super(props);
  
    this.onKeyDown = this.onKeyDown.bind(this);
    this.onKeyDownForPasswordReset = this.onKeyDownForPasswordReset.bind(this);
    this.createForm = this.createForm.bind(this);
    this.onClickOK = this.onClickOK.bind(this);
    this.onChangeUsername = this.onChangeUsername.bind(this);
    this.onChangePassword = this.onChangePassword.bind(this);
    this.onClickShowPassword = this.onClickShowPassword.bind(this);
    this.onMouseDownPassword = this.onMouseDownPassword.bind(this);
    this.loginViaTwitter = this.loginViaTwitter.bind(this);
    this.loginViaFacebook = this.loginViaFacebook.bind(this);
    this.loginViaGoogle = this.loginViaGoogle.bind(this);
    this.loginViaGithub = this.loginViaGithub.bind(this);
    this.loginAndLinkAccount = this.loginAndLinkAccount.bind(this);
    this.resetPassword = this.resetPassword.bind(this);
    
    this.state = {
      email: "",
      password: "",
      showPassword: false,
      loggingIn: false,
      loginError: "",
      lastLoginFailure: {}, // {provider: "localStorage", uid: "aaa", date: 2928393044, count: 1}
      dialogOpen: "",
      linkingInfo: null,
      resetError: "",
    };
  }
  
  public async componentDidMount(){
    const {social, onOK} = this.props;
    
    if(!social){
      return;
    }
    
    let loginFunc;
    if(social === "twitter"){
      loginFunc = this.loginViaTwitter;
    }
    else if(social === "facebook"){
      loginFunc = this.loginViaFacebook;
    }
    else if(social === "google"){
      loginFunc = this.loginViaGoogle;
    }
    else if(social === "github"){
      loginFunc = this.loginViaGithub;
    }
    else{
      return;
    }
    
    loginFunc()
      .then(() => {
        if(typeof(onOK) === "function"){
          onOK();
        }
      })
      .catch(async (reason) => {
        if(process.env.REACT_APP_ENV === "development"){
          console.error("Error while signing in to social auth provider");
          console.error(reason);
        }
  
        if(reason === "no-data-in-localStorage"){
          return this.setState({
            dialogOpen: "createDataForNewBrowser",
          });
        }
        else if(reason && reason.code === "auth/account-exists-with-different-credential"){
          const signInMethods = await getSignInMethod(reason.email);
          
          if(!signInMethods){
            return this.setState({
              dialogOpen: "failedSocialLogin",
            });
          }
          
          return this.setState({
            dialogOpen: "accountAlreadyExistsWithDifferentCredential",
            loginError: reason.email,
            linkingInfo: {
              providers: signInMethods,
              credential: reason.credential,
            },
          });
        }
        else if(reason === "email-not-verified"){
          return this.setState({
            dialogOpen: "emailNotVerified",
          });
        }
        
        return this.setState({
          dialogOpen: "failedSocialLogin",
        });
      });
  }
  
  public render(){
    const {
      classes,
      theme,
      i18n,
      lang,
      onOK,
      onCancel,
      social,
      loadDataFromLocalStorage,
    } = this.props;
    let {open} = this.props;
    const {
      loggingIn,
      dialogOpen,
      email,
      resetError,
    } = this.state;
    const t = i18n.getFixedT(lang, "Auth");
    
    open = (open !== false && !social);
    
    const providerFormStyle: React.CSSProperties = {
      maxHeight: 400,
    };
    const {providerForm, error} = this.createForm();
    
    const dialog = (() => {
      if(dialogOpen === "createDataForNewBrowser"){
        return (
          <ConfirmDialog
            lang={lang}
            title={t("loginDialog.createDataForNewBrowserTitle")}
            description={t("loginDialog.createDataForNewBrowserDesc")}
            onClickOK={async () => {
              const user = getCurrentUser();
              if(!user){
                this.setState({
                  dialogOpen: "",
                  loginError: t("addAccountDialog.failedToCreateAccount"),
                  loggingIn: false,
                });
                return;
              }
          
              const uid = user.uid;
              const displayName = user.displayName || email;
              const uidAsPassword = uid;
              const initialState = A.createInitialState(uid, displayName, uidAsPassword);
          
              this.setState({
                dialogOpen: "",
              }, async () => {
                // Use firebase uid as a password for `localStorage` (Not for firebase authentication password).
                // Yes, this is un-secure, but I decided to implement like this.
                // When an user tries to login via social login, this app can never know user password.
                // Data in localStorage cannot be encrypted by password for those users.
                //
                // But I don't think this change would significantly lower security level for localStorage.
                // Because when thief can steal the data in localStorage, it means user can steal every data on browser.
                // I mean at this case security is already broken.
                await A.createUserDataToLocalStorage(uid, initialState, uidAsPassword);
                await loadDataFromLocalStorage(uid, uidAsPassword);
                if(typeof(onOK) === "function"){
                  onOK();
                }
              });
            }}
            onClickCancel={async () => {
              if(getCurrentUser()){
                await signOut();
              }
          
              this.setState({
                dialogOpen: "",
                loggingIn: false,
              });
            }}
          />
        );
      }
      else if(dialogOpen === "accountAlreadyExistsWithDifferentCredential"){
        return (
          <ConfirmDialog
            lang={lang}
            title={t("loginDialog.accountAlreadyExistsWithDifferentCredentialTitle")}
            onClickOK={async () => {
              const {linkingInfo} = this.state;
              if(!linkingInfo){
                return;
              }
          
              await this.loginAndLinkAccount(email, linkingInfo.providers, linkingInfo.credential)
                .catch(reason => {
                  return this.setState({
                    dialogOpen: "failedSocialLogin",
                    loginError: "",
                  });
                });
            }}
            onClickCancel={() => {
              this.setState({
                dialogOpen: "",
                loginError: "",
              });
            }}
          >
            <p>
              {t("loginDialog.accountAlreadyExistsWithDifferentCredentialDesc", {
                email: this.state.loginError,
                social: this.props.social,
              })}
            </p>
          </ConfirmDialog>
        );
      }
      else if(dialogOpen === "emailNotVerified"){
        return (
          <ConfirmDialog
            lang={lang}
            title={t("loginDialog.emailNotVerifiedTitle")}
            description={t("loginDialog.emailNotVerifiedDesc")}
            onClickOK={async () => {
              const user = getCurrentUser();
              if(user){
                setLanguageForAuth(lang);
                
                let e;
                await user.sendEmailVerification()
                  .catch(reason => {
                    if(process.env.REACT_APP_ENV === "development"){
                      console.error("Error while sending verification email", reason);
                    }
                
                    e = true;
                  });
            
                if(!e){
                  return this.setState({
                    dialogOpen: "verificationSent",
                    loginError: "",
                    loggingIn: false,
                  });
                }
              }
          
              this.setState({
                dialogOpen: "",
                loginError: "",
                loggingIn: false,
              });
            }}
            onClickCancel={() => {
              this.setState({
                dialogOpen: "",
                loginError: t<string>("loginDialog.emailNotVerifiedTitle"),
                loggingIn: false,
              });
            }}
          />
        );
      }
      else if(dialogOpen === "failedSocialLogin"){
        return (
          <ConfirmDialog
            alert={true}
            lang={lang}
            title={t("loginDialog.failedSocialLoginTitle")}
            onClickOK={() => {
              this.setState({
                dialogOpen: "",
                loginError: "",
              });
            }}
          />
        );
      }
      else if(dialogOpen === "verificationSent"){
        return (
          <EmailVerificationMonitor
            onClose={() => {
              this.setState({
                dialogOpen: "",
              });
            }}
            onVerified={async () => {
              if(process.env.REACT_APP_ENV === "development"){
                console.log("Email verification confirmed!");
              }
          
              const user = getCurrentUser();
              if(!user){
                return this.setState({
                  dialogOpen: "",
                  loginError: t<string>("addAccountDialog.loginStateExpired"),
                });
              }
          
              this.onClickOK();
            }}
          />
        );
      }
      else if(dialogOpen === "resetPassword"){
        return (
          <ConfirmDialog
            lang={lang}
            title={t("loginDialog.resetPasswordTitle")}
            onClickOK={this.resetPassword}
            onClickCancel={() => {
              this.setState({
                dialogOpen: "",
                loginError: "",
                email: "",
              });
            }}
          >
            <div
              className={classes.emailForPasswordReset}
              dangerouslySetInnerHTML={{
                __html: t("loginDialog.enterEmailForPasswordReset"),
              }}
            />
            <form>
              <FormControl className={classes.accountFormControl}>
                <TextField
                  required={true}
                  fullWidth={true}
                  id="login-user"
                  label={t<string>("addAccountDialog.username")}
                  value={email}
                  onChange={this.onChangeUsername}
                  onKeyDown={this.onKeyDownForPasswordReset}
                  inputProps={{className: classes.userNameInput}}
                  autoComplete="off"
                  autoFocus={true}
                />
              </FormControl>
              <div className={classes.inputError}>
                {resetError}
              </div>
            </form>
          </ConfirmDialog>
        );
      }
      else if(dialogOpen === "resettingEmailSent"){
        return (
          <ConfirmDialog
            alert={true}
            lang={lang}
            title={t("loginDialog.resettingEmailSentTitle")}
            description={t("loginDialog.resettingEmailSentDesc")}
            onClickOK={() => {
              this.setState({
                dialogOpen: "",
                email: "",
                resetError: "",
              });
            }}
          />
        );
      }
      
      return null;
    })();
    
    return (
      <div>
        <Dialog
          open={open}
          onClose={onCancel}
          scroll="paper"
          aria-labelledby="scroll-dialog-title"
          classes={{paper: classes.dialogPaper, root: classes.dialogRoot}}
          disableBackdropClick={true}
          disableEscapeKeyDown={true}
        >
          <DialogContent classes={{root: classes.dialogContentRoot}}>
            <div className={classes.iconContainer}>
              <div className={classes.icon}>
                <LoginIcon fontSize="large" color="primary"/>
              </div>
              <div>{t("loginDialog.title")}</div>
            </div>
            <div className={classes.providerForm} style={providerFormStyle}>
              {providerForm}
            </div>
            <div className={classes.resetPassword}>
              <span onClick={() => this.setState({dialogOpen: "resetPassword"})}>
                {t("loginDialog.ifYouForgotPassword")}
              </span>
            </div>
          </DialogContent>
          <DialogActions>
            <Button onClick={onCancel} color="primary" disabled={loggingIn}>
              {t("addAccountDialog.dialogCancel")}
            </Button>
            <Button onClick={this.onClickOK} color="primary" disabled={loggingIn}>
              {t("addAccountDialog.dialogOK")}
            </Button>
          </DialogActions>
        </Dialog>
        {dialog}
      </div>
    );
  }
  
  public createForm(){
    const {classes, i18n, lang, theme} = this.props;
    const {email, password, showPassword, loginError, loggingIn} = this.state;
    const t = i18n.getFixedT(lang, "Auth");
    let loginErrorMessage: string = loginError;
    
    let error = null;
    let providerForm = null;
    
    if(email.length < 1){
      error = t<string>("addAccountDialog.emptyUsername");
    }
    
    if(loginError && typeof(loginError) !== "string"){
      loginErrorMessage = t<string>("loginDialog.failedToLogin");
    }
    
    let loader;
    if(loggingIn){
      loader = (
        <div style={{display: "flex", flexDirection: "row"}}>
          <div>
            <CircularProgress size={theme.typography.fontSize} color="secondary"/>
          </div>
          <div style={{flex: "auto", marginLeft: theme.spacing.unit}}>
            {t<string>("loginDialog.loggingIn")}
          </div>
        </div>
      );
    }
  
    providerForm = (
      <form>
        <FormControl className={classes.accountFormControl}>
          <TextField
            required={true}
            fullWidth={true}
            id="login-user"
            label={t<string>("addAccountDialog.username")}
            value={email}
            onChange={this.onChangeUsername}
            onKeyDown={this.onKeyDown}
            inputProps={{className: classes.userNameInput}}
            autoComplete="off"
            autoFocus={true}
            disabled={loggingIn}
          />
        </FormControl>
        <FormControl className={classes.accountFormControl}>
          <InputLabel htmlFor="addProvider-adornment-password">{t<string>("addAccountDialog.password")} *</InputLabel>
          <Input
            fullWidth={true}
            required={true}
            id="addProvider-adornment-password"
            type={showPassword ? "text" : "password"}
            value={password}
            onChange={this.onChangePassword}
            onKeyDown={this.onKeyDown}
            autoComplete="off"
            disabled={loggingIn}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="Toggle password visibility"
                  onClick={this.onClickShowPassword}
                  onMouseDown={this.onMouseDownPassword}
                >
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            }
          />
        </FormControl>
        <div className={classes.inputError}>
          {loginErrorMessage || error || loader}
        </div>
      </form>
    );
    
    return {
      providerForm,
      error,
      loginErrorMessage,
    };
  }
  
  public onClickOK(){
    const {onOK, login} = this.props;
    const {email, password} = this.state;
  
    if(email.length < 1){
      return;
    }
  
    this.setState({
      loggingIn: true,
      loginError: "",
    }, () => {
      login(email, password)
        .then(() => {
          if(typeof(onOK) === "function"){
            onOK();
          }
        })
        .catch(reason => {
          const {i18n, lang} = this.props;
          const t = i18n.getFixedT(lang, "Auth");
          
          let loginError = "";
        
          if(reason === ERROR_reason.failedToDecryptData){
            loginError = t<string>("loginDialog.incorrectPassword");
          }
          else if(reason === "auth/invalid-email"){
            loginError = t<string>("loginDialog.invalidEmail");
          }
          else if(reason === "auth/user-disabled"){
            loginError = t<string>("loginDialog.userDisabled");
          }
          else if(reason === "auth/user-not-found"){
            loginError = t<string>("loginDialog.userNotFound");
          }
          else if(reason === "auth/wrong-password"){
            loginError = t<string>("loginDialog.incorrectPassword");
          }
          else if(reason === "no-data-in-localStorage"){
            return this.setState({
              dialogOpen: "createDataForNewBrowser",
            });
          }
          else if(reason === "email-not-verified"){
            return this.setState({
              dialogOpen: "emailNotVerified",
            });
          }
          else{
            if(process.env.REACT_APP_ENV === "development"){
              console.error(reason);
            }
            loginError = t<string>("loginDialog.failedToLogin");
          }
        
          let {lastLoginFailure} = this.state;
          let timeToWait = 0;
          if(!isLastLoginFailureNotEmpty(lastLoginFailure)){
            lastLoginFailure = {provider: localStorageType, uid: email, date: Date.now, count: 1};
          }
          else if(lastLoginFailure.provider === localStorageType && lastLoginFailure.uid === email){
            lastLoginFailure.count++;
            timeToWait = 1300 * lastLoginFailure.count - (Date.now() - lastLoginFailure.date);
            timeToWait = timeToWait >= 0 ? timeToWait : 0;
            lastLoginFailure.date = Date.now();
          }
          else{
            lastLoginFailure = {provider: localStorageType, uid: email, date: Date.now, count: 1};
          }
        
          window.setTimeout(() => {
            this.setState({
              loginError,
              loggingIn: false,
              lastLoginFailure,
            });
          }, timeToWait);
        })
      ;
    });
  }
  
  public onChangeUsername(e: React.ChangeEvent<HTMLTextAreaElement>): void {
    this.setState({
      email: e.target.value,
    });
  }
  
  public onChangePassword(e: React.ChangeEvent<HTMLTextAreaElement>): void {
    this.setState({
      password: e.target.value,
    });
  }
  
  public onKeyDown(e: React.KeyboardEvent<HTMLInputElement>){
    if(e.key === "Enter"){
      e.preventDefault();
      e.stopPropagation();
      
      this.onClickOK();
    }
  }
  
  public async onKeyDownForPasswordReset(e: React.KeyboardEvent<HTMLInputElement>){
    if(e.key === "Enter"){
      e.preventDefault();
      e.stopPropagation();
    
      await this.resetPassword();
    }
  }
  
  public onClickShowPassword(e: React.MouseEvent){
    this.setState(prevState => ({
      showPassword: !prevState.showPassword,
    }));
  }
  
  public onMouseDownPassword(e: React.MouseEvent){
    e.preventDefault();
  }
  
  public async signInWithProvider(provider: AuthProvider){
    const {socialLogin} = this.props;
    await socialLogin(provider);
    return true;
  }
  
  public async loginViaTwitter(){
    const {lang} = this.props;
    const provider = new TwitterAuthProvider();
    setLanguageForAuth(lang);
    await this.signInWithProvider(provider);
  }
  
  public async loginViaFacebook(){
    const {lang} = this.props;
    const provider = new FacebookAuthProvider();
    setLanguageForAuth(lang);
    await this.signInWithProvider(provider);
  }
  
  public async loginViaGoogle(){
    const {lang} = this.props;
    const provider = new GoogleAuthProvider();
    setLanguageForAuth(lang);
    await this.signInWithProvider(provider);
  }
  
  public async loginViaGithub(){
    const {lang} = this.props;
    const provider = new GithubAuthProvider();
    setLanguageForAuth(lang);
    await this.signInWithProvider(provider);
  }
  
  public async loginAndLinkAccount(email: string, providers: string[], linkingCredential: firebase.auth.AuthCredential){
    const {social, onOK} = this.props;
  
    if(!providers || !Array.isArray(providers) || providers.length === 0 || !social){
      throw new Error("Invalid arguments");
    }
  
    let loginFunc;
    if(providers.includes("twitter.com")){
      loginFunc = this.loginViaTwitter;
    }
    else if(providers.includes("facebook.com")){
      loginFunc = this.loginViaFacebook;
    }
    else if(providers.includes("google.com")){
      loginFunc = this.loginViaGoogle;
    }
    else if(providers.includes("github.com")){
      loginFunc = this.loginViaGithub;
    }
    else{
      throw new Error("Unknown providers");
    }
    
    let noDataInLocalStorage = false;
    const resultLogin = await loginFunc()
      .catch(async (reason) => {
        if(process.env.REACT_APP_ENV === "development"){
          console.error("Error while signing in to social auth provider");
          console.error(reason);
        }
      
        if(reason === "no-data-in-localStorage"){
          noDataInLocalStorage = true;
          return true;
        }
      
        return false;
      });
    
    const user = getCurrentUser();
    if(!user || resultLogin){
      throw new Error("Invalid arguments");
    }
    
    const resultLinking = await user.linkAndRetrieveDataWithCredential(linkingCredential)
      .catch(async (reason) => {
        if(process.env.REACT_APP_ENV === "development"){
          console.error("Error while linking credential");
          console.error(reason);
        }
        
        return null;
      });
    
    if(noDataInLocalStorage){
      this.setState({
        dialogOpen: "createDataForNewBrowser",
      });
    }
    else{
      if(typeof(onOK) === "function"){
        onOK();
      }
    }
  }
  
  public async resetPassword(){
    const {i18n, lang} = this.props;
    const {email} = this.state;
    const t = i18n.getFixedT(lang, "Auth");
    
    const error = await sendPasswordResetEmail(email, lang);
    
    if(error){
      if(error === "auth/invalid-email"){
        return this.setState({
          resetError: t("loginDialog.invalidEmail"),
        });
      }
      
      /**
       * Do nothing other than invalid-email error.
       * The most imaginable error is wrong email address (sending email to non registered address).
       * But if one can know whether an email address is registered to the service,
       * it may be kind of a privacy breach.
       * So even if error happens, the app won't tell that in order to hide registered users.
       */
    }
    
    this.setState({
      dialogOpen: "resettingEmailSent",
      resetError: "",
    });
  }
}





const styles: StyleRulesCallback = (theme) => ({
  dialogRoot: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  dialogPaper: {
    minWidth: 320,
    width: 440,
  },
  dialogContentRoot: {
    flex: "1 1 auto",
    padding: "0 24px",
    overflowY: "auto",
  },
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
  formControl: {
    margin: theme.spacing.unit,
    minWidth: 120,
    width: "100%",
  },
  pleaseSelect: {
    fontSize: theme.typography.fontSize * .9,
  },
  providerForm: {
    transition: "all ease .3s",
    maxHeight: 0,
    overflow: "hidden",
    marginTop: theme.spacing.unit * 2,
    margin: theme.spacing.unit,
  },
  accountFormControl: {
    display: "block",
    marginBottom: theme.spacing.unit,
  },
  inputError: {
    color: theme.palette.error.main,
    fontSize: theme.typography.fontSize * .9,
    marginTop: theme.spacing.unit,
  },
  iconContainer: {
    marginBottom: theme.spacing.unit*3,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    fontSize: "1.4rem",
  },
  userNameInput: {
    backgroundColor: "unset",
    color: "unset",
  },
  emailForPasswordReset: {
    fontSize: ".9rem",
    marginBottom: theme.spacing.unit*2,
  },
  resetPassword: {
    "margin": theme.spacing.unit,
    "marginTop": theme.spacing.unit*2,
    "fontSize": ".9rem",
    "& span": {
      cursor: "pointer",
    },
  },
});

let _Component: React.ComponentType<any> = LoginDialog;
_Component = withTranslation("Auth")(_Component);
_Component = withStyles(styles, {withTheme: true})(_Component);

const ComponentWithHoc = _Component as React.ComponentType<Omit<ILoginDialogProps, TReservedHocProps>>;



const mapStateToProps = (rootReduxState: RootState) => {
  const AppState = rootReduxState.App;
  const {lang} = AppState;
  
  return {lang};
};

const mapDispatchToProps = (dispatch: ThunkDispatch<RootState, undefined, A.RootActions>) => {
  return {
    login: (
      uid: string,
      password: string,
    ) => dispatch<Promise<void>>(A.login(uid, password)),
    socialLogin: (provider: firebase.auth.AuthProvider) => dispatch(A.socialLogin(provider)),
    loadDataFromLocalStorage: (
      uid: string,
      password: string,
    ) => dispatch(A.loadDataFromLocalStorage(uid, password)),
  };
};

const LoginDialogContainer = connect(mapStateToProps, mapDispatchToProps)(ComponentWithHoc);

export default LoginDialogContainer;
