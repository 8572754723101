import * as types from "../../types/constant";
import * as A from "../../types/app/pageInput";
import * as S from "../../../core/reducers/Finance/state";
import * as SA from "../../../reducers/App/state";

export const updateFilterDate = (
  {clear, from, to}: {clear?: boolean, from: number|null, to: number|null},
): A.IUpdateFilterDateAction => {
  let filter: SA.Filter<SA.FlowFilterColumn> | null = null;
  if (clear || (!from && !to)) {
    filter = null;
  }
  else if(from && to){
    filter = {
      column: "date",
      rule: "between",
      values: [from, to],
    };
  }
  else if(from){
    filter = {
      column: "date",
      rule: "largerThanOET",
      values: from,
    };
  }
  else if(to){
    filter = {
      column: "date",
      rule: "lessThanOET",
      values: to,
    };
  }
  
  return {
    type: types.UPDATE_FINANCE_FILTER_DATE,
    payload: {filter},
  };
};

export const updateBalanceFilterDate = (
  {clear, from, to}: {clear?: boolean, from: S.CustomDate|null, to: S.CustomDate|null},
): A.IUpdateBalanceFilterDateAction => {
  let filter: SA.Filter<SA.BalanceFilterColumn> | null = null;
  if (clear || (!from && !to)) {
    filter = null;
  } else if (from && to) {
    filter = {
      column: "date",
      rule: "between",
      values: [from, to],
    };
  } else if (from) {
    filter = {
      column: "date",
      rule: "largerThanOET",
      values: from,
    };
  } else if (to) {
    filter = {
      column: "date",
      rule: "lessThanOET",
      values: to,
    };
  }
  
  return {
    type: types.UPDATE_FINANCE_BALANCE_FILTER_DATE,
    payload: {filter},
  };
};

export const updateFilterItem = (rule: SA.FilterRule, values?: number[]): A.IUpdateFilterItemAction => {
  if (!Array.isArray(values) || values.length < 1) {
    return {
      type: types.UPDATE_FINANCE_FILTER_ITEM,
      payload: {
        rule: "noFilter",
      },
    };
  }
  
  return {
    type: types.UPDATE_FINANCE_FILTER_ITEM,
    payload: {
      rule,
      values,
    },
  };
};

export const updateFilterItemGroup = (rule: SA.FilterRule, values?: number[]): A.IUpdateFilterItemGroupAction => {
  if (!Array.isArray(values) || values.length < 1) {
    return {
      type: types.UPDATE_FINANCE_FILTER_ITEMGROUP,
      payload: {
        rule: "noFilter",
      },
    };
  }
  
  return {
    type: types.UPDATE_FINANCE_FILTER_ITEMGROUP,
    payload: {
      rule,
      values,
    },
  };
};

export const updateFilterAccount = (rule: SA.FilterRule, values?: number[]): A.IUpdateFilterAccountAction => {
  if (!Array.isArray(values) || values.length < 1) {
    return {
      type: types.UPDATE_FINANCE_FILTER_ACCOUNT,
      payload: {
        rule: "noFilter",
      },
    };
  }
  
  return {
    type: types.UPDATE_FINANCE_FILTER_ACCOUNT,
    payload: {
      rule,
      values,
    },
  };
};

export const updateFilterTag = (rule: SA.FilterRule, values?: string[]): A.IUpdateFilterTagAction => {
  if (!Array.isArray(values) || values.length < 1) {
    return {
      type: types.UPDATE_FINANCE_FILTER_TAG,
      payload: {
        rule: "noFilter",
      },
    };
  }
  
  return {
    type: types.UPDATE_FINANCE_FILTER_TAG,
    payload: {
      rule,
      values,
    },
  };
};

export const updateBalanceFilterAccount = (
  rule: SA.FilterRule,
  values?: number[],
): A.IUpdateBalanceFilterAccountAction => {
  if (!Array.isArray(values) || values.length < 1) {
    return {
      type: types.UPDATE_FINANCE_BALANCE_FILTER_ACCOUNT,
      payload: {
        rule: "noFilter",
      },
    };
  }
  
  return {
    type: types.UPDATE_FINANCE_BALANCE_FILTER_ACCOUNT,
    payload: {
      rule,
      values,
    },
  };
};

export const updateFilterValue = (rule: SA.FilterRule, values?: number[]): A.IUpdateFilterValueAction => {
  if (!rule || !values) {
    return {
      type: types.UPDATE_FINANCE_FILTER_VALUE,
      payload: {},
    };
  }
  
  return {
    type: types.UPDATE_FINANCE_FILTER_VALUE,
    payload: {
      rule,
      values,
    },
  };
};

export const updateBalanceViewType = (viewType: string): A.IUpdateBalanceViewTypeAction => {
  return {
    type: types.UPDATE_FINANCE_BALANCE_VIEW_TYPE,
    payload: {viewType},
  };
};

export const changeShowButtonConfig = (): A.IChangeShowButtonConfigAction => {
  return {
    type: types.CHANGE_FINANCE_FLOW_SHOW_BUTTON,
  };
};

export const changeShowButtonConfigForBalance = (): A.IChangeShowButtonConfigForBalanceAction => {
  return {
    type: types.CHANGE_FINANCE_BALANCE_SHOW_BUTTON,
  };
};

export const updateScrollFlow = (row: number): A.IUpdateScrollFlowAction => {
  return {
    type: types.UPDATE_SCROLL_POSITION_FLOW,
    payload: {row},
  };
};

export const updateScrollBalance = (row: number): A.IUpdateScrollBalanceAction => {
  return {
    type: types.UPDATE_SCROLL_POSITION_BALANCE,
    payload: {row},
  };
};

export const updateScrollBalanceDate = (row: number): A.IUpdateScrollBalanceDateAction => {
  return {
    type: types.UPDATE_SCROLL_POSITION_BALANCE_FOR_DATE,
    payload: {row},
  };
};
