import {getCurrentDate, getNowInCustomDate} from "../../../../core/util/finance";
import * as R from "../../../../core/reducers/Finance/state";
import {GeneratorParams} from "../../../../core/util/dataGenerator";
import {CURRENCY_JPY} from "../../../../core/util/constants";
import {getFXValue} from "../../../../core/util/currency";

type IGetParamsFunc = (currency: R.Currency) => GeneratorParams;

/**
 * "name": "Employee D", "age": "36", "sex": "Male", "annualIncome": "$ 80,000", "family": "Wife, Children(2)", "money": "Loan",
 *
 * spending
 *  He recently purchased a home with a mortgage. He is being forced to repay the loan.
 *  Because he supports three families, expenses of food and living supplies are large.
 *  Also educational expenses have begun to increase. He is making an effort to suppress other expenses as much as possible.
 *
 * income
 *  He is steadily working as a company employee and he is paid a handsome basic salary and bonus every year.
 *  Since the number of families has increased, he got a job with a lot of overtime and keeps his life level.
 *  I feel uneasy about the fact that the performance of the company is not very stable and the fluctuation of the bonus amount is large.
 */
const getParams: IGetParamsFunc = (currency) => {
  const now = getCurrentDate();
  const startY = now.getFullYear();
  const startM = 1;
  const nowCustomDate = getNowInCustomDate();
  const JPY = CURRENCY_JPY.id;
  
  return {
    years: 3,
    start_fund: getFXValue(1000000, JPY, currency.id, nowCustomDate),
    start_debt: getFXValue(60000000, JPY, currency.id, nowCustomDate),
    start_fixedAsset: getFXValue(60000000, JPY, currency.id, nowCustomDate),
    debt_interest: 1.5,
    debt_period: 35,
    repayment_method: "equalPayments", // ["equalPayments", "equalInstalments"]
    income_allowances: getFXValue(30000, JPY, currency.id, nowCustomDate),
    income_basePay: getFXValue(370000, JPY, currency.id, nowCustomDate),
    income_bonusAmount: getFXValue(2600000, JPY, currency.id, nowCustomDate),
    income_bonusCount: 2,
    income_commuting: getFXValue(200000, JPY, currency.id, nowCustomDate),
    income_otherIncome: getFXValue(0, JPY, currency.id, nowCustomDate),
    income_overtime: 20,
    income_rfBonusAmount: 30,
    income_rfOtherIncome: 20,
    income_rfOvertime: 30,
    income_rfRiseRate: 30,
    income_riseRate: 3.3,
    income_workingHours: 7.5,
    income_overtimeRaiseRate: 25,
    income_pensionChargeRate: 9.15,
    income_healthInsuranceChargeRate: 4.4,
    income_employmentInsuranceChargeRate: 0.3,
    fixedAssetUsefulLives: 47,
    autoFixedAssetUsefulLives: true,
    income_feeding: 2,
    startY: startY,
    startM: startM,
    income_prevYearTotalIncome: null,
    income_prevYearTotalSocialInsurance: null,
    income_initialNormalizedPay: null,
    income_TotalIncome2YearsAgo: null,
    income_TotalSocialInsurance2YearsAgo: null,
    income_initialNormalizedPayLastTerm: null,
    spending_hobby: getFXValue(30000, JPY, currency.id, nowCustomDate),
    spending_beauty: getFXValue(12000, JPY, currency.id, nowCustomDate),
    spending_communication: getFXValue(13000, JPY, currency.id, nowCustomDate),
    spending_commuting: getFXValue(20000, JPY, currency.id, nowCustomDate),
    spending_education: getFXValue(35000, JPY, currency.id, nowCustomDate),
    spending_electricity: getFXValue(10000, JPY, currency.id, nowCustomDate),
    spending_food: getFXValue(80000, JPY, currency.id, nowCustomDate),
    spending_gasWater: getFXValue(6000, JPY, currency.id, nowCustomDate),
    spending_goods: getFXValue(40000, JPY, currency.id, nowCustomDate),
    spending_house: getFXValue(0, JPY, currency.id, nowCustomDate),
    spending_medical: getFXValue(10000, JPY, currency.id, nowCustomDate),
    spending_unclassified: getFXValue(10000, JPY, currency.id, nowCustomDate),
    spending_trip_budget: getFXValue(40, JPY, currency.id, nowCustomDate),
    spending_trip_count: 4,
    spending_rf: 30,
    spending_debtBonusChargeRate: 20,
    currency,
  };
};

export default getParams;
