export default abstract class AbstractStorage {
  protected __type = "abstract";
  
  /**
   * @constructor
   * @param {string} type
   */
  protected constructor(type: string){
    this.__type = type;
  }
  
  /**
   * Get storage type
   * @returns {string}
   */
  public getType(): string {
    return this.__type;
  }
  
  /**
   * Interface for getting data asynchronously.
   *
   * @param {string} name
   * @returns {Promise}
   */
  public abstract async get(name: string): Promise<any>;
  
  /**
   * Interface for saving data asynchronously.
   *
   * @param {string} name
   * @param {*} value
   * @returns {Promise}
   */
  public abstract async save(name: string, value: any): Promise<{name: string, value: any}>;
  
  /**
   * @param {string} name
   * @returns {Promise}
   */
  public abstract async remove(name: string): Promise<string>;
}
