import {FinanceState} from "../state";
import * as C from "../../../util/constants";
import getInitialFinanceStateDev from "./dev";

const getInitialFinanceState = (): FinanceState => {
  if(process.env.REACT_APP_ENV === "development"){
    return getInitialFinanceStateDev();
  }
  
  return {
    unsaved: false,
    saving: false,
    version: 10, // Major version change means state tree format is changed. This is breaking.
    autoReBalanceFlow: true,
    planning_periodGroups: [],
    planning_projects: [
    ],
    data_flow: [
    ],
    data_balance: [
    ],
    master_items: [
      {id: C.ITEM__UNACCOUNTED, group: C.ITEM_GROUP__UNCOUNTING, order: -1, name:  "未記帳金/Missing"},
      {id: C.ITEM__START_AMOUNT, group: C.ITEM_GROUP__UNCOUNTING, order: -2, name:  "開始残高/Start amount"},
    ],
    master_itemGroups: [
      {id: C.ITEM_GROUP__UNCOUNTING, name: "集計対象外/Not Counted", order: 0},
      {id: C.ITEM_GROUP__GENERAL, name: "一般科目/General Item", order: 1},
    ],
    master_accounts: [
    ],
    master_accountTypes: [
      {id: C.ACCOUNT_TYPE__CASH, name: "現預金/Cash", type: "totalAsset", order: 0}, // Fixed account type.
      {id: C.ACCOUNT_TYPE__LOAN, name: "借入金/Debt", type: "liability", order: 1}, // Fixed account type.
    ],
    currencyId: C.CURRENCY_JPY.id,
    currencies: [
      C.CURRENCY_JPY,
      C.CURRENCY_USD,
      C.CURRENCY_EUR,
      C.CURRENCY_CNY,
    ],
  };
};

export default getInitialFinanceState;
