import * as C from "../core/util/constants";
import {AppState} from "./App/state";
import {FinanceState} from "../core/reducers/Finance/state";
import {RootState, StateType} from "./index";

const currentVersion = {
  App: 9,
  Finance: 10,
  Settings: 6,
};
export default currentVersion;



const update = {
  App: updateStateVersion_App,
  Finance: updateStateVersion_Finance,
  Settings: updateStateVersion_Settings,
};


export function updateRootState(state: RootState): RootState {
  const newState = {...state};
  
  const stateProps = Object.keys(newState);
  const sortMap: {[key: string]: number} = {
    App: 0,
    Finance: 1,
    Settings: 2,
  };
  stateProps.sort((a, b) => sortMap[a] - sortMap[b]);
  
  stateProps.forEach(key => {
    const prop = key as keyof RootState;
    newState[prop] = tryUpdateStateVersion(newState, prop);
  });
  
  if(newState.App.version >= 7){
    if(newState.hasOwnProperty("Settings")){
      // @ts-ignore
      delete newState.Settings;
    }
  }
  
  if(newState.App.version >= 8){
    if(newState.App.hasOwnProperty("currencies")){
      // @ts-ignore
      delete newState.App.currencies;
    }
    
    if(newState.App.hasOwnProperty("currencyId")){
      // @ts-ignore
      delete newState.App.currencyId;
    }
  }
  
  if(newState.Finance.version >= 9){
    // @ts-ignore
    delete newState.Finance.page_Input_flow_filters;
    // @ts-ignore
    delete newState.Finance.page_Input_balance_filters;
    // @ts-ignore
    delete newState.Finance.page_Input_flow_showButtons;
    // @ts-ignore
    delete newState.Finance.page_Input_balance_showButtons;
    // @ts-ignore
    delete newState.Finance.page_Input_balance_viewType;
    // @ts-ignore
    delete newState.Finance.scroll_flow;
    // @ts-ignore
    delete newState.Finance.scroll_balance;
    // @ts-ignore
    delete newState.Finance.scroll_balance_date;
    // @ts-ignore
    delete newState.Finance.analysis_menu;
    // @ts-ignore
    delete newState.Finance.analysis_monthlyPLStatement_ym;
    // @ts-ignore
    delete newState.Finance.analysis_yearlyPLStatement_startYM;
    // @ts-ignore
    delete newState.Finance.analysis_yearlyPLStatement_endYM;
    // @ts-ignore
    delete newState.Finance.analysis_monthlyPLHistory_startYM;
    // @ts-ignore
    delete newState.Finance.analysis_monthlyPLHistory_endYM;
    // @ts-ignore
    delete newState.Finance.analysis_yearlyPLHistory_startY;
    // @ts-ignore
    delete newState.Finance.analysis_yearlyPLHistory_endY;
    // @ts-ignore
    delete newState.Finance.analysis_BSBreakdown_ym;
    // @ts-ignore
    delete newState.Finance.analysis_monthlyBSHistory_startYM;
    // @ts-ignore
    delete newState.Finance.analysis_monthlyBSHistory_endYM;
    // @ts-ignore
    delete newState.Finance.analysis_monthlyBSHistory_view;
    // @ts-ignore
    delete newState.Finance.analysis_monthlyItemHistory_startYM;
    // @ts-ignore
    delete newState.Finance.analysis_monthlyItemHistory_endYM;
    // @ts-ignore
    delete newState.Finance.analysis_monthlyItemGroupHistory_startYM;
    // @ts-ignore
    delete newState.Finance.analysis_monthlyItemGroupHistory_endYM;
    // @ts-ignore
    delete newState.Finance.page_Planning_viewing;
    // @ts-ignore
    delete newState.Finance.page_Planning_filters;
    // @ts-ignore
    delete newState.Finance.page_Planning_activeFilter;
    // @ts-ignore
    delete newState.Finance.page_Planning_sortRules;
    // @ts-ignore
    delete newState.Finance.page_Planning_rowsPerPage;
    // @ts-ignore
    delete newState.Finance.page_Planning_resultPage;
    // @ts-ignore
    delete newState.Finance.page_Planning_projectViewType;
  }
  
  return newState;
}


/**
 * @param {Object} state
 * @param {string} property - "App", "Finance", "Settings", etc
 */
function tryUpdateStateVersion(state: any, property: StateType): RootState[StateType] {
  // let oldState = state;
  state = JSON.parse(JSON.stringify(state));
  
  const versionFrom = state[property].version;
  
  if(versionFrom === currentVersion[property]){
    return state[property];
  }
  
  if(typeof(update[property]) !== "function"){
    throw new Error("Unknown state property to update");
  }
 
  const {isUpdated, nextState} = update[property](state);
  
  if(isUpdated){
    console.log(`State version updated. target=${property} from=${versionFrom} to=${currentVersion[property]}`);
    /*
    let now = Date.now();
    localStorage.setItem(`backup_data_${now}`, JSON.stringify(oldState));
    console.log(`Old data is saved as backup_data_${now}`);
    */
  }
  
  return nextState;
}



function updateStateVersion_App(state: any): {isUpdated: boolean, nextState: AppState} {
  let isUpdated = false;
  let appState = state.App;
  const versionFrom = typeof(appState.version) === "number" ? appState.version : -1;
  
  if(versionFrom < 1){
    appState = {
      ...appState,
      welcoming: false,
    };
    
    isUpdated = true;
  }
  
  if(versionFrom < 2){
    appState = {
      ...appState,
      acceptLicense: false,
      acceptPrivacyPolicy: false,
    };
    
    isUpdated = true;
  }
  
  if(versionFrom < 3){
    if(appState.account){
      const account = {...appState.account};
      
      // @ts-ignore
      delete account.password;
      account.isEncrypted = true;
      appState = {
        ...appState,
        account,
      };
      
      isUpdated = true;
    }
  }
  
  if(versionFrom < 4){
    // @ts-ignore
    if(Array.isArray(appState.attachedAccounts)){
      appState = {...appState};
      
      // @ts-ignore
      delete appState.attachedAccounts;
    }
    
    isUpdated = true;
  }
  
  if(versionFrom < 5){
    if(appState.account){
      const account = {...appState.account};
      
      // @ts-ignore
      account.uid = account.user;
  
      // @ts-ignore
      delete account.user;
  
      appState = {
        ...appState,
        account,
      };
      
      isUpdated = true;
    }
  }
  
  if(versionFrom < 6){
    if(appState.account){
      const account = {...appState.account};
      account.displayName = "";
      
      appState = {
        ...appState,
        account,
      };
  
      isUpdated = true;
    }
  }
  
  if(versionFrom < 7){
    // @ts-ignore
    const settingsState = tryUpdateStateVersion(state, "Settings");
    
    appState = {
      ...appState,
      // @ts-ignore
      currencyId: settingsState.currencyId,
      // @ts-ignore
      currencies: settingsState.currencies,
      // @ts-ignore
      theme: settingsState.theme,
    };
    
    isUpdated = true;
  }
  
  if(versionFrom < 9){
    const financeState = tryUpdateStateVersion(state, "Finance");
    
    appState = {
      ...appState,
      // @ts-ignore
      page_Input_flow_filters: financeState.page_Input_flow_filters,
      // @ts-ignore
      page_Input_balance_filters: financeState.page_Input_balance_filters,
      // @ts-ignore
      page_Input_flow_showButtons: financeState.page_Input_flow_showButtons,
      // @ts-ignore
      page_Input_balance_showButtons: financeState.page_Input_balance_showButtons,
      // @ts-ignore
      page_Input_balance_viewType: financeState.page_Input_balance_viewType,
      // @ts-ignore
      scroll_flow: financeState.scroll_flow,
      // @ts-ignore
      scroll_balance: financeState.scroll_balance,
      // @ts-ignore
      scroll_balance_date: financeState.scroll_balance_date,
      // @ts-ignore
      analysis_menu: financeState.analysis_menu,
      // @ts-ignore
      analysis_monthlyPLStatement_ym: financeState.analysis_monthlyPLStatement_ym,
      // @ts-ignore
      analysis_yearlyPLStatement_startYM: financeState.analysis_yearlyPLStatement_startYM,
      // @ts-ignore
      analysis_yearlyPLStatement_endYM: financeState.analysis_yearlyPLStatement_endYM,
      // @ts-ignore
      analysis_monthlyPLHistory_startYM: financeState.analysis_monthlyPLHistory_startYM,
      // @ts-ignore
      analysis_monthlyPLHistory_endYM: financeState.analysis_monthlyPLHistory_endYM,
      // @ts-ignore
      analysis_yearlyPLHistory_startY: financeState.analysis_yearlyPLHistory_startY,
      // @ts-ignore
      analysis_yearlyPLHistory_endY: financeState.analysis_yearlyPLHistory_endY,
      // @ts-ignore
      analysis_BSBreakdown_ym: financeState.analysis_BSBreakdown_ym,
      // @ts-ignore
      analysis_monthlyBSHistory_startYM: financeState.analysis_monthlyBSHistory_startYM,
      // @ts-ignore
      analysis_monthlyBSHistory_endYM: financeState.analysis_monthlyBSHistory_endYM,
      // @ts-ignore
      analysis_monthlyBSHistory_view: financeState.analysis_monthlyBSHistory_view,
      // @ts-ignore
      analysis_monthlyItemHistory_startYM: financeState.analysis_monthlyItemHistory_startYM,
      // @ts-ignore
      analysis_monthlyItemHistory_endYM: financeState.analysis_monthlyItemHistory_endYM,
      // @ts-ignore
      analysis_monthlyItemGroupHistory_startYM: financeState.analysis_monthlyItemGroupHistory_startYM,
      // @ts-ignore
      analysis_monthlyItemGroupHistory_endYM: financeState.analysis_monthlyItemGroupHistory_endYM,
      // @ts-ignore
      page_Planning_viewing: financeState.page_Planning_viewing,
      // @ts-ignore
      page_Planning_filters: financeState.page_Planning_filters,
      // @ts-ignore
      page_Planning_activeFilter: financeState.page_Planning_activeFilter,
      // @ts-ignore
      page_Planning_sortRules: financeState.page_Planning_sortRules,
      // @ts-ignore
      page_Planning_rowsPerPage: financeState.page_Planning_rowsPerPage,
      // @ts-ignore
      page_Planning_resultPage: financeState.page_Planning_resultPage,
      // @ts-ignore
      page_Planning_projectViewType: financeState.page_Planning_projectViewType,
    };
    
    isUpdated = true;
  }
  
  // Increment version
  appState = {
    ...appState,
    version: currentVersion.App,
  };
  
  return {
    isUpdated,
    nextState: appState,
  };
}



function updateStateVersion_Finance(state: any): {isUpdated: boolean, nextState: FinanceState} {
  let isUpdated = false;
  let financeState = state.Finance;
  const versionFrom = typeof(financeState.version) === "number" ? financeState.version : -1;
  
  if(versionFrom < 1){
    const newProps = {
      page_Planning_viewing: null,
      page_Planning_filters: [
        {
          id: -1,
          label: "All",
          filterRules: [],
          order: -1,
        },
      ],
      page_Planning_activeFilter: -1,
      page_Planning_sortRules: [],
      page_Planning_rowsPerPage: 10,
      page_Planning_resultPage: 0,
      page_Planning_periodGroups: [],
      planning_projects: [],
    };
    
    financeState = {
      ...financeState,
      ...newProps,
    };
    
    isUpdated = true;
  }
  
  if(versionFrom < 2){
    financeState = {
      ...financeState,
      page_Planning_projectViewType: "simple",
    };
  
    isUpdated = true;
  }
  
  if(versionFrom < 3){
    let {planning_projects} = financeState;
    planning_projects = planning_projects.map((p: any) => {
      return {
        ...p,
        countTaggedFlowOnly: false,
        fixDate: null,
      };
    });
    
    financeState = {
      ...financeState,
      planning_projects,
    };
    
    isUpdated = true;
  }
  
  if(versionFrom < 4){
    financeState = {
      ...financeState,
    };
    
    // @ts-ignore
    delete financeState.analysis_monthlyItemHistory_selectedItems;
    // @ts-ignore
    delete financeState.analysis_monthlyItemGroupHistory_selectedItemGroups;
    
    isUpdated = true;
  }
  
  if(versionFrom < 5){
    let currencyId: number;
    
    // @ts-ignore
    if(state.Settings.version >= 6){
      // @ts-ignore
      const currency = state.Settings.currencies.find(c => c.id === state.Settings.currencyId);
      currencyId = currency ? currency.id : -1; // JPY. Usually currency should be found.
    }
    else{
      currencyId = state.App.lang === "ja" ? -1 : -2; // JPY : USD
    }
    
    const master_accounts = financeState.master_accounts.map((a: any) => {
      return {
        ...a,
        currency: currencyId, // JPY
      };
    });
  
    financeState = {
      ...financeState,
      master_accounts,
    };
  
    isUpdated = true;
  }
  
  if(versionFrom < 6){
    financeState = {
      ...financeState,
      analysis_monthlyBSHistory_view: "byAssetType",
    };
    
    isUpdated = true;
  }
  
  if(versionFrom < 7){
    let currencyId: number;
  
    // @ts-ignore
    if(state.Settings.version >= 6){
      // @ts-ignore
      const currency = state.Settings.currencies.find(c => c.id === state.Settings.currencyId);
      currencyId = currency ? currency.id : -1; // JPY. Usually currency should be found.
    }
    else{
      currencyId = state.App.lang === "ja" ? -1 : -2; // JPY : USD
    }
    
    const newProjects = financeState.planning_projects.map((p: any) => {
      return {
        ...p,
        currencyId,
      };
    });
    
    financeState = {
      ...financeState,
      planning_projects: newProjects,
    };
    
    isUpdated = true;
  }
  
  if(versionFrom < 8){
    financeState = {
      ...financeState,
      currencyId: state.App.currencyId,
      currencies: state.App.currencies,
    };
  
    isUpdated = true;
  }
  
  if(versionFrom < 9){
    // Do nothing
  }
  
  if(versionFrom < 10){
    financeState = {
      ...financeState,
      // @ts-ignore
      planning_periodGroups: financeState.page_Planning_periodGroups,
    };
  
    // @ts-ignore
    delete financeState.page_Planning_periodGroups;
  
    isUpdated = true;
  }
  
  // Increment version
  financeState = {
    ...financeState,
    version: currentVersion.Finance,
  };
  
  return {
    isUpdated,
    nextState: financeState,
  };
}



function updateStateVersion_Settings(state: RootState): {isUpdated: boolean, nextState: any} {
  let isUpdated = false;
  // @ts-ignore
  let settingsState = state.Settings;
  const versionFrom = typeof(settingsState.version) === "number" ? settingsState.version : -1;
  
  if(versionFrom < 1){
    settingsState = {...settingsState};
  
    // @ts-ignore
    settingsState.theme = {
      // @ts-ignore
      ...settingsState.theme,
      typography: {useNextVariants: true},
    };
  
    isUpdated = true;
  }
  
  if(versionFrom < 2){
    settingsState = {...settingsState};
  
    // @ts-ignore
    settingsState.theme = {
      // @ts-ignore
      ...settingsState.theme,
      typography: {
        useNextVariants: true,
        body2: {
          fontSize: "inherit",
        },
      },
    };
    
    isUpdated = true;
  }
  
  if(versionFrom < 3){
    settingsState = {...settingsState};
  
    // @ts-ignore
    settingsState.theme = {
      // @ts-ignore
      ...settingsState.theme,
      typography: {
        useNextVariants: true,
        body2: {
          fontSize: "inherit",
        },
      },
    };
  
    // @ts-ignore
    settingsState.theme.palette = {
      // @ts-ignore
      ...settingsState.theme.palette,
      text: {
        primary: "rgba(34,34,34,1)",
        secondary: "rgba(33,33,33,.6)",
      },
    };
    
    isUpdated = true;
  }
  
  if(versionFrom < 4){
    settingsState = {...settingsState};
    // @ts-ignore
    settingsState.theme = "dark";
    
    isUpdated = true;
  }
  
  if(versionFrom < 5){
    settingsState = {...settingsState};
    // @ts-ignore
    delete settingsState.target;
  
    isUpdated = true;
  }
  
  if(versionFrom < 6){
    // @ts-ignore
    const {currency} = settingsState;
    let currencyId = C.CURRENCY_JPY.id;
    if(currency.symbol === "円" || currency.symbol === "￥"){
      currencyId = C.CURRENCY_JPY.id;
    }
    else if(currency.symbol === "$"){
      currencyId = C.CURRENCY_USD.id;
    }
    else if(currency.symbol === "€"){
      currencyId = C.CURRENCY_EUR.id;
    }
    else if(currency.symbol === "元"){
      currencyId = C.CURRENCY_CNY.id;
    }
    
    settingsState = {
      ...settingsState,
      currencyId,
      currencies: [
        C.CURRENCY_JPY,
        C.CURRENCY_USD,
        C.CURRENCY_EUR,
        C.CURRENCY_CNY,
      ],
    };
  
    isUpdated = true;
  }
  
  // Increment version
  settingsState = {
    ...settingsState,
    version: currentVersion.Settings,
  };
  
  return {
    isUpdated,
    nextState: settingsState,
  };
}
