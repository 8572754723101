import React from "react";
import {withTranslation} from "react-i18next";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import MenuIcon from "@material-ui/icons/Menu";
import Chip from "@material-ui/core/Chip";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import IconButton from "@material-ui/core/IconButton";
import JpFlag from "../../i18n/flags/Component/JpFlag";
import UsFlag from "../../i18n/flags/Component/UsFlag";
import PleaseSaveIcon from "mdi-react/FloppyIcon";
import CircularProgress from "@material-ui/core/CircularProgress";
import formatMoney from "accounting-js/lib/formatMoney";

import Account from "./Account/__container__";

import i18next from "i18next";
import * as R from "../../core/reducers/Finance/state";
import {Theme} from "@material-ui/core/styles/createMuiTheme";
import withStyles, {ClassNameMap, StyleRulesCallback} from "@material-ui/core/styles/withStyles";
import {shouldSidebarClosed} from "../../util/general";
import * as RA from "../../reducers/App/state";
import {CustomTheme} from "../../util/theme";
import {Account as Account1} from "../../core/reducers/App/state";
import {Omit, TReservedHocProps} from "../../util/type";

interface ICustomAppBarProps {
  classes: ClassNameMap;
  theme: Theme;
  i18n: i18next.i18n;
  lang: RA.Lang;
  unsaved: boolean;
  saving: boolean;
  provider?: string;
  currency: R.Currency;
  activity: string;
  account: Account1|null;
  changeLanguage: (l: RA.Lang) => void;
  changeActivity: (a: string) => void;
  handleDrawerToggle: () => void;
  saveData: () => void;
}

interface ICustomAppBarState {
  showDemoParams: boolean;
}

class CustomAppBar extends React.PureComponent<ICustomAppBarProps, ICustomAppBarState> {
  constructor(props: ICustomAppBarProps){
    super(props);
    
    this.getSaveButton = this.getSaveButton.bind(this);
    this.getDemoIcon = this.getDemoIcon.bind(this);
    this.onClickSaveButton = this.onClickSaveButton.bind(this);
    this.onClickTitle = this.onClickTitle.bind(this);
    this.onClickAbout = this.onClickAbout.bind(this);
    this.onClickLangJa = this.onClickLangJa.bind(this);
    this.onClickLangEn = this.onClickLangEn.bind(this);
    this.getDemoParams = this.getDemoParams.bind(this);
    this.onKeyDown = this.onKeyDown.bind(this);
    this.tryToSaveData = this.tryToSaveData.bind(this);
    
    this.state = {
      showDemoParams: false,
    };
  }
  
  public componentDidMount(){
    window.addEventListener("keydown", this.onKeyDown, false);
  }
  
  public componentWillUnmount(){
    if(typeof(this.onKeyDown) === "function"){
      window.removeEventListener("keydown", this.onKeyDown);
    }
  }
  
  public render(){
    const {
      classes,
      i18n,
      lang,
      handleDrawerToggle,
      activity,
      account,
    } = this.props;
    const t = i18n.getFixedT(lang, "App");
  
    const demoIcon = this.getDemoIcon();
    const saveButton = this.getSaveButton();
    
    let sidebarOpener;
    if(shouldSidebarClosed(activity, Boolean(account))){
      sidebarOpener = (
        <IconButton
          color="inherit"
          aria-label="open drawer"
          style={{visibility: "hidden"}}
        >
          <MenuIcon/>
        </IconButton>
      );
    }
    else{
      sidebarOpener = (
        <IconButton
          color="inherit"
          aria-label="open drawer"
          onClick={handleDrawerToggle}
        >
          <MenuIcon/>
        </IconButton>
      );
    }
    
    return (
      <AppBar position="absolute" className={classes.appBar}>
        <Toolbar>
          {sidebarOpener}
          <div>
            <Typography variant="h6" className={classes.title} onClick={this.onClickTitle}>
              {t<string>("title")}
            </Typography>
            <div className={classes.appVersion}>
              {process.env.REACT_APP_VERSION}
            </div>
          </div>
          <div className={classes.subTitle}>
            {t<string>("subTitle")}
          </div>
          <div className={classes.spacer} />
          <div
            className={classes.about}
            onClick={this.onClickAbout}
          >
            {t("termsAndConditions")}
          </div>
          {demoIcon}
          {saveButton}
          <IconButton className={classes.flag} onClick={this.onClickLangJa}>
            <JpFlag width={24} height={24} />
          </IconButton>
          <IconButton className={classes.flagEnd} onClick={this.onClickLangEn}>
            <UsFlag width={24} height={24} />
          </IconButton>
          <Account />
        </Toolbar>
      </AppBar>
    );
  }
  
  public getSaveButton(){
    const {
      unsaved,
      saving,
      classes,
      theme,
      i18n,
      lang,
      provider,
    } = this.props;
    const t = i18n.getFixedT(lang, "App");
    
    if(!provider || provider === "memory" || (!unsaved && !saving)){
      return null;
    }
    
    let Icon;
    
    if(unsaved && !saving){
      Icon = (
        <IconButton className={classes.save} title={t<string>("thereAreUnsavedChanges")} onClick={this.onClickSaveButton}>
          <PleaseSaveIcon className={classes.saveIcon}/>
        </IconButton>
      );
    }
    else if(saving){
      Icon = (
        <IconButton className={classes.save}>
          <CircularProgress size={theme.typography.fontSize}/>
        </IconButton>
      );
    }
    
    return Icon;
  }
  
  public getDemoIcon(){
    const {account, classes, i18n, lang} = this.props;
    const {showDemoParams} = this.state;
    const t = i18n.getFixedT(lang, "App");
    
    if(!account || !(account.demo && typeof(account.demo) === "object")){
      return null;
    }
    
    let demoParams;
    if(showDemoParams){
      demoParams = this.getDemoParams();
    }
    
    return (
      <ClickAwayListener onClickAway={() => this.setState({showDemoParams: false})}>
        <div
          style={{display: "inline-block", position: "relative"}}
          onClick={
            () => this.setState({
              showDemoParams: true,
            })
          }
        >
          <Chip className={classes.demo} label={t<string>("demoMode")} />
          {demoParams}
        </div>
      </ClickAwayListener>
    );
  }
  
  public async onClickSaveButton(){
    await this.tryToSaveData();
  }
  
  public onClickTitle(){
    const {changeActivity, activity} = this.props;
    
    if(activity === "welcome"){
      return;
    }
    
    changeActivity("home");
  }
  
  public onClickAbout(){
    const {changeActivity, activity} = this.props;
  
    if(activity === "welcome"){
      return;
    }
  
    changeActivity("about");
  }
  
  public onClickLangJa(){
    const {changeLanguage} = this.props;
    changeLanguage("ja");
  }
  
  public onClickLangEn(){
    const {changeLanguage} = this.props;
    changeLanguage("en");
  }
  
  public getDemoParams(){
    const {
      classes,
      i18n,
      lang,
      currency,
      theme,
      account,
    } = this.props;
    
    if(!account || !account.demo || typeof(account.demo) !== "object"){
      return;
    }
    const demo = account.demo;
    
    const t = i18n.getFixedT(lang, "Demo");
  
    const formatter_Money = (v: number|null|undefined) => typeof(v) === "number" ? formatMoney(v, currency) : t<string>("unknown");
    const formatter_Hours = (v: number|null|undefined) => t<string>("hours", {hours: v});
    const formatter_Times = (v: number|null|undefined) => t<string>("times", {times: v});
    const formatter_Percent = (v: number|null|undefined) => `${v} %`;
    const formatter_Years = (v: number|null|undefined) => t<string>("years", {years: v});
    
    const makeParam = (textId: string, value: number|string|undefined) => {
      return (
        <div key={textId}><div className={classes.demoParam}>{t(textId)}: {value}</div></div>
      );
    };
    
    const params_income = [
      {textId: "startY", value: demo.startY},
      {textId: "startM", value: demo.startM},
      {textId: "period", value: formatter_Years(demo.years)},
      {textId: "startFund", value: formatter_Money(demo.start_fund)},
      {textId: "startFixedAsset", value: formatter_Money(demo.start_fixedAsset)},
      {textId: "startDebt", value: formatter_Money(demo.start_debt)},
      {textId: "debtInterest", value: formatter_Percent(demo.debt_interest)},
      {textId: "debtPeriod", value: formatter_Years(demo.debt_period)},
      {textId: "spending_debtBonusChargeRate", value: formatter_Percent(demo.spending_debtBonusChargeRate)},
      {textId: "repaymentMethod", value: t((demo.repayment_method) as string)},
      {textId: "income_TotalIncome2YearsAgo", value: formatter_Money(demo.income_TotalIncome2YearsAgo)},
      {textId: "income_TotalSocialInsurance2YearsAgo", value: formatter_Money(demo.income_TotalSocialInsurance2YearsAgo)},
      {textId: "initialNormalizedPayLastTerm", value: formatter_Money(demo.income_initialNormalizedPayLastTerm)},
      {textId: "income_prevYearTotalIncome", value: formatter_Money(demo.income_prevYearTotalIncome)},
      {textId: "income_prevYearTotalSocialInsurance", value: formatter_Money(demo.income_prevYearTotalSocialInsurance)},
      {textId: "initialNormalizedPay", value: formatter_Money(demo.income_initialNormalizedPay)},
      {textId: "basePay", value: formatter_Money(demo.income_basePay)},
      {textId: "allowances", value: formatter_Money(demo.income_allowances)},
      {textId: "commutingAllowance", value: formatter_Money(demo.income_commuting)},
      {textId: "riseRate", value: formatter_Percent(demo.income_riseRate)},
      {textId: "bonusAmount", value: formatter_Money(demo.income_bonusAmount)},
      {textId: "bonusCount", value: formatter_Times(demo.income_bonusCount)},
      {textId: "otherIncome", value: formatter_Money(demo.income_otherIncome)},
      {textId: "overtime", value: formatter_Hours(demo.income_overtime)},
      {textId: "income_workingHours", value: formatter_Hours(demo.income_workingHours)},
      {textId: "income_overtimeRaiseRate", value: formatter_Percent(demo.income_overtimeRaiseRate)},
      {textId: "rfBonusAmount", value: formatter_Percent(demo.income_rfBonusAmount)},
      {textId: "rfOtherIncome", value: formatter_Percent(demo.income_rfOtherIncome)},
      {textId: "rfOvertime", value: formatter_Percent(demo.income_rfOvertime)},
      {textId: "rfRiseRate", value: formatter_Percent(demo.income_rfRiseRate)},
    ];
  
    const params_spending = [
      {textId: "food", value: formatter_Money(demo.spending_food)},
      {textId: "goods", value: formatter_Money(demo.spending_goods)},
      {textId: "hobby", value: formatter_Money(demo.spending_hobby)},
      {textId: "beauty", value: formatter_Money(demo.spending_beauty)},
      {textId: "communication", value: formatter_Money(demo.spending_communication)},
      {textId: "commuting", value: formatter_Money(demo.spending_commuting)},
      {textId: "education", value: formatter_Money(demo.spending_education)},
      {textId: "electricity", value: formatter_Money(demo.spending_electricity)},
      {textId: "gasWater", value: formatter_Money(demo.spending_gasWater)},
      {textId: "house", value: formatter_Money(demo.spending_house)},
      {textId: "medical", value: formatter_Money(demo.spending_medical)},
      {textId: "tripYearly", value: formatter_Money(demo.spending_trip_budget)},
      {textId: "tripCount", value: formatter_Times(demo.spending_trip_count)},
      {textId: "unclassified", value: formatter_Money(demo.spending_unclassified)},
      {textId: "rfSpending", value: formatter_Percent(demo.spending_rf)},
    ];
  
    const params_others = [
      {textId: "income_employmentInsuranceChargeRate", value: formatter_Percent(demo.income_employmentInsuranceChargeRate)},
      {textId: "income_healthInsuranceChargeRate", value: formatter_Percent(demo.income_healthInsuranceChargeRate)},
      {textId: "income_pensionChargeRate", value: formatter_Percent(demo.income_pensionChargeRate)},
      {textId: "feeding", value: demo.income_feeding},
      {textId: "income_payday", value: demo.income_payday},
    ];
    
    return (
      <div
        style={{
          position: "absolute",
          maxHeight: 300,
          width: 600,
          overflow: "auto",
          top: 40,
          right: 0,
          backgroundColor: theme.palette.secondary.main,
          boxShadow: `3px 3px 8px ${theme.palette.secondary.main}`,
          color: theme.palette.secondary.contrastText,
          padding: 16,
        }}
      >
        <div style={{paddingLeft: 8, paddingRight: 8, marginBottom: 16}}>
          {t<string>("demoParams")}
        </div>
        <div
          style={{
            display: "flex",
            flexWrap: "wrap",
          }}
        >
          {params_income.map(p => makeParam(p.textId, p.value))}
        </div>
        <div
          style={{
            display: "flex",
            flexWrap: "wrap",
          }}
        >
          {params_spending.map(p => makeParam(p.textId, p.value))}
        </div>
        <div
          style={{
            display: "flex",
            flexWrap: "wrap",
          }}
        >
          {params_others.map(p => makeParam(p.textId, p.value))}
        </div>
      </div>
    );
  }
  
  public async onKeyDown(e: unknown){
    const keyboardEvent = e as React.KeyboardEvent;
    // Save data when Ctrl + s
    if(keyboardEvent.key === "s" && keyboardEvent.ctrlKey){
      keyboardEvent.stopPropagation();
      keyboardEvent.preventDefault();
      
      await this.tryToSaveData();
    }
  }
  
  public async tryToSaveData(){
    const {unsaved, saveData, account} = this.props;
    if(!unsaved || !account){
      return;
    }
    
    await saveData();
  }
  
  public onSubmitForm(e: React.FormEvent){
    e.preventDefault();
  }
}





const styles: StyleRulesCallback = (theme: CustomTheme) => ({
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
  },
  title: {
    color: theme.palette.primary.contrastText,
    fontFamily: "cursive",
    cursor: "pointer",
  },
  subTitle: {
    marginLeft: 30,
    color: theme.palette.primary.contrastText,
    fontSize: ".85rem",
  },
  spacer: {
    flex: "auto",
  },
  appVersion: {
    fontSize: ".8rem",
    textAlign: "center",
  },
  about: {
    marginRight: theme.spacing.unit*2,
    fontSize: ".9rem",
    color: theme.palette.primary.contrastText,
    cursor: "pointer",
  },
  save: {
    margin: theme.spacing.unit / 2,
  },
  flag: {
    margin: theme.spacing.unit / 2,
  },
  flagEnd: {
    margin: theme.spacing.unit / 2,
    marginRight: theme.spacing.unit * 2,
  },
  saveIcon: {
    color: theme.palette.primary.contrastText,
    opacity: .8,
  },
  demo: {
    marginRight: theme.spacing.unit * 2,
    cursor: "pointer",
  },
  demoParam: {
    padding: "3px 8px",
    fontSize: ".8rem",
  },
  loginError: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: theme.palette.error.main,
    marginTop: theme.spacing.unit*2,
  },
  passwordInput: {
    "display": "flex",
    "alignItems": "center",
    "justifyContent": "center",
    "& input": {
      width: 240,
    },
  },
});



let _Component: React.ComponentType<any> = CustomAppBar;
_Component = withStyles(styles, {withTheme: true})(_Component);
_Component = withTranslation("App")(_Component);

export default _Component as React.ComponentType<Omit<ICustomAppBarProps, TReservedHocProps>>;
