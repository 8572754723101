import LocalStorage, {type as localStorageType} from "./localStorage";
import MemoryStorage, {type as memoryStorageType} from "./memoryStorage";
import FirebaseStorage, {type as firebaseStorageType} from "./firebaseStorage";

export {
  localStorageType,
  memoryStorageType,
  firebaseStorageType,
};

export default function getStorage(storageType: string){
  if(storageType === localStorageType){
    return LocalStorage;
  }
  else if(storageType === memoryStorageType){
    return MemoryStorage;
  }
  else if(storageType === firebaseStorageType){
    return FirebaseStorage;
  }
  
  return MemoryStorage;
}
