import React, {Fragment} from "react";
import {withTranslation} from "react-i18next";
import Drawer from "@material-ui/core/Drawer";
import List from "@material-ui/core/List";
import withWidth from "@material-ui/core/withWidth";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Collapse from "@material-ui/core/Collapse";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import ScrollBars from "react-custom-scrollbars";
import classNames from "classnames";
import {IGetActivities} from "../Activity";

import i18next from "i18next";
import {Theme} from "@material-ui/core/styles/createMuiTheme";
import withStyles, {ClassNameMap, StyleRulesCallback} from "@material-ui/core/styles/withStyles";
import {shouldSidebarClosed} from "../../util/general";
import {Lang} from "../../reducers/App/state";
import {Account} from "../../core/reducers/App/state";
import {Omit, TReservedHocProps} from "../../util/type";


const drawerWidth = 240;

interface ICustomDrawerProps {
  classes: ClassNameMap;
  theme: Theme;
  i18n: i18next.i18n;
  lang: Lang;
  account: Account | null;
  activity: string;
  getActivities: IGetActivities;
  changeActivity: (activity: string) => void;
  open: boolean;
  height: number;
}

interface ICustomDrawerState {
  menuOpened: boolean[];
}

class CustomDrawer extends React.PureComponent<ICustomDrawerProps, ICustomDrawerState> {
  constructor(props: ICustomDrawerProps){
    super(props);
  
    this.state = {
      menuOpened: [],
    };
    
    this.getActivityMenuList = this.getActivityMenuList.bind(this);
    this.onActivityMenuClickedHandler = this.onActivityMenuClickedHandler.bind(this);
    this.getHeaderHeight = this.getHeaderHeight.bind(this);
  }
  
  public static getDerivedStateFromProps(props: Readonly<ICustomDrawerProps>, state: ICustomDrawerState){
    let {menuOpened} = state;
    const {getActivities, account} = props;
    const activities = getActivities(Boolean(account));
    
    if(menuOpened.length !== activities.length){
      menuOpened = new Array(activities.length);
      menuOpened.fill(true);
      return {menuOpened};
    }
    
    return null;
  }
  
  public onActivityMenuClickedHandler(activity: string){
    const {changeActivity} = this.props;
    return (event: Event) => {
      changeActivity(activity);
    };
  }
  
  public render(){
    const {classes, open: sidebarOpen, height, activity, account} = this.props;
  
    const menuList = this.getActivityMenuList();
    
    if(shouldSidebarClosed(activity, Boolean(account))){
      return (
        <Drawer
          variant="permanent"
          classes={{
            paper: classNames(classes.drawerPaper, classes.drawerPaperHide),
          }}
          open={false}
        >
          <div className={classes.toolbar} />
          <ScrollBars style={{width: drawerWidth, height}} autoHide={true}>
            {menuList}
          </ScrollBars>
        </Drawer>
      );
    }
    
    return (
      <Drawer
        variant="permanent"
        classes={{
          paper: classNames(classes.drawerPaper, !sidebarOpen && classes.drawerPaperClose),
        }}
        open={sidebarOpen}
      >
        <div className={classes.toolbar} />
        <ScrollBars style={{width: drawerWidth, height}} autoHide={true}>
          {menuList}
        </ScrollBars>
      </Drawer>
    );
  }
  
  public getActivityMenuList(){
    const {
      classes,
      i18n,
      lang,
      open: sidebarOpen,
      activity,
      getActivities,
      changeActivity,
      account,
    } = this.props;
    const t = i18n.getFixedT(lang, "App");
    
    let {menuOpened} = this.state;
    const menuItems: Array<React.ReactElement<any>> = [];
    
    let childItemClassName = classes.nested;
    if(!sidebarOpen){
      childItemClassName = classNames(childItemClassName, classes.nestedOnClose);
    }
    
    const activities = getActivities(Boolean(account));
    
    activities.forEach((act, i) => {
      if(act.hide){
        return;
      }
      
      let menuItem;
      
      if(Array.isArray(act.children)){
        const childItems: Array<React.ReactElement<any>> = [];
        act.children.forEach((child, j) => {
          const idOfLabel = child.label ? child.label : child.activity;
          
          let onClick;
          if(child.activity){
            onClick = ()=>{
              if(child.activity !== activity){
                changeActivity(child.activity);
              }
            };
          }
          
          let rootClass;
          if(child.activity === activity){
            rootClass = classNames(classes.selected, childItemClassName);
          }
          else{
            rootClass = classNames(classes.notSelected, childItemClassName);
          }
          
          const icon = child.Icon ? (
            <ListItemIcon>
              <child.Icon style={{width: 24}} />
            </ListItemIcon>
          ) : undefined;
          
          const childItem = (
            <ListItem
              button={true}
              key={`menu-${i}:${j}`}
              classes={{root: rootClass}}
              onClick={onClick}
              title={!sidebarOpen ? t<string>(`menu.${idOfLabel}`) : undefined}
              selected={child.activity === activity}
            >
              {icon}
              <ListItemText primary={t<string>(`menu.${idOfLabel}`)} />
            </ListItem>
          );
          
          childItems.push(childItem);
        });
        
        const onMenuParentClicked = () => {
          menuOpened = [...menuOpened];
          menuOpened[i] = !menuOpened[i];
          this.setState({menuOpened});
        };
  
        const labelId = act.label ? act.label : act.activity;
        let rootClassName = "";
        if(act.activity === activity){
          rootClassName = classNames(classes.selected, {[classes.sidebarClose]: !sidebarOpen});
        }
        else{
          rootClassName = classNames(classes.notSelected, {[classes.sidebarClose]: !sidebarOpen});
        }
        
        const Icon = act.Icon ? (
          <ListItemIcon>
            <act.Icon style={{width: 24}} />
          </ListItemIcon>) : undefined;
        
        menuItem = (
          <Fragment key={`menu-${i}`}>
            <ListItem
              button={true}
              onClick={(onMenuParentClicked)}
              classes={{root: rootClassName}}
              title={!sidebarOpen ? t<string>(`menu.${labelId}`) : undefined}
            >
              {Icon}
              <ListItemText primary={t<string>(`menu.${labelId}`)} />
              {menuOpened[i] ? <ExpandLess/> : <ExpandMore/>}
            </ListItem>
            <Collapse in={menuOpened[i]} timeout="auto" unmountOnExit={true}>
              <List disablePadding={true}>
                {childItems}
              </List>
            </Collapse>
          </Fragment>
        );
      }
      else{
        let onClick;
        if(act.activity){
          onClick = () => {
            changeActivity(act.activity);
          };
        }
  
        const labelId = act.label ? act.label : act.activity;
        let rootClassName = "";
        if(act.activity === activity){
          rootClassName = classNames(classes.selected, {[classes.sidebarClose]: !sidebarOpen});
        }
        else{
          rootClassName = classNames(classes.notSelected, {[classes.sidebarClose]: !sidebarOpen});
        }
        
        const Icon = act.Icon ? (
          <ListItemIcon>
            <act.Icon style={{width: 24}} />
          </ListItemIcon>
        ) : undefined;
        
        menuItem = (
          <ListItem
            button={true}
            key={`menu-${i}`}
            onClick={onClick}
            classes={{root: rootClassName}}
            title={!sidebarOpen ? t<string>(`menu.${labelId}`) : undefined}
            selected={act.activity === activity}
          >
            {Icon}
            <ListItemText primary={t<string>(`menu.${labelId}`)} />
          </ListItem>
        );
      }
      
      menuItems.push(menuItem);
    });
    
    return (
      <List>{menuItems}</List>
    );
  }
  
  public getHeaderHeight(){
    const {theme} = this.props;
    const {screen} = window;
    
    // @ts-ignore
    const orientation = screen.msOrientation || (screen.orientation || screen.mozOrientation || {}).type;
    const windowWidth = window.innerWidth || window.document.documentElement.clientWidth || document.body.clientWidth;
    
    let toolbarHeight = theme.mixins.toolbar.minHeight || 64;
  
    if(orientation.startsWith("landscape") && windowWidth > 600
      && theme.mixins.toolbar["@media (min-width:600px)"])
    {
      // @ts-ignore
      toolbarHeight = theme.mixins.toolbar["@media (min-width:600px)"].minHeight;
    }
    else if(orientation.startsWith("landscape")
      && theme.mixins.toolbar["@media (min-width:0px) and (orientation: landscape)"]){
      // @ts-ignore
      toolbarHeight = theme.mixins.toolbar["@media (min-width:0px) and (orientation: landscape)"].minHeight;
    }
    else if(theme.mixins.toolbar["@media (min-width:600px)"]){
      // @ts-ignore
      toolbarHeight = theme.mixins.toolbar["@media (min-width:600px)"].minHeight;
    }
    
    return toolbarHeight;
  }
}





const styles: StyleRulesCallback = (theme) => ({
  drawerPaper: {
    position: "relative",
    paddingTop: 0,
    width: drawerWidth,
    whiteSpace: "nowrap",
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    overflow: "hidden",
  },
  drawerPaperClose: {
    overflowX: "hidden",
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing.unit * 7,
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing.unit * 9,
    },
  },
  drawerPaperHide: {
    overflowX: "hidden",
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: 0,
    [theme.breakpoints.up("sm")]: {
      width: 0,
    },
  },
  toolbar: theme.mixins.toolbar,
  nested: {
    "paddingLeft": theme.spacing.unit * 6,
    "paddingTop": 2,
    "paddingBottom": 2,
    "&:first-child": {
    },
    "& > div:nth-child(1)": {
      fontSize: ".9rem",
      opacity: 0,
    },
    "& > div:nth-child(2)": {
      padding: 0,
      marginLeft: -theme.spacing.unit,
      opacity: 1,
    },
    "& > div:nth-child(2) > span": {
      fontSize: ".9rem",
    },
  },
  nestedOnClose: {
    "paddingLeft": theme.spacing.unit * 3,
    "& > div:nth-child(1)": {
      opacity: 1,
    },
    "& > div:nth-child(2)": {
      opacity: 0,
    },
  },
  selected: {
    "paddingLeft": theme.spacing.unit * 3,
    "& > div + div": {
      borderRight: "2px solid rgba(0,0,0,.3)",
      paddingLeft: 0,
    },
    "&$selected": {
      backgroundColor: "inherit",
    },
  },
  notSelected: {
    "paddingLeft": theme.spacing.unit * 3,
    "& > div + div": {
      borderRight: "2px solid transparent",
      paddingLeft: 0,
    },
  },
  sidebarClose: {
    "& > div + div": {
      opacity: 0,
    },
  },
});





let _Component: React.ComponentType<any> = CustomDrawer;
_Component = withStyles(styles, {withTheme: true})(_Component);
_Component = withWidth()(_Component);
_Component = withTranslation("App")(_Component);

export default _Component as React.ComponentType<Omit<ICustomDrawerProps, TReservedHocProps>>;
