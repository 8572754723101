export const CHANGE_CURRENCY = "CHANGE_CURRENCY";
export const MODIFY_CURRENCY = "MODIFY_CURRENCY";
export const SAVE_FINANCE_DATA = "SAVE_FINANCE_DATA";
export const SAVING_FINANCE_DATA = "SAVING_FINANCE_DATA";
export const FAILED_SAVE_FINANCE_DATA = "FAILED_SAVE_FINANCE_DATA";
export const REPLACE_FINANCE_FLOW_DATA = "REPLACE_FINANCE_FLOW_DATA";
export const CHANGE_FINANCE_FLOW_DATA = "CHANGE_FINANCE_FLOW_DATA";
export const CHANGE_PLAN_TAG_FOR_FLOW_DATA = "CHANGE_PLAN_TAG_FOR_FLOW_DATA";
export const DELETE_PLAN_TAG_IN_ALL_FLOW_DATA = "DELETE_PLAN_TAG_IN_ALL_FLOW_DATA";
export const REPLACE_FINANCE_BALANCE_DATA = "REPLACE_FINANCE_BALANCE_DATA";
export const CHANGE_FINANCE_BALANCE_DATA = "CHANGE_FINANCE_BALANCE_DATA";
export const REBALANCE_FINANCE_FLOW_DATA = "REBALANCE_FINANCE_FLOW_DATA";
export const ADD_ITEM = "ADD_ITEM";
export const ADD_ITEM_GROUP = "ADD_ITEM_GROUP";
export const ADD_ACCOUNT = "ADD_ACCOUNT";
export const ADD_ACCOUNT_TYPE = "ADD_ACCOUNT_TYPE";
export const EDIT_ITEM = "EDIT_ITEM";
export const EDIT_ITEM_GROUP = "EDIT_ITEM_GROUP";
export const EDIT_ACCOUNT = "EDIT_ACCOUNT";
export const EDIT_ACCOUNT_TYPE = "EDIT_ACCOUNT_TYPE";
export const REMOVE_ITEM = "REMOVE_ITEM";
export const REMOVE_ITEM_GROUP = "REMOVE_ITEM_GROUP";
export const REMOVE_ACCOUNT = "REMOVE_ACCOUNT";
export const REMOVE_ACCOUNT_TYPE = "REMOVE_ACCOUNT_TYPE";
export const REPLACE_ITEM = "REPLACE_ITEM";
export const REPLACE_ITEM_GROUP = "REPLACE_ITEM_GROUP";
export const REPLACE_ACCOUNT = "REPLACE_ACCOUNT";
export const REPLACE_ACCOUNT_TYPE = "REPLACE_ACCOUNT_TYPE";
export const ADD_PLANNING_PROJECT = "ADD_PLANNING_PROJECT";
export const MODIFY_PLANNING_PROJECT = "MODIFY_PLANNING_PROJECT";
export const RENAME_PLANNING_PROJECT = "RENAME_PLANNING_PROJECT";
export const DELETE_PLANNING_PROJECT = "DELETE_PLANNING_PROJECT";
export const DELETE_PLANNING_PROJECTS = "DELETE_PLANNING_PROJECTS";
export const ADD_PLANNING_PERIOD_GROUP = "ADD_PLANNING_PERIOD_GROUP";
export const MODIFY_PLANNING_PERIOD_GROUP = "MODIFY_PLANNING_PERIOD_GROUP";
export const DELETE_PLANNING_PERIOD_GROUP = "DELETE_PLANNING_PERIOD_GROUP";
export const RENAME_PLANNING_PERIOD_GROUP = "RENAME_PLANNING_PERIOD_GROUP";
export const UPDATE_PLANNING_STATUS = "UPDATE_PLANNING_STATUS";
export const UPDATE_PLANNING_BUDGET = "UPDATE_PLANNING_BUDGET";
export const UPDATE_PLANNING_CURRENCY = "UPDATE_PLANNING_CURRENCY";
export const UPDATE_PLANNING_PROTECT = "UPDATE_PLANNING_PROTECT";
export const UPDATE_PLANNING_TAG_ONLY = "UPDATE_PLANNING_TAG_ONLY";
export const UPDATE_PLANNING_PERIOD_GROUP = "UPDATE_PLANNING_PERIOD_GROUP";
export const CHANGE_PLANNED_SPENDING = "CHANGE_PLANNED_SPENDING";
export const ADD_REVIEW_FOR_A_PLAN = "ADD_REVIEW_FOR_A_PLAN";
export const REMOVE_REVIEW_FOR_A_PLAN = "REMOVE_REVIEW_FOR_A_PLAN";
export const MODIFY_REVIEW_FOR_A_PLAN = "MODIFY_REVIEW_FOR_A_PLAN";
export const UPDATE_STATUS_OF_REVIEW_FOR_A_PLAN = "UPDATE_STATUS_OF_REVIEW_FOR_A_PLAN";
export const INITIALIZE_FINANCIAL_ITEM = "INITIALIZE_FINANCIAL_ITEM";
export const INITIALIZE_FINANCIAL_ACCOUNT = "INITIALIZE_FINANCIAL_ACCOUNT";
export const INITIALIZE_FINANCIAL_BALANCE = "INITIALIZE_FINANCIAL_BALANCE";
export const SORT_BALANCE = "SORT_BALANCE";

export const CHANGE_LANGUAGE = "CHANGE_LANGUAGE";
export const SWITCH_ACCOUNT = "SWITCH_ACCOUNT";
export const SWITCHING_ACCOUNT = "SWITCHING_ACCOUNT";
export const FAILED_SWITCH_USER_ACCOUNT = "FAILED_SWITCH_USER_ACCOUNT";
export const DELETE_USER_ACCOUNT = "DELETE_USER_ACCOUNT";
export const LOAD_ENTIRE_STATE = "LOAD_ENTIRE_STATE";
export const LOGOUT = "LOGOUT";
