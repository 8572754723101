/**
 * Note:
 *   Tax and social insurance are calculated based on Japanese laws.
 *
 * References:
 *   所得税のしくみ
 *     https://www.nta.go.jp/publication/pamph/koho/kurashi/html/01_1.htm
 *   個人住民税
 *     http://www.tax.metro.tokyo.jp/kazei/kojin_ju.html
 *   平成30年分　源泉徴収税額表
 *     https://www.nta.go.jp/publication/pamph/gensen/zeigakuhyo2017/01.htm
 *   賞与に対する源泉徴収税額の算出率の表（平成30年分）
 *     https://www.nta.go.jp/publication/pamph/gensen/zeigakuhyo2017/data/15-16.pdf
 *   No.1130 社会保険料控除
 *     https://www.nta.go.jp/taxes/shiraberu/taxanswer/shotoku/1130.htm
 *   住民税はいくら？源泉徴収票の年収から計算するには
 *      https://allabout.co.jp/gm/gc/469823/
 *    社会保険の標準報酬月額について
 *     https://support.freee.co.jp/hc/ja/articles/202849460-%E7%A4%BE%E4%BC%9A%E4%BF%9D%E9%99%BA%E3%81%AE%E6%A8%99%E6%BA%96%E5%A0%B1%E9%85%AC%E6%9C%88%E9%A1%8D%E3%81%AB%E3%81%A4%E3%81%84%E3%81%A6
 *
 *   https://www.freee.co.jp/kb/kb-payroll/how-to-calculate-income-tax/
 *   https://hoken.azukichi.net/koyo_keisan.html
 *   http://www.tax.metro.tokyo.jp/shitsumon/sonota/index_j.html
 *
 */
import formatMoney from "accounting-js/lib/formatMoney";
import {fromYen, toYen, shuffleArray, getDaysInMonth} from "./general";
import Util from "../core/util/finance";
import * as G from "../core/util/dataGenerator";
import * as S from "../core/reducers/Finance/state";
import * as C from "../core/util/constants";

const BONUS_DATE = 10;



/////////////// Data generators ///////////////
//#region master data
const generateItems: G.GenerateItemsFunc = (t) => {
  const itemGroups = [
    {id: C.ITEM_GROUP__UNCOUNTING, name: t<string>("notCounted")}, // Fixed item group
    {id: C.ITEM_GROUP__GENERAL, name: t<string>("generalItem")}, // Fixed item group
    {id: 1, name: t<string>("salary")},
    {id: 2, name: t<string>("living")},
    {id: 3, name: t<string>("housing")},
    {id: 4, name: t<string>("forFun")},
    {id: 5, name: t<string>("development")},
  ].map((ig, order) => ({...ig, order}));
  
  const items = [
    {id: 0, name: t<string>("food"), group: 2},
    {id: 1, name: t<string>("goods"), group: 2},
    {id: 2, name: t<string>("hobby"), group: 4},
    {id: 3, name: t<string>("communication"), group: 4},
    {id: 4, name: t<string>("commuting"), group: 2},
    {id: 5, name: t<string>("medical"), group: 2},
    {id: 6, name: t<string>("beauty"), group: 5},
    {id: 7, name: t<string>("education"), group: 5},
    {id: 8, name: t<string>("gasWater"), group: 3},
    {id: 9, name: t<string>("electricity"), group: 3},
    {id: 10, name: t<string>("house"), group: 3},
    {id: 29, name: t<string>("trip"), group: 4},
    {id: 11, name: t<string>("unclassified"), group: -2},
  
    {id: 12, name: t<string>("income_basePay"), group: 1},
    {id: 13, name: t<string>("income_overtime"), group: 1},
    {id: 14, name: t<string>("income_bonus"), group: 1},
    {id: 15, name: t<string>("income_allowances"), group: 1},
    {id: 16, name: t<string>("income_commuting"), group: 1},
    {id: 17, name: t<string>("income_otherIncome"), group: -2},
    {id: 18, name: t<string>("pension"), group: 1},
    {id: 19, name: t<string>("healthInsurance"), group: 1},
    {id: 20, name: t<string>("employmentInsurance"), group: 1},
    {id: 21, name: t<string>("incomeTax"), group: 1},
    {id: 22, name: t<string>("inhabitantTax"), group: 1},
    {id: 23, name: t<string>("otherDeduction"), group: 1},
    
    {id: 25, name: t<string>("withdrawOrDeposit"), group: -1},
    {id: 26, name: t<string>("repayDebt"), group: -1},
    {id: 27, name: t<string>("borrow"), group: -1},
    {id: 28, name: t<string>("repay"), group: -1},
    
    {id: 30, name: t<string>("interests"), group: -2},
    {id: 31, name: t<string>("depreciation"), group: -2},
    
    {id: 32, name: t<string>("yearEndAdjustment"), group: 1},
    
    {id: C.ITEM__UNACCOUNTED, name: t<string>("unknownItem"), group: -1}, // Fixed item
    {id: C.ITEM__START_AMOUNT, name: t<string>("startingBalance"), group: -1}, // Fixed item
  ].map((item, order) => ({...item, order}));
  
  return {items, itemGroups};
};

const generateAccounts: G.GenerateAccountsFunc = (t, currency) => {
  const accountTypes = [
    {id: C.ACCOUNT_TYPE__CASH, name: t<string>("cash"), type: "totalAsset"}, // Fixed account type.
    {id: C.ACCOUNT_TYPE__LOAN, name: t<string>("debt"), type: "liability"}, // Fixed account type.
    {id: 2, name: t<string>("asset"), type: "totalAsset"},
  ].map((at, order) => ({...at, order})) as S.AccountTypeMaster[];
  
  const accounts = [
    {id: 0, name: t<string>("wallet"), type: -1, currency: currency.id},
    {id: 1, name: t<string>("aBank"), type: -1, currency: currency.id},
    {id: 2, name: t<string>("aCreditCard"), type: -2, currency: currency.id},
    {id: 3, name: t<string>("anAssetAccount"), type: 2, currency: currency.id},
    {id: 4, name: t<string>("aDebtAccount"), type: -2, currency: currency.id},
    {id: 5, name: t<string>("aFixedAsset"), type: 2, currency: currency.id},
  ].map((a, order) => ({...a, order}));
  
  return {accounts, accountTypes};
};

//#endregion




/////////////// Utility ///////////////
const logPay: G.LogPayFunc = (state, cursor, totalPay, socialInsurances, incomeTax, isBonus) => {
  if(!state.paidLog[cursor.current.y]){
    state.paidLog[cursor.current.y] = {income: [], socialInsurances: [], incomeTax: []};
  }
  
  if(isBonus){
    state.paidLog[cursor.current.y].income[12 + cursor.current.m] = totalPay;
    state.paidLog[cursor.current.y].socialInsurances[12 + cursor.current.m] = socialInsurances;
    state.paidLog[cursor.current.y].incomeTax[12 + cursor.current.m] = incomeTax;
  }
  else{
    state.paidLog[cursor.current.y].income[cursor.current.m] = totalPay;
    state.paidLog[cursor.current.y].socialInsurances[cursor.current.m] = socialInsurances;
    state.paidLog[cursor.current.y].incomeTax[cursor.current.m] = incomeTax;
  }
};



const getPension: G.GetSocialInsuranceFunc = (pay, currency, chargeRate, isBonus) => {
  const pay_yen = toYen(pay, currency);
  
  if(isBonus){
    const t = Math.floor(pay_yen / 1000);
    return Math.floor(fromYen(t * 1000 * chargeRate / 100, currency));
  }
  
  const normalizedPay = getNormalizedPayForPension(pay_yen);
  return Math.floor(fromYen(normalizedPay * chargeRate / 100, currency));
};



const getHealthInsurance: G.GetSocialInsuranceFunc = (pay, currency, chargeRate, isBonus) => {
  const pay_yen = toYen(pay, currency);
  
  if(isBonus){
    const t = Math.floor(pay_yen / 1000);
    return Math.floor(fromYen(t * 1000 * chargeRate / 100, currency));
  }
  
  const normalizedPay = getNormalizedPayForHealthInsurance(pay_yen);
  return Math.floor(fromYen(normalizedPay * chargeRate / 100, currency));
};



const getEmploymentInsurance: G.GetSocialInsuranceFunc = (pay, currency, chargeRate, isBonus) => {
  return Math.floor(pay * chargeRate / 100);
};



const getTaxForAnnualIncome: G.GetTaxForAnnualIncomeFunc = (annualIncome, socialInsurances, currency, feedingObj, cursor) => {
  const {y} = cursor;
  annualIncome = toYen(annualIncome, currency);
  socialInsurances = toYen(socialInsurances, currency);
  let earningsBeingTaxed = 0;
  
  const netIncome = annualIncome - getSalaryDeduction(annualIncome, false, y);
  if(netIncome <= 0){
    return 0;
  }
  
  earningsBeingTaxed = netIncome;
  
  // Base deduction
  earningsBeingTaxed -= getBaseDeduction(earningsBeingTaxed, false, y);
  // Social Insurances deduction
  earningsBeingTaxed -= socialInsurances;
  // Personal deduction
  earningsBeingTaxed -= getPersonalDeduction(feedingObj, false);
  
  if(earningsBeingTaxed <= 0){
    earningsBeingTaxed = 0;
  }
  
  return fromYen(getIncomeTaxByDeductedIncome(earningsBeingTaxed), currency);
};



// https://www.nta.go.jp/publication/pamph/gensen/zeigakuhyo2017/data/15-16.pdf
const getTaxForBonus: G.GetTaxForBonusFunc = (bonus, currency, socialInsurances, prevMonthPayDeducted, feeding=0) => {
  if(typeof(prevMonthPayDeducted) !== "number" || prevMonthPayDeducted <= 0){
    return getMonthlyIncomeTax(bonus, socialInsurances, currency, feeding);
  }
  
  bonus = toYen(bonus, currency);
  socialInsurances = toYen(socialInsurances, currency);
  prevMonthPayDeducted = toYen(prevMonthPayDeducted, currency);
  
  const bonusBeingTaxed = bonus - socialInsurances;
  const taxRate = getBonusTaxRate(prevMonthPayDeducted, feeding);
  
  return Math.floor(fromYen(bonusBeingTaxed * taxRate/100, currency));
};



// http://www.tax.metro.tokyo.jp/shitsumon/sonota/index_j.html
const getMonthlyInhabitantTax: G.GetMonthlyInhabitantTaxFunc = (totalEarningsYear, socialInsurancesYear, currency, feeding, cursor) => {
  const {y} = cursor.current;
  totalEarningsYear = toYen(totalEarningsYear, currency);
  socialInsurancesYear = toYen(socialInsurancesYear, currency);
  let earningsBeingTaxed = 0;
  
  // 所得控除(必要経費)の計算
  // 年間収入から給与控除を引くことで「所得(利益)」が得られる。
  const netIncome = totalEarningsYear - getSalaryDeduction(totalEarningsYear, true, y);
  if(netIncome <= 0){
    return 0;
  }
  
  earningsBeingTaxed = netIncome;
  
  // Base deduction
  earningsBeingTaxed -= getBaseDeduction(earningsBeingTaxed, true, y);
  // Social Insurances deduction
  earningsBeingTaxed -= socialInsurancesYear;
  // Personal deduction
  earningsBeingTaxed -= getPersonalDeduction(feeding, true);
  // Adjustment deduction
  earningsBeingTaxed -= getAdjustmentDeduction(earningsBeingTaxed);
  
  if(earningsBeingTaxed <= 0){
    earningsBeingTaxed = 0;
  }
  
  const tax = earningsBeingTaxed * 0.10 + 3500 + 1500;
  return Math.round(fromYen(tax/12, currency));
};



const getOtherDeduction: G.GetOtherDeductionFunc = (pay) => {
  const rate = 0.012;
  return Math.floor(pay * rate);
};



/**
 * https://www.nenkin.go.jp/service/kounen/hokenryo-gaku/gakuhyo/20170822.files/2.pdf
 * @param {number} pay
 */
/* tslint:disable:curly */
const getNormalizedPayForPension: G.GetNormalizedPayFunc = (pay) => {
  if(pay < 93000) return 88000;
  if(93000 <= pay && pay < 101000) return 98000;
  if(101000 <= pay && pay < 107000) return 104000;
  if(107000 <= pay && pay < 114000) return 110000;
  if(114000 <= pay && pay < 122000) return 118000;
  if(122000 <= pay && pay < 130000) return 126000;
  if(130000 <= pay && pay < 138000) return 134000;
  if(138000 <= pay && pay < 146000) return 142000;
  if(146000 <= pay && pay < 155000) return 150000;
  if(155000 <= pay && pay < 165000) return 160000;
  if(165000 <= pay && pay < 175000) return 170000;
  if(175000 <= pay && pay < 185000) return 180000;
  if(185000 <= pay && pay < 195000) return 190000;
  if(195000 <= pay && pay < 210000) return 200000;
  if(210000 <= pay && pay < 230000) return 220000;
  if(230000 <= pay && pay < 250000) return 240000;
  if(250000 <= pay && pay < 270000) return 260000;
  if(270000 <= pay && pay < 290000) return 280000;
  if(290000 <= pay && pay < 310000) return 300000;
  if(310000 <= pay && pay < 330000) return 320000;
  if(330000 <= pay && pay < 350000) return 340000;
  if(350000 <= pay && pay < 370000) return 360000;
  if(370000 <= pay && pay < 395000) return 380000;
  if(395000 <= pay && pay < 425000) return 410000;
  if(425000 <= pay && pay < 455000) return 440000;
  if(455000 <= pay && pay < 485000) return 470000;
  if(485000 <= pay && pay < 515000) return 500000;
  if(515000 <= pay && pay < 545000) return 530000;
  if(545000 <= pay && pay < 575000) return 560000;
  if(575000 <= pay && pay < 605000) return 590000;
  if(605000 <= pay) return 620000;
  return pay;
};
/* tslint:enable:curly */



/**
 * https://www.kyoukaikenpo.or.jp/~/media/Files/shared/hokenryouritu/h30/ippan4gatu_2/h30413tokyo_02.pdf
 * @param {number} pay
 */
/* tslint:disable:curly */
const getNormalizedPayForHealthInsurance: G.GetNormalizedPayFunc = (pay) => {
  if(pay < 63000) return 58000;
  if(63000 <= pay && pay < 73000) return 68000;
  if(73000 <= pay && pay < 83000) return 78000;
  if(83000 <= pay && pay < 93000) return 88000;
  if(93000 <= pay && pay < 101000) return 98000;
  if(101000 <= pay && pay < 107000) return 104000;
  if(107000 <= pay && pay < 114000) return 110000;
  if(114000 <= pay && pay < 122000) return 118000;
  if(122000 <= pay && pay < 130000) return 126000;
  if(130000 <= pay && pay < 138000) return 134000;
  if(138000 <= pay && pay < 146000) return 142000;
  if(146000 <= pay && pay < 155000) return 150000;
  if(155000 <= pay && pay < 165000) return 160000;
  if(165000 <= pay && pay < 175000) return 170000;
  if(175000 <= pay && pay < 185000) return 180000;
  if(185000 <= pay && pay < 195000) return 190000;
  if(195000 <= pay && pay < 210000) return 200000;
  if(210000 <= pay && pay < 230000) return 220000;
  if(230000 <= pay && pay < 250000) return 240000;
  if(250000 <= pay && pay < 270000) return 260000;
  if(270000 <= pay && pay < 290000) return 280000;
  if(290000 <= pay && pay < 310000) return 300000;
  if(310000 <= pay && pay < 330000) return 320000;
  if(330000 <= pay && pay < 350000) return 340000;
  if(350000 <= pay && pay < 370000) return 360000;
  if(370000 <= pay && pay < 395000) return 380000;
  if(395000 <= pay && pay < 425000) return 410000;
  if(425000 <= pay && pay < 455000) return 440000;
  if(455000 <= pay && pay < 485000) return 470000;
  if(485000 <= pay && pay < 515000) return 500000;
  if(515000 <= pay && pay < 545000) return 530000;
  if(545000 <= pay && pay < 575000) return 560000;
  if(575000 <= pay && pay < 605000) return 590000;
  if(605000 <= pay && pay < 635000) return 620000;
  if(635000 <= pay && pay < 665000) return 650000;
  if(665000 <= pay && pay < 695000) return 680000;
  if(695000 <= pay && pay < 730000) return 710000;
  if(730000 <= pay && pay < 770000) return 750000;
  if(770000 <= pay && pay < 810000) return 790000;
  if(810000 <= pay && pay < 855000) return 830000;
  if(855000 <= pay && pay < 905000) return 880000;
  if(905000 <= pay && pay < 955000) return 930000;
  if(955000 <= pay && pay < 1005000) return 980000;
  if(1005000 <= pay && pay < 1055000) return 1030000;
  if(1055000 <= pay && pay < 1115000) return 1090000;
  if(1115000 <= pay && pay < 1175000) return 1150000;
  if(1175000 <= pay && pay < 1235000) return 1210000;
  if(1235000 <= pay && pay < 1295000) return 1270000;
  if(1295000 <= pay && pay < 1355000) return 1330000;
  if(1355000 <= pay) return 1390000;
  
  return pay;
};
/* tslint:enable:curly */


/* tslint:disable:curly */
const getBonusTaxRate: G.GetBonusTaxRateFunc = (prevMonthSalaryDeducted, feeding=0) => {
  if(feeding === 0){
    if(prevMonthSalaryDeducted < 68000) return 0;
    if(68000 <= prevMonthSalaryDeducted && prevMonthSalaryDeducted < 79000) return 2.042;
    if(79000 <= prevMonthSalaryDeducted && prevMonthSalaryDeducted < 252000) return 4.084;
    if(252000 <= prevMonthSalaryDeducted && prevMonthSalaryDeducted < 300000) return 6.126;
  
    if(300000 <= prevMonthSalaryDeducted && prevMonthSalaryDeducted < 334000) return 8.168;
    if(334000 <= prevMonthSalaryDeducted && prevMonthSalaryDeducted < 363000) return 10.21;
    if(363000 <= prevMonthSalaryDeducted && prevMonthSalaryDeducted < 395000) return 12.252;
  
    if(395000 <= prevMonthSalaryDeducted && prevMonthSalaryDeducted < 426000) return 14.294;
    if(426000 <= prevMonthSalaryDeducted && prevMonthSalaryDeducted < 550000) return 16.336;
    if(550000 <= prevMonthSalaryDeducted && prevMonthSalaryDeducted < 647000) return 18.378;
  
    if(647000 <= prevMonthSalaryDeducted && prevMonthSalaryDeducted < 699000) return 20.42;
    if(699000 <= prevMonthSalaryDeducted && prevMonthSalaryDeducted < 730000) return 22.462;
    if(730000 <= prevMonthSalaryDeducted && prevMonthSalaryDeducted < 764000) return 24.504;
  
    if(764000 <= prevMonthSalaryDeducted && prevMonthSalaryDeducted < 804000) return 26.546;
    if(804000 <= prevMonthSalaryDeducted && prevMonthSalaryDeducted < 857000) return 28.588;
    if(857000 <= prevMonthSalaryDeducted && prevMonthSalaryDeducted < 926000) return 30.63;
  
    if(926000 <= prevMonthSalaryDeducted && prevMonthSalaryDeducted < 1321000) return 32.672;
    if(1321000 <= prevMonthSalaryDeducted && prevMonthSalaryDeducted < 1532000) return 35.735;
    if(1532000 <= prevMonthSalaryDeducted && prevMonthSalaryDeducted < 2661000) return 38.798;
  
    if(2661000 <= prevMonthSalaryDeducted && prevMonthSalaryDeducted < 3548000) return 41.861;
    return 45.945;
  }
  else if(feeding === 1){
    if(prevMonthSalaryDeducted < 94000) return 0;
    if(94000 <= prevMonthSalaryDeducted && prevMonthSalaryDeducted < 243000) return 2.042;
    if(243000 <= prevMonthSalaryDeducted && prevMonthSalaryDeducted < 282000) return 4.084;
    if(282000 <= prevMonthSalaryDeducted && prevMonthSalaryDeducted < 338000) return 6.126;
  
    if(338000 <= prevMonthSalaryDeducted && prevMonthSalaryDeducted < 365000) return 8.168;
    if(365000 <= prevMonthSalaryDeducted && prevMonthSalaryDeducted < 394000) return 10.21;
    if(394000 <= prevMonthSalaryDeducted && prevMonthSalaryDeducted < 422000) return 12.252;
  
    if(422000 <= prevMonthSalaryDeducted && prevMonthSalaryDeducted < 455000) return 14.294;
    if(455000 <= prevMonthSalaryDeducted && prevMonthSalaryDeducted < 550000) return 16.336;
    if(550000 <= prevMonthSalaryDeducted && prevMonthSalaryDeducted < 663000) return 18.378;
  
    if(663000 <= prevMonthSalaryDeducted && prevMonthSalaryDeducted < 720000) return 20.42;
    if(720000 <= prevMonthSalaryDeducted && prevMonthSalaryDeducted < 752000) return 22.462;
    if(752000 <= prevMonthSalaryDeducted && prevMonthSalaryDeducted < 787000) return 24.504;
  
    if(787000 <= prevMonthSalaryDeducted && prevMonthSalaryDeducted < 826000) return 26.546;
    if(826000 <= prevMonthSalaryDeducted && prevMonthSalaryDeducted < 885000) return 28.588;
    if(885000 <= prevMonthSalaryDeducted && prevMonthSalaryDeducted < 956000) return 30.63;
  
    if(956000 <= prevMonthSalaryDeducted && prevMonthSalaryDeducted < 1346000) return 32.672;
    if(1346000 <= prevMonthSalaryDeducted && prevMonthSalaryDeducted < 1560000) return 35.735;
    if(1560000 <= prevMonthSalaryDeducted && prevMonthSalaryDeducted < 2685000) return 38.798;
  
    if(2685000 <= prevMonthSalaryDeducted && prevMonthSalaryDeducted < 3580000) return 41.861;
    return 45.945;
  }
  else if(feeding === 2){
    if(prevMonthSalaryDeducted < 133000) return 0;
    if(133000 <= prevMonthSalaryDeducted && prevMonthSalaryDeducted < 269000) return 2.042;
    if(269000 <= prevMonthSalaryDeducted && prevMonthSalaryDeducted < 312000) return 4.084;
    if(312000 <= prevMonthSalaryDeducted && prevMonthSalaryDeducted < 369000) return 6.126;
  
    if(369000 <= prevMonthSalaryDeducted && prevMonthSalaryDeducted < 393000) return 8.168;
    if(393000 <= prevMonthSalaryDeducted && prevMonthSalaryDeducted < 420000) return 10.21;
    if(420000 <= prevMonthSalaryDeducted && prevMonthSalaryDeducted < 450000) return 12.252;
  
    if(450000 <= prevMonthSalaryDeducted && prevMonthSalaryDeducted < 484000) return 14.294;
    if(484000 <= prevMonthSalaryDeducted && prevMonthSalaryDeducted < 550000) return 16.336;
    if(550000 <= prevMonthSalaryDeducted && prevMonthSalaryDeducted < 678000) return 18.378;
  
    if(678000 <= prevMonthSalaryDeducted && prevMonthSalaryDeducted < 741000) return 20.42;
    if(741000 <= prevMonthSalaryDeducted && prevMonthSalaryDeducted < 774000) return 22.462;
    if(774000 <= prevMonthSalaryDeducted && prevMonthSalaryDeducted < 810000) return 24.504;
  
    if(810000 <= prevMonthSalaryDeducted && prevMonthSalaryDeducted < 852000) return 26.546;
    if(852000 <= prevMonthSalaryDeducted && prevMonthSalaryDeducted < 914000) return 28.588;
    if(914000 <= prevMonthSalaryDeducted && prevMonthSalaryDeducted < 987000) return 30.63;
  
    if(987000 <= prevMonthSalaryDeducted && prevMonthSalaryDeducted < 1370000) return 32.672;
    if(1370000 <= prevMonthSalaryDeducted && prevMonthSalaryDeducted < 1589000) return 35.735;
    if(1589000 <= prevMonthSalaryDeducted && prevMonthSalaryDeducted < 2708000) return 38.798;
  
    if(2708000 <= prevMonthSalaryDeducted && prevMonthSalaryDeducted < 3611000) return 41.861;
    return 45.945;
  }
  else if(feeding === 3){
    if(prevMonthSalaryDeducted < 171000) return 0;
    if(171000 <= prevMonthSalaryDeducted && prevMonthSalaryDeducted < 295000) return 2.042;
    if(295000 <= prevMonthSalaryDeducted && prevMonthSalaryDeducted < 345000) return 4.084;
    if(345000 <= prevMonthSalaryDeducted && prevMonthSalaryDeducted < 398000) return 6.126;
  
    if(398000 <= prevMonthSalaryDeducted && prevMonthSalaryDeducted < 417000) return 8.168;
    if(417000 <= prevMonthSalaryDeducted && prevMonthSalaryDeducted < 445000) return 10.21;
    if(445000 <= prevMonthSalaryDeducted && prevMonthSalaryDeducted < 477000) return 12.252;
  
    if(477000 <= prevMonthSalaryDeducted && prevMonthSalaryDeducted < 513000) return 14.294;
    if(513000 <= prevMonthSalaryDeducted && prevMonthSalaryDeducted < 557000) return 16.336;
    if(557000 <= prevMonthSalaryDeducted && prevMonthSalaryDeducted < 693000) return 18.378;
  
    if(693000 <= prevMonthSalaryDeducted && prevMonthSalaryDeducted < 762000) return 20.42;
    if(762000 <= prevMonthSalaryDeducted && prevMonthSalaryDeducted < 796000) return 22.462;
    if(796000 <= prevMonthSalaryDeducted && prevMonthSalaryDeducted < 833000) return 24.504;
  
    if(833000 <= prevMonthSalaryDeducted && prevMonthSalaryDeducted < 879000) return 26.546;
    if(879000 <= prevMonthSalaryDeducted && prevMonthSalaryDeducted < 942000) return 28.588;
    if(942000 <= prevMonthSalaryDeducted && prevMonthSalaryDeducted < 1017000) return 30.63;
  
    if(1017000 <= prevMonthSalaryDeducted && prevMonthSalaryDeducted < 1394000) return 32.672;
    if(1394000 <= prevMonthSalaryDeducted && prevMonthSalaryDeducted < 1617000) return 35.735;
    if(1617000 <= prevMonthSalaryDeducted && prevMonthSalaryDeducted < 2732000) return 38.798;
  
    if(2732000 <= prevMonthSalaryDeducted && prevMonthSalaryDeducted < 3643000) return 41.861;
    return 45.945;
  }
  else if(feeding === 4){
    if(prevMonthSalaryDeducted < 210000) return 0;
    if(210000 <= prevMonthSalaryDeducted && prevMonthSalaryDeducted < 300000) return 2.042;
    if(300000 <= prevMonthSalaryDeducted && prevMonthSalaryDeducted < 378000) return 4.084;
    if(378000 <= prevMonthSalaryDeducted && prevMonthSalaryDeducted < 424000) return 6.126;
  
    if(424000 <= prevMonthSalaryDeducted && prevMonthSalaryDeducted < 444000) return 8.168;
    if(444000 <= prevMonthSalaryDeducted && prevMonthSalaryDeducted < 470000) return 10.21;
    if(470000 <= prevMonthSalaryDeducted && prevMonthSalaryDeducted < 504000) return 12.252;
  
    if(504000 <= prevMonthSalaryDeducted && prevMonthSalaryDeducted < 543000) return 14.294;
    if(543000 <= prevMonthSalaryDeducted && prevMonthSalaryDeducted < 591000) return 16.336;
    if(591000 <= prevMonthSalaryDeducted && prevMonthSalaryDeducted < 708000) return 18.378;
  
    if(708000 <= prevMonthSalaryDeducted && prevMonthSalaryDeducted < 783000) return 20.42;
    if(783000 <= prevMonthSalaryDeducted && prevMonthSalaryDeducted < 818000) return 22.462;
    if(818000 <= prevMonthSalaryDeducted && prevMonthSalaryDeducted < 859000) return 24.504;
  
    if(859000 <= prevMonthSalaryDeducted && prevMonthSalaryDeducted < 906000) return 26.546;
    if(906000 <= prevMonthSalaryDeducted && prevMonthSalaryDeducted < 970000) return 28.588;
    if(970000 <= prevMonthSalaryDeducted && prevMonthSalaryDeducted < 1048000) return 30.63;
  
    if(1048000 <= prevMonthSalaryDeducted && prevMonthSalaryDeducted < 1419000) return 32.672;
    if(1419000 <= prevMonthSalaryDeducted && prevMonthSalaryDeducted < 1645000) return 35.735;
    if(1645000 <= prevMonthSalaryDeducted && prevMonthSalaryDeducted < 2756000) return 38.798;
  
    if(2756000 <= prevMonthSalaryDeducted && prevMonthSalaryDeducted < 3675000) return 41.861;
    return 45.945;
  }
  else if(feeding === 5){
    if(prevMonthSalaryDeducted < 243000) return 0;
    if(243000 <= prevMonthSalaryDeducted && prevMonthSalaryDeducted < 300000) return 2.042;
    if(300000 <= prevMonthSalaryDeducted && prevMonthSalaryDeducted < 406000) return 4.084;
    if(406000 <= prevMonthSalaryDeducted && prevMonthSalaryDeducted < 450000) return 6.126;
  
    if(450000 <= prevMonthSalaryDeducted && prevMonthSalaryDeducted < 472000) return 8.168;
    if(472000 <= prevMonthSalaryDeducted && prevMonthSalaryDeducted < 496000) return 10.21;
    if(496000 <= prevMonthSalaryDeducted && prevMonthSalaryDeducted < 531000) return 12.252;
  
    if(531000 <= prevMonthSalaryDeducted && prevMonthSalaryDeducted < 574000) return 14.294;
    if(574000 <= prevMonthSalaryDeducted && prevMonthSalaryDeducted < 618000) return 16.336;
    if(618000 <= prevMonthSalaryDeducted && prevMonthSalaryDeducted < 723000) return 18.378;
  
    if(723000 <= prevMonthSalaryDeducted && prevMonthSalaryDeducted < 804000) return 20.42;
    if(804000 <= prevMonthSalaryDeducted && prevMonthSalaryDeducted < 841000) return 22.462;
    if(841000 <= prevMonthSalaryDeducted && prevMonthSalaryDeducted < 885000) return 24.504;
  
    if(885000 <= prevMonthSalaryDeducted && prevMonthSalaryDeducted < 934000) return 26.546;
    if(934000 <= prevMonthSalaryDeducted && prevMonthSalaryDeducted < 998000) return 28.588;
    if(998000 <= prevMonthSalaryDeducted && prevMonthSalaryDeducted < 1078000) return 30.63;
  
    if(1078000 <= prevMonthSalaryDeducted && prevMonthSalaryDeducted < 1443000) return 32.672;
    if(1443000 <= prevMonthSalaryDeducted && prevMonthSalaryDeducted < 1674000) return 35.735;
    if(1674000 <= prevMonthSalaryDeducted && prevMonthSalaryDeducted < 2780000) return 38.798;
  
    if(2780000 <= prevMonthSalaryDeducted && prevMonthSalaryDeducted < 3706000) return 41.861;
    return 45.945;
  }
  else if(feeding === 6){
    if(prevMonthSalaryDeducted < 275000) return 0;
    if(275000 <= prevMonthSalaryDeducted && prevMonthSalaryDeducted < 333000) return 2.042;
    if(333000 <= prevMonthSalaryDeducted && prevMonthSalaryDeducted < 431000) return 4.084;
    if(431000 <= prevMonthSalaryDeducted && prevMonthSalaryDeducted < 476000) return 6.126;
  
    if(476000 <= prevMonthSalaryDeducted && prevMonthSalaryDeducted < 499000) return 8.168;
    if(499000 <= prevMonthSalaryDeducted && prevMonthSalaryDeducted < 525000) return 10.21;
    if(525000 <= prevMonthSalaryDeducted && prevMonthSalaryDeducted < 559000) return 12.252;
  
    if(559000 <= prevMonthSalaryDeducted && prevMonthSalaryDeducted < 602000) return 14.294;
    if(602000 <= prevMonthSalaryDeducted && prevMonthSalaryDeducted < 645000) return 16.336;
    if(645000 <= prevMonthSalaryDeducted && prevMonthSalaryDeducted < 739000) return 18.378;
  
    if(739000 <= prevMonthSalaryDeducted && prevMonthSalaryDeducted < 825000) return 20.42;
    if(825000 <= prevMonthSalaryDeducted && prevMonthSalaryDeducted < 865000) return 22.462;
    if(865000 <= prevMonthSalaryDeducted && prevMonthSalaryDeducted < 911000) return 24.504;
  
    if(911000 <= prevMonthSalaryDeducted && prevMonthSalaryDeducted < 961000) return 26.546;
    if(961000 <= prevMonthSalaryDeducted && prevMonthSalaryDeducted < 1026000) return 28.588;
    if(1026000 <= prevMonthSalaryDeducted && prevMonthSalaryDeducted < 1108000) return 30.63;
  
    if(1108000 <= prevMonthSalaryDeducted && prevMonthSalaryDeducted < 1468000) return 32.672;
    if(1468000 <= prevMonthSalaryDeducted && prevMonthSalaryDeducted < 1702000) return 35.735;
    if(1702000 <= prevMonthSalaryDeducted && prevMonthSalaryDeducted < 2803000) return 38.798;
  
    if(2803000 <= prevMonthSalaryDeducted && prevMonthSalaryDeducted < 3738000) return 41.861;
    return 45.945;
  }
  else{
    if(prevMonthSalaryDeducted < 308000) return 0;
    if(308000 <= prevMonthSalaryDeducted && prevMonthSalaryDeducted < 372000) return 2.042;
    if(372000 <= prevMonthSalaryDeducted && prevMonthSalaryDeducted < 456000) return 4.084;
    if(456000 <= prevMonthSalaryDeducted && prevMonthSalaryDeducted < 502000) return 6.126;
  
    if(502000 <= prevMonthSalaryDeducted && prevMonthSalaryDeducted < 527000) return 8.168;
    if(527000 <= prevMonthSalaryDeducted && prevMonthSalaryDeducted < 553000) return 10.21;
    if(553000 <= prevMonthSalaryDeducted && prevMonthSalaryDeducted < 588000) return 12.252;
  
    if(588000 <= prevMonthSalaryDeducted && prevMonthSalaryDeducted < 627000) return 14.294;
    if(627000 <= prevMonthSalaryDeducted && prevMonthSalaryDeducted < 671000) return 16.336;
    if(671000 <= prevMonthSalaryDeducted && prevMonthSalaryDeducted < 754000) return 18.378;
  
    if(754000 <= prevMonthSalaryDeducted && prevMonthSalaryDeducted < 848000) return 20.42;
    if(848000 <= prevMonthSalaryDeducted && prevMonthSalaryDeducted < 890000) return 22.462;
    if(890000 <= prevMonthSalaryDeducted && prevMonthSalaryDeducted < 937000) return 24.504;
  
    if(937000 <= prevMonthSalaryDeducted && prevMonthSalaryDeducted < 988000) return 26.546;
    if(988000 <= prevMonthSalaryDeducted && prevMonthSalaryDeducted < 1054000) return 28.588;
    if(1054000 <= prevMonthSalaryDeducted && prevMonthSalaryDeducted < 1139000) return 30.63;
  
    if(1139000 <= prevMonthSalaryDeducted && prevMonthSalaryDeducted < 1492000) return 32.672;
    if(1492000 <= prevMonthSalaryDeducted && prevMonthSalaryDeducted < 1730000) return 35.735;
    if(1730000 <= prevMonthSalaryDeducted && prevMonthSalaryDeducted < 2827000) return 38.798;
  
    if(2827000 <= prevMonthSalaryDeducted && prevMonthSalaryDeducted < 3770000) return 41.861;
    return 45.945;
  }
};
/* tslint:enable:curly */



const getMonthlyIncomeTax: G.GetMonthlyIncomeTaxFunc = (totalPay, socialInsurance, currency, feeding=0) => {
  const totalPay_inYen = toYen(totalPay, currency);
  const socialInsurance_inYen = toYen(socialInsurance, currency);
  
  const targetPay = totalPay_inYen - socialInsurance_inYen;
  const taxTable = getIncomeTaxTableForMonthlyWithholdingTax();
  let feedingExtra = 0;
  
  if(feeding > 7){
    feedingExtra = feeding - 7;
    feeding = 7;
  }
  
  if(targetPay < 88000){
    return 0;
  }
  
  for(let i=0;i<taxTable.length;i++){
    const [min, max, values] = taxTable[i];
    if(min <= targetPay && targetPay < max){
      let tax = values[feeding];
      tax -= feedingExtra * 1610;
      tax = Math.max(tax, 0);
      return fromYen(tax, currency);
    }
  }
  
  if(860000 <= targetPay && targetPay < 970000){
    const values = [97350,89920,82480,75930,69470,63010,56530,50070];
    const taxExtra = targetPay - 860000;
    let tax = values[feeding];
    tax += taxExtra * 23.483/100;
    tax -= feedingExtra * 1610;
    tax = Math.max(tax, 0);
    return fromYen(tax, currency);
  }
  else if(970000 < targetPay && targetPay < 1720000){
    const values = [123190,115760,108320,101770,95310,88850,82370,75910];
    const taxExtra = targetPay - 970000;
    let tax = values[feeding];
    tax += taxExtra * 33.693/100;
    tax -= feedingExtra * 1610;
    tax = Math.max(tax, 0);
    return fromYen(tax, currency);
  }
  else if(1720000 < targetPay && targetPay < 3550000){
    const values = [375890,368460,361020,354470,348010,341550,335070,328610];
    const taxExtra = targetPay - 1720000;
    let tax = values[feeding];
    tax += taxExtra * 40.84/100;
    tax -= feedingExtra * 1610;
    tax = Math.max(tax, 0);
    return fromYen(tax, currency);
  }
  else{
    const values = [1123270,1115840,1108400,1101850,1095390,1088930,1082450,1075990];
    const taxExtra = targetPay - 3550000;
    let tax = values[feeding];
    tax += taxExtra * 45.945/100;
    tax -= feedingExtra * 1610;
    tax = Math.max(tax, 0);
    return fromYen(tax, currency);
  }
};



const getIncomeTaxTableForMonthlyWithholdingTax: G.GetIncomeTaxTableForMonthlyWithholdingTaxFunc = () => {
  return [
    [88000, 89000, [130,0,0,0,0,0,0,0]],
    [89000, 90000, [180,0,0,0,0,0,0,0]],
    [90000, 91000, [230,0,0,0,0,0,0,0]],
    [91000, 92000, [290,0,0,0,0,0,0,0]],
    [92000, 93000, [340,0,0,0,0,0,0,0]],
    
    [93000, 94000, [390,0,0,0,0,0,0,0]],
    [94000, 95000, [440,0,0,0,0,0,0,0]],
    [95000, 96000, [490,0,0,0,0,0,0,0]],
    [96000, 97000, [540,0,0,0,0,0,0,0]],
    [97000, 98000, [590,0,0,0,0,0,0,0]],
    
    [98000, 99000, [640,0,0,0,0,0,0,0]],
    [99000, 101000, [720,0,0,0,0,0,0,0]],
    [101000, 103000, [830,0,0,0,0,0,0,0]],
    [103000, 105000, [930,0,0,0,0,0,0,0]],
    [105000, 107000, [1030,0,0,0,0,0,0,0]],
    
    [107000, 109000, [1130,0,0,0,0,0,0,0]],
    [109000, 111000, [1240,0,0,0,0,0,0,0]],
    [111000, 113000, [1340,0,0,0,0,0,0,0]],
    [113000, 115000, [1440,0,0,0,0,0,0,0]],
    [115000, 117000, [1540,0,0,0,0,0,0,0]],
    
    [117000, 119000, [1640,0,0,0,0,0,0,0]],
    [119000, 121000, [1750,120,0,0,0,0,0,0]],
    [121000, 123000, [1850,220,0,0,0,0,0,0]],
    [123000, 125000, [1950,330,0,0,0,0,0,0]],
    [125000, 127000, [2050,430,0,0,0,0,0,0]],
    
    [127000, 129000, [2150,530,0,0,0,0,0,0]],
    [129000, 131000, [2260,630,0,0,0,0,0,0]],
    [131000, 133000, [2360,740,0,0,0,0,0,0]],
    [133000, 135000, [2460,840,0,0,0,0,0,0]],
    [135000, 137000, [2550,930,0,0,0,0,0,0]],
    
    [137000, 139000, [2610,990,0,0,0,0,0,0]],
    [139000, 141000, [2680,1050,0,0,0,0,0,0]],
    [141000, 143000, [2740,1110,0,0,0,0,0,0]],
    [143000, 145000, [2800,1170,0,0,0,0,0,0]],
    [145000, 147000, [2860,1240,0,0,0,0,0,0]],
    
    [147000, 149000, [2920,1300,0,0,0,0,0,0]],
    [149000, 151000, [2980,1360,0,0,0,0,0,0]],
    [151000, 153000, [3050,1430,0,0,0,0,0,0]],
    [153000, 155000, [3120,1500,0,0,0,0,0,0]],
    [155000, 157000, [3200,1570,0,0,0,0,0,0]],
    
    [157000, 159000, [3270,1640,0,0,0,0,0,0]],
    [159000, 161000, [3340,1720,100,0,0,0,0,0]],
    [161000, 163000, [3410,1790,170,0,0,0,0,0]],
    [163000, 165000, [3480,1860,250,0,0,0,0,0]],
    [165000, 167000, [3550,1930,320,0,0,0,0,0]],
    
    [167000, 169000, [3620,2000,390,0,0,0,0,0]],
    [169000, 171000, [3700,2070,460,0,0,0,0,0]],
    [171000, 173000, [3770,2140,530,0,0,0,0,0]],
    [173000, 175000, [3840,2220,600,0,0,0,0,0]],
    [175000, 177000, [3910,2290,670,0,0,0,0,0]],
    
    [177000, 179000, [3980,2360,750,0,0,0,0,0]],
    [179000, 181000, [4050,2430,820,0,0,0,0,0]],
    [181000, 183000, [4120,2500,890,0,0,0,0,0]],
    [183000, 185000, [4200,2570,960,0,0,0,0,0]],
    [185000, 187000, [4270,2640,1030,0,0,0,0,0]],
    
    [187000, 189000, [4340,2720,1100,0,0,0,0,0]],
    [189000, 191000, [4410,2790,1170,0,0,0,0,0]],
    [191000, 193000, [4480,2860,1250,0,0,0,0,0]],
    [193000, 195000, [4550,2930,1320,0,0,0,0,0]],
    [195000, 197000, [4630,3000,1390,0,0,0,0,0]],
    
    [197000, 199000, [4700,3070,1460,0,0,0,0,0]],
    [199000, 201000, [4770,3140,1530,0,0,0,0,0]],
    [201000, 203000, [4840,3220,1600,0,0,0,0,0]],
    [203000, 205000, [4910,3290,1670,0,0,0,0,0]],
    [205000, 207000, [4980,3360,1750,130,0,0,0,0]],
    
    [207000, 209000, [5050,3430,1820,200,0,0,0,0]],
    [209000, 211000, [5130,3500,1890,280,0,0,0,0]],
    [211000, 213000, [5200,3570,1960,350,0,0,0,0]],
    [213000, 215000, [5270,3640,2030,420,0,0,0,0]],
    [215000, 217000, [5340,3720,2100,490,0,0,0,0]],
    
    [217000, 219000, [5410,3790,2170,560,0,0,0,0]],
    [219000, 221000, [5480,3860,2250,630,0,0,0,0]],
    [221000, 224000, [5560,3950,2340,710,0,0,0,0]],
    [224000, 227000, [5680,4060,2440,830,0,0,0,0]],
    [227000, 230000, [5780,4170,2550,930,0,0,0,0]],
    
    [230000, 233000, [5890,4280,2650,1040,0,0,0,0]],
    [233000, 236000, [5990,4380,2770,1140,0,0,0,0]],
    [236000, 239000, [6110,4490,2870,1260,0,0,0,0]],
    [239000, 242000, [6210,4590,2980,1360,0,0,0,0]],
    [242000, 245000, [6320,4710,3080,1470,0,0,0,0]],
    
    [245000, 248000, [6420,4810,3200,1570,0,0,0,0]],
    [248000, 251000, [6530,4920,3300,1680,0,0,0,0]],
    [251000, 254000, [6640,5020,3410,1790,170,0,0,0]],
    [254000, 257000, [6750,5140,3510,1900,290,0,0,0]],
    [257000, 260000, [6850,5240,3620,2000,390,0,0,0]],
    
    [260000, 263000, [6960,5350,3730,2110,500,0,0,0]],
    [263000, 266000, [7070,5450,3840,2220,600,0,0,0]],
    [266000, 269000, [7180,5560,3940,2330,710,0,0,0]],
    [269000, 272000, [7280,5670,4050,2430,820,0,0,0]],
    [272000, 275000, [7390,5780,4160,2540,930,0,0,0]],
    
    [275000, 278000, [7490,5880,4270,2640,1030,0,0,0]],
    [278000, 281000, [7610,5990,4370,2760,1140,0,0,0]],
    [281000, 284000, [7710,6100,4480,2860,1250,0,0,0]],
    [284000, 287000, [7820,6210,4580,2970,1360,0,0,0]],
    [287000, 290000, [7920,6310,4700,3070,1460,0,0,0]],
    
    [290000, 293000, [8040,6420,4800,3190,1570,0,0,0]],
    [293000, 296000, [8140,6520,4910,3290,1670,0,0,0]],
    [296000, 299000, [8250,6640,5010,3400,1790,160,0,0]],
    [299000, 302000, [8420,6740,5130,3510,1890,280,0,0]],
    [302000, 305000, [8670,6860,5250,3630,2010,400,0,0]],
    
    [305000, 308000, [8910,6980,5370,3760,2130,520,0,0]],
    [308000, 311000, [9160,7110,5490,3880,2260,640,0,0]],
    [311000, 314000, [9400,7230,5620,4000,2380,770,0,0]],
    [314000, 317000, [9650,7350,5740,4120,2500,890,0,0]],
    [317000, 320000, [9890,7470,5860,4250,2620,1010,0,0]],
    
    [320000, 323000, [10140,7600,5980,4370,2750,1130,0,0]],
    [323000, 326000, [10380,7720,6110,4490,2870,1260,0,0]],
    [326000, 329000, [10630,7840,6230,4610,2990,1380,0,0]],
    [329000, 332000, [10870,7960,6350,4740,3110,1500,0,0]],
    [332000, 335000, [11120,8090,6470,4860,3240,1620,0,0]],
    
    [335000, 338000, [11360,8210,6600,4980,3360,1750,130,0]],
    [338000, 341000, [11610,8370,6720,5110,3480,1870,260,0]],
    [341000, 344000, [11850,8620,6840,5230,3600,1990,380,0]],
    [344000, 347000, [12100,8860,6960,5350,3730,2110,500,0]],
    [347000, 350000, [12340,9110,7090,5470,3850,2240,620,0]],
    
    [350000, 353000, [12590,9350,7210,5600,3970,2360,750,0]],
    [353000, 356000, [12830,9600,7330,5720,4090,2480,870,0]],
    [356000, 359000, [13080,9840,7450,5840,4220,2600,990,0]],
    [359000, 362000, [13320,10090,7580,5960,4340,2730,1110,0]],
    [362000, 365000, [13570,10330,7700,6090,4460,2850,1240,0]],
    
    [365000, 368000, [13810,10580,7820,6210,4580,2970,1360,0]],
    [368000, 371000, [14060,10820,7940,6330,4710,3090,1480,0]],
    [371000, 374000, [14300,11070,8070,6450,4830,3220,1600,0]],
    [374000, 377000, [14550,11310,8190,6580,4950,3340,1730,100]],
    [377000, 380000, [14790,11560,8320,6700,5070,3460,1850,220]],
    
    [380000, 383000, [15040,11800,8570,6820,5200,3580,1970,350]],
    [383000, 386000, [15280,12050,8810,6940,5320,3710,2090,470]],
    [386000, 389000, [15530,12290,9060,7070,5440,3830,2220,590]],
    [389000, 392000, [15770,12540,9300,7190,5560,3950,2340,710]],
    [392000, 395000, [16020,12780,9550,7310,5690,4070,2460,840]],
    
    [395000, 398000, [16260,13030,9790,7430,5810,4200,2580,960]],
    [398000, 401000, [16510,13270,10040,7560,5930,4320,2710,1080]],
    [401000, 404000, [16750,13520,10280,7680,6050,4440,2830,1200]],
    [404000, 407000, [17000,13760,10530,7800,6180,4560,2950,1330]],
    [407000, 410000, [17240,14010,10770,7920,6300,4690,3070,1450]],
    
    [410000, 413000, [17490,14250,11020,8050,6420,4810,3200,1570]],
    [413000, 416000, [17730,14500,11260,8170,6540,4930,3320,1690]],
    [416000, 419000, [17980,14740,11510,8290,6670,5050,3440,1820]],
    [419000, 422000, [18220,14990,11750,8530,6790,5180,3560,1940]],
    [422000, 425000, [18470,15230,12000,8770,6910,5300,3690,2060]],
    
    [425000, 428000, [18710,15480,12240,9020,7030,5420,3810,2180]],
    [428000, 431000, [18960,15720,12490,9260,7160,5540,3930,2310]],
    [431000, 434000, [19210,15970,12730,9510,7280,5670,4050,2430]],
    [434000, 437000, [19450,16210,12980,9750,7400,5790,4180,2550]],
    [437000, 440000, [19700,16460,13220,10000,7520,5910,4300,2680]],
    
    [440000, 443000, [20090,16700,13470,10240,7650,6030,4420,2800]],
    [443000, 446000, [20580,16950,13710,10490,7770,6160,4540,2920]],
    [446000, 449000, [21070,17190,13960,10730,7890,6280,4670,3040]],
    [449000, 452000, [21560,17440,14200,10980,8010,6400,4790,3170]],
    [452000, 455000, [22050,17680,14450,11220,8140,6520,4910,3290]],
    
    [455000, 458000, [22540,17930,14690,11470,8260,6650,5030,3410]],
    [458000, 461000, [23030,18170,14940,11710,8470,6770,5160,3530]],
    [461000, 464000, [23520,18420,15180,11960,8720,6890,5280,3660]],
    [464000, 467000, [24010,18660,15430,12200,8960,7010,5400,3780]],
    [467000, 470000, [24500,18910,15670,12450,9210,7140,5520,3900]],
    
    [470000, 473000, [24990,19150,15920,12690,9450,7260,5650,4020]],
    [473000, 476000, [25480,19400,16160,12940,9700,7380,5770,4150]],
    [476000, 479000, [25970,19640,16410,13180,9940,7500,5890,4270]],
    [479000, 482000, [26460,20000,16650,13430,10190,7630,6010,4390]],
    [482000, 485000, [26950,20490,16900,13670,10430,7750,6140,4510]],
    
    [485000, 488000, [27440,20980,17140,13920,10680,7870,6260,4640]],
    [488000, 491000, [27930,21470,17390,14160,10920,7990,6380,4760]],
    [491000, 494000, [28420,21960,17630,14410,11170,8120,6500,4880]],
    [494000, 497000, [28910,22450,17880,14650,11410,8240,6630,5000]],
    [497000, 500000, [29400,22940,18120,14900,11660,8420,6750,5130]],
    
    [500000, 503000, [29890,23430,18370,15140,11900,8670,6870,5250]],
    [503000, 506000, [30380,23920,18610,15390,12150,8910,6990,5370]],
    [506000, 509000, [30880,24410,18860,15630,12390,9160,7120,5490]],
    [509000, 512000, [31370,24900,19100,15880,12640,9400,7240,5620]],
    [512000, 515000, [31860,25390,19350,16120,12890,9650,7360,5740]],
    
    [515000, 518000, [32350,25880,19590,16370,13130,9890,7480,5860]],
    [518000, 521000, [32840,26370,19900,16610,13380,10140,7610,5980]],
    [521000, 524000, [33330,26860,20390,16860,13620,10380,7730,6110]],
    [524000, 527000, [33820,27350,20880,17100,13870,10630,7850,6230]],
    [527000, 530000, [34310,27840,21370,17350,14110,10870,7970,6350]],
    
    [530000, 533000, [34800,28330,21860,17590,14360,11120,8100,6470]],
    [533000, 536000, [35290,28820,22350,17840,14600,11360,8220,6600]],
    [536000, 539000, [35780,29310,22840,18080,14850,11610,8380,6720]],
    [539000, 542000, [36270,29800,23330,18330,15090,11850,8630,6840]],
    [542000, 545000, [36760,30290,23820,18570,15340,12100,8870,6960]],
    
    [545000, 548000, [37250,30780,24310,18820,15580,12340,9120,7090]],
    [548000, 551000, [37740,31270,24800,19060,15830,12590,9360,7210]],
    [551000, 554000, [38280,31810,25340,19330,16100,12860,9630,7350]],
    [554000, 557000, [38830,32370,25890,19600,16380,13140,9900,7480]],
    [557000, 560000, [39380,32920,26440,19980,16650,13420,10180,7630]],
    
    [560000, 563000, [39930,33470,27000,20530,16930,13690,10460,7760]],
    [563000, 566000, [40480,34020,27550,21080,17200,13970,10730,7900]],
    [566000, 569000, [41030,34570,28100,21630,17480,14240,11010,8040]],
    [569000, 572000, [41590,35120,28650,22190,17760,14520,11280,8180]],
    [572000, 575000, [42140,35670,29200,22740,18030,14790,11560,8330]],
    
    [575000, 578000, [42690,36230,29750,23290,18310,15070,11830,8610]],
    [578000, 581000, [43240,36780,30300,23840,18580,15350,12110,8880]],
    [581000, 584000, [43790,37330,30850,24390,18860,15620,12380,9160]],
    [584000, 587000, [44340,37880,31410,24940,19130,15900,12660,9430]],
    [587000, 590000, [44890,38430,31960,25490,19410,16170,12940,9710]],
    
    [590000, 593000, [45440,38980,32510,26050,19680,16450,13210,9990]],
    [593000, 596000, [46000,39530,33060,26600,20130,16720,13490,10260]],
    [596000, 599000, [46550,40080,33610,27150,20690,17000,13760,10540]],
    [599000, 602000, [47100,40640,34160,27700,21240,17280,14040,10810]],
    [602000, 605000, [47650,41190,34710,28250,21790,17550,14310,11090]],
    
    [605000, 608000, [48200,41740,35270,28800,22340,17830,14590,11360]],
    [608000, 611000, [48750,42290,35820,29350,22890,18100,14870,11640]],
    [611000, 614000, [49300,42840,36370,29910,23440,18380,15140,11920]],
    [614000, 617000, [49860,43390,36920,30460,23990,18650,15420,12190]],
    [617000, 620000, [50410,43940,37470,31010,24540,18930,15690,12470]],
    
    [620000, 623000, [50960,44500,38020,31560,25100,19210,15970,12740]],
    [623000, 626000, [51510,45050,38570,32110,25650,19480,16240,13020]],
    [626000, 629000, [52060,45600,39120,32660,26200,19760,16520,13290]],
    [629000, 632000, [52610,46150,39680,33210,26750,20280,16800,13570]],
    [632000, 635000, [53160,46700,40230,33760,27300,20830,17070,13840]],
    
    [635000, 638000, [53710,47250,40780,34320,27850,21380,17350,14120]],
    [638000, 641000, [54270,47800,41330,34870,28400,21930,17620,14400]],
    [641000, 644000, [54820,48350,41880,35420,28960,22480,17900,14670]],
    [644000, 647000, [55370,48910,42430,35970,29510,23030,18170,14950]],
    [647000, 650000, [55920,49460,42980,36520,30060,23590,18450,15220]],
    
    [650000, 653000, [56470,50010,43540,37070,30610,24140,18730,15500]],
    [653000, 656000, [57020,50560,44090,37620,31160,24690,19000,15770]],
    [656000, 659000, [57570,51110,44640,38180,31710,25240,19280,16050]],
    [659000, 662000, [58130,51660,45190,38730,32260,25790,19550,16330]],
    [662000, 665000, [58680,52210,45740,39280,32810,26340,19880,16600]],
    
    [665000, 668000, [59230,52770,46290,39830,33370,26890,20430,16880]],
    [668000, 671000, [59780,53320,46840,40380,33920,27440,20980,17150]],
    [671000, 674000, [60330,53870,47390,40930,34470,28000,21530,17430]],
    [674000, 677000, [60880,54420,47950,41480,35020,28550,22080,17700]],
    [677000, 680000, [61430,54970,48500,42030,35570,29100,22640,17980]],
    
    [680000, 683000, [61980,55520,49050,42590,36120,29650,23190,18260]],
    [683000, 686000, [62540,56070,49600,43140,36670,30200,23740,18530]],
    [686000, 689000, [63090,56620,50150,43690,37230,30750,24290,18810]],
    [689000, 692000, [63640,57180,50700,44240,37780,31300,24840,19080]],
    [692000, 695000, [64190,57730,51250,44790,38330,31860,25390,19360]],
    
    [695000, 698000, [64740,58280,51810,45340,38880,32410,25940,19630]],
    [698000, 701000, [65290,58830,52360,45890,39430,32960,26490,20030]],
    [701000, 704000, [65840,59380,52910,46450,39980,33510,27050,20580]],
    [704000, 707000, [66400,59930,53460,47000,40530,34060,27600,21130]],
    [707000, 710000, [66950,60480,54010,47550,41090,34610,28150,21690]],
    
    [710000, 713000, [67500,61040,54560,48100,41640,35160,28700,22240]],
    [713000, 716000, [68050,61590,55110,48650,42190,35710,29250,22790]],
    [716000, 719000, [68600,62140,55660,49200,42740,36270,29800,23340]],
    [719000, 722000, [69150,62690,56220,49750,43290,36820,30350,23890]],
    [722000, 725000, [69700,63240,56770,50300,43840,37370,30910,24440]],
    
    [725000, 728000, [70260,63790,57320,50860,44390,37920,31460,24990]],
    [728000, 731000, [70810,64340,57870,51410,44940,38470,32010,25550]],
    [731000, 734000, [71360,64890,58420,51960,45500,39020,32560,26100]],
    [734000, 737000, [71910,65450,58970,52510,46050,39570,33110,26650]],
    [737000, 740000, [72460,66000,59520,53060,46600,40130,33660,27200]],
    
    [740000, 743000, [73010,66550,60080,53610,47150,40680,34210,27750]],
    [743000, 746000, [73560,67100,60630,54160,47700,41230,34770,28300]],
    [746000, 749000, [74110,67650,61180,54720,48250,41780,35320,28850]],
    [749000, 752000, [74670,68200,61730,55270,48800,42330,35870,29400]],
    [752000, 755000, [75220,68750,62280,55820,49360,42880,36420,29960]],
    
    [755000, 758000, [75770,69310,62830,56370,49910,43430,36970,30510]],
    [758000, 761000, [76320,69860,63380,56920,50460,43980,37520,31060]],
    [761000, 764000, [76870,70410,63940,57470,51010,44540,38070,31610]],
    [764000, 767000, [77420,70960,64490,58020,51560,45090,38620,32160]],
    [767000, 770000, [77970,71510,65040,58570,52110,45640,39180,32710]],
    
    [770000, 773000, [78530,72060,65590,59130,52660,46190,39730,33260]],
    [773000, 776000, [79080,72610,66140,59680,53210,46740,40280,33820]],
    [776000, 779000, [79630,73160,66690,60230,53770,47290,40830,34370]],
    [779000, 782000, [80180,73720,67240,60780,54320,47840,41380,34920]],
    [782000, 785000, [80730,74270,67790,61330,54870,48400,41930,35470]],
    
    [785000, 788000, [81280,74820,68350,61880,55420,48950,42480,36020]],
    [788000, 791000, [81830,75370,68900,62430,55970,49500,43040,36570]],
    [791000, 794000, [82460,75920,69450,62990,56520,50050,43590,37120]],
    [794000, 797000, [83100,76470,70000,63540,57070,50600,44140,37670]],
    [797000, 800000, [83730,77020,70550,64090,57630,51150,44690,38230]],
    
    [800000, 803000, [84370,77580,71100,64640,58180,51700,45240,38780]],
    [803000, 806000, [85000,78130,71650,65190,58730,52250,45790,39330]],
    [806000, 809000, [85630,78680,72210,65740,59280,52810,46340,39880]],
    [809000, 812000, [86260,79230,72760,66290,59830,53360,46890,40430]],
    [812000, 815000, [86900,79780,73310,66840,60380,53910,47450,40980]],
    
    [815000, 818000, [87530,80330,73860,67400,60930,54460,48000,41530]],
    [818000, 821000, [88160,80880,74410,67950,61480,55010,48550,42090]],
    [821000, 824000, [88800,81430,74960,68500,62040,55560,49100,42640]],
    [824000, 827000, [89440,82000,75510,69050,62590,56110,49650,43190]],
    [827000, 830000, [90070,82630,76060,69600,63140,56670,50200,43740]],
    
    [830000, 833000, [90710,83260,76620,70150,63690,57220,50750,44290]],
    [833000, 836000, [91360,83930,77200,70720,64260,57800,51330,44860]],
    [836000, 839000, [92060,84630,77810,71340,64870,58410,51940,45480]],
    [839000, 842000, [92770,85340,78420,71950,65490,59020,52550,46090]],
    [842000, 845000, [93470,86040,79040,72560,66100,59640,53160,46700]],
    
    [845000, 848000, [94180,86740,79650,73180,66710,60250,53780,47310]],
    [848000, 851000, [94880,87450,80260,73790,67320,60860,54390,47930]],
    [851000, 854000, [95590,88150,80870,74400,67940,61470,55000,48540]],
    [854000, 857000, [96290,88860,81490,75010,68550,62090,55610,49150]],
    [857000, 860000, [97000,89560,82130,75630,69160,62700,56230,49760]],
  ];
};



/* tslint:disable:curly */
const getIncomeTaxByDeductedIncome: G.GetIncomeTaxByDeductedIncomeFunc = (deductedIncome) => {
  let tax = 0;
  
  if(deductedIncome <= 1950000) tax = deductedIncome * 0.05;
  else if(195000 < deductedIncome && deductedIncome <= 3300000) tax = deductedIncome * 0.10 - 97500;
  else if(3300000 < deductedIncome && deductedIncome <= 6950000) tax = deductedIncome * 0.20 - 427500;
  else if(6950000 < deductedIncome && deductedIncome <= 9000000) tax = deductedIncome * 0.23 - 636000;
  else if(9000000 < deductedIncome && deductedIncome <= 18000000) tax = deductedIncome * 0.33 - 1536000;
  else if(18000000 < deductedIncome && deductedIncome <= 40000000) tax = deductedIncome * 0.40 - 2796000;
  else tax = deductedIncome * 0.45 - 4796000;
  
  // 100円未満の端数切り捨て
  // https://www.nta.go.jp/publication/pamph/koho/kurashi/html/01_1.htm
  return Math.floor(tax / 100) * 100;
};
/* tslint:enable:curly */



/* tslint:disable:curly */
const getSalaryDeduction: G.GetSalaryDeductionFunc = (annualSalary, isInhabitantTax, year) => {
  let deduction = 0;
  
  // 所得税は2020年1月以降
  // 住民税は2021年1月以降の住民税
  if((isInhabitantTax && year < 2021) || (!isInhabitantTax && year < 2020)){
    if(annualSalary <= 1625000) deduction = 650000;
    else if(1625000 < annualSalary && annualSalary <= 1800000) deduction = annualSalary * 0.4;
    else if(1800000 < annualSalary && annualSalary <= 3600000) deduction = annualSalary * 0.3 + 180000;
    else if(3600000 < annualSalary && annualSalary <= 6600000) deduction = annualSalary * 0.2 + 540000;
    else if(6600000 < annualSalary && annualSalary <= 10000000) deduction = annualSalary * 0.1 + 1200000;
    else if(10000000 < annualSalary) deduction = 2200000;
  }
  else{
    if(annualSalary <= 1625000) deduction = 550000;
    else if(1625000 < annualSalary && annualSalary <= 1800000) deduction = annualSalary * 0.4 - 100000;
    else if(1800000 < annualSalary && annualSalary <= 3600000) deduction = annualSalary * 0.3 + 80000;
    else if(3600000 < annualSalary && annualSalary <= 6600000) deduction = annualSalary * 0.2 + 440000;
    else if(6600000 < annualSalary && annualSalary <= 8500000) deduction = annualSalary * 0.1 + 1100000;
    else if(8500000 < annualSalary) deduction = 1950000;
  }
  
  return Math.floor(deduction);
};
/* tslint:enable:curly */



/* tslint:disable:curly */
const getBaseDeduction: G.GetBaseDeductionFunc = (annualSalary, isInhabitantTax, year) => {
  if((isInhabitantTax && year < 2021) || (!isInhabitantTax && year < 2020)){
    return 380000 - (isInhabitantTax ? 50000 : 0);
  }
  else{
    if(annualSalary <= 24000000) return 480000 - (isInhabitantTax ? 50000 : 0);
    if(24000000 < annualSalary && annualSalary <= 24500000) return 320000 - (isInhabitantTax ? 50000 : 0);
    if(24500000 < annualSalary && annualSalary <= 25000000) return 160000 - (isInhabitantTax ? 50000 : 0);
    if(25000000 < annualSalary) return 0;
    return 0;
  }
};
/* tslint:enable:curly */



const getPersonalDeduction: G.GetPersonalDeductionFunc = (feeding, isInhabitantTax) => {
  let deduction = 0;
  
  if(isInhabitantTax){
    if(feeding && typeof(feeding) === "object"){
      deduction += 330000 * (feeding.general || 0);
      deduction += 450000 * (feeding.specific || 0);
      deduction += 380000 * (feeding.old || 0);
      deduction += 450000 * (feeding.oldParents || 0);
    }
  }
  else{
    if(feeding && typeof(feeding) === "object"){
      deduction += 380000 * (feeding.general || 0);
      deduction += 630000 * (feeding.specific || 0);
      deduction += 480000 * (feeding.old || 0);
      deduction += 580000 * (feeding.oldParents || 0);
    }
  }
  
  return deduction;
};



const getAdjustmentDeduction: G.GetAdjustmentDeductionFunc = (earningsBeingTaxed) => {
  // Adjustment deduction   https://www.city.suwa.lg.jp/www/info/detail.jsp?id=8355
  let adjustmentDeduction = 0;
  if(earningsBeingTaxed <= 2000000){
    adjustmentDeduction = 50000 < earningsBeingTaxed ? 50000 * 0.05 : earningsBeingTaxed * 0.05;
  }
  else{
    adjustmentDeduction = 50000 - (earningsBeingTaxed - 2000000);
    adjustmentDeduction = adjustmentDeduction < 2500 ? 2500 : adjustmentDeduction;
  }
  
  return adjustmentDeduction;
};



const getElapsedMonths: G.GetElapsedMonthsFunc = (cursor) => {
  const {start, current} = cursor;
  
  return (current.y - start.y)*12 + (current.m - start.m);
};



const getElapsedYears: G.GetElapsedYearsFunc = (cursor) => {
  return Math.floor(getElapsedMonths(cursor) / 12);
};



const getDate: G.GetDateFunc = (current) => {
  return new Date(`${current.y}-${current.m}-${1} 00:00:00`);
};



const getFactor: G.GetFactorFunc = (seedFactor) => {
  const seed = Math.random() * seedFactor;
  return seed * (Math.random() > 0.5 ? 1 : -1) / 100;
};


const rebalanceFlowAndBalance: G.RebalanceFlowAndBalance = (newFlow, sorted_balance) => {
  const newBalance: S.ActiveBalanceData[] = [];
  const sorted_newFlow = Util.getSortedFlowData(newFlow);
  
  for(let i=sorted_newFlow.length-1;i>=0;i--){
    const f = sorted_newFlow[i];
    
    const index = Util.findBalanceIndex(sorted_balance, f.date, f.account, true);
    if(typeof(index) === "number"){
      sorted_balance[index].balance += f.value;
  
      for(let j=index-1;j>=0;j--){
        if(sorted_balance[j].account === f.account){
          sorted_balance[j].balance += f.value;
        }
      }
    }
    else{
      const index2 = newBalance.findIndex(b => b.date === f.date && b.account === f.account);
      if(index2 > -1){
        newBalance[index2].balance += f.value;
      }
      else{
        const sorted_merged_balance = Util.getSortedBalanceData(sorted_balance.concat(newBalance));
        const b = Util.findBalanceAtDay(sorted_merged_balance, f.date, f.account);
        newBalance.push({date: f.date, account: f.account, balance: b.balance + f.value, note: ""});
      }
      
      for(let j=sorted_balance.length-1;j>=0;j--){
        const b = sorted_balance[j];
        
        if(b.date > f.date && b.account === f.account){
          sorted_balance[j].balance += f.value;
        }
      }
    }
  }
  
  sorted_balance.splice(sorted_balance.length, 0, ...newBalance);
};


//#region user data
const init: G.InitFunc = (data, params, state) => {
  const {
    start_debt,
    start_fixedAsset,
    currency,
    income_feeding,
  } = params;
  let {start_fund} = params;
  let wallet = 0;
  
  const now = Util.getCurrentDate();
  
  const y = typeof(params.startY) === "number" ? params.startY : now.getFullYear();
  const m = typeof(params.startM) === "number" ? params.startM : 1;
  
  state.cursor.start = {y, m};
  state.cursor.current = {y, m};
  state.cursor.monthIndex = 0;
  
  if(typeof(start_fund) !== "number"){
    start_fund = fromYen(1000000, currency);
  }
  if(typeof(params.income_payday) !== "number"){
    params.income_payday = C.Demo_defaultParams.income_payday;
  }
  if(typeof(params.income_overtimeRaiseRate) !== "number"){
    params.income_overtimeRaiseRate = C.Demo_defaultParams.income_overtimeRaiseRate;
  }
  if(typeof(params.income_workingHours) !== "number"){
    params.income_workingHours = C.Demo_defaultParams.income_workingHours;
  }
  if(typeof(params.income_pensionChargeRate) !== "number"){
    params.income_pensionChargeRate = C.Demo_defaultParams.income_pensionChargeRate;
  }
  if(typeof(params.income_healthInsuranceChargeRate) !== "number"){
    params.income_healthInsuranceChargeRate = C.Demo_defaultParams.income_healthInsuranceChargeRate;
  }
  if(typeof(params.income_employmentInsuranceChargeRate) !== "number"){
    params.income_employmentInsuranceChargeRate = C.Demo_defaultParams.income_employmentInsuranceChargeRate;
  }
  if(typeof(params.spending_debtBonusChargeRate) !== "number"){
    params.spending_debtBonusChargeRate = C.Demo_defaultParams.spending_debtBonusChargeRate;
  }
  if(typeof(params.fixedAssetUsefulLives) !== "number" || params.autoFixedAssetUsefulLives === true){
    const fixedAsset_yen = toYen(start_fixedAsset, currency);
    if(fixedAsset_yen < 200000){
      params.fixedAssetUsefulLives = 1;
    }
    else if(fixedAsset_yen < 6000000){
      params.fixedAssetUsefulLives = 6; // As a brand-new car
    }
    else{
      params.fixedAssetUsefulLives = 47; // As a brand-new house
    }
  }
  if(typeof(params.income_feeding) !== "number"){
    params.income_feeding = C.Demo_defaultParams.income_feeding;
  }
  
  if(toYen(start_fund, currency) > 100000){
    wallet = fromYen(30000, currency);
    start_fund -= wallet;
  }
  else{
    wallet = start_fund;
    start_fund = 0;
  }
  
  state.basePay = params.income_basePay;
  state.bonusDetermined = params.income_bonusAmount * (1 + getFactor(params.income_rfBonusAmount));
  
  state.paidLog = {};
  state.paidLog[state.cursor.current.y-1] = {income: [], socialInsurances: [], incomeTax: []};
  
  let totalYearlyEarnings = 0;
  let totalYearlySocialInsurances = 0;
  if(params.startM < 6){
    let totalYearlyEarnings2 = 0;
    let totalYearlySocialInsurances2 = 0;
    
    if(typeof(params.income_TotalIncome2YearsAgo) === "number"){
      totalYearlyEarnings2 = params.income_TotalIncome2YearsAgo;
    }
    else{
      totalYearlyEarnings2 = state.basePay * 12 + params.income_bonusAmount;
    }
    if(typeof(params.income_TotalSocialInsurance2YearsAgo) === "number"){
      totalYearlySocialInsurances2 = params.income_TotalSocialInsurance2YearsAgo;
    }
    else{
      for(let month=1;month<=12;month++){
        const pay = Math.round(totalYearlyEarnings2/12);
        const pension = getPension(pay, currency, params.income_pensionChargeRate, false);
        const healthInsurance = getHealthInsurance(pay, currency, params.income_healthInsuranceChargeRate, false);
        const employmentInsurance = getEmploymentInsurance(pay, currency, params.income_employmentInsuranceChargeRate, false);
        totalYearlySocialInsurances2 += Math.round(pension + healthInsurance + employmentInsurance);
      }
    }
  
    state.paidLog[state.cursor.current.y-2] = {income: [], socialInsurances: [], incomeTax: []};
    state.paidLog[state.cursor.current.y-2].income[12] = totalYearlyEarnings2;
    state.paidLog[state.cursor.current.y-2].socialInsurances[12] = totalYearlySocialInsurances2;
    state.monthlyInhabitantTax = getMonthlyInhabitantTax(
      totalYearlyEarnings2, totalYearlySocialInsurances2, currency, {general: income_feeding}, state.cursor,
    );
  }
  
  if(typeof(params.income_prevYearTotalIncome) === "number"){
    totalYearlyEarnings = params.income_prevYearTotalIncome;
  }
  else{
    totalYearlyEarnings = state.basePay * 12 + params.income_bonusAmount;
  }
  if(typeof(params.income_prevYearTotalSocialInsurance) === "number"){
    totalYearlySocialInsurances = params.income_prevYearTotalSocialInsurance;
  }
  else{
    for(let month=1;month<=12;month++){
      const pay = Math.round(totalYearlyEarnings/12);
      const pension = getPension(pay, currency, params.income_pensionChargeRate, false);
      const healthInsurance = getHealthInsurance(pay, currency, params.income_healthInsuranceChargeRate, false);
      const employmentInsurance = getEmploymentInsurance(pay, currency, params.income_employmentInsuranceChargeRate, false);
      totalYearlySocialInsurances += Math.round(pension + healthInsurance + employmentInsurance);
    }
  }
  
  state.paidLog[state.cursor.current.y-1].income[12] = totalYearlyEarnings;
  state.paidLog[state.cursor.current.y-1].socialInsurances[12] = totalYearlySocialInsurances;
  
  if(params.startM >= 6){
    state.monthlyInhabitantTax = getMonthlyInhabitantTax(
      totalYearlyEarnings, totalYearlySocialInsurances, currency, {general: income_feeding}, state.cursor,
    );
  }
  
  if(params.startM < 9){
    if(typeof(params.income_initialNormalizedPayLastTerm) !== "number"){
      state.standardMonthlyRemuneration = {remuneration: state.basePay, 4: null, 5: null, 6: null};
    }
    else{
      state.standardMonthlyRemuneration = {remuneration: params.income_initialNormalizedPayLastTerm, 4: null, 5: null, 6: null};
    }
  }
  else{
    if(typeof(params.income_initialNormalizedPay) !== "number"){
      state.standardMonthlyRemuneration = {remuneration: state.basePay, 4: null, 5: null, 6: null};
    }
    else{
      state.standardMonthlyRemuneration = {remuneration: params.income_initialNormalizedPay, 4: null, 5: null, 6: null};
    }
  }
  
  state.bonusRepaymentCursor = 0;
  calculateDebtRepaymentPlan(params, state);
  calculateDepreciationPlan(params, state);
  
  
  
  // Initial flow/balance
  const date = Util.createCustomDate(y, m, 1);
  data.data_balance.push({date, account: 0, balance: wallet, note: ""});
  data.data_balance.push({date, account: 1, balance: start_fund, note: ""});
  data.data_balance.push({date, account: 4, balance: -start_debt, note: ""});
  data.data_balance.push({date, account: 5, balance: start_fixedAsset, note: ""});
  data.data_flow.push({date, item: -2, account: 0, value: wallet, notes: ""});
  data.data_flow.push({date, item: -2, account: 1, value: start_fund, notes: ""});
  data.data_flow.push({date, item: -2, account: 4, value: -start_debt, notes: ""});
  data.data_flow.push({date, item: -2, account: 5, value: start_fixedAsset, notes: ""});
};



const generateIncome_Salary: G.GenerateIncome_SalaryFunc = (data, params, state, option) => {
  const {
    income_allowances,
    income_commuting,
    income_otherIncome,
    income_overtime,
    income_rfOtherIncome,
    income_rfOvertime,
    income_rfRiseRate,
    income_payday,
    income_overtimeRaiseRate,
    income_workingHours,
    income_pensionChargeRate,
    income_healthInsuranceChargeRate,
    income_employmentInsuranceChargeRate,
    income_feeding,
    currency,
  } = params;
  const {
    paidLog,
    cursor,
  } = state;
  
  const {t} = option;
  let newFlow = [] as S.ActiveFlowData[];
  
  const date = getDate(cursor.current);
  const elapsedMonths = getElapsedMonths(cursor);
  
  // Get every year's pay raise.
  let note_payRise;
  if(elapsedMonths > 0 && elapsedMonths % 12 === 0){
    // Determine raise rate
    state.riseRate = params.income_riseRate * (1 + getFactor(income_rfRiseRate));
    const payUp = state.basePay * state.riseRate / 100;
    state.basePay += payUp;
    state.basePay = Math.floor(state.basePay);
    note_payRise = t<string>("payRiseDetermined", {
      rise: Math.floor(state.riseRate * 10) / 10,
      value: formatMoney(Math.round(payUp), currency),
    });
  }
  
  // Calculate inhabitant tax at June
  let note_inhabitantTax;
  if(cursor.current.m === 6){
    const totalEarnings = paidLog[cursor.current.y-1].income.reduce((acc, val) => val + acc, 0);
    const totalInsurance = paidLog[cursor.current.y-1].socialInsurances.reduce((acc, val) => val + acc, 0);
    state.monthlyInhabitantTax = getMonthlyInhabitantTax(
      totalEarnings, totalInsurance, currency, {general: income_feeding}, cursor,
    );
    const tax = formatMoney(Math.round(state.monthlyInhabitantTax*12), currency);
    note_inhabitantTax = t<string>("inhabitantTaxDetermined", {tax});
  }
  else if(cursor.monthIndex === 0){
    const tax = formatMoney(Math.round(state.monthlyInhabitantTax*12), currency);
    note_inhabitantTax = t<string>("inhabitantTaxDetermined", {tax});
  }
  
  // Decide overtime
  const adjusted_income_overtime = income_overtime * (1 + Math.abs(getFactor(income_rfOvertime)));
  const note_overtime = t<string>("overtimeDetermined", {hour: Math.round(adjusted_income_overtime)});
  
  // Decide overtime pay
  date.setMonth(date.getMonth()+1);
  date.setDate(0);
  let daysOfMonth = 0;
  for(let d=1;d<=date.getDate();d++){
    const isWorkingDay = [1,2,3,4,5].includes(new Date(`${date.getFullYear()}-${date.getMonth()+1}-${d} 00:00:00`).getDay());
    daysOfMonth += isWorkingDay ? 1 : 0;
  }
  const basePay_for_hour = state.basePay / (daysOfMonth * income_workingHours);
  const overtimePay_for_hour = basePay_for_hour * ((100 + income_overtimeRaiseRate) / 100);
  const overTimePay = Math.round(overtimePay_for_hour * adjusted_income_overtime);
  
  date.setDate(income_payday || C.Demo_defaultParams.income_payday);
  const payDay = Util.getCustomDateFromDate(date);
  
  newFlow = newFlow.concat([
    {date: payDay, item: 12, account: 1, value: state.basePay, notes: note_payRise ? note_payRise : ""},
    {date: payDay, item: 13, account: 1, value: overTimePay, notes: note_overtime},
    {date: payDay, item: 15, account: 1, value: income_allowances, notes: ""},
  ]);
  
  let otherIncome = income_otherIncome / 12;
  otherIncome += otherIncome * getFactor(income_rfOtherIncome);
  otherIncome = Math.floor(otherIncome);
  let dateOfOtherIncome = Math.floor(Math.random() * (daysOfMonth - 1) + 1);
  dateOfOtherIncome = Util.createCustomDate(cursor.current.y, cursor.current.m, dateOfOtherIncome);
  
  newFlow = newFlow.concat([
    {date: dateOfOtherIncome, item: 17, account: 1, value: otherIncome, notes: ""},
  ]);
  
  // Calculate Standard Monthly Remuneration(標準報酬月額)
  let note_standardRemuneration = "";
  if([4,5,6].includes(cursor.current.m)){
    state.standardMonthlyRemuneration[(cursor.current.m as 4|5|6)] = state.basePay + overTimePay + income_allowances;
  }
  else if(cursor.current.m === 9){
    let months = 0;
    let sum = 0;
    for(let month=4;month<7;month++){
      const r = state.standardMonthlyRemuneration[(month as 4|5|6)];
      if(typeof(r) === "number" && r > 0){
        months++;
        sum += r;
      }
    }
    
    if(months === 3){
      state.standardMonthlyRemuneration.remuneration = Math.round(sum / months);
    }
    else if(months > 0){
      for(let month=4;month<7;month++){
        const r = state.standardMonthlyRemuneration[(month as 4|5|6)];
        if(typeof(r) === "number" && r > 0){
          state.standardMonthlyRemuneration.remuneration = r;
          break;
        }
      }
    }
    else{
      state.standardMonthlyRemuneration.remuneration = state.basePay;
    }
  
    const remuneration_forPension = Math.ceil(fromYen(
      getNormalizedPayForPension(toYen(state.standardMonthlyRemuneration.remuneration, currency)),
      currency,
    ));
    const remuneration_forHealthIns = Math.ceil(fromYen(
      getNormalizedPayForHealthInsurance(toYen(state.standardMonthlyRemuneration.remuneration, currency)),
      currency,
    ));
    
    if(remuneration_forPension === remuneration_forHealthIns){
      note_standardRemuneration = t<string>("standardRemuneration", {remuneration: formatMoney(remuneration_forPension, currency)});
    }
    else{
      note_standardRemuneration = t<string>("standardRemuneration", {
        remuneration: `${formatMoney(remuneration_forPension, currency)}/${formatMoney(remuneration_forHealthIns, currency)}`,
      });
    }
  }
  
  if(cursor.monthIndex === 0){
    const remuneration_forPension = Math.ceil(fromYen(
      getNormalizedPayForPension(toYen(state.standardMonthlyRemuneration.remuneration, currency)),
      currency,
    ));
    const remuneration_forHealthIns = Math.ceil(fromYen(
      getNormalizedPayForHealthInsurance(toYen(state.standardMonthlyRemuneration.remuneration, currency)),
      currency,
    ));
  
    if(remuneration_forPension === remuneration_forHealthIns){
      note_standardRemuneration = t<string>("standardRemuneration", {remuneration: formatMoney(remuneration_forPension, currency)});
    }
    else{
      note_standardRemuneration = t<string>("standardRemuneration", {
        remuneration: `${formatMoney(remuneration_forPension, currency)}/${formatMoney(remuneration_forHealthIns, currency)}`,
      });
    }
  }
  
  // Transport allowance at every half year
  if(elapsedMonths % 6 === 0){
    newFlow.push({date: payDay, item: 16, account: 1, value: income_commuting/2, notes: ""});
  }
  
  // Social insurances
  const totalPay = state.basePay + overTimePay + income_allowances;
  const {remuneration} = state.standardMonthlyRemuneration;
  const pension = getPension(remuneration, currency, income_pensionChargeRate, false);
  const healthInsurance = getHealthInsurance(remuneration, currency, income_healthInsuranceChargeRate, false);
  const employmentInsurance = getEmploymentInsurance(remuneration, currency, income_employmentInsuranceChargeRate, false);
  const socialInsurances = pension + healthInsurance + employmentInsurance;
  const inhabitantTax = state.monthlyInhabitantTax;
  const incomeTax = getMonthlyIncomeTax(totalPay, socialInsurances, currency, income_feeding);
  const otherIncomeDeduction = getOtherDeduction(state.basePay);
  
  newFlow = newFlow.concat([
    {date: payDay, item: 18, account: 1, value: -pension, notes: note_standardRemuneration},
    {date: payDay, item: 19, account: 1, value: -healthInsurance, notes: note_standardRemuneration},
    {date: payDay, item: 20, account: 1, value: -employmentInsurance, notes: note_standardRemuneration},
  ]);
  
  // Taxes
  newFlow = newFlow.concat([
    {date: payDay, item: 21, account: 1, value: -incomeTax, notes: ""},
    {date: payDay, item: 22, account: 1, value: -inhabitantTax, notes: note_inhabitantTax ? note_inhabitantTax : ""},
    {date: payDay, item: 23, account: 1, value: -otherIncomeDeduction, notes: ""},
  ]);
  
  state.prevMonthPayDeducted = totalPay - socialInsurances;
  
  logPay(state, cursor, totalPay, socialInsurances, incomeTax, false);
  
  // Year-end adjustment
  if(cursor.current.m === 12){
    const annualIncome = state.paidLog[cursor.current.y].income.reduce((acc, val) => acc + val, 0);
    const socialInsurance = state.paidLog[cursor.current.y].socialInsurances.reduce((acc, val) => acc + val, 0);
    const taxPaid = state.paidLog[cursor.current.y].incomeTax.reduce((acc, val) => acc + val, 0);
    let taxOfIncome = getTaxForAnnualIncome(annualIncome, socialInsurance, currency, {general: income_feeding}, cursor.current);
    
    // 復興特別所得税
    let drTax = 0;
    if(2013 <= cursor.current.y && cursor.current.y <= 2037){
      drTax = Math.floor(taxOfIncome * 0.021 / 100) * 100;
    }
    
    taxOfIncome = taxOfIncome + drTax;
  
    const extraTaxPaid = taxPaid - taxOfIncome;
    const notes = t<string>("yearEndAdjustment_note", {taxPaid, incomeTax: taxOfIncome});
    newFlow = newFlow.concat([
      {date: payDay, item: 32, account: 1, value: extraTaxPaid, notes},
    ]);
  }
  
  //////////////////////////////////
  // Rebalance flow and balance
  //////////////////////////////////
  data.data_flow = data.data_flow.concat(newFlow);
  data.data_balance = Util.getSortedBalanceData(data.data_balance) as S.ActiveBalanceData[];
  
  rebalanceFlowAndBalance(newFlow, data.data_balance);
};



const generateIncome_Bonus: G.GenerateIncome_BonusFunc = (data, params, state) => {
  const {
    income_rfBonusAmount,
    income_bonusAmount,
    income_bonusCount,
    income_pensionChargeRate,
    income_healthInsuranceChargeRate,
    income_employmentInsuranceChargeRate,
    income_feeding,
    currency,
  } = params;
  const {
    prevMonthPayDeducted,
    cursor,
  } = state;
  let bonusDetermined = state.bonusDetermined;
  
  const date = getDate(cursor.current);
  const elapsedMonths = getElapsedMonths(cursor);
  
  // Determine bonus amount every year
  if(elapsedMonths % 12 === 0){
    state.bonusDetermined = Math.round(income_bonusAmount * (1 + getFactor(income_rfBonusAmount)));
    bonusDetermined = state.bonusDetermined;
  }
  
  date.setDate(BONUS_DATE);
  const bonus_date = Util.getCustomDateFromDate(date);
  
  let newFlow = [] as S.ActiveFlowData[];
  
  let pension = 0;
  let healthInsurance = 0;
  let employmentInsurance = 0;
  let socialInsurances = 0;
  let tax = 0;
  
  const getFlow = (bonus: number) => {
    pension = getPension(bonus, currency, income_pensionChargeRate, true);
    healthInsurance = getHealthInsurance(bonus, currency, income_healthInsuranceChargeRate, true);
    employmentInsurance = getEmploymentInsurance(bonus, currency, income_employmentInsuranceChargeRate, true);
    socialInsurances = pension + healthInsurance + employmentInsurance;
    tax = getTaxForBonus(bonus, currency, socialInsurances, prevMonthPayDeducted, income_feeding);
    
    return [
      {date: bonus_date, item: 14, account: 1, value: bonus, notes: ""},
      {date: bonus_date, item: 18, account: 1, value: -pension, notes: ""},
      {date: bonus_date, item: 19, account: 1, value: -healthInsurance, notes: ""},
      {date: bonus_date, item: 20, account: 1, value: -employmentInsurance, notes: ""},
      {date: bonus_date, item: 21, account: 1, value: -tax, notes: ""},
    ];
  };
  
  if(income_bonusCount === 1 && cursor.current.m === 6){
    newFlow = newFlow.concat(getFlow(bonusDetermined));
  }
  else if(income_bonusCount === 2 && [6, 12].includes(cursor.current.m)){
    if(cursor.current.m === 6){
      let rate = Math.floor(Math.random()*100)/100;
      if(Math.abs(rate - 0.5) > 0.2){
        rate = Math.floor(Math.random()*100)/100;
      }
      if(Math.abs(rate - 0.5) > 0.3){
        rate = Math.floor(Math.random()*100)/100;
      }
      if(Math.abs(rate - 0.5) > 0.4){
        rate = Math.floor(Math.random()*100)/100;
      }
      bonusDetermined *= rate;
      bonusDetermined = Math.round(bonusDetermined);
      state.bonusDetermined -= bonusDetermined;
    }
    else{
      bonusDetermined = state.bonusDetermined;
    }
    newFlow = newFlow.concat(getFlow(bonusDetermined));
  }
  else if(income_bonusCount === 3 && [3, 6, 12].includes(cursor.current.m)){
    if([3, 6].includes(cursor.current.m)){
      let rate = Math.floor(Math.random()*100)/100;
      if(Math.abs(rate - 0.3) > 0.2){
        rate = Math.floor(Math.random()*100)/100;
      }
      if(Math.abs(rate - 0.3) > 0.3){
        rate = Math.floor(Math.random()*100)/100;
      }
      if(Math.abs(rate - 0.3) > 0.4){
        rate = Math.floor(Math.random()*100)/100;
      }
      bonusDetermined *= rate;
      bonusDetermined = Math.round(bonusDetermined);
      state.bonusDetermined -= bonusDetermined;
    }
    else{
      bonusDetermined = state.bonusDetermined;
    }
    newFlow = newFlow.concat(getFlow(bonusDetermined));
  }
  else if(income_bonusCount === 4 && [3, 6, 9, 12].includes(cursor.current.m)){
    if([3, 6, 9].includes(cursor.current.m)){
      let rate = Math.floor(Math.random()*100)/100;
      if(Math.abs(rate - 0.25) > 0.2){
        rate = Math.floor(Math.random()*100)/100;
      }
      if(Math.abs(rate - 0.25) > 0.3){
        rate = Math.floor(Math.random()*100)/100;
      }
      if(Math.abs(rate - 0.25) > 0.4){
        rate = Math.floor(Math.random()*100)/100;
      }
      bonusDetermined *= rate;
      bonusDetermined = Math.round(bonusDetermined);
      state.bonusDetermined -= bonusDetermined;
    }
    else{
      bonusDetermined = state.bonusDetermined;
    }
    newFlow = newFlow.concat(getFlow(bonusDetermined));
  }
  
  if(newFlow.length < 1){
    return;
  }
  
  logPay(state, cursor, bonusDetermined, socialInsurances, tax, true);
  
  data.data_flow = data.data_flow.concat(newFlow);
  
  //////////////////////////////////
  // Rebalance flow and balance
  //////////////////////////////////
  const balanceChange = newFlow.reduce((acc, flow) => acc + flow.value, 0);
  
  if(balanceChange === 0){
    return;
  }
  
  data.data_balance = Util.getSortedBalanceData(data.data_balance);
  const summedFlow = [{date: bonus_date, item: Infinity, account: 1, value: balanceChange, notes: ""}];
  rebalanceFlowAndBalance(summedFlow, data.data_balance);
};



const generateSpending: G.GenerateSpendingFunc = (data, params, state, option) => {
  const {
    spending_rf,
    income_payday,
    currency,
  } = params;
  const {
    depreciations,
    cursor,
  } = state;
  let newFlow = [] as S.ActiveFlowData[];
  
  // Make yearly plan for trip
  planYearlyBudget(params, state, "spending_trip_budget", params.spending_trip_count);
  
  // Every year, depreciate fixed asset
  const elapsedYears = getElapsedYears(cursor);
  if(elapsedYears > 0 && cursor.monthIndex % 12 === 0 && elapsedYears - 1 < depreciations.length){
    const date = getDate(cursor.current);
    date.setDate(1);
    const customDate = Util.getCustomDateFromDate(date);
    
    newFlow = newFlow.concat([
      {date: customDate, item: 31, account: 5, value: -depreciations[elapsedYears - 1], notes: ""},
    ]);
  }
  
  // latest debt
  const debt = data.data_balance.reduce((acc, balance) => {
    if(balance.account !== 4){
      return acc;
    }
    if(acc.date < balance.date){
      return balance;
    }
    return acc;
  }, {date: 0, balance: 0});
  
  if(debt.balance < 0){
    const debtRepayment = repayDebt(params, state, option);
    if(debtRepayment.length > 0){
      newFlow = newFlow.concat(debtRepayment);
    }
  }
  
  const mkFlow_daily = (itemID: number, accountID: number, valueName: keyof G.GeneratorParams, fixed?: boolean): void => {
    newFlow = newFlow.concat(createSpending_Daily(cursor, itemID, accountID, {
      factor: fixed ? 0 : spending_rf,
      baseValue: (params[valueName] as number),
      currency,
    }));
  };
  
  const mkFlow_once = (itemID: number, accountID: number, valueName: keyof G.GeneratorParams, day?: number, fixed?: boolean): void => {
    if(typeof(params[valueName]) !== "number" || typeof(spending_rf) !== "number"){
      return;
    }
    
    const factor = fixed ? 0 : spending_rf;
    const baseValue = params[valueName] as number;
    
    newFlow = newFlow.concat(createSpending_Once(cursor, itemID, accountID, {
      factor,
      baseValue,
    }, day));
  };
  
  mkFlow_daily(0, 0, "spending_food");
  mkFlow_daily(1, 0, "spending_goods");
  mkFlow_daily(2, 0, "spending_hobby");
  mkFlow_daily(4, 0, "spending_commuting");
  mkFlow_daily(5, 0, "spending_medical");
  mkFlow_daily(6, 0, "spending_beauty");
  mkFlow_daily(7, 0, "spending_education");
  mkFlow_once(3, 1, "spending_communication", 10);
  mkFlow_once(8, 1, "spending_gasWater", 10);
  mkFlow_once(9, 1, "spending_electricity", 10);
  mkFlow_once(10, 1, "spending_house", 26, true);
  mkFlow_once(11, 0, "spending_unclassified");
  
  // Flow for trip
  if(state.yearlyBudgets.spending_trip_budget.months.includes(cursor.current.m)){
    const yearlyBudget = state.yearlyBudgets.spending_trip_budget.remaining;
    let spending = 0;
    if(Math.max(...state.yearlyBudgets.spending_trip_budget.months) === cursor.current.m){
      spending = yearlyBudget;
    }
    else{
      const percent = (1 / params.spending_trip_count) * ((Math.random() - 0.5)/2 + 1);
      spending = Math.round(yearlyBudget * percent);
      state.yearlyBudgets.spending_trip_budget.remaining -= spending;
    }
  
    const days = getDaysInMonth(new Date(`${cursor.current.y}-${cursor.current.m}-01 00:00:00`));
    const spendingDay = Math.floor(Math.random() * (days - 1 + 1) + 1);
    const date = Util.createCustomDate(cursor.current.y, cursor.current.m, spendingDay);
    newFlow = newFlow.concat({date, item: 29, account: 1, value: -spending, notes: ""});
  }
  
  newFlow.sort((a, b) => {
    if(a.date !== b.date) { return a.date - b.date; }
    if(a.item !== b.item) { return a.item - b.item; }
    if(a.value !== b.value) { return a.value - b.value; }
    return 0;
  });
  
  interface IDayValue {[day: number]: number;}
  
  const walletFlow = newFlow.filter(f => f.account === 0).reduce((acc: IDayValue, flow) => {
    const day = Util.getDateFromCustomDate(flow.date).getDate();
    if(typeof(acc[day]) !== "number"){
      acc[day] = 0;
    }
    acc[day] += flow.value;
    return acc;
  }, {}) as IDayValue;
  
  const bankFlow = newFlow.filter(f => f.account === 1).reduce((acc: IDayValue, flow) => {
    const day = Util.getDateFromCustomDate(flow.date).getDate();
    if(typeof(acc[day]) !== "number"){
      acc[day] = 0;
    }
    acc[day] += flow.value;
    return acc;
  }, {}) as IDayValue;
  
  const debtFlow = newFlow.filter(f => f.account === 4).reduce((acc: IDayValue, flow) => {
    const day = Util.getDateFromCustomDate(flow.date).getDate();
    if(typeof(acc[day]) !== "number"){
      acc[day] = 0;
    }
    acc[day] += flow.value;
    return acc;
  }, {}) as IDayValue;
  
  const fixedAssetFlow = newFlow.filter(f => f.account === 5).reduce((acc: IDayValue, flow) => {
    const day = Util.getDateFromCustomDate(flow.date).getDate();
    if(typeof(acc[day]) !== "number"){
      acc[day] = 0;
    }
    acc[day] += flow.value;
    return acc;
  }, {}) as IDayValue;
  
  let endDayOfMonth: number = 0;
  {
    const date = new Date(`${cursor.current.y}-${cursor.current.m}-01 00:00:00`);
    date.setMonth(date.getMonth()+1);
    date.setDate(0);
    endDayOfMonth = date.getDate();
  }
  
  let {data_balance} = data;
  const walletBalanceForDay: IDayValue = {};
  const bankBalanceForDay: IDayValue = {};
  const cardBalanceForDay: IDayValue = {};
  const debtBalanceForDay: IDayValue = {};
  const fixedAssetBalanceForDay: IDayValue = {};
  
  data_balance = [...data_balance];
  data_balance.sort((a, b)=> a.date - b.date);
  
  for(let i=0;i<data_balance.length;i++){
    const b = data_balance[i];
    const {y, m, d} = Util.parseCustomDate(b.date);
    
    if(y > cursor.current.y || (y === cursor.current.y && m > cursor.current.m)){
      break;
    }
    
    if(y < cursor.current.y || (y === cursor.current.y && m < cursor.current.m)){
      switch(b.account){
        case 0: walletBalanceForDay[1] = b.balance; break;
        case 1: bankBalanceForDay[1] = b.balance; break;
        case 2: cardBalanceForDay[1] = b.balance; break;
        case 4: debtBalanceForDay[1] = b.balance; break;
        case 5: fixedAssetBalanceForDay[1] = b.balance; break;
        default: break;
      }
      continue;
    }
    
    if(y !== cursor.current.y || m !== cursor.current.m){
      continue;
    }
    
    switch(b.account){
      case 0: walletBalanceForDay[d] = b.balance; break;
      case 1: bankBalanceForDay[d] = b.balance; break;
      case 2: cardBalanceForDay[d] = b.balance; break;
      case 4: debtBalanceForDay[d] = b.balance; break;
      case 5: fixedAssetBalanceForDay[d] = b.balance; break;
      default: break;
    }
  }
  
  if(typeof(walletBalanceForDay[1]) !== "number") { walletBalanceForDay[1] = 0; }
  if(typeof(bankBalanceForDay[1]) !== "number") { bankBalanceForDay[1] = 0; }
  if(typeof(cardBalanceForDay[1]) !== "number") { cardBalanceForDay[1] = 0; }
  if(typeof(debtBalanceForDay[1]) !== "number") { debtBalanceForDay[1] = 0; }
  if(typeof(fixedAssetBalanceForDay[1]) !== "number") { fixedAssetBalanceForDay[1] = 0; }
  
  for(let d=2;d<=endDayOfMonth;d++){
    if(typeof(walletBalanceForDay[d]) !== "number"){
      walletBalanceForDay[d] = walletBalanceForDay[d-1];
    }
    if(typeof(bankBalanceForDay[d]) !== "number"){
      bankBalanceForDay[d] = bankBalanceForDay[d-1];
    }
    if(typeof(cardBalanceForDay[d]) !== "number"){
      cardBalanceForDay[d] = cardBalanceForDay[d-1];
    }
    if(typeof(debtBalanceForDay[d]) !== "number"){
      debtBalanceForDay[d] = debtBalanceForDay[d-1];
    }
    if(typeof(fixedAssetBalanceForDay[d]) !== "number"){
      fixedAssetBalanceForDay[d] = fixedAssetBalanceForDay[d-1];
    }
  }
  
  const updateBalance = (balance: IDayValue, flow: number, day: number) => {
    balance[day] += flow;
    for(let d2=day+1;d2<=endDayOfMonth;d2++){
      balance[d2] += flow;
    }
  };
  
  const loans = [] as S.ActiveFlowData[];
  for(let d=1;d<=endDayOfMonth;d++){
    const date = Util.createCustomDate(cursor.current.y, cursor.current.m, d);
    
    if(typeof(walletFlow[d]) === "number"){
      updateBalance(walletBalanceForDay, walletFlow[d], d);
      
      if(walletBalanceForDay[d] < 0){
        if(bankBalanceForDay[d] < fromYen(40000, currency)){
          const lending = fromYen(200000, currency);
          loans.push({date, item: 27, account: 2, value: -lending, notes: ""});
          updateBalance(cardBalanceForDay, -lending, d);
          loans.push({date, item: 25, account: 1, value: lending, notes: ""});
          updateBalance(bankBalanceForDay, lending, d);
        }
  
        const withdrawal = fromYen(40000, currency);
        loans.push({date, item: 25, account: 1, value: -withdrawal, notes: ""});
        updateBalance(bankBalanceForDay, -withdrawal, d);
        loans.push({date, item: 25, account: 0, value: withdrawal, notes: ""});
        updateBalance(walletBalanceForDay, withdrawal, d);
      }
    }
    if(typeof(bankFlow[d]) === "number"){
      updateBalance(bankBalanceForDay, bankFlow[d], d);
      
      if(bankBalanceForDay[d] < fromYen(40000, currency)){
        let lending = fromYen(200000, currency);
        if(bankBalanceForDay[d] * -1 > fromYen(200000, currency)){
          lending = bankBalanceForDay[d] * -1 + fromYen(100000, currency);
        }
        loans.push({date, item: 27, account: 2, value: -lending, notes: ""});
        updateBalance(cardBalanceForDay, -lending, d);
        loans.push({date, item: 25, account: 1, value: lending, notes: ""});
        updateBalance(bankBalanceForDay, lending, d);
      }
    }
    if(typeof(debtFlow[d]) === "number"){
      updateBalance(debtBalanceForDay, debtFlow[d], d);
    }
    if(typeof(fixedAssetFlow[d]) === "number"){
      updateBalance(fixedAssetBalanceForDay, fixedAssetFlow[d], d);
    }
    
    if(d === income_payday && bankBalanceForDay[d] > fromYen(140000, currency) && cardBalanceForDay[d] < 0){
      const repay = Math.max(-fromYen(100000, currency), cardBalanceForDay[d]);
      loans.push({date, item: 28, account: 1, value: repay, notes: ""});
      updateBalance(bankBalanceForDay, repay, d);
      loans.push({date, item: 26, account: 2, value: -repay, notes: ""});
      updateBalance(cardBalanceForDay, -repay, d);
    }
  }
  
  const flowMerged = newFlow.concat(loans);
  
  data.data_flow = data.data_flow.concat(flowMerged);
  
  //////////////////////////////////
  // Rebalance flow and balance
  //////////////////////////////////
  data.data_balance = Util.getSortedBalanceData(data.data_balance);
  rebalanceFlowAndBalance(flowMerged, data.data_balance);
};



const createSpending_Daily: G.CreateSpending_DailyFunc = (cursor, itemID, accountID, valueParams) => {
  const date = new Date(`${cursor.current.y}-${cursor.current.m}-01 00:00:00`);
  date.setMonth(date.getMonth()+1);
  date.setDate(0);
  const endDayOfMonth = date.getDate();
  let days = Array(endDayOfMonth).fill(0).map((_, i) => i + 1);
  
  // Spending an item occurs X days in a month
  let spendingDays = Math.round(Math.random() * (24 - 8) + 8);
  const minValueInADay = fromYen(1000, valueParams.currency);
  if(valueParams.baseValue / spendingDays < minValueInADay){
    spendingDays = Math.floor(valueParams.baseValue / minValueInADay) || 1;
  }
  
  days = shuffleArray(days);
  days = days.slice(0, spendingDays);
  days.sort((a, b) => a- b);
  
  const {factor, baseValue} = valueParams;
  let min;
  let max;
  min = Math.max(0, baseValue - baseValue * factor / 100);
  max = baseValue + baseValue * factor / 100;
  const value = Math.round(Math.random() * (max -min) + min);
  
  let multipliers = Array(days.length).fill(0).map(() => Math.round(Math.random() * 100));
  const sum = multipliers.reduce((acc, val) => acc + val, 0);
  multipliers = multipliers.map(p => p / sum);
  
  return days.map((day, i) => {
    const d = Util.createCustomDate(cursor.current.y, cursor.current.m, day);
    return {date: d, item: itemID, account: accountID, value: -Math.round(value * multipliers[i]), notes: ""};
  });
};



const createSpending_Once: G.CreateSpending_OnceFunc = (cursor, itemID, accountID, valueParams, day) => {
  const date = new Date(`${cursor.current.y}-${cursor.current.m}-01 00:00:00`);
  if(typeof(day) !== "number"){
    date.setMonth(date.getMonth()+1);
    date.setDate(0);
    const endDayOfMonth = date.getDate();
    const walker = Math.random() * 100;
    day = (walker % endDayOfMonth) + 1;
  }
  
  date.setDate(day);
  
  let value;
  if(typeof(valueParams) === "number"){
    value = valueParams;
  }
  else{
    const {factor, baseValue} = valueParams;
    let min;
    let max;
    min = Math.max(0, baseValue - baseValue * factor / 100);
    max = baseValue + baseValue * factor / 100;
    value = Math.random() * (max -min) + min;
    value = -Math.round(value);
  }
  
  return {date: Util.getCustomDateFromDate(date), item: itemID, account: accountID, value, notes: ""};
};



const planYearlyBudget: G.PlanYearlyBudgetFunc = (params, state, baseValuePropName, count) => {
  const {spending_rf} = params;
  const {cursor} = state;
  
  // Determine yearly trip
  if(cursor.monthIndex === 0 || cursor.current.m === 1){
    if(!state.yearlyBudgets[baseValuePropName]){
      state.yearlyBudgets[baseValuePropName] = {months: [], remaining: 0};
    }
    state.yearlyBudgets[baseValuePropName].months = [];
    for(let i=0;i<count;i++){
      let month = Math.floor(Math.random() * (13 - 1) + 1);
      if(state.yearlyBudgets[baseValuePropName].months.includes(month)) { month = Math.floor(Math.random() * (13 - 1) + 1); }
      if(state.yearlyBudgets[baseValuePropName].months.includes(month)) { month = Math.floor(Math.random() * (13 - 1) + 1); }
      if(state.yearlyBudgets[baseValuePropName].months.includes(month)) { month = Math.floor(Math.random() * (13 - 1) + 1); }
      state.yearlyBudgets[baseValuePropName].months.push(month);
    }
    
    let budget;
    const factor = spending_rf;
    const baseValue = params[baseValuePropName] as number;
    let min;
    let max;
    min = Math.max(0, baseValue - baseValue * factor / 100);
    max = baseValue + baseValue * factor / 100;
    budget = Math.random() * (max -min) + min;
    budget = Math.round(budget);
    state.yearlyBudgets[baseValuePropName].remaining = budget;
  }
};



const repayDebt: G.RepayDebtFunc = (params, state, option) => {
  const {
    income_bonusCount,
    debt_interest,
    income_payday,
  } = params;
  const {
    monthlyDebtRepayments,
    bonusDebtRepayments,
    bonusRepaymentCursor,
    cursor,
  } = state;
  const {t} = option;
  
  if(typeof(debt_interest) !== "number"
  || typeof(income_payday) !== "number"
  ){
    return [];
  }
  
  const bonus_date = getDate(cursor.current);
  bonus_date.setDate(BONUS_DATE);
  const bonus_customDate = Util.getCustomDateFromDate(bonus_date);
  
  let newFlow = [] as S.ActiveFlowData[];
  
  if((income_bonusCount === 1 && cursor.current.m === 6)
    || (income_bonusCount === 2 && [6, 12].includes(cursor.current.m))
    || (income_bonusCount === 3 && [3, 6, 12].includes(cursor.current.m))
    || (income_bonusCount === 4 && [3, 6, 9, 12].includes(cursor.current.m))
  ){
    if(bonusRepaymentCursor < bonusDebtRepayments.length){
      const r = bonusDebtRepayments[bonusRepaymentCursor];
      
      newFlow = newFlow.concat([
        {
          date: bonus_customDate, item: 30, account: 1, value: -r.interest,
          notes: t<string>("debtInterest_note", {interest: debt_interest}),
        }, // interest
        {date: bonus_customDate, item: 28, account: 1, value: -r.principal, notes: ""}, // principal repayment
        {date: bonus_customDate, item: 26, account: 4, value: r.principal, notes: ""}, // deposit
      ]);
      
      state.bonusRepaymentCursor++;
    }
  }
  
  const repayment_date = getDate(cursor.current);
  repayment_date.setDate(income_payday);
  const repayment_customDate = Util.getCustomDateFromDate(repayment_date);
  const repayment = monthlyDebtRepayments[cursor.monthIndex];
  
  if(repayment){
    newFlow = newFlow.concat([
      {
        date: repayment_customDate, item: 30, account: 1, value: -repayment.interest,
        notes: t<string>("debtInterest_note", {interest: debt_interest}),
      }, // interest
      {date: repayment_customDate, item: 28, account: 1, value: -repayment.principal, notes: ""}, // principal repayment
      {date: repayment_customDate, item: 26, account: 4, value: repayment.principal, notes: ""}, // deposit
    ]);
  }
  
  return newFlow;
};



const calculateDebtRepaymentPlan: G.CalculateDebtRepaymentPlanFunc = (params, state) => {
  const {
    debt_interest,
    debt_period,
    repayment_method,
    start_debt,
    income_bonusCount,
    spending_debtBonusChargeRate,
  } = params;
  
  if(typeof(debt_period) !== "number"
    || typeof(spending_debtBonusChargeRate) !== "number"
    || typeof(debt_interest) !== "number"
  ){
    return;
  }
  
  state.principal = start_debt;
  state.monthlyDebtRepayments = Array(debt_period*12).fill(0);
  state.bonusDebtRepayments = Array(income_bonusCount * debt_period).fill(0);
  
  const bonusCharge = spending_debtBonusChargeRate / 100;
  
  if(income_bonusCount > 0){
    state.principalBonus = Math.floor(start_debt * bonusCharge);
    state.principal -= state.principalBonus;
  }
  
  if(repayment_method === "equalInstalments"){
    const monthlyRepayments = Math.floor(state.principal / (debt_period*12));
    
    for(let i=0;i<state.monthlyDebtRepayments.length;i++){
      let principalLastMonth;
      let interest;
      let principal;
      let total;
      
      if(i === 0){
        principalLastMonth = state.principal;
      }
      else{
        principalLastMonth = state.monthlyDebtRepayments[i-1].principalBalance;
      }
      
      interest = Math.ceil(principalLastMonth * debt_interest/100 / 12);
      principal = monthlyRepayments;
      total = principal + interest;
  
      state.monthlyDebtRepayments[i] = {
        interest,
        principal,
        total,
        principalBalance: principalLastMonth - principal,
      };
    }
  
    const lastMonthlyRepayment = state.monthlyDebtRepayments[state.monthlyDebtRepayments.length - 1];
    state.monthlyDebtRepayments[state.monthlyDebtRepayments.length - 1].principal = lastMonthlyRepayment.principal
      + lastMonthlyRepayment.principalBalance;
    
    if(income_bonusCount > 0){
      const bonusRepayments = Math.floor(state.principalBonus / (income_bonusCount * debt_period));
      
      for(let i=0;i<state.bonusDebtRepayments.length;i++){
        let principalLastBonus;
        let interest;
        let principal;
        let total;
        
        if(i === 0){
          principalLastBonus = state.principalBonus;
        }
        else{
          principalLastBonus = state.bonusDebtRepayments[i-1].principalBalance;
        }
        
        interest = Math.ceil(principalLastBonus * debt_interest/100 / income_bonusCount);
        principal = bonusRepayments;
        total = principal + interest;
  
        state.bonusDebtRepayments[i] = {
          interest,
          principal,
          total,
          principalBalance: principalLastBonus - principal,
        };
      }
  
      const lastBonusRepayment = state.bonusDebtRepayments[state.bonusDebtRepayments.length - 1];
      state.bonusDebtRepayments[state.bonusDebtRepayments.length - 1].principal = lastBonusRepayment.principal
        + lastBonusRepayment.principalBalance;
    }
  }
  else{
    let bonusRepayments = state.principalBonus;
    let monthlyRepayments = state.principal;
    let r;
    let Sum;
    let n;
    
    r = debt_interest / 100 / 12;
    Sum = monthlyRepayments;
    n = debt_period * 12;
    monthlyRepayments = r !== 0 ? Math.floor((r*Sum*(1+r)**n)/((1+r)**n - 1)) : Sum / n;
    
    for(let i=0;i<state.monthlyDebtRepayments.length;i++){
      let principalLastMonth;
      let interest;
      let principal;
      let total;
      
      if(i === 0){
        principalLastMonth = state.principal;
      }
      else{
        principalLastMonth = state.monthlyDebtRepayments[i-1].principalBalance;
      }
      
      interest = Math.ceil(principalLastMonth * debt_interest/100 / 12);
      principal = monthlyRepayments - interest;
      total = monthlyRepayments;
  
      state.monthlyDebtRepayments[i] = {
        interest,
        principal,
        total,
        principalBalance: principalLastMonth - principal,
      };
    }
  
    const lastMonthlyRepayment = state.monthlyDebtRepayments[state.monthlyDebtRepayments.length - 1];
    state.monthlyDebtRepayments[state.monthlyDebtRepayments.length - 1].principal = lastMonthlyRepayment.principal
      + lastMonthlyRepayment.principalBalance;
    
    if(income_bonusCount > 0){
      r = debt_interest / 100 / income_bonusCount;
      Sum = bonusRepayments;
      n = income_bonusCount * debt_period;
      bonusRepayments = r !== 0 ? Math.floor((r*Sum*(1+r)**n)/((1+r)**n - 1)) : Sum / n;
      
      for(let i=0;i<state.bonusDebtRepayments.length;i++){
        let principalLastBonus;
        let interest;
        let principal;
        let total;
        
        if(i === 0){
          principalLastBonus = state.principalBonus;
        }
        else{
          principalLastBonus = state.bonusDebtRepayments[i-1].principalBalance;
        }
        
        interest = Math.ceil(principalLastBonus * debt_interest/100 / income_bonusCount);
        principal = bonusRepayments - interest;
        total = bonusRepayments;
  
        state.bonusDebtRepayments[i] = {
          interest,
          principal,
          total,
          principalBalance: principalLastBonus - principal,
        };
      }
  
      const lastBonusRepayment = state.bonusDebtRepayments[state.bonusDebtRepayments.length - 1];
      state.bonusDebtRepayments[state.bonusDebtRepayments.length - 1].principal = lastBonusRepayment.principal
        + lastBonusRepayment.principalBalance;
    }
  }
};



const calculateDepreciationPlan: G.CalculateDepreciationPlanFunc = (params, state) => {
  const {start_fixedAsset, currency, fixedAssetUsefulLives} = params;
  
  if(typeof(fixedAssetUsefulLives) !== "number"){
    return;
  }
  
  // Calculate depreciation of fixed assets
  let depreciatingAssetValue = 0;
  const start_fixedAsset_yen = toYen(start_fixedAsset, currency);
  if(start_fixedAsset_yen < 12000000){
    depreciatingAssetValue = start_fixedAsset;
  }
  else if(start_fixedAsset_yen < 300000000){
    depreciatingAssetValue = start_fixedAsset / 2;
  }
  else{
    depreciatingAssetValue = fromYen(300000000, currency);
  }
  
  const depreciationRate = 1 / fixedAssetUsefulLives;
  state.depreciations = Array(fixedAssetUsefulLives)
    .fill(0)
    .map(_ => depreciatingAssetValue * depreciationRate) as number[];
};
//#endregion


/////////////// Main modules ///////////////
const process: G.ProcessFunc = (data, params, state, option) => {
  generateIncome_Bonus(data, params, state);
  generateIncome_Salary(data, params, state, option);
  generateSpending(data, params, state, option);
  
  // Cut off zero-valued cash flow
  data.data_flow = data.data_flow.filter(flow => flow.value !== 0);
  
  const date = new Date(`${state.cursor.current.y}-${state.cursor.current.m}-1 00:00:00`);
  date.setMonth(date.getMonth()+1);
  state.cursor.current.y = date.getFullYear();
  state.cursor.current.m = date.getMonth()+1;
  state.cursor.monthIndex = getElapsedMonths(state.cursor);
};

export const generateDemoData: G.GenerateDemoDataFunc = (params, t, reportCallback) => {
  return new Promise((resolve, reject) => {
    const options = {t};
    const {years, currency} = params;
    
    const months = years * 12;
    
    let timer: number | undefined;
    
    const itemsItemGroups = generateItems(t);
    const accountsAccountTypes = generateAccounts(t, currency);
    
    const result: G.GenerateResult = {
      master_items: itemsItemGroups.items,
      master_itemGroups: itemsItemGroups.itemGroups,
      master_accounts: accountsAccountTypes.accounts,
      master_accountTypes: accountsAccountTypes.accountTypes,
      data_flow: [],
      data_balance: [],
    };
    
    const state: G.GeneratorState = {
      cursor: {start: {y: 0, m: 0}, current: {y: 0, m: 0}, monthIndex: 0},
      paidLog: {},
      standardMonthlyRemuneration: {remuneration: 0, 4: 0, 5: 0, 6: 0},
      basePay: 0,
      riseRate: 0,
      bonusDetermined: 0,
      monthlyInhabitantTax: 0,
      bonusRepaymentCursor: 0,
      prevMonthPayDeducted: 0,
      depreciations: [],
      yearlyBudgets: {},
      principal: 0,
      principalBonus: 0,
      monthlyDebtRepayments: [],
      bonusDebtRepayments: [],
    };
    
    init(result, params, state);
    
    const tick = (): void => {
      process(result, params, state, options);
      reportCallback(Math.floor(state.cursor.monthIndex/months*100), state.cursor.current);
      
      if(state.cursor.monthIndex >= months){
        window.clearTimeout(timer);
        timer = undefined;
        return resolve(result);
      }
      else{
        timer = window.setTimeout(tick, 20);
      }
    };
    
    timer = window.setTimeout(tick, 20);
  });
};
