import {
  AcceptLicenseAction,
  CreateUserAccountActions,
  FailedCreateUserAccountAction,
  InitializeAccountAction,
} from "./account";
import {ChangeActivityAction, ToggleSidebarAction} from "./activity";
import {SaveDataActions} from "./save";
import {ChangeThemeAction} from "./theme";
import {pageAnalysisAction} from "./pageAnalysis";
import {pageInputAction} from "./pageInput";
import {pagePlanningAction} from "./pagePlanning";
import {RootState} from "../../../reducers";
import {
  DeleteUserAccountAction,
  LoadAccountFromDataAction,
  LogoutAction,
  SwitchAccountActions,
  ChangeLanguageAction,
} from "../../../core/actions/types";
import {Account} from "../../../core/reducers/App/state";

export * from "./account";
export * from "./activity";
export * from "./save";
export * from "./theme";
export * from "./pageAnalysis";
export * from "./pageInput";
export * from "./pagePlanning";
export * from "../../../core/actions/types/app/language";
export * from "../../../core/actions/types/app/account";


export type AppActions = (
  SwitchAccountActions<Account, RootState>
  | LogoutAction
  | FailedCreateUserAccountAction
  | CreateUserAccountActions
  | DeleteUserAccountAction
  | LoadAccountFromDataAction<RootState>
  | InitializeAccountAction
  | AcceptLicenseAction
  | ChangeActivityAction
  | ChangeLanguageAction
  | SaveDataActions
  | ChangeThemeAction
  | ToggleSidebarAction
  | pageAnalysisAction
  | pageInputAction
  | pagePlanningAction
);
