import {firebaseFunctions, firebaseAuth} from "../index";

const fbCloseAccount = firebaseFunctions.httpsCallable("closeAccount");

export async function closeAccount(){
  const result = await fbCloseAccount().catch(reason => {
    if(process.env.NODE_ENV === "development"){
      console.error("Error while executing Firebase function", reason);
    }
    
    return false;
  });
  
  if(!result){
    return;
  }
  
  const user = firebaseAuth.currentUser;
  if(!user){
    return;
  }
  
  return user.delete();
}
