import {connect} from "react-redux";
import {ThunkDispatch} from "redux-thunk";
import Welcome from "../Welcome";
import {
  acceptLicense,
  acceptPrivacyPolicy,
  changeActivity,
  initializeAccount,
  initializeFinancialAccount,
  initializeFinancialBalance,
  initializeFinancialItem,
  logout,
  saveAppData,
  saveRootData,
} from "../../../../actions/";
import * as S from "../../../../core/reducers/Finance/state";
import * as A from "../../../../actions";
import {getCurrencyInState} from "../../../../core/util/currency";
import {InitialBalance} from "../../../../core/actions/types/finance";
import {Account} from "../../../../core/reducers/App/state";
import {RootState} from "../../../../reducers";


const mapStateToProps = (rootReduxState: RootState) => {
  const {
    lang,
    account,
    acceptLicense: licenseAgreement,
    acceptPrivacyPolicy: privacyPolicyAgreement,
    activity,
  } = rootReduxState.App;
  const {
    master_items,
    master_itemGroups,
    master_accounts,
    master_accountTypes,
    currencies,
  } = rootReduxState.Finance;
  const currency =  getCurrencyInState(rootReduxState.Finance);
  
  if(account === null){
    throw new Error("Attempting to open Welcome page when not logged in");
  }
  
  return {
    lang,
    currency,
    account: (account as Account),
    activity,
    licenseAgreement,
    privacyPolicyAgreement,
    items: master_items,
    itemGroups: master_itemGroups,
    accounts: master_accounts,
    accountTypes: master_accountTypes,
    currencies,
  };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<RootState, undefined, A.RootActions>) => {
  return {
    initializeAccount: () => dispatch(initializeAccount()),
    initializeFinancialItem: (
      items: S.ItemMaster[],
      itemGroups: S.ItemGroupMaster[],
    ) => dispatch(initializeFinancialItem(items, itemGroups)),
    initializeFinancialAccount: (
      accounts: S.AccountMaster[],
      accountTypes: S.AccountTypeMaster[]) => dispatch(initializeFinancialAccount(accounts, accountTypes)),
    initializeFinancialBalance: (initialBalance: InitialBalance[]) => dispatch(initializeFinancialBalance(initialBalance)),
    agreeTerms: (accept: boolean) => {
      dispatch(acceptLicense(accept));
      dispatch(acceptPrivacyPolicy(accept));
      return Promise.resolve();
    },
    disagreeTerms: async () => {
      await dispatch(logout());
      dispatch(changeActivity("home"));
    },
    saveDataAll: async () => {
      await dispatch(saveRootData());
    },
    saveDataApp: async () => {
      await dispatch(saveAppData());
    },
  };
};

const WelcomeContainer = connect(mapStateToProps, mapDispatchToProps)(Welcome);

export default WelcomeContainer;
