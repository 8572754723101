import {getCurrentDate, getNowInCustomDate} from "../../../../core/util/finance";
import * as R from "../../../../core/reducers/Finance/state";
import {GeneratorParams} from "../../../../core/util/dataGenerator";
import {CURRENCY_JPY} from "../../../../core/util/constants";
import {getFXValue} from "../../../../core/util/currency";

type IGetParamsFunc = (currency: R.Currency) => GeneratorParams;

/**
 * "name": "Employee B", "age": "23", "sex": "Male", "annualIncome": "$ 36,000", "family": "Single", "money": "Repaying student loan",
 *
 * spending
 *  A new employee who just graduated university.
 *  Because he prioritizes repayment of student loan, he pays much attention not to spend too much money.
 *  Sometimes he wants to spend a bit much money but generally his financial condition stays healthy.
 *
 * income
 *  Yearly pay rise is not so good. Bonus amount is stable.
 *  He cannot work overtime much because he is a fresher, thus his living cost does not have much margin.
 *  Since he doesn't have much deposit, he prioritizes to save money first.
 */
const getParams: IGetParamsFunc = (currency) => {
  const now = getCurrentDate();
  const startY = now.getFullYear();
  const startM = 1;
  const nowCustomDate = getNowInCustomDate();
  const JPY = CURRENCY_JPY.id;
  
  return {
    years: 3,
    start_fund: getFXValue(1000000, JPY, currency.id, nowCustomDate),
    start_debt: getFXValue(2000000, JPY, currency.id, nowCustomDate),
    start_fixedAsset: getFXValue(0, JPY, currency.id, nowCustomDate),
    debt_interest: 0.5,
    debt_period: 20,
    repayment_method: "equalPayments", // ["equalPayments", "equalInstalments"]
    income_allowances: getFXValue(30000, JPY, currency.id, nowCustomDate),
    income_basePay: getFXValue(220000, JPY, currency.id, nowCustomDate),
    income_bonusAmount: getFXValue(600000, JPY, currency.id, nowCustomDate),
    income_bonusCount: 2,
    income_commuting: getFXValue(120000, JPY, currency.id, nowCustomDate),
    income_otherIncome: getFXValue(0, JPY, currency.id, nowCustomDate),
    income_overtime: 5,
    income_rfBonusAmount: 10,
    income_rfOtherIncome: 20,
    income_rfOvertime: 30,
    income_rfRiseRate: 20,
    income_riseRate: 2,
    income_workingHours: 8,
    income_overtimeRaiseRate: 25,
    income_pensionChargeRate: 9.15,
    income_healthInsuranceChargeRate: 4.4,
    income_employmentInsuranceChargeRate: 0.3,
    fixedAssetUsefulLives: 47,
    autoFixedAssetUsefulLives: true,
    income_feeding: 0,
    startY: startY,
    startM: startM,
    income_prevYearTotalIncome: null,
    income_prevYearTotalSocialInsurance: null,
    income_initialNormalizedPay: null,
    income_TotalIncome2YearsAgo: null,
    income_TotalSocialInsurance2YearsAgo: null,
    income_initialNormalizedPayLastTerm: null,
    spending_hobby: getFXValue(20000, JPY, currency.id, nowCustomDate),
    spending_beauty: getFXValue(4000, JPY, currency.id, nowCustomDate),
    spending_communication: getFXValue(13000, JPY, currency.id, nowCustomDate),
    spending_commuting: getFXValue(20000, JPY, currency.id, nowCustomDate),
    spending_education: getFXValue(5000, JPY, currency.id, nowCustomDate),
    spending_electricity: getFXValue(3000, JPY, currency.id, nowCustomDate),
    spending_food: getFXValue(45000, JPY, currency.id, nowCustomDate),
    spending_gasWater: getFXValue(5000, JPY, currency.id, nowCustomDate),
    spending_goods: getFXValue(20000, JPY, currency.id, nowCustomDate),
    spending_house: getFXValue(65000, JPY, currency.id, nowCustomDate),
    spending_medical: getFXValue(3000, JPY, currency.id, nowCustomDate),
    spending_unclassified: getFXValue(10000, JPY, currency.id, nowCustomDate),
    spending_trip_budget: getFXValue(200000, JPY, currency.id, nowCustomDate),
    spending_trip_count: 4,
    spending_rf: 30,
    spending_debtBonusChargeRate: 0,
    currency,
  };
};

export default getParams;
