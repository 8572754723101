import {AppState} from "../state";
import getInitialAppStateDev from "./dev";

const getInitialAppState = (): AppState => {
  if(process.env.REACT_APP_ENV === "development"){
    return getInitialAppStateDev();
  }
  
  return {
    unsaved: false,
    saving: false,
    version: 9, // Major version change means state tree format is changed. This is breaking.
    lang: "ja",
    account: null, // {provider: "localStorage", uid: "aaa@example.com", isEncrypted: true}
    switchingAccount: false,
    activity: "home",
    sidebarOpen: true,
    welcoming: true,
    acceptLicense: false, // when false, user should not use this app
    acceptPrivacyPolicy: false, // when false, user should not use this app
    theme: "dark",
  
    page_Input_flow_filters: [],
    page_Input_balance_filters: [],
    page_Input_flow_showButtons: true,
    page_Input_balance_showButtons: true,
    page_Input_balance_viewType: "date",
    scroll_flow: -1,
    scroll_balance: -1,
    scroll_balance_date: -1,
    analysis_menu: "monthlyPLStatement",
    analysis_monthlyPLStatement_ym: "",
    analysis_yearlyPLStatement_startYM: null,
    analysis_yearlyPLStatement_endYM: null,
    analysis_monthlyPLHistory_startYM: null,
    analysis_monthlyPLHistory_endYM: null,
    analysis_yearlyPLHistory_startY: null,
    analysis_yearlyPLHistory_endY: null,
    analysis_BSBreakdown_ym: "",
    analysis_monthlyBSHistory_startYM: null,
    analysis_monthlyBSHistory_endYM: null,
    analysis_monthlyBSHistory_view: "byAssetType",
    analysis_monthlyItemHistory_startYM: null,
    analysis_monthlyItemHistory_endYM: null,
    analysis_monthlyItemGroupHistory_startYM: null,
    analysis_monthlyItemGroupHistory_endYM: null,
    page_Planning_viewing: null, // null for index page, string for detail planning page.
    page_Planning_filters: [],
    page_Planning_activeFilter: -1,
    page_Planning_sortRules: [],
    page_Planning_rowsPerPage: 25,
    page_Planning_resultPage: 0,
    page_Planning_projectViewType: "simple",
  };
};

export default getInitialAppState;
