import {getCurrentDate, getNowInCustomDate} from "../../../../core/util/finance";
import * as R from "../../../../core/reducers/Finance/state";
import {GeneratorParams} from "../../../../core/util/dataGenerator";
import {CURRENCY_JPY} from "../../../../core/util/constants";
import {getFXValue} from "../../../../core/util/currency";

type IGetParamsFunc = (currency: R.Currency) => GeneratorParams;

/**
 * "name": "Employee A", "age": "32", "sex": "Female", "annualIncome": "$ 60,000", "family": "Single", "money": "Enjoy life",
 *
 * spending
 *  Office worker at an urban city. Traveling abroad 2 times in a year.
 *  Spending about $4,000 per a trip. Monthly income/spending is deficit.
 *  Bonus is balancing her account. Monthly food expenses is average but amusement expenses is bit larger.
 *  She has no wish to marry nor purchase house. She is satisfied living in rent house.
 *
 * income
 *  Large part of annual income comes from bonus. Base pay is not so good.
 *  Bonus amount is not stable. When bonus is small, it goes much deficit and need to borrow money from bank.
 */
const getParams: IGetParamsFunc = (currency) => {
  const now = getCurrentDate();
  const startY = now.getFullYear();
  const startM = 1;
  const nowCustomDate = getNowInCustomDate();
  const JPY = CURRENCY_JPY.id;
  
  return {
    years: 3,
    start_fund: getFXValue(1000000, JPY, currency.id, nowCustomDate),
    start_debt: getFXValue(0, JPY, currency.id, nowCustomDate),
    start_fixedAsset: getFXValue(0, JPY, currency.id, nowCustomDate),
    debt_interest: 0,
    debt_period: 35,
    repayment_method: "equalPayments", // ["equalPayments", "equalInstalments"]
    income_allowances: getFXValue(30000, JPY, currency.id, nowCustomDate),
    income_basePay: getFXValue(300000, JPY, currency.id, nowCustomDate),
    income_bonusAmount: getFXValue(2000000, JPY, currency.id, nowCustomDate),
    income_bonusCount: 2,
    income_commuting: getFXValue(120000, JPY, currency.id, nowCustomDate),
    income_otherIncome: getFXValue(0, JPY, currency.id, nowCustomDate),
    income_overtime: 10,
    income_rfBonusAmount: 70,
    income_rfOtherIncome: 20,
    income_rfOvertime: 15,
    income_rfRiseRate: 30,
    income_riseRate: 2,
    income_workingHours: 8,
    income_overtimeRaiseRate: 25,
    income_pensionChargeRate: 9.15,
    income_healthInsuranceChargeRate: 4.4,
    income_employmentInsuranceChargeRate: 0.3,
    fixedAssetUsefulLives: 47,
    autoFixedAssetUsefulLives: true,
    income_feeding: 0,
    startY: startY,
    startM: startM,
    income_prevYearTotalIncome: null,
    income_prevYearTotalSocialInsurance: null,
    income_initialNormalizedPay: null,
    income_TotalIncome2YearsAgo: null,
    income_TotalSocialInsurance2YearsAgo: null,
    income_initialNormalizedPayLastTerm: null,
    spending_hobby: getFXValue(40000, JPY, currency.id, nowCustomDate),
    spending_beauty: getFXValue(50000, JPY, currency.id, nowCustomDate),
    spending_communication: getFXValue(13000, JPY, currency.id, nowCustomDate),
    spending_commuting: getFXValue(10000, JPY, currency.id, nowCustomDate),
    spending_education: getFXValue(2000, JPY, currency.id, nowCustomDate),
    spending_electricity: getFXValue(7000, JPY, currency.id, nowCustomDate),
    spending_food: getFXValue(70000, JPY, currency.id, nowCustomDate),
    spending_gasWater: getFXValue(5000, JPY, currency.id, nowCustomDate),
    spending_goods: getFXValue(35000, JPY, currency.id, nowCustomDate),
    spending_house: getFXValue(100000, JPY, currency.id, nowCustomDate),
    spending_medical: getFXValue(10000, JPY, currency.id, nowCustomDate),
    spending_unclassified: getFXValue(10000, JPY, currency.id, nowCustomDate),
    spending_trip_budget: getFXValue(800000, JPY, currency.id, nowCustomDate),
    spending_trip_count: 2,
    spending_rf: 30,
    spending_debtBonusChargeRate: 20,
    currency,
  };
};

export default getParams;
