import {Action} from "redux";
import {ThunkAction} from "redux-thunk";
import * as types from "../../types/constant";
import * as A from "../../types/app/save";
import {saveState} from "../../../util/provider";
import {
  FAILED_SAVE_FINANCE_DATA,
  SAVE_FINANCE_DATA,
  SAVING_FINANCE_DATA,
} from "../../../core/actions/types/constant";
import {RootState} from "../../../reducers";
import {SaveFinanceDataActions} from "../../../core/actions/types/finance/save";

type TAsyncAction<U extends Action, R = Promise<void>> = ThunkAction<R, RootState, undefined, U>;

export const saveAppData = (): TAsyncAction<A.SaveAppDataActions> => {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      const state = getState();
      if(!state.App.unsaved){
        return resolve();
      }
      const {account} = state.App;
      
      if(!account || !account.provider || !account.uid){
        return reject("No account");
      }
    
      dispatch({
        type: types.SAVING_APP_DATA,
      });
    
      const {provider, uid} = account;
      const uidAsPassword = uid || "";
    
      return saveState("App", state, provider, uid, uidAsPassword)
        .then(() => {
          dispatch({
            type: types.SAVE_APP_DATA,
          });
        
          resolve();
        })
        .catch(reason => {
          dispatch({
            type: types.FAILED_SAVE_APP_DATA,
          });
        
          reject(reason);
        });
    });
  };
};

export const saveFinanceData = (): TAsyncAction<SaveFinanceDataActions> => {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      const state = getState();
      if(!state.Finance.unsaved){
        return resolve();
      }
  
      const {account} = state.App;
      if(!account || !account.provider || !account.uid){
        return reject("No account");
      }
      
      dispatch({
        type: SAVING_FINANCE_DATA,
      });
  
      const {provider, uid} = account;
      const uidAsPassword = uid || "";
      
      return saveState("Finance", state, provider, uid, uidAsPassword)
        .then(() => {
          dispatch({
            type: SAVE_FINANCE_DATA,
          });
          
          resolve();
        })
        .catch(reason => {
          dispatch({
            type: FAILED_SAVE_FINANCE_DATA,
          });
          
          reject(reason);
        });
    });
  };
};

export const saveRootData  = (): TAsyncAction<A.SaveDataActions> => {
  return async (dispatch, getState) => {
    const saveAppTask = saveAppData()(dispatch, getState, undefined);
    const saveFinTask = saveFinanceData()(dispatch, getState, undefined);
    
    await saveAppTask;
    await saveFinTask;
  };
};
