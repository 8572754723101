import {Reducer} from "redux";
import {AppState, Lang, PlanSortRule} from "./state";
import * as types from "../../actions/types/constant";
import * as T from "../../actions/types";
import getInitialAppState from "./initialState";
import {
  CHANGE_LANGUAGE,
  DELETE_PLANNING_PROJECT,
  DELETE_PLANNING_PROJECTS,
  DELETE_USER_ACCOUNT,
  FAILED_SWITCH_USER_ACCOUNT,
  LOAD_ENTIRE_STATE,
  LOGOUT,
  REMOVE_ACCOUNT,
  REMOVE_ITEM,
  SWITCH_ACCOUNT,
  SWITCHING_ACCOUNT,
} from "../../core/actions/types/constant";
import {
  IRemoveAccountsAction,
  IRemoveItemsAction,
  DeletePlanningProjectAction,
  DeletePlanningProjectsAction,
  ILoadAccountFromDataPayload,
  ISwitchAccountPayload,
} from "../../core/actions/types";
import {RootState} from "../";

type R = Reducer<AppState, T.RootActions>;

const appReducer: R = (state = getInitialAppState(), action) => {
  switch(action.type){
    case types.SAVING_APP_DATA: {
      return {...state, saving: true};
    }
    case types.FAILED_SAVE_APP_DATA: {
      return {...state, saving: false};
    }
    case types.SAVE_APP_DATA: {
      return {...state, saving: false, unsaved: false};
    }
    case CHANGE_LANGUAGE: {
      const {lang} = action.payload as T.IChangeLanguagePayload<Lang>;
      if(lang && state.lang !== lang){
        return {
          ...state,
          unsaved: true,
          lang,
        };
      }
      
      return state;
    }
    case types.CHANGE_ACTIVITY: {
      const {activity} = action.payload as T.ChangeActivityPayload;
      
      if(state.activity === activity){
        return state;
      }
      
      return {...state, activity};
    }
    case types.FAILED_CREATE_USER_ACCOUNT: {
      break;
    }
    case LOAD_ENTIRE_STATE: {
      const {state: newState} = action.payload as ILoadAccountFromDataPayload<RootState>;
      const {lang} = state;
  
      const App = newState.App as AppState;
      
      if(App.account && App.activity === "home"){
        if(App.welcoming && !(App.account && App.account.demo)){
          return {
            ...App,
            lang,
            activity: "welcome",
          };
        }
        else{
          return {
            ...App,
            lang,
            activity: "dashboard",
          };
        }
      }
      
      return {...App, lang};
    }
    case SWITCHING_ACCOUNT: {
      return {...state, switchingAccount: true};
    }
    case SWITCH_ACCOUNT: {
      const {state: stateInAction, account} = action.payload as ISwitchAccountPayload<RootState>;
  
      const App = stateInAction.App as AppState;
      const AppProps = {
        account: {...account},
        switchingAccount: false,
        activity: App.welcoming ? "welcome" : "dashboard",
        demo: undefined,
      };
      
      return {
        ...App,
        ...AppProps,
      };
    }
    case FAILED_SWITCH_USER_ACCOUNT: {
      return {...state, switchingAccount: false};
    }
    case LOGOUT: {
      const App = getInitialAppState();
      
      return {...App, activity: "home"};
    }
    case DELETE_USER_ACCOUNT: {
      return getInitialAppState();
    }
    case types.TOGGLE_SIDEBAR: {
      return {...state, sidebarOpen: !state.sidebarOpen};
    }
    case types.INITIALIZE_ACCOUNT: {
      return {
        ...state,
        welcoming: false,
        activity: "dashboard",
        unsaved: true,
      };
    }
    case types.ACCEPT_LICENSE: {
      const {accept} = action.payload as T.AcceptLicensePayload;
      
      if(state.acceptLicense !== accept){
        const newState = {...state};
        newState.acceptLicense = accept;
        newState.unsaved = true;
        
        if(accept && newState.welcoming){
          newState.activity = "welcome";
        }
        
        return newState;
      }
      
      return state;
    }
    case types.ACCEPT_PRIVACY_POLICY: {
      const {accept} = action.payload as T.AcceptLicensePayload;
    
      if(state.acceptPrivacyPolicy !== accept){
        const newState = {...state};
        newState.acceptPrivacyPolicy = accept;
        newState.unsaved = true;
        
        if(accept && newState.welcoming){
          newState.activity = "welcome";
        }
  
        return newState;
      }
    
      return state;
    }
    case types.CHANGE_THEME: {
      const {theme} = action.payload;
    
      return {...state, theme, unsaved: true};
    }
    case types.CHANGE_ANALYSIS_DATE: {
      const {
        analysisType,
        startY,
        startM,
        endY,
        endM,
      } = action.payload as T.IChangeAnalysisDatePayload;
    
      switch(analysisType){
        case "monthlyPLStatement": {
          const newState = {...state};
        
          if(startY && startM){
            newState.analysis_monthlyPLStatement_ym = `${startY}/${startM.toString().padStart(2, "0")}`;
          }
          else if(startY === null && startM === null){
            newState.analysis_monthlyPLStatement_ym = "";
          }
        
          newState.unsaved = true;
          return newState;
        }
        case "monthlyBSStatement": {
          const newState = {...state};
        
          if(startY && startM){
            newState.analysis_BSBreakdown_ym = `${startY}/${startM.toString().padStart(2, "0")}`;
          }
          else if(startY === null && startM === null){
            newState.analysis_BSBreakdown_ym = "";
          }
        
          newState.unsaved = true;
          return newState;
        }
        case "yearlyPLStatement": {
          const newState = {...state};
        
          newState.analysis_yearlyPLStatement_startYM = (()=>{
            if(typeof(startY) === "number" && typeof(startM) === "number"){
              return startY*100 + startM;
            }
            else{
              return null;
            }
          })();
        
          newState.analysis_yearlyPLStatement_endYM = (()=>{
            if(typeof(endY) === "number" && typeof(endM) === "number"){
              return (endY*100 + endM);
            }
            else{
              return null;
            }
          })();
        
          newState.unsaved = true;
          return newState;
        }
        case "yearlyPLHistory": {
          const newState = {...state};
        
          newState.analysis_yearlyPLHistory_startY = startY !== null ? `${startY}` : null;
          newState.analysis_yearlyPLHistory_endY = endY !== null ? `${endY}` : null;
        
          newState.unsaved = true;
          return newState;
        }
        case "monthlyPLHistory": {
          const newState = {...state};
        
          if(startY && startM){
            newState.analysis_monthlyPLHistory_startYM = `${startY}/${startM.toString().padStart(2, "0")}`;
          }
          else if(startY === null && startM === null){
            newState.analysis_monthlyPLHistory_startYM = "";
          }
        
          if(endY && endM){
            newState.analysis_monthlyPLHistory_endYM = `${endY}/${endM.toString().padStart(2, "0")}`;
          }
          else if(endY === null && endM === null){
            newState.analysis_monthlyPLHistory_endYM = "";
          }
        
          newState.unsaved = true;
          return newState;
        }
        case "monthlyBSHistory": {
          const newState = {...state};
        
          if(startY && startM){
            newState.analysis_monthlyBSHistory_startYM = `${startY}/${startM.toString().padStart(2, "0")}`;
          }
          else if(startY === null && startM === null){
            newState.analysis_monthlyBSHistory_startYM = "";
          }
        
          if(endY && endM){
            newState.analysis_monthlyBSHistory_endYM = `${endY}/${endM.toString().padStart(2, "0")}`;
          }
          else if(endY === null && endM === null){
            newState.analysis_monthlyBSHistory_endYM = "";
          }
        
          newState.unsaved = true;
          return newState;
        }
        case "monthlyItemHistory": {
          const newState = {...state};
        
          if(startY && startM){
            newState.analysis_monthlyItemHistory_startYM = `${startY}/${startM.toString().padStart(2, "0")}`;
          }
          else if(startY === null && startM === null){
            newState.analysis_monthlyItemHistory_startYM = "";
          }
        
          if(endY && endM){
            newState.analysis_monthlyItemHistory_endYM = `${endY}/${endM.toString().padStart(2, "0")}`;
          }
          else if(endY === null && endM === null){
            newState.analysis_monthlyItemHistory_endYM = "";
          }
        
          newState.unsaved = true;
          return newState;
        }
        case "monthlyItemGroupHistory": {
          const newState = {...state};
        
          if(startY && startM){
            newState.analysis_monthlyItemGroupHistory_startYM = `${startY}/${startM.toString().padStart(2, "0")}`;
          }
          else if(startY === null && startM === null){
            newState.analysis_monthlyItemGroupHistory_startYM = "";
          }
        
          if(endY && endM){
            newState.analysis_monthlyItemGroupHistory_endYM = `${endY}/${endM.toString().padStart(2, "0")}`;
          }
          else if(endY === null && endM === null){
            newState.analysis_monthlyItemGroupHistory_endYM = "";
          }
        
          newState.unsaved = true;
          return newState;
        }
        default:
          break;
      }
    
      return state;
    }
    case types.CHANGE_ANALYSIS_BS_HISTORY_VIEW: {
      const {view} = action.payload as T.IChangeAnalysisBSHistoryViewPayload;
      return {
        ...state,
        analysis_monthlyBSHistory_view: view,
      };
    }
    case types.UPDATE_FINANCE_FILTER_DATE: {
      const {filter} = (action as T.IUpdateFilterDateAction).payload;
    
      const filters = state.page_Input_flow_filters.filter(f => f.column !== "date");
      if(filter){
        filters.push(filter);
      }
    
      return {...state, page_Input_flow_filters: filters, unsaved: true};
    }
    case types.UPDATE_FINANCE_BALANCE_FILTER_DATE: {
      const {filter} = (action as T.IUpdateBalanceFilterDateAction).payload;
    
      const filters = state.page_Input_balance_filters.filter(f => f.column !== "date");
      if(filter){
        filters.push(filter);
      }
    
      return {...state, page_Input_balance_filters: filters, unsaved: true};
    }
    case types.UPDATE_FINANCE_FILTER_ITEM: {
      const {rule, values} = (action as T.IUpdateFilterItemAction).payload;
      const filters = state.page_Input_flow_filters.filter(f => f.column !== "item");
    
      if(rule !== "noFilter"){
        const filter = {
          column: "item" as "item",
          rule,
          values,
        };
        filters.push(filter);
      }
    
      return {...state, page_Input_flow_filters: filters, unsaved: true};
    }
    case types.UPDATE_FINANCE_FILTER_ITEMGROUP: {
      const {rule, values} = (action as T.IUpdateFilterItemGroupAction).payload;
      const filters = state.page_Input_flow_filters.filter(f => f.column !== "itemGroup");
    
      if(rule !== "noFilter"){
        const filter = {
          column: "itemGroup" as "itemGroup",
          rule,
          values,
        };
        filters.push(filter);
      }
    
      return {...state, page_Input_flow_filters: filters, unsaved: true};
    }
    case types.UPDATE_FINANCE_FILTER_ACCOUNT: {
      const {rule, values} = (action as T.IUpdateFilterAccountAction).payload;
      const filters = state.page_Input_flow_filters.filter(f => f.column !== "account");
    
      if(rule !== "noFilter"){
        const filter = {
          column: "account" as "account",
          rule,
          values,
        };
        filters.push(filter);
      }
    
      return {...state, page_Input_flow_filters: filters, unsaved: true};
    }
    case types.UPDATE_FINANCE_BALANCE_FILTER_ACCOUNT: {
      const {rule, values} = (action as T.IUpdateBalanceFilterAccountAction).payload;
      const filters = state.page_Input_balance_filters.filter(f => f.column !== "account");
    
      if(rule !== "noFilter"){
        const filter = {
          column: "account" as "account",
          rule,
          values,
        };
        filters.push(filter);
      }
    
      return {...state, page_Input_balance_filters: filters, unsaved: true};
    }
    case types.UPDATE_FINANCE_FILTER_TAG: {
      const {rule, values} = (action as T.IUpdateFilterTagAction).payload;
      const filters = state.page_Input_flow_filters.filter(f => f.column !== "planTags");
    
      if(rule !== "noFilter"){
        const filter = {
          column: "planTags" as "planTags",
          rule,
          values,
        };
        filters.push(filter);
      }
    
      return {...state, page_Input_flow_filters: filters, unsaved: true};
    }
    case types.UPDATE_FINANCE_FILTER_VALUE: {
      const {rule, values} = (action as T.IUpdateFilterValueAction).payload;
      const filters = state.page_Input_flow_filters.filter(f => f.column !== "value");
    
      if(rule){
        const filter = {
          column: "value" as "value",
          rule,
          values,
        };
        filters.push(filter);
      }
    
      return {...state, page_Input_flow_filters: filters, unsaved: true};
    }
    case types.UPDATE_FINANCE_BALANCE_VIEW_TYPE: {
      const {viewType} = (action as T.IUpdateBalanceViewTypeAction).payload;
      return {...state, page_Input_balance_viewType: viewType, unsaved: true};
    }
    case types.CHANGE_FINANCE_FLOW_SHOW_BUTTON: {
      const page_Input_flow_showButtons = !state.page_Input_flow_showButtons;
      return {...state, page_Input_flow_showButtons, unsaved: true};
    }
    case types.CHANGE_FINANCE_BALANCE_SHOW_BUTTON: {
      const page_Input_balance_showButtons = !state.page_Input_balance_showButtons;
      return {...state, page_Input_balance_showButtons, unsaved: true};
    }
    case types.UPDATE_SCROLL_POSITION_FLOW: {
      const {row} = (action as T.IUpdateScrollFlowAction).payload;
      return {...state, scroll_flow: row};
    }
    case types.UPDATE_SCROLL_POSITION_BALANCE: {
      const {row} = (action as T.IUpdateScrollBalanceAction).payload;
      return {...state, scroll_balance: row};
    }
    case types.UPDATE_SCROLL_POSITION_BALANCE_FOR_DATE: {
      const {row} = (action as T.IUpdateScrollBalanceDateAction).payload;
      return {...state, scroll_balance_date: row};
    }
    case REMOVE_ITEM: {
      const {items} = (action as IRemoveItemsAction).payload;
      let {page_Input_flow_filters} = state;
    
      page_Input_flow_filters = page_Input_flow_filters.map(f => {
        if(f.column !== "item"){
          return f;
        }
      
        const values = f.values.filter((v: number) => !items.some(item => item.id === v));
        return {...f, values};
      });
    
      return {...state, page_Input_flow_filters, unsaved: true};
    }
    case REMOVE_ACCOUNT: {
      const {accounts} = (action as IRemoveAccountsAction).payload;
      let {
        page_Input_flow_filters,
        page_Input_balance_filters,
      } = state;
    
      page_Input_flow_filters = page_Input_flow_filters.map(f => {
        if(f.column !== "account"){
          return f;
        }
      
        const values = f.values.filter((v: number) => !accounts.some(account => account.id === v));
        return {...f, values};
      });
    
      page_Input_balance_filters = page_Input_balance_filters.map(f => {
        if(f.column !== "account"){
          return f;
        }
      
        const values = f.values.filter((v: number) => !accounts.some(account => account.id === v));
        return {...f, values};
      });
    
      return {
        ...state,
        page_Input_flow_filters,
        page_Input_balance_filters,
        unsaved: true,
      };
    }
    case types.CHANGE_PLANNING_PROJECT: {
      const {id} = (action as T.GotoPlanningProjectAction).payload;
      const page_Planning_viewing = `plan/${id}`;
    
      return {
        ...state,
        page_Planning_viewing,
      };
    }
    case types.CHANGE_PLANNING_PAGE: {
      const {page} = (action as T.ChangePlanningPageAction).payload;
    
      return {
        ...state,
        page_Planning_viewing: page,
      };
    }
    case types.SWITCH_PLANNING_FILTER: {
      const {id} = (action as T.SwitchPlanningFilterAction).payload;
      return {
        ...state,
        page_Planning_activeFilter: id,
      };
    }
    case types.ADD_PLANNING_FILTER: {
      const {filter} = (action as T.AddPlanningFilterAction).payload;
      let {page_Planning_filters: filters} = state;
      filters = [...filters];
    
      filter.id = filters.length > 0 ? Math.max(...filters.map(f => f.id)) + 1 : 0;
      filter.order = filters.length > 0 ? Math.max(...filters.map(f => f.order)) + 1 : 0;
    
      filters.push(filter);
      filters = filters.map((f, i) => {
        return {
          ...f,
          order: i,
        };
      });
    
      return {
        ...state,
        page_Planning_filters: filters,
      };
    }
    case types.CHANGE_PLANNING_FILTER_ORDER: {
      const orders = (action as T.ChangePlanningFilterOrderAction).payload.orders;
      let {page_Planning_filters: filters} = state;
    
      if(orders.length !== filters.length){
        return state;
      }
    
      filters = [...filters];
    
      const ordersForId = orders.reduce((acc: {[id: number]: number}, id, i) => {
        acc[id] = i;
        return acc;
      }, {}) as {[id: number]: number};
    
      for(let i=0;i<filters.length;i++){
        const {id} = filters[i];
        const order = ordersForId[id];
        if(typeof(order) !== "number"){
          return state;
        }
      
        filters[i] = {
          ...filters[i],
          order,
        };
      }
    
      return {
        ...state,
        page_Planning_filters: filters,
      };
    }
    case types.CHANGE_PLANNING_FILTER_LABEL: {
      const {id, label} = (action as T.ChangePlanningFilterLabelAction).payload;
      let {page_Planning_filters} = state;
    
      page_Planning_filters = page_Planning_filters.map((f, i) => {
        if(f.id === id){
          return {...f, label};
        }
      
        return f;
      });
    
      return {
        ...state,
        page_Planning_filters,
      };
    }
    case types.CHANGE_PLANNING_FILTER_RULES: {
      const {id, rules} = (action as T.ChangePlanningFilterRulesAction).payload;
      let {page_Planning_filters} = state;
    
      page_Planning_filters = page_Planning_filters.map(f => {
        if(f.id === id){
          return {...f, filterRules: rules};
        }
      
        return f;
      });
    
      return {
        ...state,
        page_Planning_filters,
      };
    }
    case types.REMOVE_PLANNING_FILTER: {
      const {idList} = (action as T.RemovePlanningFilterAction).payload;
      let {page_Planning_filters} = state;
    
      // Do not remove 'All' filter.
      if(idList.includes(-1)){
        return state;
      }
    
      page_Planning_filters = page_Planning_filters.filter(f => {
        return !idList.includes(f.id) && !f.protect;
      });
    
      return {
        ...state,
        page_Planning_filters,
      };
    }
    case types.UPDATE_PLANNING_SORT_RULES: {
      const {rules} = (action as T.UpdatePlanningSortRulesAction).payload;
      let page_Planning_sortRules: PlanSortRule[] = [];
    
      if(Array.isArray(rules) && rules.length > 0){
        page_Planning_sortRules = [...rules];
      }
    
      return {
        ...state,
        page_Planning_sortRules,
      };
    }
    case types.UPDATE_PLANNING_ROWS_PER_PAGE: {
      let {rows_per_page} = (action as T.UpdatePlanningRowsPerPageAction).payload;
      if(typeof(rows_per_page) !== "number" || rows_per_page <= 0){
        rows_per_page = 10;
      }
    
      return {
        ...state,
        page_Planning_rowsPerPage: rows_per_page,
      };
    }
    case types.CHANGE_PLANNING_PROJECT_VIEWTYPE: {
      const {viewType} = (action as T.ChangePlanningProjectViewTypeAction).payload;
      return {
        ...state,
        page_Planning_projectViewType: viewType,
      };
    }
    case types.UPDATE_PLANNING_RESULT_PAGE: {
      const {page} = (action as T.UpdatePlanningResultPageAction).payload;
      return {
        ...state,
        page_Planning_resultPage: page,
      };
    }
    case DELETE_PLANNING_PROJECT: {
      const {id} = (action as DeletePlanningProjectAction).payload;
      const {page_Input_flow_filters} = state;
    
      const newFilters = page_Input_flow_filters.map(f => {
        if(f.column !== "planTags"){
          return f;
        }
      
        const values = f.values.filter((v: string) => v !== id);
        return {...f, values};
      });
    
      return {
        ...state,
        page_Input_flow_filters: newFilters,
      };
    }
    case DELETE_PLANNING_PROJECTS: {
      const {idList} = (action as DeletePlanningProjectsAction).payload;
      let {page_Input_flow_filters} = state;
    
      idList.forEach(id => {
        page_Input_flow_filters = page_Input_flow_filters.map(f => {
          if(f.column !== "planTags"){
            return f;
          }
        
          f.values = f.values.filter((v: string) => v !== id);
          return f;
        });
      });
    
      return {
        ...state,
        page_Input_flow_filters,
      };
    }
    
    default:
      break;
  }
  
  return state;
};

export default appReducer;
