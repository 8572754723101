import React, {Fragment} from "react";
import {withTranslation} from "react-i18next";
import formatMoney from "accounting-js/lib/formatMoney";
import {
  Area,
  AreaChart,
  Bar,
  CartesianGrid,
  ComposedChart,
  Line,
  LineChart,
  ReferenceLine,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";

import withStyles, {ClassNameMap, StyleRulesCallback} from "@material-ui/core/styles/withStyles";
import * as S from "../../../core/reducers/Finance/state";
import i18next from "i18next";
import {ITEM__START_AMOUNT, ITEM__UNACCOUNTED} from "../../../core/util/constants";
import {Lang} from "../../../reducers/App/state";
import {CustomTheme} from "../../../util/theme";
import {Omit, TReservedHocProps} from "../../../util/type";


const dashboardBaseWidth = 386;
const dashboardBaseHeight = 320;
const donutVoidColor = "#cfd8dc";
const donutOccupyColor = "#689f38";
const donutOccupyWarningColor = "#fb8c00";
const donutOccupyDangerColor = "#f44336";


interface IDashboardProps {
  classes: ClassNameMap;
  theme: CustomTheme;
  i18n: i18next.i18n;
  lang: Lang;
  bsSummary: {
    totalAsset: number,
    liability: number,
    netAsset: number,
  };
  monthlyBS?: Array<{
    period: Date,
    totalAsset: number|undefined,
    liability: number|undefined,
    netAsset: number|undefined,
  }>;
  monthlyPL?: Array<{
    period: number;
    profit: number|null;
    loss: number|null;
    pl: number|null;
  }>;
  monthlyItem?: Array<{
    period: number;
    data: {
      [itemId: number]: number;
    };
  }>;
  monthlyGroupPl?: Array<{
    period: number;
    data: {
      [itemId: number]: number;
    };
  }>;
  items: S.ItemMaster[];
  itemGroups: S.ItemGroupMaster[];
  changeAnalysisMenu: (menu: string) => void;
  currency: S.Currency;
}

interface IDashboardState {
  
}

interface IDashboardOption {
  format: (money: string|number|React.ReactText[], min?: boolean) => string;
}

class Dashboard extends React.Component<IDashboardProps, IDashboardState> {
  constructor(props: IDashboardProps){
    super(props);
    
    this.format = this.format.bind(this);
  }
  
  public render(){
    const {classes, i18n, lang} = this.props;
    const t = i18n.getFixedT(lang, "Home");
    const {format} = this;
    
    return (
      <div className={classes.root}>
        <div className={classes.dashboardTitle}>
          {t<string>("dashboard.asset")}
        </div>
        <div className={classes.dashboard}>
          {Dashboard.getMonthlyBSChart(this.props, null, {format})}
          {Dashboard.getMonthlyBS(this.props, null, {format})}
          {Dashboard.getBalanceSheet(this.props, null, {format})}
        </div>
        <div className={classes.dashboardTitle}>
          {t<string>("dashboard.profitLoss")}
        </div>
        <div className={classes.dashboard}>
          {Dashboard.getMonthlyPLChart(this.props, null, {format})}
        </div>
        <div className={classes.dashboard}>
          {Dashboard.getMonthlyIncomeAndSpending(this.props, null, {format})}
          {Dashboard.getMonthlyAccumulatedPLChart(this.props, null, {format})}
          {Dashboard.getYearlyPLAccumulated(this.props, null, {format})}
        </div>
        <div className={classes.dashboardTitle}>
          {t<string>("dashboard.itemPL")}
        </div>
        <div className={classes.dashboard}>
          {Dashboard.getMonthlyGroupPL(this.props, null, {format})}
          {Dashboard.getMonthlyItemSummary(this.props, null, {format})}
        </div>
      </div>
    );
  }
  
  public static getBalanceSheet(props: IDashboardProps, state: IDashboardState|null, option: IDashboardOption){
    const {i18n, lang, theme, classes, bsSummary, changeAnalysisMenu} = props;
    const t = i18n.getFixedT(lang, "Home");
    const {format} = option;
    const {totalAsset, liability, netAsset} = bsSummary;
    const capitalRatio = totalAsset > 0 ? Math.floor(netAsset / totalAsset * 1000) / 10 : 0;
  
    const okColor = (theme.custom && theme.custom.positive) || "rgb(46,125,50)";
    const dangerColor = (theme.custom && theme.custom.negative) || "#ba000d";
    const totalAssetColor = (theme.custom && theme.custom.incomeColor) || "#3f51b5";
    const liabilityColor = (theme.custom && theme.custom.spendColor) || "#ba000d";
    const netAssetColor = (theme.custom && theme.custom.netIncomeColor) || "#2e7d32";
    
    let ratio;
    let evaluation;
    if(capitalRatio >= 95){
      ratio = <span style={{color: okColor}}>{t<string>("dashboard.assessment.yourCapitalRation", {ratio: capitalRatio})} </span>;
      evaluation = t<string>("dashboard.assessment.100");
    }
    else if(capitalRatio > 70){
      ratio = <span>{t<string>("dashboard.assessment.yourCapitalRation", {ratio: capitalRatio})} </span>;
      evaluation = t<string>("dashboard.assessment.70");
    }
    else if(capitalRatio > 30){
      ratio = <span>{t<string>("dashboard.assessment.yourCapitalRation", {ratio: capitalRatio})} </span>;
      evaluation = t<string>("dashboard.assessment.30");
    }
    else if(capitalRatio > 0){
      ratio = <span style={{color: dangerColor}}>{t<string>("dashboard.assessment.yourCapitalRation", {ratio: capitalRatio})} </span>;
      evaluation = t<string>("dashboard.assessment.0");
    }
    else{
      ratio = <span style={{color: dangerColor}}>{t<string>("dashboard.assessment.yourCapitalRation", {ratio: capitalRatio})} </span>;
      evaluation = t<string>("dashboard.assessment.minus");
    }
    
    const comment = (
      <Fragment>
        {ratio}
        {evaluation}
      </Fragment>
    );
    
    return (
      <div className={classes.dashboardItemContainer}>
        <div className={classes.dashboardItem}>
          <div
            style={{height: 50, textAlign: "center", cursor: "pointer"}}
            onClick={() => changeAnalysisMenu("monthlyBSBreakdown")}
          >
            {t<string>("dashboard.bs")}
          </div>
          <div style={{display: "flex", flexDirection: "row", width: "100%", height: "calc(100% - 50px - 60px)"}}>
            <div style={{width: "calc(50% - 8px)", position: "relative", display: "flex", flexDirection: "column"}}>
              <div style={{height: 20, lineHeight: "20px", color: totalAssetColor, textAlign: "center"}}>
                {t<string>("dashboard.totalAsset")}
              </div>
              <div style={{flex: "auto", display: "flex", flexDirection: "row"}}>
                <div
                  style={{
                    alignContent: "center",
                    fontSize: theme.typography.fontSize * 1.7,
                    justifyContent: "center",
                    width: "100%",
                    textAlign: "center",
                    alignSelf: "center",
                    fontWeight: "bold",
                    color: totalAssetColor,
                  }}
                >
                  {format(totalAsset || 0)}
                </div>
              </div>
            </div>
            <div style={{width: 0, borderLeft: "1px solid rgba(33,33,33,.2)", marginLeft: 8, marginRight: 8, boxSizing: "content-box"}}/>
            <div style={{display: "flex", flexDirection: "column", width: "calc(50% - 8px)"}}>
              <div style={{height: "50%"}}>
                <div style={{height: 20, lineHeight: "20px", color: liabilityColor, textAlign: "center"}}>
                  {t<string>("dashboard.liability")}
                </div>
                <div style={{flex: "auto", display: "flex", flexDirection: "row", height: "calc(100% - 20px)"}}>
                  <div
                    style={{
                      alignContent: "center",
                      fontSize: theme.typography.fontSize * 1.7,
                      justifyContent: "center",
                      width: "100%",
                      textAlign: "center",
                      alignSelf: "center",
                      fontWeight: "bold",
                      color: liabilityColor,
                    }}
                  >
                    {format(liability || 0)}
                  </div>
                </div>
              </div>
              <div style={{height: "50%"}}>
                <div style={{height: 20, lineHeight: "20px", color: netAssetColor, textAlign: "center"}}>
                  {t<string>("dashboard.netAsset")}
                </div>
                <div style={{flex: "auto", display: "flex", flexDirection: "row", height: "calc(100% - 20px)"}}>
                  <div
                    style={{
                      alignContent: "center",
                      fontSize: theme.typography.fontSize * 1.7,
                      justifyContent: "center",
                      width: "100%",
                      textAlign: "center",
                      alignSelf: "center",
                      fontWeight: "bold",
                      color: netAssetColor,
                    }}
                  >
                    {format(netAsset || 0)}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div style={{height: 60, fontSize: "0.8rem", paddingTop: 8}}>
            {comment}
          </div>
        </div>
      </div>
    );
  }
  
  public static getDonutChart(props: IDashboardProps, state: IDashboardState|null, option: IDashboardOption){
    const {i18n, lang, classes, bsSummary} = props;
    const t = i18n.getFixedT(lang, "Home");
    const {totalAsset, netAsset} = bsSummary;
  
    let capitalRatio: number|string = totalAsset > 0 ? Math.floor(netAsset / totalAsset * 1000) / 10 : 0;
    const rotate = `${capitalRatio / 100 * 360}deg`;
    
    if(typeof(totalAsset) !== "number" || totalAsset === 0 || isNaN(totalAsset)){
      capitalRatio = "-";
    }
  
    let occupyColor;
    if(capitalRatio >= 30 || capitalRatio === "-"){
      occupyColor = donutOccupyColor;
    }
    else if(capitalRatio >= (100/6)){
      occupyColor = donutOccupyWarningColor;
    }
    else{
      occupyColor = donutOccupyDangerColor;
    }
  
    const donutStyle = {
      background: `${donutVoidColor} linear-gradient(to right, ${donutVoidColor} 50%, ${occupyColor} 50%`,
      color: occupyColor,
    };
  
    const capitalBar = (
      <div
        style={{
          position: "absolute",
          display: "block",
          backgroundColor: donutVoidColor,
          height: "100%",
          width: "50%",
          bottom: 0,
          right: 0,
          borderRadius: "0 100% 100% 0 / 50%",
          transformOrigin: "0 50%",
          transform: `rotate(${rotate})`,
        }}
      />
    );
    
    let adjuster;
    if(capitalRatio > 50){
      donutStyle.background = occupyColor;
      
      adjuster = (
        <div
          style={{
            position: "absolute",
            display: "block",
            backgroundColor: occupyColor,
            height: "100%",
            width: "50%",
            bottom: 0,
            right: 0,
            borderRadius: "0 100% 100% 0 / 50%",
            zIndex: 1,
          }}
        />
      );
    }
    
    const textBlock = (
      <div className={classes.donutText}>
        <div>{capitalRatio}%</div>
      </div>
    );
    
    return (
      <div className={classes.dashboardItemContainer}>
        <div className={classes.dashboardItem}>
          <div style={{height: 50, textAlign: "center"}}>
            {t<string>("dashboard.capitalRatio")}
          </div>
          <div className={classes.donut} style={donutStyle}>
            {capitalBar}
            {adjuster}
            {textBlock}
          </div>
        </div>
      </div>
    );
  }
  
  public static getMonthlyBS(props: IDashboardProps, state: IDashboardState|null, option: IDashboardOption){
    const {i18n, lang, classes, monthlyBS, theme, changeAnalysisMenu} = props;
    const t = i18n.getFixedT(lang, "Home");
    const {format} = option;
    
    if(!monthlyBS){
      return Dashboard.noData(props, t<string>("dashboard.monthlyBS"));
    }
    
    let monthlyBS_ext = monthlyBS.map((bs) => {
      return {
        ...bs,
        liability: typeof(bs.liability) === "number" ? -bs.liability : undefined,
        periodStr: `${bs.period.getFullYear()}/${(bs.period.getMonth()+1).toString().padStart(2, "0")}`,
      };
    });
  
    monthlyBS_ext.sort((a, b) => b.period.getTime() - a.period.getTime());
    monthlyBS_ext = monthlyBS_ext.filter((_, i) => i < 6);
  
    const totalAssetColor = (theme.custom && theme.custom.incomeColor) || "#3f51b5";
    const liabilityColor = (theme.custom && theme.custom.spendColor) || "#ba000d";
    const netAssetColor = (theme.custom && theme.custom.netIncomeColor) || "#2e7d32";
    
    return (
      <div className={classes.dashboardItemContainer}>
        <div className={classes.dashboardItem}>
          <div
            style={{height: 50, textAlign: "center", cursor: "pointer"}}
            onClick={() => changeAnalysisMenu("monthlyBSHistory")}
          >
            {t<string>("dashboard.monthlyBS")}
          </div>
          <div
            style={{
              height: "calc(100% - 50px)", width: "100%",
              position: "relative", display: "flex", flexDirection: "column", justifyContent: "space-around",
              fontSize: theme.typography.fontSize * .9,
            }}
          >
            <div style={{display: "flex", flexDirection: "row", width: "100%", paddingTop: 4}}>
              <div style={{width: 65}} />
              <div style={{width: 95, textAlign: "right", paddingRight: 5}}>
                {t<string>("dashboard.totalAsset")}
              </div>
              <div style={{width: 95, textAlign: "right", paddingRight: 5}}>
                {t<string>("dashboard.liability")}
              </div>
              <div style={{width: 95, textAlign: "right", paddingRight: 5}}>
                {t<string>("dashboard.netAsset")}
              </div>
            </div>
            {
              monthlyBS_ext.map(bs => (
                <Fragment key={bs.periodStr}>
                  <div style={{height: 0, width: "100%", boxSizing: "content-box", borderTop: "1px solid rgba(33,33,33,.2)"}} />
                  <div key={bs.periodStr} style={{display: "flex", flexDirection: "row", width: "100%"}}>
                    <div style={{width: 65, textAlign: "left", paddingLeft: 5}}>
                      {bs.periodStr}
                    </div>
                    <div style={{width: 95, textAlign: "right", paddingRight: 5}}>
                      <div style={{color: totalAssetColor}}>{typeof(bs.totalAsset) === "number" ? format(bs.totalAsset) : ""}</div>
                    </div>
                    <div style={{width: 95, textAlign: "right", paddingRight: 5}}>
                      <div style={{color: liabilityColor}}>{typeof(bs.liability) === "number" ? format(bs.liability) : ""}</div>
                    </div>
                    <div style={{width: 95, textAlign: "right", paddingRight: 5}}>
                      <div style={{color: netAssetColor}}>{typeof(bs.netAsset) === "number" ? format(bs.netAsset) : ""}</div>
                    </div>
                  </div>
                </Fragment>
              ))
            }
          </div>
        </div>
      </div>
    );
  }
  
  public static getMonthlyBSChart(props: IDashboardProps, state: IDashboardState|null, option: IDashboardOption){
    const {i18n, lang, classes, monthlyBS, changeAnalysisMenu} = props;
    const t = i18n.getFixedT(lang, "Home");
    const {format} = option;
    
    if(!monthlyBS){
      return Dashboard.noData(props, t<string>("dashboard.monthlyBS"));
    }
    
    const monthlyBS_ext = monthlyBS.map((bs) => {
      return {
        ...bs,
        [t<string>("dashboard.totalAsset")]: bs.totalAsset,
        liability: typeof(bs.liability) === "number" ? Math.abs(bs.liability) : undefined,
        period: `${bs.period.getFullYear()}/${bs.period.getMonth()+1}`,
      };
    });
    
    const totalAssets = monthlyBS_ext
      .map(bs => bs.totalAsset)
      .filter(a => typeof(a) === "number") as number[];
    let assetMax = Math.max(...totalAssets);
    let assetMin = Math.min(...totalAssets);
    
    if(assetMax > 1){
      const logMax = Math.floor(Math.log10(assetMax));
      assetMax = Math.round(assetMax / (10 ** logMax) + 1) * 10 ** logMax;
    }
    
    if(assetMin > 1){
      const logMin = Math.floor(Math.log10(assetMin));
      assetMin = Math.floor(assetMin / (10 ** logMin))* 10 ** logMin;
    }
  
    const tickFormatter_Y = (value: number) => format(value, true);
    const tooltipFormatter = (value: string|number|React.ReactText[], name: string) => format(value);
    
    return (
      <div className={classes.dashboardItemContainer}>
        <div className={classes.dashboardItem}>
          <div
            style={{height: 50, textAlign: "center", cursor: "pointer"}}
            onClick={() => changeAnalysisMenu("monthlyBSHistory")}
          >
            {t<string>("dashboard.monthlyBS")}
          </div>
          <div className={classes.monthlyBSChart} style={{height: 220}}>
            <LineChart width={350} height={220} data={monthlyBS_ext}>
              <XAxis dataKey="period" interval={2} padding={{left: 24, right: 24}} tickSize={8}/>
              <YAxis domain={[assetMin, assetMax]} tickFormatter={tickFormatter_Y} width={80}/>
              <CartesianGrid strokeDasharray="3 3"/>
              <Tooltip
                formatter={tooltipFormatter}
                labelStyle={{
                  color: "rgba(35,35,35,1)",
                }}
              />
              <Line
                type="monotone"
                dataKey={(t<string>("dashboard.totalAsset") as string)}
                stroke="#8884d8"
                dot={true}
                activeDot={{r: 4}}
              />
            </LineChart>
          </div>
        </div>
      </div>
    );
  }
  
  public static getMonthlyPLChart(props: IDashboardProps, state: IDashboardState|null, option: IDashboardOption){
    const {i18n, lang, classes, changeAnalysisMenu, theme} = props;
    let {monthlyPL} = props;
    const t = i18n.getFixedT(lang, "Home");
    const {format} = option;
    
    if(!monthlyPL || Math.max(...monthlyPL.map(pl => Math.abs(pl.pl || 0))) === 0){
      return Dashboard.noData(props, t<string>("dashboard.monthlyPL"), 3, 1);
    }
  
    monthlyPL = monthlyPL.slice(Math.max(monthlyPL.length - 13, 0)); // We only need 13 months data at most
  
    const sumKey = t<string>("dashboard.profit");
    
    const monthlyPL_ext = monthlyPL.map((pl, i) => {
      const periodStr = pl.period.toString();
      return {
        [sumKey]: null as number|null,
        [t<string>("dashboard.pl")]: pl.pl,
        [t<string>("dashboard.income")]: pl.profit,
        [t<string>("dashboard.spending")]: typeof(pl.loss) === "number" ? -pl.loss : null,
        period: `${periodStr.substr(0, 4)}/${+periodStr.substr(4, 2)}`, // For we need extra space, make month able to be 1 digit
        base: {
          ...pl,
        },
      };
    });
    
    monthlyPL_ext.forEach((pl, i) => {
      if(i > 0){
        const prevSum = monthlyPL_ext[i-1][sumKey];
        
        if(typeof(prevSum) === "number"){
          if(typeof(pl.base.pl) === "number"){
            monthlyPL_ext[i][sumKey] = prevSum + pl.base.pl;
          }
          else{
            monthlyPL_ext[i][sumKey] = prevSum;
          }
          return;
        }
        else{
          monthlyPL_ext[i][sumKey] = pl.base.pl;
          return;
        }
      }
      
      monthlyPL_ext[0][sumKey] = null;// pl.base.pl;
    });
    
    const tickFormatter_Y = (value: string|number|React.ReactText[]) => format(value, true);
    const tooltipFormatter = (value: string|number|React.ReactText[], name: string) => format(value, true);
  
    const incomeColor = (theme.custom && theme.custom.incomeColor) || "#3f51b5";
    const spendColor = (theme.custom && theme.custom.spendColor) || "#ba000d";
    const netIncomeColor = (theme.custom && theme.custom.netIncomeColor) || "#2e7d32";
  
    return (
      <div className={classes.dashboardItemContainer3w1h}>
        <div className={classes.dashboardItem}>
          <div
            style={{height: 50, textAlign: "center", cursor: "pointer"}}
            onClick={() => changeAnalysisMenu("monthlyPLHistory")}
          >
            {t<string>("dashboard.monthlyPL")}
          </div>
          <div className={classes.monthlyBSChart} style={{height: 220}}>
            <ComposedChart width={1100} height={220} data={monthlyPL_ext}>
              <CartesianGrid strokeDasharray="3 3"/>
              <XAxis dataKey="period" interval={2} padding={{left: 24, right: 24}}/>
              <YAxis tickFormatter={tickFormatter_Y} width={80}/>
              <Tooltip
                formatter={tooltipFormatter}
                labelStyle={{
                  color: "rgba(35,35,35,1)",
                }}
              />
              <ReferenceLine y={0}/>
              <Bar dataKey={(t<string>("dashboard.income") as string)} stroke={incomeColor} fill={incomeColor} fillOpacity={0.3}/>
              <Bar dataKey={(t<string>("dashboard.spending") as string)} stroke={spendColor} fill={spendColor} fillOpacity={0.3}/>
              <Line type="monotone" dataKey={(t<string>("dashboard.pl") as string)} stroke={netIncomeColor}/>
            </ComposedChart>
          </div>
        </div>
      </div>
    );
  }
  
  public static getMonthlyAccumulatedPLChart(props: IDashboardProps, state: IDashboardState|null, option: IDashboardOption){
    const {i18n, lang, classes} = props;
    let {monthlyPL} = props;
    const t = i18n.getFixedT(lang, "Home");
    const {format} = option;
    
    if(!monthlyPL || Math.max(...monthlyPL.map(pl => Math.abs(pl.pl || 0))) === 0){
      return Dashboard.noData(props, t<string>("dashboard.monthlyPL"));
    }
    
    monthlyPL = monthlyPL.slice(Math.max(monthlyPL.length - 13, 0)); // We only need 13 months data at most
  
    const sumKey = t<string>("dashboard.profit");
  
    const monthlyPL_ext = monthlyPL.map((pl, i) => {
      const periodStr = pl.period.toString();
      return {
        [sumKey]: null as number|null,
        period: `${periodStr.substr(0, 4)}/${+periodStr.substr(4, 2)}`, // For we need extra space, make month able to be 1 digit
        base: {
          ...pl,
        },
      };
    });
    
    monthlyPL_ext.forEach((pl, i) => {
      if(i > 0){
        const prevSum = monthlyPL_ext[i-1][sumKey];
        
        if(typeof(prevSum) === "number"){
          if(typeof(pl.base.pl) === "number"){
            monthlyPL_ext[i][sumKey] = prevSum + pl.base.pl;
          }
          else{
            monthlyPL_ext[i][sumKey] = prevSum;
          }
          return;
        }
        else{
          monthlyPL_ext[i][sumKey] = pl.base.pl;
          return;
        }
      }
      
      monthlyPL_ext[0][sumKey] = null;// pl.pl;
    });
  
    const tickFormatter_Y = (value: string|number|React.ReactText[]) => format(value, true);
    const tooltipFormatter = (value: string|number|React.ReactText[], name: string) => format(value, true);
    
    return (
      <div className={classes.dashboardItemContainer}>
        <div className={classes.dashboardItem}>
          <div style={{height: 50, textAlign: "center"}}>
            {t<string>("dashboard.monthlyAccumulatedPL")}
          </div>
          <div className={classes.monthlyBSChart} style={{height: 220}}>
            <AreaChart width={350} height={220} data={monthlyPL_ext}>
              <CartesianGrid strokeDasharray="3 3"/>
              <XAxis dataKey="period" interval={2} padding={{left: 24, right: 24}}/>
              <YAxis tickFormatter={tickFormatter_Y} width={80}/>
              <Tooltip
                formatter={tooltipFormatter}
                labelStyle={{
                  color: "rgba(35,35,35,1)",
                }}
              />
              <Area type="monotone" dataKey={sumKey}/>
            </AreaChart>
          </div>
        </div>
      </div>
    );
  }
  
  public static getYearlyPLAccumulated(props: IDashboardProps, state: IDashboardState|null, option: IDashboardOption){
    const {i18n, lang, classes, theme, monthlyPL, changeAnalysisMenu} = props;
    const t = i18n.getFixedT(lang, "Home");
    const {format} = option;
    
    if(!monthlyPL || Math.max(...monthlyPL.map(pl => Math.abs(pl.pl || 0))) === 0){
      return Dashboard.noData(props, t<string>("dashboard.yearlyPL"));
    }
  
    const monthlyPL_ext = monthlyPL.map(pl => {
      const periodStr = pl.period.toString();
      const YYYY = periodStr.substr(0, 4);
      const MM = periodStr.substr(4, 2);
      const date = new Date(`${YYYY}-${MM}-01 00:00:00`);
      
      return {
        // loss: Math.abs(pl.loss),
        period: date,
        base: {
          ...pl,
        },
      };
    });
    
    const thisYear = monthlyPL_ext[monthlyPL_ext.length - 1].period.getFullYear();
    const monthlyPL_extThisYear = monthlyPL_ext.filter(pl => pl.period.getFullYear() === thisYear);
    const months = monthlyPL_extThisYear.map(pl => pl.period.getMonth());
    
    if(months.length === 0){
      return Dashboard.noData(props, t<string>("dashboard.yearlyPL"));
    }
  
    const maxMonth = Math.max(...months);
  
    const lastYear = thisYear - 1;
    const monthlyPL_extLastYear = monthlyPL_ext.filter(pl => pl.period.getFullYear() === lastYear && pl.period.getMonth() <= maxMonth);
  
    const accumulatedIncome = monthlyPL_extThisYear.reduce((acc, pl) => {
      return acc + (pl.base.profit ? pl.base.profit : 0);
    }, 0);
  
    const accumulatedExpense = monthlyPL_extThisYear.reduce((acc, pl) => {
      return acc + (pl.base.loss ? pl.base.loss : 0);
    }, 0);
  
    const accumulatedPL = monthlyPL_extThisYear.reduce((acc, pl) => {
      return acc + (pl.base.pl ? pl.base.pl : 0);
    }, 0);
    
    const s = monthlyPL_extThisYear[0].period;
    const e = monthlyPL_extThisYear[monthlyPL_extThisYear.length-1].period;
    const startThisYear = `${s.getFullYear()}/${(s.getMonth()+1).toString().padStart(2, "0")}`;
    const endThisYear = `${e.getFullYear()}/${(e.getMonth()+1).toString().padStart(2, "0")}`;
  
  
  
    const accumulatedIncomeLastYear = monthlyPL_extLastYear.reduce((acc, pl) => {
      return acc + (pl.base.profit ? pl.base.profit : 0);
    }, 0);
  
    const accumulatedExpenseLastYear = monthlyPL_extLastYear.reduce((acc, pl) => {
      return acc + (pl.base.loss ? pl.base.loss : 0);
    }, 0);
  
    const accumulatedPLLastYear = monthlyPL_extLastYear.reduce((acc, pl) => {
      return acc + (pl.base.pl ? pl.base.pl : 0);
    }, 0);
  
    // let startLastYear = `${monthlyPL_extLastYear[0].period.getFullYear()}/${(monthlyPL_extLastYear[0].period.getMonth()+1).toString().padStart(2, "0")}`;
    // let endLastYear = `${monthlyPL_extLastYear[monthlyPL_extLastYear.length-1].period.getFullYear()}/${(monthlyPL_extLastYear[monthlyPL_extLastYear.length-1].period.getMonth()+1).toString().padStart(2, "0")}`;
  
    const incomeColor = (theme.custom && theme.custom.incomeColor) || "#3f51b5";
    const spendColor = (theme.custom && theme.custom.spendColor) || "#ba000d";
    const netIncomeColor = (theme.custom && theme.custom.netIncomeColor) || "#2e7d32";
    
    return (
      <div className={classes.dashboardItemContainer}>
        <div className={classes.dashboardItem}>
          <div
            style={{height: 30, textAlign: "center", cursor: "pointer"}}
            onClick={() => changeAnalysisMenu("yearlyPLBreakdown")}
          >
            {t<string>("dashboard.yearlyPL")}
          </div>
          <div
            style={{
              height: 20,
              fontSize: theme.typography.fontSize * .85,
              color: theme.palette.text.secondary,
              display: "flex",
              flexDirection: "row",
              margin: "0 30px",
            }}
          >
            <div>{t<string>("dashboard.period")}: {startThisYear} - {endThisYear}</div>
            <div style={{flex: "auto"}} />
            <div>{t<string>("dashboard.greyed")}: {t<string>("dashboard.YoY")}</div>
          </div>
          <div
            style={{
              height: "calc(100% - 50px)",
              width: "100%",
              position: "relative",
              display: "flex",
              flexDirection: "column",
              alignItems: "stretch",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-around",
                alignItems: "center",
                height: "33%",
              }}
            >
              <div style={{display: "flex", flexDirection: "row", width: "100%", justifyContent: "space-between"}}>
                <div style={{paddingLeft: 30, color: incomeColor}}>{t<string>("dashboard.income")}</div>
                <div style={{flex: "auto", paddingRight: 30, textAlign: "right"}}>
                  <div
                    style={{
                      fontSize: theme.typography.fontSize * 1.7,
                      color: incomeColor,
                    }}
                  >
                    {format(accumulatedIncome)}
                  </div>
                  <div style={{fontSize: theme.typography.fontSize * .95, color: theme.palette.text.secondary}}>
                    {format(accumulatedIncomeLastYear)}
                  </div>
                </div>
              </div>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-around",
                alignItems: "center",
                height: "33%",
            }}
            >
              <div style={{display: "flex", flexDirection: "row", width: "100%", justifyContent: "space-between"}}>
                <div style={{paddingLeft: 30, color: spendColor}}>{t<string>("dashboard.spending")}</div>
                <div style={{flex: "auto", paddingRight: 30, textAlign: "right"}}>
                  <div
                    style={{
                      fontSize: theme.typography.fontSize * 1.7,
                      color: spendColor,
                    }}
                  >
                    {format(accumulatedExpense)}
                  </div>
                  <div style={{fontSize: theme.typography.fontSize * .95, color: theme.palette.text.secondary}}>
                    {format(accumulatedExpenseLastYear)}
                  </div>
                </div>
              </div>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-around",
                alignItems: "center",
                height: "33%",
                borderTop: "1px solid rgba(33,33,33,.2)",
              }}
            >
              <div style={{display: "flex", flexDirection: "row", width: "100%", justifyContent: "space-between"}}>
                <div style={{paddingLeft: 30, color: netIncomeColor}}>{t<string>("dashboard.profit")}</div>
                <div style={{flex: "auto", paddingRight: 30, textAlign: "right"}}>
                  <div
                    style={{
                      fontSize: theme.typography.fontSize * 1.7,
                      color: netIncomeColor,
                    }}
                  >
                    {format(accumulatedPL)}
                  </div>
                  <div style={{fontSize: theme.typography.fontSize * .95, color: theme.palette.text.secondary}}>
                    {format(accumulatedPLLastYear)}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
  
  public static getMonthlyIncomeAndSpending(props: IDashboardProps, state: IDashboardState|null, option: IDashboardOption){
    const {i18n, lang, classes, theme, monthlyPL, changeAnalysisMenu} = props;
    const t = i18n.getFixedT(lang, "Home");
    const {format} = option;
  
    if(!monthlyPL || Math.max(...monthlyPL.map(pl => Math.abs(pl.pl || 0))) === 0){
      return Dashboard.noData(props, t<string>("dashboard.monthlyInOut"));
    }
    
    interface IMonthlyPLExt {
      period: Date;
      base: {
        period: number;
        profit: number|null;
        loss: number|null;
        pl: number|null;
      };
      lastYear?: IMonthlyPLExt;
    }
    
    const monthlyPL_ext: IMonthlyPLExt[] = monthlyPL.map(pl => {
      const periodStr = pl.period.toString();
      const YYYY = periodStr.substr(0, 4);
      const MM = periodStr.substr(4, 2);
      const date = new Date(`${YYYY}-${MM}-01 00:00:00`);
    
      return {
        period: date,
        base: {
          ...pl,
        },
      };
    });
  
    const monthlyPL_ext2 = monthlyPL_ext.slice(Math.max(monthlyPL_ext.length - 4, 0)); // We only need 4 months data at most
    for(let i=0;i<monthlyPL_ext2.length;i++){
      const date = new Date(monthlyPL_ext2[i].period);
      date.setFullYear(date.getFullYear()-1);
  
      const plLastYear = monthlyPL_ext.find(pl => {
        return pl.period.getFullYear() === date.getFullYear() && pl.period.getMonth() === date.getMonth();
      });
      
      if(plLastYear){
        monthlyPL_ext2[i].lastYear = {...plLastYear};
      }
    }
    
    monthlyPL_ext2.reverse();
  
    const incomeColor = (theme.custom && theme.custom.incomeColor) || "#3f51b5";
    const spendColor = (theme.custom && theme.custom.spendColor) || "#ba000d";
    const netIncomeColor = (theme.custom && theme.custom.netIncomeColor) || "#2e7d32";
  
    return (
      <div className={classes.dashboardItemContainer}>
        <div className={classes.dashboardItem}>
          <div
            style={{height: 30, textAlign: "center", cursor: "pointer"}}
            onClick={() => changeAnalysisMenu("monthlyPLBreakdown")}
          >
            {t<string>("dashboard.monthlyInOut")}
          </div>
          <div
            style={{
              height: 20,
              fontSize: theme.typography.fontSize * .85,
              color: theme.palette.text.secondary,
              display: "flex",
              flexDirection: "row",
              margin: "0 5px",
            }}
          >
            <div />
            <div style={{flex: "auto"}} />
            <div>{t<string>("dashboard.greyed")}: {t<string>("dashboard.YoY")}</div>
          </div>
          <div
            style={{
              height: "calc(100% - 50px)",
              width: "100%",
              position: "relative",
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-around",
              fontSize: theme.typography.fontSize * .9,
            }}
          >
            <div style={{display: "flex", flexDirection: "row", width: "100%", paddingTop: 4}}>
              <div style={{width: 65}} />
              <div style={{width: 95, textAlign: "right", paddingRight: 5}}>
                {t<string>("dashboard.income")}
              </div>
              <div style={{width: 95, textAlign: "right", paddingRight: 5}}>
                {t<string>("dashboard.spending")}
              </div>
              <div style={{width: 95, textAlign: "right", paddingRight: 5}}>
                {t<string>("dashboard.pl")}
              </div>
            </div>
            {
              monthlyPL_ext2.map(pl => {
                const profit_thisYear = typeof(pl.base.profit) === "number" ? format(pl.base.profit) : "-";
                const profit_lastYear = pl.lastYear && typeof(pl.lastYear.base.profit) === "number" ? format(pl.lastYear.base.profit) : "-";
                const loss_thisYear = typeof(pl.base.loss) === "number" ? format(pl.base.loss) : "-";
                const loss_lastYear = pl.lastYear && typeof(pl.lastYear.base.loss) === "number" ? format(pl.lastYear.base.loss) : "-";
                const pl_thisYear = typeof(pl.base.pl) === "number" ? format(pl.base.pl) : "-";
                const pl_lastYear = pl.lastYear && typeof(pl.lastYear.base.pl) === "number" ? format(pl.lastYear.base.pl) : "-";
                
                return (
                  <Fragment key={pl.period.getTime()}>
                    <div style={{height: 0, width: "100%", boxSizing: "content-box", borderTop: "1px solid rgba(33,33,33,.2)"}} />
                    <div key={pl.period.getTime()} style={{display: "flex", flexDirection: "row", width: "100%"}}>
                      <div style={{width: 65, textAlign: "left", paddingLeft: 5}}>
                        {pl.period.getFullYear()}/{(pl.period.getMonth()+1).toString().padStart(2, "0")}
                      </div>
                      <div style={{width: 95, textAlign: "right", paddingRight: 5}}>
                        <div style={{color: incomeColor}}>{profit_thisYear}</div>
                        <div style={{fontSize: theme.typography.fontSize * .8, color: theme.palette.text.secondary}}>
                          {pl.lastYear ? `${profit_lastYear}` : null}
                        </div>
                      </div>
                      <div style={{width: 95, textAlign: "right", paddingRight: 5}}>
                        <div style={{color: spendColor}}>{loss_thisYear}</div>
                        <div style={{fontSize: theme.typography.fontSize * .8, color: theme.palette.text.secondary}}>
                          {pl.lastYear ? `${loss_lastYear}` : null}
                        </div>
                      </div>
                      <div style={{width: 95, textAlign: "right", paddingRight: 5}}>
                        <div style={{color: netIncomeColor}}>{pl_thisYear}</div>
                        <div style={{fontSize: theme.typography.fontSize * .8, color: theme.palette.text.secondary}}>
                          {pl.lastYear ? `${pl_lastYear}` : null}
                        </div>
                      </div>
                    </div>
                  </Fragment>
                );
              })
            }
          </div>
        </div>
      </div>
    );
  }
  
  public static getMonthlyItemSummary(props: IDashboardProps, state: IDashboardState|null, option: IDashboardOption){
    const {i18n, lang, classes, theme, monthlyItem, items, changeAnalysisMenu} = props;
    const t = i18n.getFixedT(lang, "Home");
    const {format} = option;
    const itemsToDisplay = 5;
    const ymToDisplay = 4;
    
    if(!monthlyItem
      || monthlyItem.length === 0
      || items.filter(item => ![ITEM__UNACCOUNTED, ITEM__START_AMOUNT].includes(item.id)).length === 0
      || monthlyItem.filter(item =>
           Object.keys(item.data).length > 0
           && Object.entries(item.data).filter(([_, value]) => typeof(value) === "number").length > 0,
         ).length === 0
    ){
      return Dashboard.noData(props, t<string>("dashboard.monthlyItemSummary"), 15);
    }
  
    interface IMonthlyItemExt {
      period: Date;
      base: {
        period: number;
        data: {
          [itemId: number]: number;
        }
      };
      lastYear?: IMonthlyItemExt;
      data?: Array<{id: number|string, name: string, value: number|undefined}>;
      dataLastYear?: Array<{id: number|string, name: string, value: number|undefined}>;
    }
  
    const monthlyItem_ext: IMonthlyItemExt[] = monthlyItem.map(pl => {
      const periodStr = pl.period.toString();
      const YYYY = periodStr.substr(0, 4);
      const MM = periodStr.substr(4, 2);
      const date = new Date(`${YYYY}-${MM}-01 00:00:00`);
      
      return {
        period: date,
        base: {
          ...pl,
        },
      };
    });
    
    const monthlyPL2 = monthlyItem_ext.slice(Math.max(monthlyItem_ext.length - ymToDisplay, 0)); // We only need `ymToDisplay` months data at most
    for(let i=0;i<monthlyPL2.length;i++){
      const date = new Date(monthlyPL2[i].period);
      date.setFullYear(date.getFullYear()-1);
  
      const plLastYear = monthlyItem_ext.find(pl => {
        return pl.period.getFullYear() === date.getFullYear() && pl.period.getMonth() === date.getMonth();
      });
      
      if(plLastYear){
        monthlyPL2[i].lastYear = {...plLastYear};
      }
    }
    
    monthlyPL2.reverse();
    
    let items_to_display = items.filter(item => ![ITEM__START_AMOUNT, ITEM__UNACCOUNTED].includes(item.id));
    items_to_display.sort((a, b) => a.order - b.order);
    items_to_display = items_to_display.filter((_, i) => i < itemsToDisplay); // Only display `itemsToDisplay` items_to_display
    
    const items_without_data: Array<{id: string, name: string}> = [];
    while(items_to_display.length + items_without_data.length < itemsToDisplay){
      items_without_data.push({id: `noData-${items_to_display.length}`, name: "-"});
    }
    
    const mergedItems = ([] as Array<{id: number|string, name: string, value?: number}>).concat(items_to_display, items_without_data);
    
    for(let i=0;i<monthlyPL2.length;i++){
      const {base, lastYear} = monthlyPL2[i];
      const {data} = base;
  
      monthlyPL2[i].data = mergedItems.map(item => {
        return {
          id: item.id,
          name: item.name,
          value: typeof(item.id) === "number" ? data[item.id] : undefined,
        };
      });
  
      if(lastYear){
        const {data: prevData} = lastYear.base;
        monthlyPL2[i].dataLastYear = mergedItems.map(item => {
          return {
            id: item.id,
            name: item.name,
            value: typeof(item.id) === "number" ? prevData[item.id] : undefined,
          };
        });
      }
    }
    
    return (
      <div className={classes.dashboardItemContainer15w1h}>
        <div className={classes.dashboardItem}>
          <div
            style={{height: 30, textAlign: "center", cursor: "pointer"}}
            onClick={() => changeAnalysisMenu("monthlyItemHistory")}
          >
            {t<string>("dashboard.monthlyItemSummary")}
          </div>
          <div
            style={{
              height: 20,
              fontSize: theme.typography.fontSize * .85,
              color: theme.palette.text.secondary,
              display: "flex",
              flexDirection: "row",
              margin: "0 5px",
            }}
          >
            <div />
            <div style={{flex: "auto"}} />
            <div>{t<string>("dashboard.greyed")}: {t<string>("dashboard.YoY")}</div>
          </div>
          <div
            style={{
              height: "calc(100% - 50px)",
              width: "100%",
              position: "relative",
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-around",
              fontSize: theme.typography.fontSize * .9,
            }}
          >
            <div style={{display: "flex", flexDirection: "row", width: "100%", paddingTop: 4}}>
              <div style={{width: 65}} />
              {
                mergedItems.map(item => (
                  <div
                    key={item.id}
                    style={{
                      width: 108,
                      textAlign: "right",
                      paddingRight: 5,
                      fontSize: "0.76rem",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      whiteSpace: "nowrap",
                      padding: "0 3px",
                    }}
                    title={item.name}
                  >
                    {item.name}
                  </div>
                ))
              }
            </div>
            {
              monthlyPL2.map(pl => {
                return (
                  <Fragment key={pl.period.getTime()}>
                    <div style={{height: 0, width: "100%", boxSizing: "content-box", borderTop: "1px solid rgba(33,33,33,.2)"}} />
                    <div key={pl.period.getTime()} style={{display: "flex", flexDirection: "row", width: "100%"}}>
                      <div style={{width: 65, textAlign: "left", paddingLeft: 5}}>
                        {pl.period.getFullYear()}/{(pl.period.getMonth()+1).toString().padStart(2, "0")}
                      </div>
                      {
                        pl.data && pl.data.map((d, i) => {
                          const value_lastYear = (() => {
                            if(!pl.dataLastYear){
                              return undefined;
                            }
                            
                            const valueLastYear = pl.dataLastYear[i].value;
                            if(typeof(valueLastYear) === "number"){
                              return format(valueLastYear);
                            }
                            
                            return undefined;
                          })();
  
                          return (
                            <div key={d.id} style={{width: 108, textAlign: "right", paddingRight: 5}}>
                              <div style={{color: "rgb(46, 125, 50)"}}>{typeof(d.value) === "number" ? format(d.value) : null}</div>
                              <div style={{fontSize: theme.typography.fontSize * .8,  color: theme.palette.text.secondary}}>
                                {value_lastYear}
                              </div>
                            </div>
                          );
                        })
                      }
                    </div>
                  </Fragment>
                );
              })
            }
          </div>
        </div>
      </div>
    );
  }
  
  public static getMonthlyGroupPL(props: IDashboardProps, state: IDashboardState|null, option: IDashboardOption){
    const {i18n, lang, classes, theme, monthlyGroupPl, itemGroups, changeAnalysisMenu} = props;
    const t = i18n.getFixedT(lang, "Home");
    const {format} = option;
    const itemGroupsToDisplay = 5;
    const ymToDisplay = 4;
  
    if(!monthlyGroupPl
      || monthlyGroupPl.length === 0
      || itemGroups.filter(itemGroup => [ITEM__UNACCOUNTED, ITEM__START_AMOUNT].includes(itemGroup.id)).length === 0
      || monthlyGroupPl.filter(itemGrup =>
        Object.keys(itemGrup.data).length > 0
        && Object.entries(itemGrup.data).filter(([_, value]) => typeof(value) === "number").length > 0,
      ).length === 0
    ){
      return Dashboard.noData(props, t<string>("dashboard.monthlyGroupedPl"), 15);
    }
  
    interface IMonthlyGroupExt {
      period: Date;
      base: {
        period: number;
        data: {
          [itemGroupId: number]: number;
        }
      };
      lastYear?: IMonthlyGroupExt;
      data?: Array<{id: number|string, name: string, value: number|undefined}>;
      dataLastYear?: Array<{id: number|string, name: string, value: number|undefined}>;
    }
  
    const monthlyGroupPl_ext: IMonthlyGroupExt[] = monthlyGroupPl.map(pl => {
      const periodStr = pl.period.toString();
      const YYYY = periodStr.substr(0, 4);
      const MM = periodStr.substr(4, 2);
      const date = new Date(`${YYYY}-${MM}-01 00:00:00`);
    
      return {
        period: date,
        base: {
          ...pl,
        },
      };
    });
  
    const monthlyPL2 = monthlyGroupPl_ext.slice(Math.max(monthlyGroupPl_ext.length - ymToDisplay, 0)); // We only need `ymToDisplay` months data at most
    for(let i=0;i<monthlyPL2.length;i++){
      const date = new Date(monthlyPL2[i].period);
      date.setFullYear(date.getFullYear()-1);
  
      const plLastYear = monthlyGroupPl_ext.find(pl => {
        return pl.period.getFullYear() === date.getFullYear() && pl.period.getMonth() === date.getMonth();
      });
      
      if(plLastYear){
        monthlyPL2[i].lastYear = {...plLastYear};
      }
    }
  
    monthlyPL2.reverse();
  
    let filteredItemGroups = [...itemGroups].filter(itemGroup => itemGroup.id > 0);
    
    if(filteredItemGroups.length < 1){
      return Dashboard.noData(props, t<string>("dashboard.monthlyGroupedPl"), 15);
    }
    
    filteredItemGroups.sort((a, b) => a.order - b.order);
    filteredItemGroups = filteredItemGroups.filter((_, i) => i < itemGroupsToDisplay); // Only display `itemGroupsToDisplay` items
    
    const itemGroupsWithoutData: Array<{id: string, name: string}> = [];
    while(filteredItemGroups.length < itemGroupsToDisplay){
      itemGroupsWithoutData.push({id: `noData-${filteredItemGroups.length}`, name: "-"});
    }
    
    const mergedItems = ([] as Array<{id: number|string, name: string, value?: number}>).concat(filteredItemGroups, itemGroupsWithoutData);
  
    for(let i=0;i<monthlyPL2.length;i++){
      const {base, lastYear} = monthlyPL2[i];
      const {data} = base;
    
      monthlyPL2[i].data = mergedItems.map(itemGroup => {
        return {
          id: itemGroup.id,
          name: itemGroup.name,
          value: typeof(itemGroup.id) === "number" ? data[itemGroup.id] : undefined,
        };
      });
    
      if(lastYear){
        const {data: prevData} = lastYear.base;
        monthlyPL2[i].dataLastYear = mergedItems.map(itemGroup => {
          return {
            id: itemGroup.id,
            name: itemGroup.name,
            value: typeof(itemGroup.id) === "number" ? prevData[itemGroup.id] : undefined,
          };
        });
      }
    }
    
    return (
      <div className={classes.dashboardItemContainer15w1h}>
        <div className={classes.dashboardItem}>
          <div
            style={{height: 30, textAlign: "center", cursor: "pointer"}}
            onClick={() => changeAnalysisMenu("monthlyItemGroupHistory")}
          >
            {t<string>("dashboard.monthlyGroupedPl")}
          </div>
          <div
            style={{
              height: 20,
              fontSize: theme.typography.fontSize * .85,
              color: theme.palette.text.secondary,
              display: "flex",
              flexDirection: "row",
              margin: "0 5px",
            }}
          >
            <div />
            <div style={{flex: "auto"}} />
            <div>{t<string>("dashboard.greyed")}: {t<string>("dashboard.YoY")}</div>
          </div>
          <div
            style={{
              height: "calc(100% - 50px)",
              width: "100%",
              position: "relative",
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-around",
              fontSize: theme.typography.fontSize * .9,
            }}
          >
            <div style={{display: "flex", flexDirection: "row", width: "100%", paddingTop: 4}}>
              <div style={{width: 65}} />
              {
                mergedItems.map(itemGroup => (
                  <div
                    key={itemGroup.id}
                    style={{
                      width: 108,
                      textAlign: "right",
                      paddingRight: 5,
                      fontSize: "0.76rem",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      whiteSpace: "nowrap",
                      padding: "0 3px",
                    }}
                    title={itemGroup.name}
                  >
                    {itemGroup.name}
                  </div>
                ))
              }
            </div>
            {
              monthlyPL2.map(pl => {
                return (
                  <Fragment key={pl.period.getTime()}>
                    <div style={{height: 0, width: "100%", boxSizing: "content-box", borderTop: "1px solid rgba(33,33,33,.2)"}} />
                    <div key={pl.period.getTime()} style={{display: "flex", flexDirection: "row", width: "100%"}}>
                      <div style={{width: 65, textAlign: "left", paddingLeft: 5}}>
                        {pl.period.getFullYear()}/{(pl.period.getMonth()+1).toString().padStart(2, "0")}
                      </div>
                      {
                        pl.data && pl.data.map((d, i) => {
                          const value_lastYear = (() => {
                            if(!pl.dataLastYear){
                              return undefined;
                            }
            
                            const valueLastYear = pl.dataLastYear[i].value;
                            if(typeof(valueLastYear) === "number"){
                              return format(valueLastYear);
                            }
            
                            return undefined;
                          })();
          
                          return (
                            <div key={d.id} style={{width: 108, textAlign: "right", paddingRight: 5}}>
                              <div style={{color: "rgb(46, 125, 50)"}}>{typeof(d.value) === "number" ? format(d.value) : null}</div>
                              <div style={{fontSize: theme.typography.fontSize * .8,  color: theme.palette.text.secondary}}>
                                {value_lastYear}
                              </div>
                            </div>
                          );
                        })
                      }
                    </div>
                  </Fragment>
                );
              })
            }
          </div>
        </div>
      </div>
    );
  }
  
  public static noData(props: IDashboardProps, header: React.ReactElement<any>|string, width=1, height=1){
    const {classes, i18n, lang} = props;
    const t = i18n.getFixedT(lang, "Home");
    
    let containerClassName = classes.dashboardItemContainer;
    if(width === 2){
      containerClassName = classes.dashboardItemContainer2w1h;
    }
    else if(width === 3){
      containerClassName = classes.dashboardItemContainer3w1h;
    }
    else if(width === 15){
      containerClassName = classes.dashboardItemContainer15w1h;
    }
    
    return (
      <div className={containerClassName}>
        <div className={classes.dashboardItem}>
          <div style={{height: 50, textAlign: "center"}}>
            {header}
          </div>
          <div className={classes.monthlyBSChart} style={{textAlign: "center"}}>
            {t<string>("dashboard.nodata")}
          </div>
        </div>
      </div>
    );
  }
  
  public format(money: string|number|React.ReactText[], mini?: boolean): string {
    if(typeof(money) !== "number" || isNaN(money)){
      return "";
    }
  
    const {currency} = this.props;
    let {symbol, format, decimal, thousand, precision} = currency;
  
    symbol = symbol || "";
    format = format || "%v";
    decimal = typeof(decimal) === "string" ? decimal : ".";
    thousand = typeof(thousand) === "string" ? thousand : ",";
    precision = typeof(precision) === "number" ? precision : 0;
  
    if(mini){
      const miniFormat = {...format};
      miniFormat.pos = miniFormat.pos.replace(" ", "");
      miniFormat.neg = miniFormat.neg.replace(" ", "");
      miniFormat.zero = miniFormat.zero.replace(" ", "");
      return formatMoney(money, {symbol, format: miniFormat, decimal, thousand, precision});
    }
    else{
      return formatMoney(money, {symbol, format, decimal, thousand, precision});
    }
  }
}

const styles: StyleRulesCallback = (theme: CustomTheme) => ({
  root: {
    "margin": theme.spacing.unit,
    "paddingTop": theme.spacing.unit,
    "& text": {
      fill: theme.palette.text.primary,
    },
  },
  dashboardHeader: {
    marginBottom: theme.spacing.unit,
  },
  dashboardTitle: {
    width: 1160,
    margin: "auto",
    padding: "0 16px",
    color: theme.palette.text.primary,
  },
  dashboard: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    justifyContent: "start",
    alignItems: "start",
    alignContent: "start",
    width: 1160,
    margin: "auto",
    marginBottom: theme.spacing.unit * 2,
  },
  dashboardItemContainer: {
    width: dashboardBaseWidth,
    height: dashboardBaseHeight,
    padding: 6,
  },
  dashboardItemContainer3w1h: {
    width: dashboardBaseWidth * 3,
    height: dashboardBaseHeight,
    padding: 6,
  },
  dashboardItemContainer2w1h: {
    width: dashboardBaseWidth * 2,
    height: dashboardBaseHeight,
    padding: 6,
  },
  dashboardItemContainer15w1h: {
    width: dashboardBaseWidth * 1.5,
    height: dashboardBaseHeight,
    padding: 6,
  },
  dashboardItem: {
    padding: theme.spacing.unit * 2,
    width: "100%",
    height: "100%",
    border: "1px solid rgba(33,33,33,.2)",
    borderRadius: 2,
    backgroundColor: (theme.custom && theme.custom.paperBgColor) || "#fff",
    boxShadow: "3px 3px 8px rgba(33,33,33,.2)",
  },
  donut: {
    "width": 200,
    "height": 200,
    "margin": "auto",
    "position": "relative",
    "borderRadius": "50%",
    "&:after": {
      content: "''",
      position: "absolute",
      width: "80%",
      height: "80%",
      top: 0,
      right: 0,
      bottom: 0,
      left: 0,
      margin: "auto",
      background: "#fff",
      borderRadius: "50%",
      zIndex: 2,
    },
  },
  donutText: {
    width: 120,
    height: 120,
    position: "absolute",
    top: 40,
    left: 40,
    zIndex: 3,
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    fontSize: 40,
  },
  monthlyBSChart: {
    boxSizing: "border-box",
    fontSize: 12,
    position: "relative",
    width: "100%",
  },
});

let _Component: React.ComponentType<any> = Dashboard;
_Component = withStyles(styles, { withTheme: true })(_Component);
_Component = withTranslation("Dashboard")(_Component);

export default _Component as React.ComponentType<Omit<IDashboardProps, TReservedHocProps>>;
