import React from "react";
import GrabberIcon from "mdi-react/DragIcon";
import CloseIcon from "mdi-react/CloseCircleIcon";
import IconButton from "@material-ui/core/IconButton";
import {ClassNameMap} from "@material-ui/core/styles/withStyles";
import {findDOMNode} from "react-dom";
import {closest} from "./utils";
import {GeneralItem, ItemBase} from "./Manager";
import * as R from "../../../core/reducers/Finance/state";

interface ISortableItemProps {
  classes: ClassNameMap;
  item: ItemBase;
  onChange: (id: number, name: string) => any;
  onRemoveButtonClicked: (id: number) => any;
  maxLength?: number;
  currencies?: R.Currency[];
  onChangeCurrency?: (id: number, currencyId: number) => any;
}

interface ISortableItemState {
  editing: boolean;
  editingValue: string;
}

class SortableItem extends React.Component<ISortableItemProps, ISortableItemState> {
  public id: number|null = null;
  public node: Element|null = null;
  public unmounting: boolean = false;
  
  constructor(props: ISortableItemProps){
    super(props);
    
    this.onChangeName = this.onChangeName.bind(this);
    this.onKeyDown = this.onKeyDown.bind(this);
    this.onClickItemName = this.onClickItemName.bind(this);
    this.onChangeCurrencyId = this.onChangeCurrencyId.bind(this);
    this.onClickRemove = this.onClickRemove.bind(this);
    this.onClickOutside = this.onClickOutside.bind(this);
    this.getCurrencyEditor = this.getCurrencyEditor.bind(this);
    
    this.state = {
      editing: false,
      editingValue: props.item.name,
    };
  }
  
  public render(){
    const {classes, item} = this.props;
    const {editing, editingValue} = this.state;
    
    let itemName;
    if(!editing){
      itemName = (
        <div onClick={this.onClickItemName} title={item.name}>
          {item.name}
        </div>
      );
    }
    else{
      itemName = (
        <input
          value={editingValue}
          onChange={this.onChangeName}
          onKeyDown={this.onKeyDown}
          autoFocus={true}
        />
      );
    }
    
    return (
      <div className={classes.item} id={`item-${item.id}`}>
        <div className={classes.grabber}>
          <div className={classes.grabberIcon}>
            <GrabberIcon />
          </div>
        </div>
        <div className={classes.itemName}>
          {itemName}
        </div>
        {this.getCurrencyEditor()}
        <IconButton
          className={classes.closeIconSmall}
          onClick={this.onClickRemove}
        >
          <CloseIcon size={15}/>
        </IconButton>
      </div>
    );
  }
  
  public componentDidMount(){
    const {onChange} = this.props;
    this.node = findDOMNode(this) as Element;
    this.id = this.props.item.id;
    
    window.addEventListener("mousedown", this.onClickOutside, true);
  }
  
  public componentWillUnmount(){
    this.unmounting = true;
    window.removeEventListener("mousedown", this.onClickOutside);
  }
  
  public getCurrencyEditor(){
    const {
      currencies,
      item,
      classes,
    } = this.props;
    if(!currencies){
      return;
    }
    
    return (
      <div className={classes.currency}>
        <select
          value={(item as GeneralItem).currency}
          onChange={this.onChangeCurrencyId}
          className={classes.currencySelect}
        >
          {
            currencies.map(c => {
              return (
                <option value={c.id} key={c.id}>{c.symbol}</option>
              );
            })
          }
        </select>
      </div>
    );
  }
  
  public onChangeName(e: React.ChangeEvent<HTMLInputElement>){
    if(this.unmounting){
      return;
    }
  
    const {maxLength} = this.props;
    let editingValue = e.target.value;
  
    if(typeof(maxLength) === "number"){
      editingValue = editingValue.substr(0, maxLength);
    }
  
    this.setState({
      editingValue,
    });
  }
  
  public onChangeCurrencyId(e: React.ChangeEvent<HTMLSelectElement>){
    const {onChangeCurrency, item} = this.props;
    
    if(typeof(onChangeCurrency) === "function"){
      const currencyId = +e.target.value;
      onChangeCurrency(item.id, currencyId);
    }
  }
  
  public onKeyDown(e: React.KeyboardEvent<HTMLInputElement>){
    if(this.unmounting){
      return;
    }
  
    const {onChange} = this.props;
    
    if(e.key === "Enter"){
      this.setState({editing: false});
      
      if(this.state.editingValue !== this.props.item.name && this.state.editingValue.length > 0){
        onChange(this.props.item.id, this.state.editingValue);
      }
    }
  }
  
  public onClickItemName(){
    if(this.unmounting){
      return;
    }
    
    this.setState({editing: true});
  }
  
  public onClickRemove(){
    const {onRemoveButtonClicked, item} = this.props;
    onRemoveButtonClicked(item.id);
  }
  
  public onClickOutside(e: MouseEvent){
    const {onChange} = this.props;
    
    if(this.unmounting){
      return;
    }
    
    const target = e.target as HTMLElement;
    if(!target){
      return;
    }
    
    if(!closest(target, (node) => (node as HTMLElement).id === `item-${this.id}`)){
      this.setState({editing: false});
      
      if(this.state.editingValue !== this.props.item.name && this.state.editingValue.length > 0){
        onChange(this.props.item.id, this.state.editingValue);
      }
    }
  }
}

export default SortableItem;
