import React from "react";
import {withTranslation} from "react-i18next";
import Menu from "@material-ui/core/Menu";
import Divider from "@material-ui/core/Divider";
import Button from "@material-ui/core/Button";
import Fab from "@material-ui/core/Fab";
import TwitterIcon from "mdi-react/TwitterIcon";
import FacebookIcon from "mdi-react/FacebookIcon";
import GithubIcon from "mdi-react/GithubCircleIcon";
import GoogleIcon from "mdi-react/GoogleIcon";
import PasswordIcon from "mdi-react/KeyVariantIcon";
import Avatar from "@material-ui/core/Avatar";
import classNames from "classnames";
import CreateAccountDialog from "../../../util/component/CreateAccountDialog";
import LoginAccountDialog from "../../../util/component/LoginDialog";
import ConfirmDialog from "../../../util/component/ConfirmDialog";
import {getAuthProviders, getCurrentUserName, getUserAvatar} from "../../../util/user";

import i18next from "i18next";
import withStyles, {ClassNameMap, StyleRulesCallback} from "@material-ui/core/styles/withStyles";
import {lighten} from "@material-ui/core/styles/colorManipulator";
import * as RA from "../../../reducers/App/state";
import {CustomTheme} from "../../../util/theme";
import {Account as Account1} from "../../../core/reducers/App/state";
import {Omit, TReservedHocProps} from "../../../util/type";

interface IAccountProps {
  classes: ClassNameMap;
  theme: CustomTheme;
  i18n: i18next.i18n;
  lang: RA.Lang;
  account: Account1 | null;
  logout: () => void;
  unsaved: boolean;
}

interface IAccountState {
  anchorEl: HTMLElement|null;
  dialogOpen: string;
  password: string;
  wrongPassword: boolean;
}

class Account extends React.PureComponent<IAccountProps, IAccountState> {
  constructor(props: IAccountProps){
    super(props);
  
    this.onAccountButtonClicked = this.onAccountButtonClicked.bind(this);
    this.onAccountClosed = this.onAccountClosed.bind(this);
    this.onDialogCancel = this.onDialogCancel.bind(this);
    this.onDialogOK = this.onDialogOK.bind(this);
  
    this.state = {
      anchorEl: null,
      dialogOpen: "",
      password: "",
      wrongPassword: false,
    };
  }
  
  public render(){
    const {anchorEl, dialogOpen} = this.state;
    const {
      classes,
      account,
      lang,
      i18n,
      theme,
      logout,
      unsaved,
    } = this.props;
    const t = i18n.getFixedT(lang, "Auth");
    
    const isMenuOpen = Boolean(anchorEl);
    
    const anchorOrigin = {
      vertical: "top" as "top",
      horizontal: "right" as "right",
    };
  
    const transformOrigin = {
      vertical: "top" as "top",
      horizontal: "right" as "right",
    };
    
    let dialog;
    if(dialogOpen === "newAccount"){
      dialog = (
        <CreateAccountDialog
          onCancel={this.onDialogCancel}
          onOK={this.onDialogOK}
        />
      );
    }
    else if(dialogOpen === "addAccount"){
      dialog = (
        <CreateAccountDialog
          onCancel={this.onDialogCancel}
          onOK={this.onDialogOK}
        />
      );
    }
    else if(dialogOpen === "login"){
      dialog = (
        <LoginAccountDialog
          onCancel={this.onDialogCancel}
          onOK={this.onDialogOK}
        />
      );
    }
    else if(dialogOpen === "confirmingLogout"){
      let description;
      if(unsaved){
        description = t<string>("loggingOutUnsaved");
      }
      else{
        description = t<string>("areYouSureToLogout");
      }
      
      dialog = (
        <ConfirmDialog
          lang={lang}
          description={description}
          onClickCancel={this.onDialogCancel}
          onClickOK={() => {
            logout();
            this.onDialogOK();
          }}
        />
      );
    }
  
    const avatar = getUserAvatar(classes, account);
    
    let loginButtonOrEmpty;
    if(account){
      loginButtonOrEmpty = (
        <div />
      );
    }
    else{
      loginButtonOrEmpty = (
        <div>
          <Button
            variant="contained"
            size="small"
            color="secondary"
            className={classes.button}
            onClick={() => this.setState({dialogOpen: "login"})}
          >
            {t<string>("login")}
          </Button>
        </div>
      );
    }
    
    let logoutButtonOrEmpty;
    if(account){
      logoutButtonOrEmpty = (
        <div>
          <Button
            variant="contained"
            size="small"
            color="secondary"
            className={classes.button}
            onClick={() => this.setState({dialogOpen: "confirmingLogout"})}
          >
            {t<string>("logout")}
          </Button>
        </div>
      );
    }
    else{
      logoutButtonOrEmpty = (
        <div />
      );
    }
    
    const authTypes = (() => {
      return getAuthProviders().map(p => {
        if(p === "twitter.com"){
          return (
            <Avatar className={classNames(classes.authTypeAvatar, classes.twitterAvatar)} key={p} title={p}>
              <TwitterIcon size={16}/>
            </Avatar>
          );
        }
        else if(p === "facebook.com"){
          return (
            <Avatar className={classNames(classes.authTypeAvatar, classes.facebookAvatar)} key={p} title={p}>
              <FacebookIcon size={16}/>
            </Avatar>
          );
        }
        else if(p === "google.com"){
          return (
            <Avatar className={classNames(classes.authTypeAvatar, classes.googleAvatar)} key={p} title={p}>
              <GoogleIcon size={16}/>
            </Avatar>
          );
        }
        else if(p === "github.com"){
          return (
            <Avatar className={classNames(classes.authTypeAvatar, classes.githubAvatar)} key={p} title={p}>
              <GithubIcon size={16}/>
            </Avatar>
          );
        }
        else if(p === "password"){
          return (
            <Avatar className={classNames(classes.authTypeAvatar, classes.passwordAvatar)} key={p} title={p}>
              <PasswordIcon size={16}/>
            </Avatar>
          );
        }
        
        return null;
      }).filter(p => p);
    })();
    
    return (
      <div>
        <Fab
          variant="round"
          aria-owns={isMenuOpen ? "menu-appbar" : undefined}
          aria-haspopup="true"
          size="small"
          color="primary"
          onClick={this.onAccountButtonClicked}
          style={{fontSize: 20}}
        >
          {avatar}
        </Fab>
        <div>
          <Menu
            id="menu-appbar"
            anchorEl={anchorEl}
            anchorOrigin={anchorOrigin}
            transformOrigin={transformOrigin}
            open={isMenuOpen}
            onClose={this.onAccountClosed}
            classes={{paper: classes.menuRoot}}
          >
            <div className={classes.currentAccount}>
              <div className={classes.accountIcon}>
                {avatar}
              </div>
              <div className={classes.accountDetail}>
                <div className={classes.username}>
                  {account ? getCurrentUserName(account) : t<string>("notLoggedIn")}
                </div>
                {account && (
                  <div className={classes.authType}>
                    {authTypes}
                  </div>
                )}
                <div className={classes.providerName}>
                  {t("storageProvider")} : {account ? t(account.provider) : t<string>("memory")}
                </div>
                <div className={classes.providerDescription}>
                  {account ? t<string>(`providerDescription.${account.provider}`) : t<string>("providerDescription.memory")}
                </div>
              </div>
            </div>
            <Divider/>
            <div className={classes.buttonControl}>
              {loginButtonOrEmpty}
              {logoutButtonOrEmpty}
            </div>
          </Menu>
        </div>
        {dialog}
      </div>
    );
  }
  
  public onAccountButtonClicked(event: React.MouseEvent<HTMLElement, MouseEvent>){
    this.setState({ anchorEl: (event.currentTarget as HTMLElement) });
  }
  
  public onAccountClosed(){
    this.setState({anchorEl: null});
  }
  
  public onDialogCancel(){
    this.setState({dialogOpen: ""});
  }
  
  public onDialogOK(){
    this.setState({dialogOpen: "", anchorEl: null});
  }
  
  public onSubmitForm(e: React.FormEvent){
    e.preventDefault();
  }
}




const styles: StyleRulesCallback = (theme: CustomTheme) => ({
  button: {
    margin: theme.spacing.unit / 2,
  },
  activeAvatar: {
    backgroundColor: theme.palette.secondary.main,
  },
  badge: {
    position: "absolute",
    bottom: theme.spacing.unit,
    right: theme.spacing.unit,
    border: `1px solid ${theme.palette.primary.main}`,
  },
  menuRoot: {
    width: 316,
  },
  currentAccount: {
    "width": 300,
    "display": "flex",
    "flexDirection": "row",
    "paddingLeft": theme.spacing.unit,
    "paddingRight": theme.spacing.unit,
    "&:focus": {
      outline: "none",
    },
  },
  username: {
    width: 240,
    fontSize: theme.typography.fontSize * 1.2,
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  },
  authType: {
    marginTop: theme.spacing.unit,
    display: "flex",
    flexWrap: "wrap",
  },
  authTypeAvatar: {
    width: 24,
    height: 24,
    display: "inline-flex",
    marginRight: theme.spacing.unit,
  },
  providerName: {
    width: 240,
    fontSize: theme.typography.fontSize * .9,
    color: theme.palette.text.primary,
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    marginTop: theme.spacing.unit,
  },
  providerDescription: {
    fontSize: theme.typography.fontSize * .85,
    color: theme.palette.text.primary,
    marginTop: theme.spacing.unit,
  },
  accountIcon: {
    boxSizing: "border-box",
    width: 40,
    textAlign: "center",
    margin: theme.spacing.unit,
  },
  accountDetail: {
    boxSizing: "border-box",
    padding: theme.spacing.unit,
    width: 244,
  },
  buttonControl: {
    "margin": theme.spacing.unit,
    "marginBottom": 0,
    "&:focus": {
      outline: "none",
    },
    "display": "flex",
    "flexDirection": "row",
    "justifyContent": "space-between",
  },
  menuItemContainer: {
    maxHeight: 325,
    overflowY: "auto",
    overflowX: "hidden",
  },
  menuItem: {
    "padding": `${theme.spacing.unit / 2}px ${theme.spacing.unit}px`,
    "display": "flex",
    "flexDirection": "row",
    "backgroundColor": "rgba(33, 33, 33, 0.03)",
    "transition": "all ease .3s",
    "cursor": "pointer",
    "&:hover": {
      backgroundColor: "rgba(0, 0, 0, 0.1)",
    },
    "&:focus": {
      outline: "none",
    },
  },
  menuControl: {
    "&:hover": {
      backgroundColor: "unset",
      cursor: "unset",
    },
    "height": "auto",
    "padding": "3px 3px 0 3px",
    "justifyContent": "center",
  },
  failedToSwitch: {
    fontSize: theme.typography.fontSize * .85,
  },
  loginError: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: theme.palette.error.main,
    marginTop: theme.spacing.unit*2,
  },
  passwordInput: {
    "display": "flex",
    "alignItems": "center",
    "justifyContent": "center",
    "& input": {
      width: 240,
    },
  },
  twitterAvatar: {
    backgroundColor: "#38A1F3",
    color: "#FFF",
  },
  facebookAvatar: {
    backgroundColor: "#3C5A99",
    color: "#FFF",
  },
  githubAvatar: {
    backgroundColor: "#333",
    color: "#FFF",
  },
  googleAvatar: {
    backgroundColor: "#DB4437",
    color: "#FFF",
  },
  passwordAvatar: {
    backgroundColor: (theme.custom && theme.custom.brightness === "dark") ?
      lighten(theme.palette.primary.main, .5) : "",
  },
});



let _Component: React.ComponentType<any> = Account;
_Component = withStyles(styles, {withTheme: true})(_Component);
_Component = withTranslation("Auth")(_Component);

export default _Component as React.ComponentType<Omit<IAccountProps, TReservedHocProps>>;
