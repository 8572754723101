import * as T from "../../types/constant";
import * as A from "../../index";

export const changeActivity = (activity: string): A.ChangeActivityAction => {
  return {
    type: T.CHANGE_ACTIVITY,
    payload: {activity},
  };
};

export const toggleSidebar = (): A.ToggleSidebarAction => {
  return {
    type: T.TOGGLE_SIDEBAR,
  };
};
