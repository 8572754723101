import DarkTheme from "./themes/dark";
import LightGreenTheme from "./themes/light_green";
import LightGreyTheme from "./themes/light_grey";
import {Theme} from "@material-ui/core";

export interface CustomTheme extends Theme {
  custom?: {
    backgroundColor: string;
    linkColor: string;
    paperBgColor: string;
    tableBgColor: string;
    tableColor: string;
    highlight: string;
    negative: string;
    positive: string;
    sundayColor: string;
    saturdayColor: string;
    incomeColor: string;
    spendColor: string;
    netIncomeColor: string;
    noChangeColor: string;
    chartLineColor1?: string;
    chartLineColor2?: string;
    brightness?: "dark" | "light";
  };
}

interface IThemeMapper {
  default: () => CustomTheme;
  [key: string]: () => CustomTheme;
}

const themeMapper: IThemeMapper = {
  default: DarkTheme,
  dark: DarkTheme,
  light_green: LightGreenTheme,
  light_grey: LightGreyTheme,
};

type IGetThemeFunc = (themeName: string) => CustomTheme;

export const getTheme: IGetThemeFunc = (themeName) => {
  if(typeof(themeMapper[themeName]) === "function"){
    return themeMapper[themeName]();
  }
  
  return themeMapper.default();
};
