import React from "react";
import HomeIcon from "@material-ui/icons/Home";
import CreateIcon from "@material-ui/icons/Create";
import AnalyzeIcon from "@material-ui/icons/TrendingUp";
import PlanningIcon from "@material-ui/icons/CallSplit";
import SettingsIcon from "@material-ui/icons/Settings";
import SettingsGeneralIcon from "@material-ui/icons/AspectRatio";
import SettingsAccountIcon from "mdi-react/AccountEditIcon";
import FinanceInputFlowIcon from "@material-ui/icons/Money";
import FinanceInputBalanceIcon from "@material-ui/icons/AccountBalance";
import FinanceInputItemsIcon from "mdi-react/DatabaseIcon";
import FinancePlanIcon from "mdi-react/NoteTextIcon";
import FinancePlanPeriodGroup from "mdi-react/CalendarMultipleIcon";
import FinanceAnalysisPLBreakdownM from "mdi-react/CircleSlice5Icon";
import FinanceAnalysisPLBreakdownY from "mdi-react/CircleSlice7Icon";
import FinanceAnalysisPLHistoryM from "mdi-react/ResistorIcon";
import FinanceAnalysisPLHistoryY from "mdi-react/ChartAreasplineIcon";
import FinanceAnalysisBSBreakdown from "mdi-react/FileTreeIcon";
import FinanceAnalysisBSHistory from "mdi-react/PollIcon";
import FinanceAnalysisItemHistory from "mdi-react/ChartBarIcon";
import FinanceAnalysisItemGroupHistory from "mdi-react/ChartBarStackedIcon";
import AboutIcon from "mdi-react/AlertBoxIcon";
import * as ReactGA from "react-ga";

import NotFound from "./__container__/NotFound";
import Home from "./Home/__container__";
import Dashboard from "./Home/__container__/Dashboard";
import Welcome from "./Home/__container__/Welcome";

const FinanceInputFlow = React.lazy(() => import("./Finance/Input/Flow/__container__"));
const FinanceInputBalance = React.lazy(() => import("./Finance/Input/Balance/__container__"));
const FinanceInputItems = React.lazy(() => import("./Finance/Input/Items/ItemsEditor/__container__"));
const FinanceInputAccounts = React.lazy(() => import("./Finance/Input/Items/AccountsEditor/__container__"));

const FinanceAnalysisMonthlyPLBreakDown = React.lazy(() => import("./Finance/Analysis/__container__/MonthlyPLBreakDown"));
const FinanceAnalysisYearlyPLBreakDown = React.lazy(() => import("./Finance/Analysis/__container__/YearlyPLBreakDown"));
const FinanceAnalysisMonthlyPLHistory = React.lazy(() => import("./Finance/Analysis/__container__/MonthlyPLHistory"));
const FinanceAnalysisYearlyPLHistory = React.lazy(() => import("./Finance/Analysis/__container__/YearlyPLHistory"));
const FinanceAnalysisMonthlyBSBreakDown = React.lazy(() => import("./Finance/Analysis/__container__/MonthlyBSBreakDown"));
const FinanceAnalysisMonthlyBSHistory = React.lazy(() => import("./Finance/Analysis/__container__/MonthlyBSHistory"));
const FinanceAnalysisMonthlyItemHistory = React.lazy(() => import("./Finance/Analysis/__container__/MonthlyItemHistory"));
const FinanceAnalysisMonthlyItemGroupHistory = React.lazy(() => import("./Finance/Analysis/__container__/MonthlyItemGroupHistory"));

const FinancePlan = React.lazy(() => import("./Finance/Plan/__container__"));
const FinancePlanPeriodGroups = React.lazy(() => import("./Finance/Plan/PeriodGroups/__container__"));

const SettingsGeneral = React.lazy(() => import("./Settings/__container__/General"));
const SettingsAccount = React.lazy(() => import("./Settings/Account/__container__/index"));
const About = React.lazy(() => import("./About/__container__/index"));

const activityMap: {[activityName: string]: React.ComponentType<any>} = {
  "home": Home,
  "dashboard": Dashboard,
  "welcome": Welcome,
  "loggedOut": Home,
  "finance.input.flow": FinanceInputFlow,
  "finance.input.balance": FinanceInputBalance,
  "finance.input.items": FinanceInputItems,
  "finance.input.accounts": FinanceInputAccounts,
  "finance.analysis.monthlyPLBreakdown": FinanceAnalysisMonthlyPLBreakDown,
  "finance.analysis.yearlyPLBreakdown": FinanceAnalysisYearlyPLBreakDown,
  "finance.analysis.monthlyPLHistory": FinanceAnalysisMonthlyPLHistory,
  "finance.analysis.yearlyPLHistory": FinanceAnalysisYearlyPLHistory,
  "finance.analysis.monthlyBSBreakdown": FinanceAnalysisMonthlyBSBreakDown,
  "finance.analysis.monthlyBSHistory": FinanceAnalysisMonthlyBSHistory,
  "finance.analysis.monthlyItemHistory": FinanceAnalysisMonthlyItemHistory,
  "finance.analysis.monthlyItemGroupHistory": FinanceAnalysisMonthlyItemGroupHistory,
  "finance.plan.list": FinancePlan,
  "finance.plan.periodGroups": FinancePlanPeriodGroups,
  "settings.general": SettingsGeneral,
  "settings.account": SettingsAccount,
  "about": About,
};

interface IActivity {
  activity: string;
  Icon?: React.ComponentType<any>;
  label?: string;
  children?: IActivity[];
  hide?: boolean;
}

export type IGetActivities = (isLoggedIn: boolean) => IActivity[];

export const getActivities: IGetActivities = (isLoggedIn) => {
  return [
    { Icon: HomeIcon, activity: isLoggedIn ? "dashboard" : "home", label: isLoggedIn ? "dashboard" : "home"},
    { Icon: CreateIcon, activity: "finance.input.index",
      children: [
        { Icon: FinanceInputFlowIcon, activity: "finance.input.flow" },
        { Icon: FinanceInputBalanceIcon, activity: "finance.input.balance" },
        { Icon: FinanceInputItemsIcon, activity: "finance.input.items" },
        { Icon: FinanceInputItemsIcon, activity: "finance.input.accounts" },
      ],
    },
    { Icon: PlanningIcon, activity: "finance.plan.index",
      children: [
        { Icon: FinancePlanIcon, activity: "finance.plan.list" },
        { Icon: FinancePlanPeriodGroup, activity: "finance.plan.periodGroups" },
      ],
    },
    { Icon: AnalyzeIcon, activity: "finance.analysis.index",
      children: [
        { Icon: FinanceAnalysisPLBreakdownM, activity: "finance.analysis.monthlyPLBreakdown" },
        { Icon: FinanceAnalysisPLBreakdownY, activity: "finance.analysis.yearlyPLBreakdown" },
        { Icon: FinanceAnalysisPLHistoryM, activity: "finance.analysis.monthlyPLHistory" },
        { Icon: FinanceAnalysisPLHistoryY, activity: "finance.analysis.yearlyPLHistory" },
        { Icon: FinanceAnalysisBSBreakdown, activity: "finance.analysis.monthlyBSBreakdown" },
        { Icon: FinanceAnalysisBSHistory, activity: "finance.analysis.monthlyBSHistory" },
        { Icon: FinanceAnalysisItemHistory, activity: "finance.analysis.monthlyItemHistory" },
        { Icon: FinanceAnalysisItemGroupHistory, activity: "finance.analysis.monthlyItemGroupHistory" },
      ],
    },
    { Icon: SettingsIcon, activity: "settings.index",
      children: [
        { Icon: SettingsGeneralIcon, activity: "settings.general" },
        { Icon: SettingsAccountIcon, activity: "settings.account" },
      ],
    },
    { Icon: AboutIcon, activity: "about"},
    { activity: "loggedOut", hide: true },
  ];
};

type IGetActivity = (id: string) => React.ComponentType<any>;

export const getActivity: IGetActivity = (id) => {
  if(!activityMap[id]){
    return NotFound;
  }
  
  // Log pageview for google analytics
  // When activity to access has sub page/sub activity, don't log pv here.
  // PV will be logged in sub page code.
  const activitiesWithSubPage = [
    "input.index",
    "finance.analysis.index",
    "finance.plan.index",
    "settings.index",
  ];
  
  if(!activitiesWithSubPage.includes(id)){
    const virtualURL = window.location.pathname + id.replace(".", "/");
    ReactGA.pageview(virtualURL);
  }
  
  return activityMap[id];
};
