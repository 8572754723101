export const SUCCEEDED_CREATE_USER_ACCOUNT = "SUCCEEDED_CREATE_USER_ACCOUNT";
export const FAILED_CREATE_USER_ACCOUNT = "FAILED_CREATE_USER_ACCOUNT";

export const CHANGE_THEME = "CHANGE_THEME";

export const TOGGLE_SIDEBAR = "TOGGLE_SIDEBAR";

export const SAVE_APP_DATA = "SAVE_APP_DATA";
export const SAVING_APP_DATA = "SAVING_APP_DATA";
export const FAILED_SAVE_APP_DATA = "FAILED_SAVE_APP_DATA";

export const CHANGE_ACTIVITY = "CHANGE_ACTIVITY";

export const UPDATE_FINANCE_FILTER_DATE = "UPDATE_FINANCE_FILTER_DATE";
export const UPDATE_FINANCE_FILTER_ITEM = "UPDATE_FINANCE_FILTER_ITEM";
export const UPDATE_FINANCE_FILTER_ITEMGROUP = "UPDATE_FINANCE_FILTER_ITEMGROUP";
export const UPDATE_FINANCE_FILTER_ACCOUNT = "UPDATE_FINANCE_FILTER_ACCOUNT";
export const UPDATE_FINANCE_FILTER_TAG = "UPDATE_FINANCE_FILTER_TAG";
export const UPDATE_FINANCE_FILTER_VALUE = "UPDATE_FINANCE_FILTER_VALUE";
export const CHANGE_FINANCE_FLOW_SHOW_BUTTON = "CHANGE_FINANCE_FLOW_SHOW_BUTTON";
export const UPDATE_SCROLL_POSITION_FLOW = "UPDATE_SCROLL_POSITION_FLOW";

export const UPDATE_FINANCE_BALANCE_FILTER_DATE = "UPDATE_FINANCE_BALANCE_FILTER_DATE";
export const UPDATE_FINANCE_BALANCE_FILTER_ACCOUNT = "UPDATE_FINANCE_BALANCE_FILTER_ACCOUNT";
export const UPDATE_FINANCE_BALANCE_FILTER_VALUE = "UPDATE_FINANCE_BALANCE_FILTER_VALUE";
export const UPDATE_FINANCE_BALANCE_VIEW_TYPE = "UPDATE_FINANCE_BALANCE_VIEW_TYPE";
export const CHANGE_FINANCE_BALANCE_SHOW_BUTTON = "CHANGE_FINANCE_BALANCE_SHOW_BUTTON";
export const UPDATE_SCROLL_POSITION_BALANCE = "UPDATE_SCROLL_POSITION_BALANCE";
export const UPDATE_SCROLL_POSITION_BALANCE_FOR_DATE = "UPDATE_SCROLL_POSITION_BALANCE_FOR_DATE";

export const CHANGE_ANALYSIS_DATE = "CHANGE_ANALYSIS_DATE";
export const CHANGE_ANALYSIS_BS_HISTORY_VIEW = "CHANGE_ANALYSIS_BS_HISTORY_VIEW";

export const CHANGE_PLANNING_PAGE = "CHANGE_PLANNING_PAGE";
export const CHANGE_PLANNING_PROJECT = "CHANGE_PLANNING_PROJECT";
export const SWITCH_PLANNING_FILTER = "SWITCH_PLANNING_FILTER";
export const ADD_PLANNING_FILTER = "ADD_PLANNING_FILTER";
export const CHANGE_PLANNING_FILTER_ORDER = "CHANGE_PLANNING_FILTER_ORDER";
export const CHANGE_PLANNING_FILTER_LABEL = "CHANGE_PLANNING_FILTER_LABEL";
export const CHANGE_PLANNING_FILTER_RULES = "CHANGE_PLANNING_FILTER_RULES";
export const REMOVE_PLANNING_FILTER = "REMOVE_PLANNING_FILTER";
export const UPDATE_PLANNING_SORT_RULES = "UPDATE_PLANNING_SORT_RULES";
export const UPDATE_PLANNING_ROWS_PER_PAGE = "UPDATE_PLANNING_ROWS_PER_PAGE";
export const UPDATE_PLANNING_RESULT_PAGE = "UPDATE_PLANNING_RESULT_PAGE";

export const CHANGE_PLANNING_PROJECT_VIEWTYPE = "CHANGE_PLANNING_PROJECT_VIEWTYPE";

export const INITIALIZE_ACCOUNT = "INITIALIZE_ACCOUNT";
export const ACCEPT_LICENSE = "ACCEPT_LICENSE";
export const ACCEPT_PRIVACY_POLICY = "ACCEPT_PRIVACY_POLICY";
