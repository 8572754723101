import {connect} from "react-redux";
import Dashboard from "../Dashboard";
import Util from "../../../../core/util/finance";
import {changeActivity} from "../../../../actions/";
import {ThunkDispatch} from "redux-thunk";
import * as R from "../../../../core/reducers/Finance/state";
import * as A from "../../../../actions";
import {getAccountCurrencyMap, getCurrencyInState, getFXValue} from "../../../../core/util/currency";
import * as C from "../../../../core/util/constants";
import {RootState} from "../../../../reducers";


const mapStateToProps = (rootReduxState: RootState) => {
  const {lang} = rootReduxState.App;
  const {
    data_balance,
    data_flow,
    master_items,
    master_itemGroups,
    master_accounts,
    master_accountTypes,
  } = rootReduxState.Finance;
  const currency =  getCurrencyInState(rootReduxState.Finance);
  
  const sorted_data_flow = Util.getSortedFlowData(data_flow);
  const sorted_data_balance = Util.getSortedBalanceData(data_balance);
  
  const bsSummary = getBSSummary(sorted_data_balance, master_accounts, master_accountTypes, currency);
  
  const bsPeriods = getBSPeriod(sorted_data_balance, currency);
  let monthlyBS;
  if(bsPeriods && typeof(bsPeriods.end) === "number"){
    monthlyBS = getBSMonthly(sorted_data_balance, master_accounts, master_accountTypes, bsPeriods.end, 13, currency);
  }
  
  const plPeriods = getPLPeriod(sorted_data_flow, currency);
  let monthlyPL;
  let monthlyGroupPl;
  let monthlyItem;
  if(plPeriods){
    monthlyPL = getPLMonthly(sorted_data_flow, master_items, master_accounts, master_accountTypes, plPeriods.end, 25, currency);
    monthlyGroupPl = getPLForItemGroupsMonthly(
      sorted_data_flow,
      master_items,
      master_itemGroups,
      plPeriods.end,
      25,
      master_accounts,
      currency,
    );
    monthlyItem = getItemsMonthly(sorted_data_flow, master_items, plPeriods.end, 25, master_accounts, currency);
  }
  
  return {
    lang,
    currency,
    bsSummary,
    monthlyBS,
    monthlyPL,
    monthlyItem,
    monthlyGroupPl,
    items: master_items,
    itemGroups: master_itemGroups,
  };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<RootState, undefined, A.RootActions>) => {
  return {
    changeAnalysisMenu: (menu: string) => {
      dispatch(changeActivity(`finance.analysis.${menu}`));
    },
  };
};

const DashboardContainer = connect(mapStateToProps, mapDispatchToProps)(Dashboard);

export default DashboardContainer;




function getBSSummary(
  sorted_balance: R.BalanceData[],
  accounts: R.AccountMaster[],
  accountTypes: R.AccountTypeMaster[],
  currency: R.Currency,
){
  interface IAccountTypeMapper {
    [accountId: number]: {name: string, type: string, balance: number|null};
  }
  
  const accountTypeOf = accounts.reduce((acc: IAccountTypeMapper, a) => {
    const accountType = accountTypes.find(at => at.id === a.type);
    if(!accountType){
      return acc;
    }
    
    acc[a.id] = {name: a.name, type: accountType.type, balance: null};
    return acc;
  }, {}) as IAccountTypeMapper;
  
  let workingAccounts = Object.keys(accountTypeOf).length;
  
  const currencyOf = getAccountCurrencyMap(accounts);
  
  for(let i=0;i<sorted_balance.length;i++){
    if(workingAccounts <= 0){
      break;
    }
    
    const b = sorted_balance[i];
    
    if(!Util.isBalanceActive(b)){
      continue;
    }
    
    if(typeof(accountTypeOf[b.account].balance) !== "number"){
      accountTypeOf[b.account].balance = getFXValue(b.balance, currencyOf[b.account], currency.id, b.date);
      workingAccounts--;
    }
  }
  
  const totalAsset = Object.keys(accountTypeOf).reduce((acc, key) => {
    return acc + (accountTypeOf[+key].type === "totalAsset" ? accountTypeOf[+key].balance || 0 : 0);
  }, 0);
  
  let liability = Object.keys(accountTypeOf).reduce((acc, key) => {
    return acc + (accountTypeOf[+key].type === "liability" ? accountTypeOf[+key].balance || 0 : 0);
  }, 0);
  
  liability = Math.abs(liability);
  
  const netAsset = totalAsset - liability;
  
  return {totalAsset, liability, netAsset};
}



function getBSPeriod(sorted_balance: R.BalanceData[], currency: R.Currency){
  if(!Array.isArray(sorted_balance) || sorted_balance.length < 1){
    return null;
  }
  
  let start;
  let end;
  
  for(let i=0;i<sorted_balance.length;i++){
    const b = sorted_balance[i];
    if(typeof(b.balance) === "number" && typeof(b.date) === "number"){
      end = Util.getDateFromCustomDate(b.date).getTime();
      break;
    }
  }
  
  for(let i=sorted_balance.length-1;i>=0;i--){
    const b = sorted_balance[i];
    if(typeof(b.balance) === "number" && typeof(b.date) === "number"){
      start = Util.getDateFromCustomDate(b.date).getTime();
      break;
    }
  }
  
  return {
    start,
    end,
  };
}



/**
 *
 * @param {Array<Object>} sorted_balance
 * @param {Array<Object>} accounts
 * @param {Array<Object>} accountTypes
 * @param {number} end - i.e. (new Date()).getTime()
 * @param {number} months
 * @param {Object} currency
 * @returns {{period: Date, totalAsset: number, liability: number, netAsset: number}[]}
 */
function getBSMonthly(
  sorted_balance: R.BalanceData[],
  accounts: R.AccountMaster[],
  accountTypes: R.AccountTypeMaster[],
  end: number,
  months: number,
  currency: R.Currency,
){
  const periods = [] as Array<{period: number, totalAsset: number, liability: number, netAsset?: number}>;
  
  const prevYM = (ym: number): number => {
    let y = Math.floor(ym / 100);
    let m = ym % 100;
    if(m-1 < 1){
      y--;
      m = 12;
    }
    else{
      m--;
    }
    
    return y * 100 + m;
  };
  
  const nextYM = (ym: number): number => {
    let y = Math.floor(ym / 100);
    let m = ym % 100;
    if(m+1 > 12){
      y++;
      m = 1;
    }
    else{
      m++;
    }
    
    return y * 100 + m;
  };
  
  let lastDate = new Date(end);
  lastDate.setDate(1);
  lastDate.setMonth(lastDate.getMonth() + 1);
  lastDate = new Date(`${lastDate.getFullYear()}-${lastDate.getMonth()+1}-01 00:00:00`);
  lastDate.setDate(0);
  
  for(let i=0;i<months;i++){
    const period = new Date(lastDate).getTime();
    periods.unshift({period, totalAsset: 0, liability: 0});
    lastDate.setDate(0);
  }
  
  periods.sort((a, b) => a.period - b.period);
  
  interface IAccountTypeMapper {
    [id: number]: string|null;
  }
  
  const accountTypeOf = accounts.reduce((acc: IAccountTypeMapper, a) => {
    const accountType = accountTypes.find(at => at.id === a.type);
    acc[a.id] = accountType ? accountType.type : null;
    return acc;
  }, {}) as IAccountTypeMapper;
  
  const currencyOf = getAccountCurrencyMap(accounts);
  
  interface IAccountAtYM {
    [ym: number]: {
      [id: number]: number;
    };
  }
  
  const accountAtYM: IAccountAtYM = {};
  
  let minYM;
  let maxYM;
  
  for(let i=sorted_balance.length-1;i>=0;i--){
    const b = sorted_balance[i];
    if(!Util.isBalanceActive(b)){
      continue;
    }
    
    const {y, m} = Util.parseCustomDate(b.date);
    const ym = y*100 + m;
    if(!accountAtYM[ym]){
      accountAtYM[ym] = {};
    }
    
    accountAtYM[ym][b.account] = getFXValue(b.balance, currencyOf[b.account], currency.id, b.date);
    
    if(typeof(minYM) !== "number"){
      minYM = ym;
    }
    
    maxYM = ym;
  }
  
  if(typeof(minYM) !== "number" || typeof(maxYM) !== "number"){
    return [];
  }
  
  const accountIds = Object.keys(accountTypeOf);
  for(let ym=minYM;ym<=maxYM;ym=nextYM(ym)){
    for(let i=0;i<accountIds.length;i++){
      const accountId = +accountIds[i];
      
      if(!accountAtYM[ym]){
        const lastYM = prevYM(ym);
        const lastYMDataExist = (lastYM > minYM && accountAtYM[lastYM] && typeof(accountAtYM[lastYM][accountId]) === "number");
  
        accountAtYM[ym] = lastYMDataExist ? {[accountId]: accountAtYM[lastYM][accountId]} : {[accountId]: 0};
      }
      else if(typeof(accountAtYM[ym][accountId]) !== "number"){
        const lastYM = prevYM(ym);
        const lastYMDataExist = (lastYM > minYM && accountAtYM[lastYM] && typeof(accountAtYM[lastYM][accountId]) === "number");
        accountAtYM[ym][accountId] = lastYMDataExist ? accountAtYM[lastYM][accountId] : 0;
      }
    }
  }
  
  interface IAsset {
    totalAsset: number;
    liability: number;
    netAsset: number;
  }
  
  interface IAssetAtYM {
    [ym: number]: IAsset;
  }
  
  // {201009: {1: 1000, 2: 30495, 3: 3045, ...}} -> {201009: {totalAsset: 20000, liability: -1000}, 201109: {...}}
  const assetAtYM = Object.keys(accountAtYM).reduce((acc: IAssetAtYM, ymStr) => {
    const ym = +ymStr;
    acc[ym] = Object.keys(accountAtYM[ym]).reduce((acc2: IAsset, accountIdStr) => {
      const accountId = +accountIdStr;
      const accountType = accountTypeOf[accountId];
      if(accountType === "totalAsset" || accountType === "liability" || accountType === "netAsset"){
        acc2[accountType] += accountAtYM[ym][accountId];
      }
      return acc2;
    }, {totalAsset: 0, liability: 0, netAsset: 0});
    
    return acc;
  }, {}) as IAssetAtYM;
  
  return periods.map(p => {
    const date = new Date(p.period);
    const ym = date.getFullYear() * 100 + date.getMonth()+1;
    
    let totalAsset;
    let liability;
    let netAsset;
    if(assetAtYM[ym]){
      ({totalAsset, liability, netAsset} = assetAtYM[ym]);
      netAsset = totalAsset + liability + netAsset;
    }
    
    return {
      ...p,
      period: date,
      totalAsset,
      liability,
      netAsset,
    };
  });
}




function getPLPeriod(sorted_flow: R.FlowData[], currency: R.Currency){
  if(!Array.isArray(sorted_flow) || sorted_flow.length < 1){
    return null;
  }
  
  let start;
  let end;
  
  for(let i=0;i<sorted_flow.length;i++){
    const f = sorted_flow[i];
    if(Util.isFlowActive(f)){
      end = Util.getDateFromCustomDate(f.date).getTime();
      break;
    }
  }
  
  for(let i=sorted_flow.length-1;i>=0;i--){
    const f = sorted_flow[i];
    if(Util.isFlowActive(f)){
      start = Util.getDateFromCustomDate(f.date).getTime();
      break;
    }
  }
  
  if(!start || !end){
    return null;
  }
  
  return {
    start,
    end,
  };
}



function getItemsMonthly(
  sorted_flow: R.FlowData[],
  items: R.ItemMaster[],
  end: number,
  months: number,
  accounts: R.AccountMaster[],
  currency: R.Currency,
){
  interface IPeriods {
    period: number;
    data: {
      [itemId: number]: number;
    };
  }
  const periods: IPeriods[] = [];
  
  for(let i=0;i<months;i++){
    const period_date = new Date(end);
    period_date.setDate(1);
    period_date.setMonth(period_date.getMonth() - i);
    const period = period_date.getFullYear() * 100 + period_date.getMonth() + 1;
    
    periods.unshift({period, data: {}});
  }
  
  const currencyOf = getAccountCurrencyMap(accounts);
  
  let workingPeriodIndex = 0;
  
  for(let i=sorted_flow.length-1;i>=0;i--){
    if(workingPeriodIndex > periods.length - 1){
      break;
    }
    
    const f = sorted_flow[i];
    
    if(!Util.isFlowActive(f)){
      continue;
    }
    
    const {y, m} = Util.parseCustomDate(f.date);
    const currentYM = y*100 + m;
  
    if(currentYM < periods[workingPeriodIndex].period){
      continue;
    }
    
    while(workingPeriodIndex<periods.length){
      if(currentYM === periods[workingPeriodIndex].period){
        break;
      }
  
      workingPeriodIndex++;
    }
    
    if(workingPeriodIndex >= periods.length){
      workingPeriodIndex = periods.length - 1;
    }
    
    const adjustedValue = getFXValue(f.value, currencyOf[f.account], currency.id, f.date);
    
    if(typeof(periods[workingPeriodIndex].data[f.item]) !== "number"){
      periods[workingPeriodIndex].data[f.item] = adjustedValue;
    }
    else{
      periods[workingPeriodIndex].data[f.item] += adjustedValue || 0;
    }
  }
  
  return periods;
}



function getPLMonthly(
  sorted_flow: R.FlowData[],
  items: R.ItemMaster[],
  accounts: R.AccountMaster[],
  accountTypes: R.AccountTypeMaster[],
  end: number,
  months: number,
  currency: R.Currency,
){
  interface IPeriods {
    period: number;
    profit: number|null;
    loss: number|null;
    pl: number|null;
  }
  const periods: IPeriods[] = [];
  
  for(let i=0;i<months;i++){
    const period_date = new Date(end);
    period_date.setDate(1);
    period_date.setMonth(period_date.getMonth() - i);
    const period = period_date.getFullYear() * 100 + period_date.getMonth() + 1;
    
    periods.unshift({period, profit: null, loss: null, pl: null});
  }
  
  let workingPeriodIndex = 0;
  
  interface IItemOf {
    [id: number]: R.ItemMaster;
  }
  
  const itemOf = items.reduce((acc: IItemOf, item) => {
    acc[item.id] = item;
    return acc;
  }, {}) as IItemOf;
  
  const currencyOf = getAccountCurrencyMap(accounts);
  
  for(let i=sorted_flow.length-1;i>=0;i--){
    if(workingPeriodIndex > periods.length - 1){
      break;
    }
    
    const f = sorted_flow[i];
    
    if(!Util.isFlowActive(f)){
      continue;
    }
    
    if(!itemOf[f.item] || itemOf[f.item].group === C.ITEM_GROUP__UNCOUNTING){
      continue;
    }
    
    const {y, m} = Util.parseCustomDate(f.date);
    const currentYM = y*100 + m;
  
    if(currentYM < periods[workingPeriodIndex].period){
      continue;
    }
    
    while(workingPeriodIndex<periods.length){
      if(currentYM === periods[workingPeriodIndex].period){
        break;
      }
      workingPeriodIndex++;
    }
    
    if(workingPeriodIndex>=periods.length){
      workingPeriodIndex = periods.length - 1;
    }
    
    const adjustedValue = getFXValue(f.value, currencyOf[f.account], currency.id, f.date);
    
    if(adjustedValue >= 0){
      if(periods[workingPeriodIndex].profit === null){
        periods[workingPeriodIndex].profit = adjustedValue;
      }
      else{
        (periods[workingPeriodIndex].profit as number) += adjustedValue || 0;
      }
    }
    else{
      if(periods[workingPeriodIndex].loss === null){
        periods[workingPeriodIndex].loss = adjustedValue;
      }
      else{
        (periods[workingPeriodIndex].loss as number) += adjustedValue || 0;
      }
    }
    
    if(periods[workingPeriodIndex].pl === null){
      periods[workingPeriodIndex].pl = adjustedValue;
    }
    else{
      (periods[workingPeriodIndex].pl as number) += adjustedValue || 0;
    }
  }
  
  return periods;
}




function getPLForItemGroupsMonthly(
  sorted_flow: R.FlowData[],
  items: R.ItemMaster[],
  itemGroups: R.ItemGroupMaster[],
  end: number,
  months: number,
  accounts: R.AccountMaster[],
  currency: R.Currency,
){
  interface IPeriods {
    period: number;
    data: {
      [id: number]: number;
    };
  }
  const periods: IPeriods[] = [];
  
  for(let i=0;i<months;i++){
    const period_date = new Date(end);
    period_date.setDate(1);
    period_date.setMonth(period_date.getMonth() - i);
    const period = period_date.getFullYear() * 100 + period_date.getMonth() + 1;
    
    periods.unshift({period, data: {}});
  }
  
  let workingPeriodIndex = 0;
  
  interface IItemGroupOf {
    [itemGroupId: number]: R.ItemGroupMaster;
  }
  
  const itemGroupOf = itemGroups.reduce((acc: IItemGroupOf, itemGroup) => {
    if(itemGroup.id < 1){
      return acc;
    }
    
    acc[itemGroup.id] = itemGroup;
    return acc;
  }, {}) as IItemGroupOf;
  
  const currencyOf = getAccountCurrencyMap(accounts);
  
  for(let i=sorted_flow.length-1;i>=0;i--) {
    if (workingPeriodIndex > periods.length - 1) {
      break;
    }
  
    const f = sorted_flow[i];
  
    if (!Util.isFlowActive(f)) {
      continue;
    }
    
    const item = items.find(itm => itm.id === f.item);
    if(!item){
      continue;
    }
    
    if(!itemGroupOf[item.group] || Util.isFixedItemGroup(itemGroupOf[item.group])){
      continue;
    }
  
    const {y, m} = Util.parseCustomDate(f.date);
    const currentYM = y*100 + m;
  
    if(currentYM < periods[workingPeriodIndex].period){
      continue;
    }
  
    while(workingPeriodIndex<periods.length){
      if(currentYM === periods[workingPeriodIndex].period){
        break;
      }
  
      workingPeriodIndex++;
    }
    
    if(workingPeriodIndex >= periods.length){
      workingPeriodIndex = periods.length -1;
    }
    
    const adjustedValue = getFXValue(f.value, currencyOf[f.account], currency.id, f.date);
  
    if(typeof(periods[workingPeriodIndex].data[item.group]) !== "number"){
      periods[workingPeriodIndex].data[item.group] = adjustedValue;
    }
    else{
      periods[workingPeriodIndex].data[item.group] += adjustedValue || 0;
    }
  }
  
  return periods;
}
