import {connect} from "react-redux";
import ErrorBoundary from "../ErrorBoundary";
import {ThunkDispatch} from "redux-thunk";
import * as A from "../../../actions";
import {RootState} from "../../../reducers";

const mapStateToProps = (rootReduxState: RootState) => {
  const {lang} = rootReduxState.App;
  return {lang};
};

const mapDispatchToProps = (dispatch: ThunkDispatch<RootState, undefined, A.RootActions>) => {
  return {
  };
};

const Container = connect(mapStateToProps, mapDispatchToProps)(ErrorBoundary);

export default Container;
