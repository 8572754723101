import firebase from "firebase/app";
import {firebaseAuth, firebaseDb} from "../";
import {User as fbUser} from "firebase";
import {RootState} from "../../../reducers";

export async function initUserAccountData(uid: string, initialAppState: RootState){
  /**
   * Check contract first.
   * If no active contract found, then do not save user's state.
   */
  const hasValidContract = await checkUserHasContract(uid);
  if(!hasValidContract){
    return null;
  }
  
  const appStateRef = firebaseDb.ref(`users/${uid}/appState`);
  
  let error;
  await appStateRef.set(initialAppState).catch(e => {
    error = e;
  });
  
  if(error){
    return error;
  }
  
  return null;
}

export async function checkUserHasContract(uid: string){
  const contractRef = firebaseDb.ref(`users/${uid}/contract`);
  const snapshot = await contractRef.once("value");
  const contract = snapshot.val();
  if(!contract || typeof(contract) !== "object"){
    return false;
  }
  
  const now = Date.now();
  return contract.valid && (contract.validFrom <= now && now <= contract.validUntil);
}

export async function checkUserPasswordForFirebase(password: string): Promise<boolean>{
  const user = firebaseAuth.currentUser;
  if(!user){
    return false;
  }
  
  /**
   * If provider is social services, don't check password.
   */
  if(!isPasswordLogin()){
    return true;
  }
  
  if(!user.email){
    throw new Error("Email not found");
  }
  
  const credential = firebase.auth.EmailAuthProvider.credential(user.email, password);
  
  let error;
  const userCredential = await user.reauthenticateAndRetrieveDataWithCredential(credential)
    .catch(reason => {
      if(process.env.REACT_APP_ENV === "development"){
        console.error("Error while re-authenticating");
        console.error(reason);
      }
      
      error = Boolean(reason);
      return null;
    });
  
  return Boolean(userCredential && !error);
}

export function getCurrentUser(){
  return firebaseAuth.currentUser;
}

export function getUserPrimaryEmail(){
  const user = getCurrentUser();
  if(!user){
    return null;
  }
  
  return user.email;
}

export async function signOut(){
  const user = getCurrentUser();
  if(!user){
    return true;
  }
  
  return firebaseAuth.signOut();
}

export function setLanguageForAuth(lang: string){
  firebaseAuth.languageCode = lang;
}

export async function checkUserSignedIn(): Promise<fbUser | null>{
  return new Promise((resolve, reject) => {
    const unsubscribe = firebaseAuth.onAuthStateChanged((userObj: fbUser | null) => {
      if (process.env.REACT_APP_ENV === "development") {
        console.log("Check auth state: ", userObj || "No sign in information");
      }
      
      unsubscribe();
      resolve(userObj);
    });
  });
}

export async function getSignInMethod(email: string){
  return firebaseAuth.fetchSignInMethodsForEmail(email)
    .catch(reason => {
      if(process.env.REACT_APP_ENV === "development"){
        console.error("Error while fetching sign in methods for email");
        console.error(reason);
      }
      
      return null;
    });
}

export async function signInByPassword(email: string, password: string){
  return firebaseAuth.signInWithEmailAndPassword(email, password);
}

export async function signInBySocial(snsProvider: firebase.auth.AuthProvider){
  return firebaseAuth.signInWithPopup(snsProvider);
}

export async function createAccountWithPassword(email: string, password: string){
  return firebaseAuth.createUserWithEmailAndPassword(email, password || "");
}

export function isPasswordLogin(){
  const user = getCurrentUser();
  if(!user){
    return false;
  }
  
  return user.providerData.some(d => {
    return d !== null && d.providerId === firebase.auth.EmailAuthProvider.PROVIDER_ID;
  });
}

export async function sendPasswordResetEmail(email: string, lang: string){
  firebaseAuth.languageCode = lang;
  
  let error: any;
  await firebaseAuth.sendPasswordResetEmail(email)
    .catch(reason => {
      if(process.env.REACT_APP_ENV === "development"){
        console.error("Error while sending password resetting email");
        console.error(reason);
      }
      
      if(reason && reason.code){
        error = reason.code;
      }
      else if(!reason){
        error = "Unknown error";
      }
      else{
        error = reason;
      }
    });
  
  return error;
}

export function getUserImageUrl(){
  const user = getCurrentUser();
  if(!user){
    return "";
  }
  
  const provider = user.providerData.find(d => {
    if(!d){
      return false;
    }
    
    return Boolean(d.photoURL);
  });
  
  return provider ? provider.photoURL : "";
}

export async function updateEmail(newEmail: string, password: string){
  const user = getCurrentUser();
  if(!user){
    return "user-not-signed-in";
  }
  
  // If provider is social services, stop procedure.
  if(!isPasswordLogin()){
    return "not-password-sign-in";
  }
  
  if(!user.email){
    return "email-not-found";
  }
  
  const credential = firebase.auth.EmailAuthProvider.credential(user.email, password);
  
  let error: any;
  const userCredential = await user.reauthenticateAndRetrieveDataWithCredential(credential)
    .catch(reason => {
      if(process.env.REACT_APP_ENV === "development"){
        console.error("Error while re-authenticating");
        console.error(reason);
      }
      
      error = reason;
      return null;
    });
  
  if(error){
    if(error.code){
      return error.code;
    }
    
    return error;
  }
  
  if(!userCredential || !userCredential.user){
    return "no-user-credential";
  }
  
  await userCredential.user.updateEmail(newEmail)
    .catch(reason => {
      if(process.env.REACT_APP_ENV === "development"){
        console.error("Error while updating email");
        console.error(reason);
      }
  
      error = reason;
    });
  
  if(error){
    if(error.code){
      return error.code;
    }
    
    return error;
  }
  
  return null;
}

export async function updatePassword(currentPassword: string, newPassword: string){
  const user = getCurrentUser();
  if(!user){
    return "user-not-signed-in";
  }
  
  // If provider is social services, stop procedure.
  if(!isPasswordLogin()){
    return "not-password-sign-in";
  }
  
  if(!user.email){
    return "email-not-found";
  }
  
  const credential = firebase.auth.EmailAuthProvider.credential(user.email, currentPassword);
  
  let error: any;
  const userCredential = await user.reauthenticateAndRetrieveDataWithCredential(credential)
    .catch(reason => {
      if(process.env.REACT_APP_ENV === "development"){
        console.error("Error while re-authenticating");
        console.error(reason);
      }
      
      error = reason;
      return null;
    });
  
  if(error){
    if(error.code){
      return error.code;
    }
  
    return error;
  }
  
  if(!userCredential || !userCredential.user){
    return "no-user-credential";
  }
  
  await userCredential.user.updatePassword(newPassword)
    .catch(reason => {
      if(process.env.REACT_APP_ENV === "development"){
        console.error("Error while updating email");
        console.error(reason);
      }
      
      error = reason;
    });
  
  if(error){
    if(error.code){
      return error.code;
    }
    
    return error;
  }
  
  return null;
}
