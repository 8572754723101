import createMuiTheme from "@material-ui/core/styles/createMuiTheme";
import {CustomTheme} from "../index";

const getTheme = () => {
  const theme: CustomTheme = {
    ...createMuiTheme({
      typography: {
        useNextVariants: true,
        h5: {
          fontWeight: 500,
          fontSize: 26,
          letterSpacing: 0.5,
        },
        body2: {
          "fontSize": "inherit",
          "& > a": {
            color: "rgba(255,255,255,1)",
          },
        },
      },
      palette: {
        primary: {
          main: "#18202c",
        },
        secondary: {
          main: "#535f6e",
        },
        error: {
          main: "rgba(255,120,20,.9)",
        },
        text: {
          primary: "rgba(255,255,255,0.85)",
          secondary: "rgba(255,255,255,.4)",
        },
      },
      shape: {
        borderRadius: 8,
      },
    }),
    custom: {
      backgroundColor: "rgb(79, 88, 100)",
      linkColor: "#0091ea",
      paperBgColor: "#232f3e",
      tableBgColor: "#232f3e",
      tableColor: "rgba(255,255,255,.7)",
      highlight: "rgba(35,47,62,.8)",
      negative: "rgba(255,110,20,1)",
      positive: "rgba(0,255,80,.9)",
      sundayColor: "rgba(255,110,0,1)",
      saturdayColor: "rgba(0,100,255,1)",
      incomeColor: "rgb(83, 101, 201)",
      spendColor: "rgba(255,110,20,1)",
      netIncomeColor: "rgb(46, 125, 50)",
      noChangeColor: "rgba(255,255,255,.6)",
      chartLineColor1: "#44CC99",
      chartLineColor2: "#4499CC",
      brightness: "dark",
    },
  };
  
  theme.overrides = {
    MuiAppBar: {
      colorPrimary: {
        backgroundColor: "#232f3e",
        color: "#c2c2c2",
      },
    },
    MuiDrawer: {
      paper: {
        backgroundColor: "#18202c",
        paddingTop: 16,
      },
    },
    MuiPaper: {
      root: {
        backgroundColor: theme.custom ? theme.custom.paperBgColor : "",
        color: theme.palette.text.primary,
      },
      rounded: {
        borderRadius: 2,
      },
      elevation8: {
        border: "3px solid rgba(255,255,255,.4)",
      },
    },
    MuiButton: {
      textPrimary: {
        "backgroundColor": "#18202c",
        "color": "rgba(255,255,255,0.7)",
        "&:hover": {
          backgroundColor: undefined,
        },
      },
      textSecondary: {
        "backgroundColor": "#18202c",
        "color": "rgba(255,255,255,0.7)",
        "&:hover": {
          backgroundColor: undefined,
        },
      },
      containedPrimary: {
        "backgroundColor": "#18202c",
        "color": "rgba(255,255,255,0.7)",
        "border": "rgba(255,255,255,0.7)",
        "&:hover": {
          backgroundColor: undefined,
        },
      },
      outlinedPrimary: {
        "backgroundColor": "#18202c",
        "color": "rgba(255,255,255,0.7)",
        "&:hover": {
          backgroundColor: undefined,
        },
        "border": "1px solid transparent",
      },
      contained: {
        "backgroundColor": "#18202c",
        "color": "rgba(255,255,255,0.7)",
        "boxShadow": "none",
        "&:active": {
          boxShadow: "none",
        },
        "&$disabled": {
          color: "rgba(255,255,255,.7)",
        },
      },
      outlined: {
        border: "1px solid transparent",
      },
      root: {
        "borderRadius": 3,
        "&:hover": {
          color: "rgba(255,255,255,0.7)",
          backgroundColor: "rgba(255,255,255,.08)",
        },
        "&$disabled": {
          color: "rgba(255,255,255,.3)",
        },
      },
    },
    MuiListItem: {
      root: {
        "paddingTop": 4,
        "paddingBottom": 4,
        "color": "rgba(255, 255, 255, 0.7)",
        "&$selected": {
          "color": "#4fc3f7",
          "backgroundColor": "unset",
          "&:hover": {
            backgroundColor: "rgba(255, 255, 255, 0.08)",
          },
          "& > div + div": {
            borderRight: "2px solid transparent",
          },
        },
      },
      button: {
        "&:hover": {
          backgroundColor: "rgba(255, 255, 255, 0.08)",
        },
      },
    },
    MuiListItemText: {
      primary: {
        "fontWeight": theme.typography.fontWeightMedium,
        "color": "inherit",
        "fontSize": theme.typography.fontSize,
        "&$textDense": {
          fontSize: theme.typography.fontSize,
        },
      },
      textDense: {
      },
    },
    MuiListItemIcon: {
      root: {
        "color": "inherit",
        "& svg": {
          fontSize: 20,
        },
      },
    },
    MuiDivider: {
      root: {
        backgroundColor: "#404854",
      },
    },
    MuiIconButton: {
      root: {
        "color": "rgba(255,255,255,0.7)",
        "&:hover": {
          backgroundColor: "rgba(255,255,255,.09)",
        },
        "&$disabled": {
          color: "rgba(255,255,255,0.2)",
        },
      },
      colorPrimary: {
        "color": "rgba(255,255,255,0.7)",
        "&:hover": {
          backgroundColor: "rgba(255,255,255,.09)",
        },
      },
      colorSecondary: {
        "color": "rgba(255,255,255,0.7)",
        "&:hover": {
          backgroundColor: "rgba(255,255,255,.09)",
        },
      },
    },
    MuiSwitch: {
      iconChecked: {
        color: "rgba(180,180,180,1)",
      },
      bar: {
        backgroundColor: "rgba(255,255,255,.9) !important",
      },
    },
    MuiFormLabel: {
      root: {
        "color": "rgba(255,255,255,0.7)",
        "&$focused": {
          color: "rgba(255,255,255,0.7)",
        },
        "&$disabled": {
          color: "rgba(255,255,255,0.7)",
        },
      },
    },
    MuiInputBase: {
      root: {
        "&$disabled": {
          color: "rgba(255,255,255,0.7)",
        },
      },
    },
    MuiInput: {
      underline: {
        "&:before": {
          borderBottom: "1px solid rgba(255,255,255,.5)",
        },
        "&:after": {
          borderBottom: "1px solid rgba(255,255,255,.5)",
        },
      },
    },
    MuiSelect: {
      select: {
        color: "rgba(255,255,255,0.7)",
      },
      icon: {
        color: "rgba(255,255,255,0.7)",
      },
    },
    MuiCheckbox: {
      root: {
        color: undefined,
      },
      colorSecondary: {
        "&$checked": {
          color: "#535f6e",
        },
      },
    },
    MuiFormHelperText: {
      root: {
        color: "rgba(255,255,255,0.7)",
      },
    },
    MuiTableCell: {
      head: {
        color: "rgba(255,255,255,0.7)",
        fontSize: "0.75rem",
        fontWeight: 500,
      },
    },
    MuiTablePagination: {
      root: {
        color: "rgba(255,255,255,.7)",
        fontSize: ".75rem",
      },
    },
    MuiSvgIcon: {
      colorPrimary: {
        color: "rgba(255,255,255,.7)",
      },
    },
    MuiOutlinedInput: {
      root: {
        "& $notchedOutline": {
          borderColor: "rgba(255,255,255,.7)",
        },
      },
    },
    MuiFab: {
      secondary: {
        "backgroundColor": "#535f6e",
        "&:hover": {
          backgroundColor: "#737f8e",
        },
      },
    },
    MuiTabs: {
      indicator: {
        backgroundColor: "#eee",
        height: 3,
      },
    },
    MuiTab: {
      root: {
        color: theme.palette.text.primary,
      },
    },
    MuiRadio: {
      root: {
        color: theme.palette.text.primary,
      },
    },
    MuiNativeSelect: {
      icon: {
        color: "rgba(255,255,255,0.7)",
      },
      select: {
        "color": theme.palette.text.primary,
        "& option": {
          backgroundColor: theme.custom ? theme.custom.paperBgColor : "",
          color: theme.palette.text.primary,
        },
      },
    },
  };
  
  theme.mixins = {
    ...theme.mixins,
    toolbar: {
      minHeight: 56,
    },
  };
  
  return theme;
};

export default getTheme;
