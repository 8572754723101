import {getCurrentDate, getNowInCustomDate} from "../../../../core/util/finance";
import * as R from "../../../../core/reducers/Finance/state";
import {GeneratorParams} from "../../../../core/util/dataGenerator";
import {CURRENCY_JPY} from "../../../../core/util/constants";
import {getFXValue} from "../../../../core/util/currency";

type IGetParamsFunc = (currency: R.Currency) => GeneratorParams;

/**
 * "name": "Employee E", "age": "29", "sex": "Female", "annualIncome": "$ 120,000", "family": "Husband, child", "money": "High standard",
 *
 * spending
 *  She gets high salary by working at Mass media. She spends money according to her income.
 *  The rent expenditure is quite large. Because of hard work,
 *  food and living supply expenses are not as much as expected.
 *  Since she does not have time to nurture her child by herself, she outsource her child's childcare/education using private service.
 *  She rarely travel. There is repayment of her car loan.
 *
 * income
 *  Every month she earns quite a high salary.
 *  Revenue from overtime work is large. So bonus does.
 *  Because sales of the company are not fixed every year, the change in bonus is large, but since the basic salary is high level,
 *  salary will not be extremely lowered.
 */
const getParams: IGetParamsFunc = (currency) => {
  const now = getCurrentDate();
  const startY = now.getFullYear();
  const startM = 1;
  const nowCustomDate = getNowInCustomDate();
  const JPY = CURRENCY_JPY.id;
  
  return {
    years: 3,
    start_fund: getFXValue(1000000, JPY, currency.id, nowCustomDate),
    start_debt: getFXValue(3500000, JPY, currency.id, nowCustomDate),
    start_fixedAsset: getFXValue(3500000, JPY, currency.id, nowCustomDate),
    debt_interest: 1.5,
    debt_period: 5,
    repayment_method: "equalPayments", // ["equalPayments", "equalInstalments"]
    income_allowances: getFXValue(40000, JPY, currency.id, nowCustomDate),
    income_basePay: getFXValue(470000, JPY, currency.id, nowCustomDate),
    income_bonusAmount: getFXValue(3000000, JPY, currency.id, nowCustomDate),
    income_bonusCount: 2,
    income_commuting: getFXValue(120000, JPY, currency.id, nowCustomDate),
    income_otherIncome: getFXValue(0, JPY, currency.id, nowCustomDate),
    income_overtime: 80,
    income_rfBonusAmount: 40,
    income_rfOtherIncome: 20,
    income_rfOvertime: 15,
    income_rfRiseRate: 30,
    income_riseRate: 3,
    income_workingHours: 8,
    income_overtimeRaiseRate: 25,
    income_pensionChargeRate: 9.15,
    income_healthInsuranceChargeRate: 4.4,
    income_employmentInsuranceChargeRate: 0.3,
    fixedAssetUsefulLives: 5,
    autoFixedAssetUsefulLives: true,
    income_feeding: 2,
    startY: startY,
    startM: startM,
    income_prevYearTotalIncome: null,
    income_prevYearTotalSocialInsurance: null,
    income_initialNormalizedPay: null,
    income_TotalIncome2YearsAgo: null,
    income_TotalSocialInsurance2YearsAgo: null,
    income_initialNormalizedPayLastTerm: null,
    spending_hobby: getFXValue(70000, JPY, currency.id, nowCustomDate),
    spending_beauty: getFXValue(60000, JPY, currency.id, nowCustomDate),
    spending_communication: getFXValue(20000, JPY, currency.id, nowCustomDate),
    spending_commuting: getFXValue(20000, JPY, currency.id, nowCustomDate),
    spending_education: getFXValue(2000, JPY, currency.id, nowCustomDate),
    spending_electricity: getFXValue(7000, JPY, currency.id, nowCustomDate),
    spending_food: getFXValue(150000, JPY, currency.id, nowCustomDate),
    spending_gasWater: getFXValue(5000, JPY, currency.id, nowCustomDate),
    spending_goods: getFXValue(60000, JPY, currency.id, nowCustomDate),
    spending_house: getFXValue(240000, JPY, currency.id, nowCustomDate),
    spending_medical: getFXValue(12000, JPY, currency.id, nowCustomDate),
    spending_unclassified: getFXValue(30000, JPY, currency.id, nowCustomDate),
    spending_trip_budget: getFXValue(200000, JPY, currency.id, nowCustomDate),
    spending_trip_count: 1,
    spending_rf: 30,
    spending_debtBonusChargeRate: 20,
    currency,
  };
};

export default getParams;
