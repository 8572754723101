import React from "react";
import {withTranslation} from "react-i18next";
import i18next from "i18next";
import {Lang} from "../../reducers/App/state";
import {Omit, TReservedHocProps} from "../../util/type";

interface INotFoundProps {
  i18n: i18next.i18n;
  lang: Lang;
}

class NotFound extends React.Component<INotFoundProps> {
  public render(){
    const {i18n, lang} = this.props;
    const t = i18n.getFixedT(lang, "NotFound");
    
    return (
      <div>
        <h2>{t<string>("caption")}</h2>
        <p>
          {t<string>("body")}
        </p>
      </div>
    );
  }
}

let _Component: React.ComponentType<any> = NotFound;
_Component = withTranslation("NotFound")(_Component);

export default _Component as React.ComponentType<Omit<INotFoundProps, TReservedHocProps>>;
