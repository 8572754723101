import {Reducer} from "redux";
import S from "./state";
import getInitialFinanceState from "./initialState";
import {
  addUnaccountedItem,
  findBalanceIndex,
  getFlowDataSortedByDateWithSortMap,
  getSortedBalanceData,
  getSortedFlowData,
  insertBalanceAt,
  isBalanceActive,
  isFixedAccountType,
  isFlowActive,
  ModifyFutureIndexInChangeList,
  provisionToUnsortedFlowData,
  reBalanceAllFlow,
  removeBalanceAt,
  removeUnaccountedItem,
  reorderIndexMapsAfterDeleting,
  reorderIndexMapsAfterInserting,
} from "../../util/finance";
import {
  ACCOUNT_TYPE__CASH,
  ITEM__START_AMOUNT,
  ITEM_GROUP__GENERAL,
} from "../../util/constants";
import * as A from "../../actions/types/finance/input";
import * as T from "../../actions/types/constant";

type InputReducer = Reducer<S.FinanceState, A.FinanceInputActions>;

const financeReducer: InputReducer = (state = getInitialFinanceState(), action) => {
  switch(action.type){
    case T.REPLACE_FINANCE_FLOW_DATA: {
      const {flow} = (action.payload as A.IReplaceFlowDataPayload);
      return {
        ...state,
        data_flow: [...flow],
        unsaved: true,
      };
    }
    case T.REPLACE_FINANCE_BALANCE_DATA: {
      const {balance} = (action.payload as A.IReplaceBalanceDataPayload);
      return {
        ...state,
        data_balance: [...balance],
        unsaved: true,
      };
    }
    case T.CHANGE_FINANCE_FLOW_DATA: {
      const {changes, currencyId} = (action.payload as A.IChangeFlowDataPayload);
      if(!Array.isArray(changes)){
        return state;
      }
      
      if(changes.length === 0){
        return state;
      }
  
      let {data_flow, master_items, master_accounts} = state;
      data_flow = [...data_flow];
  
      let mapToSortedIndices: number[];
      let mapToOriginalIndices: number[];
      let sorted_flows: S.FlowData[];
      let sorted_balances: S.BalanceData[];
      if(state.autoReBalanceFlow){
        ({sorted_flows, mapToSortedIndices, mapToOriginalIndices} = getFlowDataSortedByDateWithSortMap(data_flow));
        sorted_balances = getSortedBalanceData(state.data_balance);
      }
      
      changes.forEach((c, changeIndex) => {
        const {index, action: changeAction} = c;
        let {data} = c;
        
        if(data){
          // Cut off extra properties
          data = {
            date: data.date,
            item: data.item,
            account: data.account,
            value: data.value,
            notes: data.notes,
          };
          // Correct format
          // Value of flow is parsed to number if value format is correct on flow table component.
          // If typeof value is string, it means it couldn't be un-formatted.
          if(changeAction === "edit" && typeof(data.value) === "string"){
            // Revert change.
            data.value = data_flow[index].value;
          }
        }
        
        if(changeAction === "remove"){
          [data] = data_flow.splice(index, 1);
          
          if(state.autoReBalanceFlow){
            const sortedIndex = mapToSortedIndices[index];
            sorted_flows.splice(sortedIndex, 1);
            
            ({mapToSortedIndices, mapToOriginalIndices} = reorderIndexMapsAfterDeleting(mapToSortedIndices, mapToOriginalIndices, index));
            
            if(!isFlowActive(data)){
              return;
            }
            
            const newMappers = provisionToUnsortedFlowData(
              data_flow,
              mapToSortedIndices,
              mapToOriginalIndices,
              sorted_flows,
              sorted_balances,
              data.date,
              data.account,
            );
            ({mapToSortedIndices, mapToOriginalIndices} = newMappers);
            ModifyFutureIndexInChangeList(changes, changeIndex+1, newMappers.change, mapToOriginalIndices);
          }
        }
        else if(changeAction === "insert"){
          data_flow.splice(index, 0, data);
          
          if(state.autoReBalanceFlow){
            let sortedIndex = 0;
            for(let i=sorted_flows.length-1;i>=0;i--){
              const f = sorted_flows[i];
              
              if(typeof(f.date) !== "number"){
                continue;
              }
              
              if(f.date <= data.date){
                sortedIndex = i;
              }
              else{
                break;
              }
            }
            sorted_flows.splice(sortedIndex, 0, data);
            
            ({mapToSortedIndices, mapToOriginalIndices} = reorderIndexMapsAfterInserting(
              mapToSortedIndices,
              mapToOriginalIndices,
              index, sortedIndex,
            ));
            
            if(!isFlowActive(data)){
              return;
            }
            
            const newMappers = provisionToUnsortedFlowData(
              data_flow,
              mapToSortedIndices,
              mapToOriginalIndices,
              sorted_flows,
              sorted_balances,
              data.date,
              data.account,
            );
            ({mapToSortedIndices, mapToOriginalIndices} = newMappers);
            ModifyFutureIndexInChangeList(changes, changeIndex+1, newMappers.change, mapToOriginalIndices);
          }
        }
        else if(changeAction === "edit"){
          const oldData = data_flow[index];
  
          const createUnknownItems = false;
          const {item, account} = data;
          // If new item/account is put
          if(typeof(item) === "string" && item.length > 0){
            if(createUnknownItems){
              master_items = [...master_items];
              
              const id = master_items.length > 0 ? Math.max(...master_items.map(i => i.id)) + 1 : 0;
              const newItem: S.ItemMaster = {id, name: item, group: ITEM_GROUP__GENERAL, order: master_items.length};
              master_items.push(newItem);
              data.item = id;
            }
            else{
              data.item = null;
            }
          }
          if(typeof(account) === "string" && account.length > 0){
            if(createUnknownItems){
              master_accounts = [...master_accounts];
              
              const id = master_accounts.length > 0 ? Math.max(...master_accounts.map(i => i.id)) + 1 : 0;
              const newAccount: S.AccountMaster = {
                id,
                name: account,
                order: master_accounts.length,
                type: ACCOUNT_TYPE__CASH,
                currency: currencyId,
              };
              master_accounts.push(newAccount);
              data.account = id;
            }
            else{
              data.account = null;
            }
          }
          
          data_flow[index] = data;
          
          if(state.autoReBalanceFlow){
            const sortedIndex = mapToSortedIndices[index];
            sorted_flows[sortedIndex] = data;
            
            const isOldFlowActive = isFlowActive(oldData);
            const isNewFlowActive = isFlowActive(data);
            
            let changeType = "nochange";
            if(isOldFlowActive && isNewFlowActive){
              if(oldData.date === data.date && oldData.account === data.account){
                changeType = "edit";
              }
              else {
                changeType = "removeAndInsert";
              }
            }
            else if(isOldFlowActive && !isNewFlowActive){
              changeType = "remove";
            }
            else if(!isOldFlowActive && isNewFlowActive){
              changeType = "insert";
            }
            
            if(changeType === "edit" || changeType === "insert"){
              const newMappers = provisionToUnsortedFlowData(
                data_flow,
                mapToSortedIndices,
                mapToOriginalIndices,
                sorted_flows,
                sorted_balances,
                data.date,
                data.account,
              );
              ({mapToSortedIndices, mapToOriginalIndices} = newMappers);
              ModifyFutureIndexInChangeList(changes, changeIndex+1, newMappers.change, mapToOriginalIndices);
            }
            else if(changeType === "remove"){
              const activeOldData = oldData as S.ActiveFlowData;
              const newMappers = provisionToUnsortedFlowData(
                data_flow,
                mapToSortedIndices,
                mapToOriginalIndices,
                sorted_flows,
                sorted_balances,
                activeOldData.date,
                activeOldData.account,
                );
              ({mapToSortedIndices, mapToOriginalIndices} = newMappers);
              ModifyFutureIndexInChangeList(changes, changeIndex+1, newMappers.change, mapToOriginalIndices);
            }
            else if(changeType === "removeAndInsert"){
              const activeOldData = oldData as S.ActiveFlowData;
              let newMappers = provisionToUnsortedFlowData(
                data_flow,
                mapToSortedIndices,
                mapToOriginalIndices,
                sorted_flows,
                sorted_balances,
                activeOldData.date,
                activeOldData.account,
                );
              ({mapToSortedIndices, mapToOriginalIndices} = newMappers);
              
              ModifyFutureIndexInChangeList(changes, changeIndex+1, newMappers.change, mapToOriginalIndices);
              newMappers = provisionToUnsortedFlowData(
                data_flow,
                mapToSortedIndices,
                mapToOriginalIndices,
                sorted_flows,
                sorted_balances,
                data.date,
                data.account,
                );
              
              ({mapToSortedIndices, mapToOriginalIndices} = newMappers);
              ModifyFutureIndexInChangeList(changes, changeIndex+1, newMappers.change, mapToOriginalIndices);
            }
          }
        }
      });
      
      data_flow = [...data_flow]; // Create shallow copy for ref diff
      
      return {...state, data_flow, master_items, master_accounts, unsaved: true};
    }
    case T.CHANGE_FINANCE_BALANCE_DATA: {
      const {changes, doSort, currencyId} = (action.payload as A.IChangeBalanceDataPayload);
      
      if(!Array.isArray(changes) || changes.length < 1){
        return state;
      }
      
      let {
        data_balance,
        data_flow,
        master_accounts,
      } = state;
  
      // Creating shallow copies for ref update
      data_balance = [...data_balance];
      
      const sorted_data_flow = getSortedFlowData(data_flow);
      
      changes.forEach(c => {
        let {index, action: changeAction, data: balanceChange} = c;
        
        if(balanceChange){
          // Cut off extra properties
          balanceChange = {
            date: balanceChange.date,
            account: balanceChange.account,
            balance: balanceChange.balance,
            note: balanceChange.note,
          };
          // Correct format
          if(typeof(balanceChange.balance) === "string"){
            balanceChange.balance = +(balanceChange.balance.replace(/[^+\-0-9]/g, ""));
          }
        }
        
        if(changeAction === "insert" || changeAction === "edit"){
          const {date, account} = balanceChange;
          
          // If there is a balance date with the date and the account, make changeAction 'edit'.
          if(typeof(date) === "number" && typeof(account) === "number"){
            const i = findBalanceIndex(data_balance, date, account);
            if(typeof(i) === "number"){
              changeAction = "edit";
              index = i;
            }
          }
        }
        
        switch(changeAction){
          case "remove": {
            return removeBalanceAt(index, data_balance, sorted_data_flow, state.autoReBalanceFlow);
          }
          case "insert": {
            return insertBalanceAt(index, balanceChange, data_balance, sorted_data_flow, state.autoReBalanceFlow);
          }
          case "edit": {
            const old_balance = data_balance[index];
            const createUnknownItems = false;
            
            const {account} = balanceChange;
            if(typeof(account) === "string" && account.length > 0){
              if(createUnknownItems){
                master_accounts = [...master_accounts];
  
                const id = master_accounts.length > 0 ? Math.max(...master_accounts.map(i => i.id)) + 1 : 0;
                const newAccount: S.AccountMaster = {
                  id,
                  name: account,
                  order: master_accounts.length,
                  type: ACCOUNT_TYPE__CASH,
                  currency: currencyId,
                };
                master_accounts.push(newAccount);
                balanceChange.account = id;
              }
              else{
                balanceChange.account = null;
              }
            }
            
            data_balance[index] = balanceChange;
            
            const sorted_data_balance = getSortedBalanceData(data_balance);
            
            if(!state.autoReBalanceFlow){
              return;
            }
            
            const wasOldBalanceActive = isBalanceActive(old_balance);
            const isNewBalanceActive = isBalanceActive(balanceChange);
            
            // No meaningful changes happen
            if(!wasOldBalanceActive && !isNewBalanceActive){
              return;
            }
            // This means old balance data is just removed
            else if(wasOldBalanceActive && !isNewBalanceActive){
              const activeOldBalance = old_balance as S.ActiveBalanceData;
              removeUnaccountedItem(sorted_data_flow, sorted_data_balance, activeOldBalance.date, activeOldBalance.account);
              return;
            }
            // New balance data is newly created
            else if(!wasOldBalanceActive && isNewBalanceActive){
              addUnaccountedItem(sorted_data_flow, sorted_data_balance, balanceChange);
              return;
            }
            // Old balance is removed and new balance is created
            else if(wasOldBalanceActive && isNewBalanceActive){
              const activeOldBalance = old_balance as S.ActiveBalanceData;
              removeUnaccountedItem(sorted_data_flow, sorted_data_balance, activeOldBalance.date, activeOldBalance.account);
              addUnaccountedItem(sorted_data_flow, sorted_data_balance, balanceChange);
              return;
            }
            
            return;
          }
          default: break;
        }
      });
  
      if(doSort){
        data_balance = getSortedBalanceData(data_balance, {
          accounts: state.master_accounts,
        }).reverse();
      }
  
      data_flow = getSortedFlowData(sorted_data_flow, {
        items: state.master_items,
        accounts: state.master_accounts,
      }).reverse();
      
      return {...state, data_balance, data_flow, master_accounts, unsaved: true};
    }
    case T.REBALANCE_FINANCE_FLOW_DATA: {
      const {data_balance, data_flow} = state;
      
      const sorted_data_flow = getSortedFlowData(data_flow);
      const sorted_data_balance = getSortedBalanceData(data_balance);
      reBalanceAllFlow(sorted_data_flow, sorted_data_balance);
      const rebalanced_data_flow = getSortedFlowData(sorted_data_flow, {
        items: state.master_items,
        accounts: state.master_accounts,
      }).reverse();
      
      return {...state, data_flow: rebalanced_data_flow, unsaved: true};
    }
    case T.SORT_BALANCE: {
      const {data_balance, master_accounts} = state;
      const sorted_data_balance = getSortedBalanceData(data_balance, {accounts: master_accounts});
      sorted_data_balance.reverse();
      
      return {...state, data_balance: sorted_data_balance, unsaved: true};
    }
    case T.ADD_ITEM: {
      const {items} = action.payload;
      let master_items = [...state.master_items];
      
      let maxItemId;
      if(master_items.length > 0){
        maxItemId = Math.max(...master_items.map(a => a.id));
      }
      else{
        maxItemId = -1;
      }
      
      for(let i=0;i<items.length;i++){
        // Cut extra properties
        const item: S.ItemMaster = {
          id: maxItemId + 1 + i,
          name: items[i].name,
          group: ITEM_GROUP__GENERAL,
          order: master_items.length + i,
        };
        
        master_items.push(item);
      }
      
      master_items.sort((a, b) => a.order - b.order);
      master_items = master_items.map((item, i) => {
        return {...item, order: i};
      });
      
      return {...state, master_items, unsaved: true};
    }
    case T.ADD_ITEM_GROUP: {
      const {itemGroups} = action.payload;
      let master_itemGroups = [...state.master_itemGroups];
      
      let maxItemId;
      if(master_itemGroups.length > 0){
        maxItemId = Math.max(...master_itemGroups.map(a => a.id));
      }
      else{
        maxItemId = 1;
      }
      
      for(let i=0; i<itemGroups.length; i++){
        // Cut extra properties
        const itemGroup: S.ItemGroupMaster = {
          id: maxItemId + 1 + i,
          name: itemGroups[i].name,
          order: master_itemGroups.length + i,
        };
  
        master_itemGroups.push(itemGroup);
      }
      
      master_itemGroups.sort((a, b) => a.order - b.order);
      master_itemGroups = master_itemGroups.map((item, i) => {
        return {...item, order: i};
      });
      
      return {...state, master_itemGroups, unsaved: true};
    }
    case T.ADD_ACCOUNT: {
      const {accounts} = action.payload;
      let master_accounts = [...state.master_accounts];
      
      let maxAccountId;
      if(master_accounts.length > 0){
        maxAccountId = Math.max(...master_accounts.map(a => a.id));
      }
      else{
        maxAccountId = -1;
      }
      
      for(let i=0;i<accounts.length;i++){
        // Cut extra properties
        const account: S.AccountMaster = {
          id: maxAccountId + 1 + i,
          name: accounts[i].name,
          type: accounts[i].type,
          order: master_accounts.length + i,
          currency: accounts[i].currency,
        };
        
        master_accounts.push(account);
      }
      
      master_accounts.sort((a, b) => a.order - b.order);
      master_accounts = master_accounts.map((item, i) => {
        return {...item, order: i};
      });
      
      return {...state, master_accounts, unsaved: true};
    }
    case T.ADD_ACCOUNT_TYPE: {
      const {accountTypes} = action.payload;
      let master_accountTypes = [...state.master_accountTypes];
      
      let maxAccountTypeId;
      if(master_accountTypes.length > 0){
        maxAccountTypeId = Math.max(...master_accountTypes.map(a => a.id));
      }
      else{
        maxAccountTypeId = -1;
      }
      
      for(let i=0;i<accountTypes.length;i++){
        // Cut extra properties
        const accountType: S.AccountTypeMaster = {
          id: maxAccountTypeId + 1 + i,
          name: accountTypes[i].name,
          type: (accountTypes[i].type as S.AccountTypeCategory),
          order: master_accountTypes.length + i,
        };
        
        master_accountTypes.push(accountType);
      }
      
      master_accountTypes.sort((a, b) => a.order - b.order);
      master_accountTypes = master_accountTypes.map((item, i) => {
        return {...item, order: i};
      });
      
      return {...state, master_accountTypes, unsaved: true};
    }
    case T.EDIT_ITEM: {
      const {item} = action.payload;
      const {master_items} = state;
      
      const index = master_items.findIndex(i => i.id === item.id);
      if(index < 0){
        return state;
      }
      
      const new_master_items = master_items.map((master_item, i) => {
        if(i === index){
          return {
            id: item.id,
            name: item.name,
            group: item.group,
            order: item.order,
          };
        }
        
        return master_item;
      });
      
      return {...state, master_items: new_master_items, unsaved: true};
    }
    case T.EDIT_ITEM_GROUP: {
      const {itemGroup} = action.payload;
      const {master_itemGroups} = state;
      
      const index = master_itemGroups.findIndex(i => i.id === itemGroup.id);
      if(index < 0){
        return state;
      }
      
      const new_master_itemGroups = master_itemGroups.map((master_itemGroup, i) => {
        if(i === index){
          return {
            id: itemGroup.id,
            name: itemGroup.name,
            order: itemGroup.order,
          };
        }
        
        return master_itemGroup;
      });
      
      return {...state, master_itemGroups: new_master_itemGroups, unsaved: true};
    }
    case T.EDIT_ACCOUNT: {
      const {account} = action.payload;
      const {master_accounts} = state;
  
      const index = master_accounts.findIndex(i => i.id === account.id);
      if(index < 0){
        return state;
      }
      
      const new_master_accounts = master_accounts.map((master_account, i) => {
        if(i === index){
          return {
            id: account.id,
            name: account.name,
            type: account.type,
            order: account.order,
            currency: account.currency,
          };
        }
        
        return master_account;
      });
      
      return {...state, master_accounts: new_master_accounts, unsaved: true};
    }
    case T.EDIT_ACCOUNT_TYPE: {
      const {accountType} = action.payload;
      const {master_accountTypes} = state;
  
      const index = master_accountTypes.findIndex(i => i.id === accountType.id);
      if(index < 0){
        return state;
      }
      
      const new_master_accountTypes = master_accountTypes.map((master_accountType, i) => {
        if(i === index){
          return {
            id: accountType.id,
            name: accountType.name,
            type: accountType.type,
            order: accountType.order,
          };
        }
        
        return master_accountType;
      });
      
      return {...state, master_accountTypes: new_master_accountTypes, unsaved: true};
    }
    case T.REMOVE_ITEM: {
      const {items} = action.payload as A.IRemoveItemsPayload;
      let {master_items} = state;
      
      master_items = master_items.filter(i => !items.some(i2 => i2.id === i.id));
      
      master_items.sort((a, b) => a.order - b.order);
      master_items = master_items.map((item, i) => {
        return {...item, order: i};
      });
      
      return {...state, master_items, unsaved: true};
    }
    case T.REMOVE_ITEM_GROUP: {
      const {itemGroups} = action.payload as A.IRemoveItemGroupsPayload;
      let {master_itemGroups} = state;
      
      master_itemGroups = master_itemGroups.filter(i => !itemGroups.some(i2 => i2.id === i.id));
      
      master_itemGroups.sort((a, b) => a.order - b.order);
      master_itemGroups = master_itemGroups.map((item, i) => {
        item.order = i;
        return item;
      });
      
      return {...state, master_itemGroups, unsaved: true};
    }
    case T.REMOVE_ACCOUNT: {
      const {accounts} = action.payload as A.IRemoveAccountsPayload;
      let {
        master_accounts,
      } = state;
      
      master_accounts = master_accounts.filter(i => !accounts.some(i2 => i2.id === i.id));
      
      master_accounts.sort((a, b) => a.order - b.order);
      master_accounts = master_accounts.map((item, i) => {
        return {...item, order: i};
      });
      
      return {
        ...state,
        master_accounts,
        unsaved: true,
      };
    }
    case T.REMOVE_ACCOUNT_TYPE: {
      const {accountTypes} = action.payload as A.IRemoveAccountTypesPayload;
      let {master_accountTypes} = state;
      
      // Fixed account types cannot be removed.
      const accountTypes_withoutFixedAccount = accountTypes.filter(at => !isFixedAccountType((at as any)));
      
      master_accountTypes = master_accountTypes.filter(i => !accountTypes_withoutFixedAccount.some(i2 => i2.id === i.id));
      
      master_accountTypes.sort((a, b) => a.order - b.order);
      master_accountTypes = master_accountTypes.map((item, i) => {
        return {...item, order: i};
      });
      
      return {...state, master_accountTypes, unsaved: true};
    }
    case T.REPLACE_ITEM: {
      const {items} = action.payload;
      return {...state, master_items: items, unsaved: true};
    }
    case T.REPLACE_ITEM_GROUP: {
      const {itemGroups} = action.payload;
      return {...state, master_itemGroups: itemGroups, unsaved: true};
    }
    case T.REPLACE_ACCOUNT: {
      const {accounts} = action.payload;
      return {...state, master_accounts: accounts, unsaved: true};
    }
    case T.REPLACE_ACCOUNT_TYPE: {
      const {accountTypes} = action.payload;
      return {...state, master_accountTypes: accountTypes, unsaved: true};
    }
    case T.CHANGE_PLAN_TAG_FOR_FLOW_DATA: {
      const {index, tags} = action.payload;
      let {data_flow} = state;
      
      if(!data_flow[index]){
        return state;
      }
  
      data_flow = data_flow.map((f, i) => {
        if(i === index){
          return {
            ...f,
            planTags: tags,
          };
        }
        
        return f;
      });
      
      return {
        ...state,
        data_flow,
      };
    }
    case T.DELETE_PLAN_TAG_IN_ALL_FLOW_DATA: {
      const {tags} = action.payload;
      let {data_flow} = state;
      
      data_flow = data_flow.map(f => {
        let {planTags} = f;
        if(!Array.isArray(planTags)){
          return f;
        }
        
        planTags = planTags.filter(t => !tags.includes(t));
        return {
          ...f,
          planTags,
        };
      });
      
      return {
        ...state,
        data_flow,
      };
    }
    default:
      break;
  }
  
  return state;
};

export default financeReducer;
