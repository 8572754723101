import {RootState} from "../reducers";


export function isSaving(rootState: RootState): boolean {
  return Object.keys(rootState).some((state) => rootState[state as keyof RootState].saving);
}

export function isUnsaved(rootState: RootState): boolean {
  return Object.keys(rootState).some(state => rootState[state as keyof RootState].unsaved);
}

