import * as A from "../../../core/actions/types/app/language";
import {Lang} from "../../../reducers/App/state";
import {CHANGE_LANGUAGE} from "../../../core/actions/types/constant";

export const changeLanguage = (lang: Lang): A.ChangeLanguageAction => {
  return {
    type: CHANGE_LANGUAGE,
    payload: {lang},
  };
};
