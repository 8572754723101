import AbstractStorage from "./abstractStorage";
import {firebaseDb} from "../firebase";
import {StorageProvider} from "../../reducers/App/state";

export const type: StorageProvider = "firebase";

export default class FirebaseStorage extends AbstractStorage {
  protected __namespace = "users";
  protected __uid = "";
  
  constructor(uid: string | undefined){
    super(type);
    
    if(typeof(uid) === "string"){
      this.__namespace = `${this.__namespace}/${uid}/appState`;
      this.__uid = uid;
    }
  }
  
  public getAbsoluteName(name: string){
    if(!name){
      return this.__namespace;
    }
    else{
      return `${this.__namespace}/${name}`;
    }
  }
  
  /**
   * @param {string} name
   * @returns {Promise<any>}
   */
  public async get(name: string): Promise<any>{
    try{
      const snapshot = await firebaseDb.ref(this.getAbsoluteName(name)).once("value");
      const serializedValue = snapshot.val();
      
      return FirebaseStorage.deSerialize(serializedValue);
    }
    catch(e){
      return null;
    }
  }
  
  /**
   * @param {string} name
   * @param {*} value
   * @returns {Promise<any>}
   */
  public async save(name: string, value: any): Promise<{name: string, value: any}>{
    const serializedValue = FirebaseStorage.serialize(value);
    await firebaseDb.ref(this.getAbsoluteName(name)).set(serializedValue);
    
    return {
      name,
      value,
    };
  }
  
  /**
   * @param {string} name
   * @returns {Promise<string>}
   */
  public async remove(name: string): Promise<string>{
    await firebaseDb.ref(this.getAbsoluteName(name)).remove();
    return name;
  }
  
  public static serialize(valueObj: any){
    return JSON.stringify(valueObj);
  }
  
  public static deSerialize(valueObj: any){
    return JSON.parse(valueObj);
  }
}
