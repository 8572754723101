import {connect} from "react-redux";
import Account from "..";
import {isUnsaved} from "../../../../util/saveState";
import {ThunkDispatch} from "redux-thunk";
import * as A from "../../../../actions";
import {RootState} from "../../../../reducers";

const mapStateToProps = (rootReduxState: RootState) => {
  const {lang, account, switchingAccount} = rootReduxState.App;
  const unsaved = isUnsaved(rootReduxState);
  
  return {lang, account, switchingAccount, unsaved};
};

const mapDispatchToProps = (dispatch: ThunkDispatch<RootState, undefined, A.RootActions>) => {
  return {
    logout: () => dispatch(A.logout()),
  };
};

const AccountContainer = connect(mapStateToProps, mapDispatchToProps)(Account);

export default AccountContainer;
