import {getCurrentDate, getNowInCustomDate} from "../../../../core/util/finance";
import * as R from "../../../../core/reducers/Finance/state";
import {GeneratorParams} from "../../../../core/util/dataGenerator";
import {CURRENCY_JPY} from "../../../../core/util/constants";
import {getFXValue} from "../../../../core/util/currency";

type IGetParamsFunc = (currency: R.Currency) => GeneratorParams;

/**
 * "name": "Employee F", "age": "39", "sex": "Male", "annualIncome": "$ 20,000", "family": "Single", "money": "Part-time worker",
 *
 * spending
 *  Rent and household goods expense is not much, but ratio of food expense is very large in overall expenditure.
 *  Purchase amount of game hardware and smartphone app is large. These are counted as ammusement expenses.
 *  There is not much expenses for self-development and skill improvement.
 * He has problems in his health condition so medical expenses are higher than average.
 *
 * income
 *  He gets no bonus because he is working part-time. The monthly income is almost constant.
 *  He has no debt for now. The living standard is stable at low.
 */
const getParams: IGetParamsFunc = (currency) => {
  const now = getCurrentDate();
  const startY = now.getFullYear();
  const startM = 1;
  const nowCustomDate = getNowInCustomDate();
  const JPY = CURRENCY_JPY.id;
  
  return {
    years: 3,
    start_fund: getFXValue(1000000, JPY, currency.id, nowCustomDate),
    start_debt: getFXValue(0, JPY, currency.id, nowCustomDate),
    start_fixedAsset: getFXValue(0, JPY, currency.id, nowCustomDate),
    debt_interest: 0,
    debt_period: 35,
    repayment_method: "equalPayments", // ["equalPayments", "equalInstalments"]
    income_allowances: getFXValue(0, JPY, currency.id, nowCustomDate),
    income_basePay: getFXValue(200000, JPY, currency.id, nowCustomDate),
    income_bonusAmount: getFXValue(0, JPY, currency.id, nowCustomDate),
    income_bonusCount: 0,
    income_commuting: getFXValue(0, JPY, currency.id, nowCustomDate),
    income_otherIncome: getFXValue(0, JPY, currency.id, nowCustomDate),
    income_overtime: 0,
    income_rfBonusAmount: 70,
    income_rfOtherIncome: 20,
    income_rfOvertime: 15,
    income_rfRiseRate: 30,
    income_riseRate: 2,
    income_workingHours: 5,
    income_overtimeRaiseRate: 25,
    income_pensionChargeRate: 9.15,
    income_healthInsuranceChargeRate: 4.4,
    income_employmentInsuranceChargeRate: 0.3,
    fixedAssetUsefulLives: 47,
    autoFixedAssetUsefulLives: true,
    income_feeding: 0,
    startY: startY,
    startM: startM,
    income_prevYearTotalIncome: null,
    income_prevYearTotalSocialInsurance: null,
    income_initialNormalizedPay: null,
    income_TotalIncome2YearsAgo: null,
    income_TotalSocialInsurance2YearsAgo: null,
    income_initialNormalizedPayLastTerm: null,
    spending_hobby: getFXValue(6000, JPY, currency.id, nowCustomDate),
    spending_beauty: getFXValue(1000, JPY, currency.id, nowCustomDate),
    spending_communication: getFXValue(4000, JPY, currency.id, nowCustomDate),
    spending_commuting: getFXValue(6000, JPY, currency.id, nowCustomDate),
    spending_education: getFXValue(0, JPY, currency.id, nowCustomDate),
    spending_electricity: getFXValue(4000, JPY, currency.id, nowCustomDate),
    spending_food: getFXValue(58000, JPY, currency.id, nowCustomDate),
    spending_gasWater: getFXValue(4000, JPY, currency.id, nowCustomDate),
    spending_goods: getFXValue(6000, JPY, currency.id, nowCustomDate),
    spending_house: getFXValue(50000, JPY, currency.id, nowCustomDate),
    spending_medical: getFXValue(15000, JPY, currency.id, nowCustomDate),
    spending_unclassified: getFXValue(6000, JPY, currency.id, nowCustomDate),
    spending_trip_budget: getFXValue(50000, JPY, currency.id, nowCustomDate),
    spending_trip_count: 2,
    spending_rf: 10,
    spending_debtBonusChargeRate: 20,
    currency,
  };
};

export default getParams;
