import React, {Component} from "react";
import {withTranslation} from "react-i18next";
import Dashboard from "./__container__/Dashboard";
import {withContentSize} from "../../ContentSizeProvider";
import CreateAccountDialog from "../../../util/component/CreateAccountDialog";
import LoginDialog from "../../../util/component/LoginDialog";
import LoadDialog from "../../../util/component/LoadFromFileDialog";
import DemoDataDialog from "../../../util/component/DemoDataDialog";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import AttentionPleaseIcon from "mdi-react/HandPointingLeftIcon";
import {Fade} from "react-slideshow-image";
import ReactGA from "react-ga";
import Scrollbars from "react-custom-scrollbars";
import TWEEN from "@tweenjs/tween.js";
import TwitterIcon from "mdi-react/TwitterIcon";
import FacebookIcon from "mdi-react/FacebookIcon";
import GithubIcon from "mdi-react/GithubCircleIcon";
import GoogleIcon from "mdi-react/GoogleIcon";
import CreateAccountIcon from "@material-ui/icons/PersonAdd";
import classNames from "classnames";
import {lighten} from "@material-ui/core/styles/colorManipulator";

import fm1 from "./images/fm1.png";
import fm2 from "./images/fm2.png";
import fm3 from "./images/fm3.png";
import m1 from "./images/m1.png";
import m2 from "./images/m2.png";
import m3 from "./images/m3.png";

import getProfile_A from "./profiles/A";
import getProfile_B from "./profiles/B";
import getProfile_C from "./profiles/C";
import getProfile_D from "./profiles/D";
import getProfile_E from "./profiles/E";
import getProfile_F from "./profiles/F";

import titleImage1_ja_dark from "./images/monthlyPLAnalaysis_ja_dark.png";
import titleImage1_ja_dark_large from "./images/monthlyPLAnalaysis_ja_dark_large.png";
import titleImage1_en_dark from "./images/monthlyPLAnalaysis_en_dark.png";
import titleImage1_en_dark_large from "./images/monthlyPLAnalaysis_en_dark_large.png";
import titleImage1_ja_light from "./images/monthlyPLAnalaysis_ja_light.png";
import titleImage1_ja_light_large from "./images/monthlyPLAnalaysis_ja_light_large.png";
import titleImage1_en_light from "./images/monthlyPLAnalaysis_en_light.png";
import titleImage1_en_light_large from "./images/monthlyPLAnalaysis_en_light_large.png";

import withStyles, {ClassNameMap, StyleRulesCallback} from "@material-ui/core/styles/withStyles";
import * as S from "../../../core/reducers/Finance/state";
import i18next from "i18next";
import {Lang} from "../../../reducers/App/state";
import {CustomTheme} from "../../../util/theme";
import {Omit, TReservedHocProps} from "../../../util/type";

interface ImageMapper {
  [lang: string]: {
    [theme: string]: {
      [id: number]: {
        main: string;
        large: string;
      };
    };
  };
}

const imageMapper: ImageMapper = {
  ja: {
    dark: {
      1: {
        main: titleImage1_ja_dark,
        large: titleImage1_ja_dark_large,
      },
    },
    light: {
      1: {
        main: titleImage1_ja_light,
        large: titleImage1_ja_light_large,
      },
    },
  },
  en: {
    dark: {
      1: {
        main: titleImage1_en_dark,
        large: titleImage1_en_dark_large,
      },
    },
    light: {
      1: {
        main: titleImage1_en_light,
        large: titleImage1_en_light_large,
      },
    },
  },
};



const scenes = [
  "getMainScene",
  "getScene2",
];
const transitionDuration = 700;
const easingFunction = TWEEN.Easing.Quadratic.InOut;


interface IHomeProps {
  classes: ClassNameMap;
  theme: CustomTheme;
  i18n: i18next.i18n;
  lang: Lang;
  availableHeight: number;
  availableWidth: number;
  currency: S.Currency;
  isLoggedIn: boolean;
  currentTheme: string;
}

interface IHomeState {
  dialogOpen: string;
  profile: string;
}



class Home extends Component<IHomeProps, IHomeState> {
  public unmounting: boolean = false;
  public scrollRef: React.RefObject<Scrollbars> = React.createRef();
  public onScroll: (e: Event) => void;
  
  constructor(props: IHomeProps){
    super(props);
  
    this.closeDialog = this.closeDialog.bind(this);
    this.getDemoProfileSelectionArea = this.getDemoProfileSelectionArea.bind(this);
    this.getProfileParams = this.getProfileParams.bind(this);
    this.getScenes = this.getScenes.bind(this);
    this.getMainScene = this.getMainScene.bind(this);
    this.getScene2 = this.getScene2.bind(this);
    
    this.onScroll = (e: Event) => {
      return;
    };
    
    this.state = {
      dialogOpen: "",
      profile: "A",
    };
  }
  
  public componentDidMount(){
    const {isLoggedIn} = this.props;
    if(isLoggedIn){
      return;
    }
    
    let currentScene = 0;
    let scrolling = false;
    
    const animate = (time?: number) => {
      if(this.unmounting){
        TWEEN.removeAll();
        return;
      }
      
      if(scrolling){
        requestAnimationFrame(animate);
        TWEEN.update(time);
      }
    };
    
    const dispatchScroll = (scrollBar: Scrollbars, from: number, to: number) => {
      scrolling = true;
      
      const position = {y: from};
      const tween = new TWEEN.Tween(position);
      tween.to({y: to}, transitionDuration);
      tween.onUpdate(({y}) => {
        scrollBar.scrollTop(y);
      });
      tween.onComplete(() => {
        scrolling = false;
      });
      tween.easing(easingFunction);
      tween.start();
      
      animate();
    };
    
    this.onScroll = (e: Event) => {
      if(this.unmounting){
        return;
      }
      
      const dialog = document.querySelector("[role='dialog']");
      if(dialog){
        return;
      }
      
      if(!(e as WheelEvent).deltaY && !["ArrowUp", "ArrowDown", "PageUp", "PageDown"].includes((e as KeyboardEvent).key)){
        return;
      }
  
      // https://www.chromestatus.com/features/6662647093133312
      // e.preventDefault();
      e.stopPropagation();
  
      if(scrolling){
        return;
      }
      
      let {availableHeight} = this.props;
      availableHeight = Math.max(availableHeight, 600);
      const scrollBar = this.scrollRef.current;
      
      if(!scrollBar){
        return;
      }
      
      let direction;
      if((e as WheelEvent).deltaY){
        direction = (e as WheelEvent).deltaY > 0 ? "down" : "up";
      }
      else{
        if(["ArrowUp", "PageUp"].includes((e as KeyboardEvent).key)){
          direction = "up";
        }
        else if(["ArrowDown", "PageDown"].includes((e as KeyboardEvent).key)){
          direction = "down";
        }
      }
      
      if(direction === "down"){
        currentScene++;
        if(currentScene <= scenes.length - 1){
          dispatchScroll(scrollBar, scrollBar.getScrollTop(), scrollBar.getScrollTop() + availableHeight);
        }
        else{
          currentScene = scenes.length - 1;
        }
      }
      else if(direction === "up"){
        currentScene--;
        if(currentScene >= 0){
          dispatchScroll(scrollBar, scrollBar.getScrollTop(), scrollBar.getScrollTop() - availableHeight);
        }
        else{
          currentScene = 0;
        }
      }
    };
    
    window.addEventListener("wheel", this.onScroll, true);
    window.addEventListener("keydown", this.onScroll, true);
  }
  
  public componentWillUnmount(){
    const {isLoggedIn} = this.props;
    if(isLoggedIn){
      return;
    }
  
    this.unmounting = true;
    
    window.removeEventListener("wheel", this.onScroll);
    window.removeEventListener("keydown", this.onScroll);
  }
  
  public render(){
    const {
      isLoggedIn,
      classes,
      i18n,
      lang,
    } = this.props;
    let {
      currentTheme,
    } = this.props;
    const {dialogOpen, profile} = this.state;
  
    if(isLoggedIn){
      return <Dashboard />;
    }
  
    currentTheme = currentTheme === "dark" ? "dark" : "light";
    
    const t_general = i18n.getFixedT(lang, "general");
    
    let dialog;
    if(dialogOpen === "newAccount"){
      dialog = (
        <CreateAccountDialog
          onOK={() => {
            // Do nothing
          }}
          onCancel={this.closeDialog}
        />
      );
    }

    else if(dialogOpen === "login"){
      dialog = (
        <LoginDialog
          onOK={() => {
            // Do nothing
          }}
          onCancel={this.closeDialog}
        />
      );
    }
    else if(["twitterLogin", "facebookLogin", "googleLogin", "githubLogin"].includes(dialogOpen)){
      dialog = (
        <LoginDialog
          onOK={() => {
            // Do nothing
          }}
          onCancel={this.closeDialog}
          social={dialogOpen.replace(/Login$/, "")}
          open={false}
        />
      );
    }
    else if(dialogOpen === "load"){
      dialog = (
        <LoadDialog
          onOK={() => {
            // Do nothing
          }}
          onCancel={this.closeDialog}
        />
      );
    }
    else if(dialogOpen === "customize_demo"){
      dialog = (
        <DemoDataDialog
          onCancel={this.closeDialog}
          onOK={() => {
            // Do nothing
          }}
        />
      );
    }
    else if(dialogOpen === "demo"){
      dialog = (
        <Dialog
          open={true}
          onClose={() => this.setState({dialogOpen: ""})}
          scroll="paper"
          aria-labelledby="scroll-dialog-title"
          classes={{paper: classes.dialogPaper, root: classes.dialogRoot}}
        >
          <DialogContent className={classes.dialogContentRoot}>
            {this.getDemoProfileSelectionArea()}
          </DialogContent>
          <DialogActions>
            <Button onClick={() => this.setState({dialogOpen: "opening_demo"})} color="primary">
              {t_general("dialogOK")}
            </Button>
            <Button onClick={() => this.setState({dialogOpen: ""})} color="primary" style={{marginLeft: 30}}>
              {t_general("dialogCancel")}
            </Button>
          </DialogActions>
        </Dialog>
      );
    }
    else if(dialogOpen === "opening_demo"){
      ReactGA.event({
        category: "Demo Profile",
        action: "Clicked Profile",
        label: profile,
      });
  
      dialog = (
        <DemoDataDialog
          onCancel={this.closeDialog}
          onOK={() => {
            // Do nothing
          }}
          skipConfig={true}
          params={this.getProfileParams(profile)}
        />
      );
    }
    else if(dialogOpen === "imageItem1"){
      dialog = (
        <Dialog
          open={true}
          onClose={() => this.setState({dialogOpen: ""})}
          scroll="paper"
          classes={{paper: classes.dialogPaper, root: classes.dialogRoot}}
        >
          <DialogContent className={classes.dialogContentRoot} style={{padding: 0}}>
            <img src={imageMapper[lang][currentTheme][1].large} alt=""/>
          </DialogContent>
        </Dialog>
      );
    }
    return (
      <div className={classes.root}>
        {this.getScenes()}
        {dialog}
      </div>
    );
  }
  
  public closeDialog(){
    this.setState({dialogOpen: ""});
  }
  
  public getDemoProfileSelectionArea(){
    const {classes, theme, i18n, lang} = this.props;
    const t = i18n.getFixedT(lang, "Home");
  
    const slideMap = ["A", "B", "C", "D", "E", "F"];
    const images = [fm1, m1, fm2, m2, fm3, m3];
    
    const slideProperties = {
      duration: 60000,
      transitionDuration: 300,
      infinite: true,
      indicators: true,
      onChange: (oldIndex: number, newIndex: number) => {
        const {document} = window;
        const newDiv = document.querySelector(`.react-slideshow-fade-images-wrap div[data-index="${newIndex}"]`) as HTMLElement;
        const oldDiv = document.querySelector(`.react-slideshow-fade-images-wrap div[data-index="${oldIndex}"]`) as HTMLElement;
        
        if(oldDiv && newDiv){
          oldDiv.style.zIndex = "0";
          newDiv.style.zIndex = "1";
          this.setState({profile: slideMap[newIndex]});
        }
      },
    };
  
    // Slide width: 646px, height: 353px
    const slides = slideMap.map((name, i) => (
      <div className={classes.slide} key={name}>
        <div style={{padding: 16}}>
          <div style={{display: "flex"}}>
            <div style={{width: 200, display: "flex", flexDirection: "column", alignItems: "center"}}>
              <div>
                <img
                  className={classes.profile}
                  src={images[i]}
                  alt="profile"
                />
              </div>
              <div style={{display: "flex", flexDirection: "column", justifyContent: "start", alignItems: "center", width: 200}}>
                <div>{t<string>(`personality.${name}`)}</div>
                <div style={{marginTop: 16}}>
                  <table style={{fontSize: ".9rem", width: 160}}>
                    <tbody>
                    <tr>
                      <td>{t<string>("personality.age")}</td>
                      <td className={classes.tblVal}>{t<string>(`personality.${name}.age`)}</td>
                    </tr>
                    <tr>
                      <td>{t<string>("personality.sex")}</td>
                      <td className={classes.tblVal}>{t<string>(`personality.${name}.sex`)}</td>
                    </tr>
                    <tr>
                      <td>{t<string>("personality.annualIncome")}</td>
                      <td className={classes.tblVal}>{t<string>(`personality.${name}.annualIncome`)}</td>
                    </tr>
                    <tr>
                      <td>{t<string>("personality.family")}</td>
                      <td className={classes.tblVal}>{t<string>(`personality.${name}.family`)}</td>
                    </tr>
                    <tr>
                      <td>{t<string>("personality.money")}</td>
                      <td className={classes.tblVal}>{t<string>(`personality.${name}.money`)}</td>
                    </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <div style={{flex: "auto", paddingLeft: 32}}>
              <div>
                {t<string>("personality.spending")}
              </div>
              <div style={{fontSize: ".87rem", marginTop: 8}}>
                {t<string>(`personality.${name}.spending`)}
              </div>
              <div style={{marginTop: 16}}>
                {t<string>("personality.income")}
              </div>
              <div style={{fontSize: ".87rem", marginTop: 8}}>
                {t<string>(`personality.${name}.income`)}
              </div>
            </div>
          </div>
        </div>
      </div>
    ));
    
    return (
      <div style={{width: 772, height: 450, padding: 8}}>
        <div style={{width: "100%", height: "100%", position: "relative"}}>
          <div style={{height: 30, marginTop: 16, textAlign: "center"}}>
            {t<string>("personality.title")}
          </div>
          {
            lang === "ja" ? (
              <Fade {...slideProperties}>
                {
                  slides.map((slide, i) => (
                    <div className="each-fade" key={slideMap[i]}>
                      {slide}
                    </div>
                  ))
                }
              </Fade>
            ) : (
              <div>
                <Fade {...slideProperties}>
                  {
                    slides.map((slide, i) => (
                      <div className="each-fade" key={slideMap[i]}>
                        {slide}
                      </div>
                    ))
                  }
                </Fade>
              </div>
            )
          }
          <div style={{position: "absolute", bottom: 16, right: 16}}>
            <a
              href="https://www.freepik.com/free-vector/people-avatars_761436.htm"
              style={{fontSize: ".75rem", textDecoration: "none", color: theme.palette.text.primary}}
              target="_blank"
              rel="noopener noreferrer"
            >
              Images by Freepik
            </a>
          </div>
        </div>
      </div>
    );
  }
  
  public getProfileParams(profile: string){
    const {currency} = this.props;
    
    switch(profile){
      case "A": {
        return getProfile_A(currency);
      }
      case "B": {
        return getProfile_B(currency);
      }
      case "C": {
        return getProfile_C(currency);
      }
      case "D": {
        return getProfile_D(currency);
      }
      case "E": {
        return getProfile_E(currency);
      }
      case "F": {
        return getProfile_F(currency);
      }
      default: break;
    }
  }
  
  public getScenes(){
    let {
      availableHeight,
    } = this.props;
    availableHeight = Math.max(availableHeight, 600);
    
    return (
      <Scrollbars
        style={{height: availableHeight}}
        ref={this.scrollRef}
        autoHide={true}
        autoHideTimeout={0}
      >
        {this.getMainScene()}
        {this.getScene2()}
      </Scrollbars>
    );
  }
  
  public getMainScene(){
    const {
      i18n,
      lang,
      theme,
      classes,
      availableWidth,
    } = this.props;
    let {
      currentTheme,
      availableHeight,
    } = this.props;
    availableHeight = Math.max(availableHeight, 600);
  
    currentTheme = currentTheme === "dark" ? "dark" : "light";
  
    const t = i18n.getFixedT(lang, "Home");
    
    return (
      <div
        style={{
          width: availableWidth,
          height: availableHeight,
          position: "relative",
        }}
      >
        <div
          style={{
            width: "100%",
            height: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <div style={{display: "flex", justifyContent: "center", flexDirection: "column"}}>
            <div onClick={() => this.setState({dialogOpen: "imageItem1"})} className={classes.imageContainer}>
              <img src={imageMapper[lang][currentTheme][1].main} alt="" height={500} />
            </div>
          </div>
          <div
            style={{
              marginLeft: 80,
              width: 500,
            }}
          >
            <h1>{t<string>("caption.headline")}</h1>
            <p>
              {t<string>("caption.headlineDesc")}
            </p>
      
            <table className={classes.itemTable}>
              <tbody>
              <tr>
                <td dangerouslySetInnerHTML={{__html: t<string>("caption.head1")}} />
                <td dangerouslySetInnerHTML={{__html: t<string>("caption.item1")}} />
              </tr>
              <tr>
                <td dangerouslySetInnerHTML={{__html: t<string>("caption.head2")}} />
                <td dangerouslySetInnerHTML={{__html: t<string>("caption.item2")}} />
              </tr>
              <tr>
                <td dangerouslySetInnerHTML={{__html: t<string>("caption.head3")}} />
                <td dangerouslySetInnerHTML={{__html: t<string>("caption.item3")}} />
              </tr>
              <tr>
                <td dangerouslySetInnerHTML={{__html: t<string>("caption.head4")}} />
                <td dangerouslySetInnerHTML={{__html: t<string>("caption.item4")}} />
              </tr>
              </tbody>
            </table>
      
            <div
              style={{
                display: "flex",
                alignItems: "flex-end",
                marginTop: theme.spacing.unit*4,
              }}
            >
              <div style={{position: "relative"}}>
                <Button
                  variant="contained"
                  size="large"
                  onClick={() => this.setState({dialogOpen: "demo"})}
                  className={classes.demoButton}
                >
                  {t<string>("demo.title")}
                  <span style={{marginLeft: theme.spacing.unit*2}} />
                  <AttentionPleaseIcon size={40} />
                </Button>
                <div className={classes.attentionPlease} />
              </div>
              <div style={{flex: "auto"}}/>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <div
                  onClick={() => this.setState({dialogOpen: "customize_demo"})}
                  style={{
                    cursor: "pointer",
                    color: (theme.custom && theme.custom.linkColor) || "blue",
                    fontSize: ".85rem",
                  }}
                >
                  {t<string>("demo_customize.title")}
                </div>
                <div
                  onClick={() => this.setState({dialogOpen: "load"})}
                  style={{
                    cursor: "pointer",
                    color: (theme.custom && theme.custom.linkColor) || "blue",
                    fontSize: ".85rem",
                    marginTop: theme.spacing.unit*2,
                  }}
                >
                  {t<string>("load.title")}
                </div>
              </div>
            </div>
      
            <div style={{marginTop: theme.spacing.unit*4, borderBottom: `1px solid ${theme.palette.text.secondary}`}} />
            
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginTop: theme.spacing.unit*4,
              }}
            >
              <div>
                <div style={{marginBottom: theme.spacing.unit, textAlign: "center"}}>
                  {t("socialLogin")}
                </div>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    flexWrap: "wrap",
                  }}
                >
                  <IconButton
                    className={classNames(classes.twitterButton, classes.socialLoginButton)}
                    onClick={() => this.setState({dialogOpen: "twitterLogin"})}
                  >
                    <TwitterIcon />
                  </IconButton>
                  <IconButton
                    className={classNames(classes.googleButton, classes.socialLoginButton)}
                    onClick={() => this.setState({dialogOpen: "googleLogin"})}
                  >
                    <GoogleIcon />
                  </IconButton>
                  <IconButton
                    className={classNames(classes.facebookButton, classes.socialLoginButton)}
                    onClick={() => this.setState({dialogOpen: "facebookLogin"})}
                  >
                    <FacebookIcon />
                  </IconButton>
                  <IconButton
                    className={classNames(classes.githubButton, classes.socialLoginButton)}
                    onClick={() => this.setState({dialogOpen: "githubLogin"})}
                  >
                    <GithubIcon />
                  </IconButton>
                </div>
              </div>
              
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                OR
              </div>
              
              <div>
                <div style={{marginBottom: theme.spacing.unit, textAlign: "center"}}>
                  {t("passwordLogin")}
                </div>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <div>
                    <div>
                      <Button
                        variant="contained"
                        size="small"
                        className={classNames(classes.loginButton, classes.passwordButton)}
                        onClick={() => this.setState({dialogOpen: "login"})}
                      >
                        {t("login")}
                      </Button>
                    </div>
                    <div>
                      <Button
                        variant="contained"
                        size="small"
                        className={classNames(classes.loginButton, classes.createAccountButton)}
                        onClick={() => this.setState({dialogOpen: "newAccount"})}
                      >
                        <CreateAccountIcon />
                        {t("newAccount")}
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
  
  public getScene2(){
    const {
      availableWidth,
    } = this.props;
    let {
      availableHeight,
    } = this.props;
    availableHeight = Math.max(availableHeight, 600);
  
    // currentTheme = currentTheme === "dark" ? "dark" : "light";
  
    // const t = i18n.getFixedT(lang, "Home");
  
    return (
      <div
        style={{
          width: availableWidth,
          height: availableHeight,
          position: "relative",
        }}
      >
        <div
          style={{
            width: "100%",
            height: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          This is currently beta version.
        </div>
      </div>
    );
  }
}





const styles: StyleRulesCallback = (theme: CustomTheme) => ({
  root: {
  },
  itemTable: {
    "& td": {
      paddingTop: 4,
      paddingBottom: 4,
    },
    "& tbody td:first-child": {
      paddingRight: theme.spacing.unit*3,
      color: (theme.custom && theme.custom.positive) || "green",
      verticalAlign: "top",
      fontSize: "1.2rem",
      whiteSpace: "nowrap",
    },
    "& tbody td:nth-child(2)": {
      verticalAlign: "top",
    },
  },
  dialogRoot: {
    "& .react-slideshow-container": {
      width: "100%",
      height: 400-47-30,
    },
    "& .react-slideshow-container .nav": {
      background: theme.palette.secondary.main,
    },
    "& .react-slideshow-container .nav:hover": {
      color: "unset",
      opacity: .6,
    },
    "& .react-slideshow-container .nav:first-of-type": {
      marginLeft: 16,
      marginRight: 0,
      marginTop: 24,
    },
    "& .react-slideshow-container .nav:last-of-type": {
      marginRight: 16,
      marginLeft: 0,
      marginTop: 24,
    },
    "& .react-slideshow-fade-wrapper": {
      width: 646,
      height: 353-30,
    },
    "& .react-slideshow-fade-images-wrap > div": {
      width: 646,
      height: 353-30,
    },
  },
  dialogPaper: {
    "maxWidth": "unset",
    "& > div:first-child": {
      paddingTop: theme.spacing.unit,
    },
  },
  dialogContentRoot: {
    padding: theme.spacing.unit,
  },
  slide: {
    padding: 16,
    position: "relative",
  },
  tblVal: {
    textAlign: "right",
  },
  profile: {
  },
  imageContainer: {
    cursor: "pointer",
    position: "relative",
    width: 589,
  },
  attentionPlease: {
    position: "absolute",
    right: -60,
    top: 0,
  },
  loginButton: {
    "padding": "6px 12px",
    "& svg": {
      marginRight: theme.spacing.unit,
    },
  },
  socialLoginButton: {
    padding: theme.spacing.unit,
    transform: "scale(0.8)",
  },
  twitterButton: {
    "backgroundColor": "#38A1F3",
    "color": "#FFF",
    "&:hover": {
      backgroundColor: lighten("#38A1F3", 0.3),
    },
  },
  facebookButton: {
    "backgroundColor": "#3C5A99",
    "color": "#FFF",
    "&:hover": {
      backgroundColor: lighten("#3C5A99", 0.3),
    },
  },
  githubButton: {
    "backgroundColor": "#333",
    "color": "#FFF",
    "&:hover": {
      backgroundColor: lighten("#333", 0.3),
    },
  },
  googleButton: {
    "backgroundColor": "#DB4437",
    "color": "#FFF",
    "&:hover": {
      backgroundColor: lighten("#DB4437", 0.3),
    },
  },
  passwordButton: {
    "marginTop": theme.spacing.unit,
    "width": 180,
    "&:hover": {
      backgroundColor: (theme.custom && theme.custom.brightness === "dark") ?
        lighten(theme.palette.primary.main, .5) : "",
    },
  },
  createAccountButton: {
    "marginTop": theme.spacing.unit,
    "width": 180,
    "&:hover": {
      backgroundColor: (theme.custom && theme.custom.brightness === "dark") ?
        lighten(theme.palette.primary.main, .5) : "",
    },
  },
  demoButton: {
    "&:hover": {
      backgroundColor: (theme.custom && theme.custom.brightness === "dark") ?
        lighten(theme.palette.primary.main, .5) : "",
    },
  },
});



let _Component: React.ComponentType<any> = Home;
_Component = withContentSize(_Component);
_Component = withStyles(styles, { withTheme: true })(_Component);
_Component = withTranslation("Home")(_Component);

export default _Component as React.ComponentType<Omit<IHomeProps, TReservedHocProps>>;
