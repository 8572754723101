import AbstractStorage from "./abstractStorage";
import {StorageProvider} from "../../reducers/App/state";

export const type: StorageProvider = "localStorage";

export default class LocalStorage extends AbstractStorage {
  protected __namespace = "storageProvider";
  
  constructor(id: string | undefined){
    super(type);
    
    if(typeof(id) === "string"){
      this.__namespace = `${this.__namespace}/${id}`;
    }
  }
  
  public getAbsoluteName(name: string){
    if(!name){
      return this.__namespace;
    }
    else{
      return `${this.__namespace}/${name}`;
    }
  }
  
  /**
   * @param {string} name
   * @returns {Promise<any>}
   */
  public async get(name: string): Promise<any>{
    const item = localStorage.getItem(this.getAbsoluteName(name));
    try{
      if(typeof item === "string"){
        return JSON.parse(item);
      }
      else{
        return null;
      }
    }
    catch(e){
      return null;
    }
  }
  
  /**
   * @param {string} name
   * @param {*} value
   * @returns {Promise<any>}
   */
  public async save(name: string, value: any): Promise<{name: string, value: any}>{
    value = JSON.stringify(value);
    localStorage.setItem(this.getAbsoluteName(name), value);
  
    return {
      name,
      value,
    };
  }
  
  /**
   * @param {string} name
   * @returns {Promise<string>}
   */
  public async remove(name: string): Promise<string>{
    localStorage.removeItem(this.getAbsoluteName(name));
    return name;
  }
}
