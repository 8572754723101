import {getCurrentDate, getNowInCustomDate} from "../../../../core/util/finance";
import * as R from "../../../../core/reducers/Finance/state";
import {GeneratorParams} from "../../../../core/util/dataGenerator";
import {CURRENCY_JPY} from "../../../../core/util/constants";
import {getFXValue} from "../../../../core/util/currency";

type IGetParamsFunc = (currency: R.Currency) => GeneratorParams;

/**
 * "name": "Employee C", "age": "26", "sex": "Female", "annualIncome": "$ 43,000", "family": "Single", "money": "Debt habit",
 *
 * spending
 *  She loves shopping. Spending is quite flashy. She borrows money without thinking repayment of debt at all.
 *  Food and rent expenses are average but amusement/travel/clothes/beauty costs far exceed average.
 *
 * income
 *  Monthly salary income is constant for her job and no bonus. She received money from her parent.
 *  This helps her account balance much.
 *  She feels no risk using the card loan so as soon as the cash on hands decreases she borrows money without thinking too much.
 */

const getParams: IGetParamsFunc = (currency) => {
  const now = getCurrentDate();
  const startY = now.getFullYear();
  const startM = 1;
  const nowCustomDate = getNowInCustomDate();
  const JPY = CURRENCY_JPY.id;
  
  return {
    years: 3,
    start_fund: getFXValue(1000000, JPY, currency.id, nowCustomDate),
    start_debt: getFXValue(0, JPY, currency.id, nowCustomDate),
    start_fixedAsset: getFXValue(0, JPY, currency.id, nowCustomDate),
    debt_interest: 3,
    debt_period: 5,
    repayment_method: "equalPayments", // ["equalPayments", "equalInstalments"]
    income_allowances: getFXValue(0, JPY, currency.id, nowCustomDate),
    income_basePay: getFXValue(360000, JPY, currency.id, nowCustomDate),
    income_bonusAmount: getFXValue(0, JPY, currency.id, nowCustomDate),
    income_bonusCount: 0,
    income_commuting: getFXValue(200000, JPY, currency.id, nowCustomDate),
    income_otherIncome: getFXValue(800000, JPY, currency.id, nowCustomDate),
    income_overtime: 0,
    income_rfBonusAmount: 70,
    income_rfOtherIncome: 20,
    income_rfOvertime: 15,
    income_rfRiseRate: 30,
    income_riseRate: 3,
    income_workingHours: 9,
    income_overtimeRaiseRate: 25,
    income_pensionChargeRate: 9.15,
    income_healthInsuranceChargeRate: 4.4,
    income_employmentInsuranceChargeRate: 0.3,
    fixedAssetUsefulLives: 47,
    autoFixedAssetUsefulLives: true,
    income_feeding: 0,
    startY: startY,
    startM: startM,
    income_prevYearTotalIncome: null,
    income_prevYearTotalSocialInsurance: null,
    income_initialNormalizedPay: null,
    income_TotalIncome2YearsAgo: null,
    income_TotalSocialInsurance2YearsAgo: null,
    income_initialNormalizedPayLastTerm: null,
    spending_hobby: getFXValue(70000, JPY, currency.id, nowCustomDate),
    spending_beauty: getFXValue(70000, JPY, currency.id, nowCustomDate),
    spending_communication: getFXValue(16000, JPY, currency.id, nowCustomDate),
    spending_commuting: getFXValue(20000, JPY, currency.id, nowCustomDate),
    spending_education: getFXValue(0, JPY, currency.id, nowCustomDate),
    spending_electricity: getFXValue(3000, JPY, currency.id, nowCustomDate),
    spending_food: getFXValue(40000, JPY, currency.id, nowCustomDate),
    spending_gasWater: getFXValue(3000, JPY, currency.id, nowCustomDate),
    spending_goods: getFXValue(20000, JPY, currency.id, nowCustomDate),
    spending_house: getFXValue(65000, JPY, currency.id, nowCustomDate),
    spending_medical: getFXValue(5000, JPY, currency.id, nowCustomDate),
    spending_unclassified: getFXValue(10000, JPY, currency.id, nowCustomDate),
    spending_trip_budget: getFXValue(600000, JPY, currency.id, nowCustomDate),
    spending_trip_count: 8,
    spending_rf: 30,
    spending_debtBonusChargeRate: 20,
    currency,
  };
};

export default getParams;
