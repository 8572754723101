import getInitialFinanceState from "./initialState";
import {getPeriodGroup, getProject, shouldLogHistory} from "../../util/finance-planning";
import {Reducer} from "redux";
import * as R from "./state";
import * as T from "../../actions/types/finance/planning";
import * as C from "../../actions/types/constant";

type PlanningReducer = Reducer<R.FinanceState, T.FinancePlanningActions>;

const planningReducer: PlanningReducer = (state = getInitialFinanceState(), action) => {
  switch(action.type){
    case C.ADD_PLANNING_PERIOD_GROUP: {
      const {
        id,
        periodGroup: draftPG,
        created_at,
        created_by,
      } = (action as T.AddPlanningPeriodGroupAction).payload;
      let {planning_periodGroups} = state;
      const periodGroup: R.PlanPeriodGroup = {
        id,
        ...draftPG,
        created_at,
        created_by,
        updated_at: created_at,
        updated_by: created_by,
      };
      
      planning_periodGroups = [...planning_periodGroups];
      planning_periodGroups.push(periodGroup);
      
      return {
        ...state,
        planning_periodGroups,
      };
    }
    case C.MODIFY_PLANNING_PERIOD_GROUP: {
      const {
        id,
        periodGroup: _periodGroup,
        updated_at,
        updated_by,
      } = (action as T.ModifyPlanningPeriodGroupAction).payload;
      let {planning_periodGroups} = state;
      let periodGroup = _periodGroup;
      
      const {index} = getPeriodGroup(planning_periodGroups, id);
      if(index < 0){
        return state;
      }
      
      periodGroup = {
        ...periodGroup,
        updated_at,
        updated_by,
      };
  
      planning_periodGroups = planning_periodGroups.map((pg, i) => {
        if(i === index){
          return {
            ...periodGroup,
          };
        }
        
        return pg;
      });
      
      return {
        ...state,
        planning_periodGroups,
      };
    }
    case C.DELETE_PLANNING_PERIOD_GROUP: {
      const {
        ids,
      } = (action as T.DeletePlanningPeriodGroupAction).payload;
      let {planning_periodGroups} = state;
      
      planning_periodGroups = planning_periodGroups.filter(pg => !ids.includes(pg.id));
  
      return {
        ...state,
        planning_periodGroups,
      };
    }
    case C.RENAME_PLANNING_PERIOD_GROUP: {
      const {
        id,
        name,
        updated_at,
        updated_by,
      } = (action as T.RenamePlanningPeriodGroupAction).payload;
      let {planning_periodGroups} = state;
    
      const {index} = getPeriodGroup(planning_periodGroups, id);
      if(index < 0){
        return state;
      }
  
      planning_periodGroups = planning_periodGroups.map((pg, i) => {
        if(i === index){
          return {
            ...planning_periodGroups[index],
            name,
            updated_at,
            updated_by,
          };
        }
        
        return pg;
      });
    
      return {
        ...state,
        planning_periodGroups,
      };
    }
    case C.ADD_PLANNING_PROJECT: {
      const {
        project: _project,
        created_by,
        created_at,
      } = (action as T.AddPlanningProjectAction).payload;
      let {planning_projects} = state;
      
      const project = {
        ..._project,
        created_by,
        created_at,
        updated_by: created_by,
        updated_at: created_at,
      };
  
      planning_projects = [...planning_projects];
      planning_projects.push(project);
      
      return {
        ...state,
        planning_projects,
      };
    }
    case C.MODIFY_PLANNING_PROJECT: {
      const {id, project, updated_by, updated_at} = (action as T.ModifyPlanningProjectAction).payload;
      let {planning_projects} = state;
  
      const index = planning_projects.findIndex(p => p.id === id);
      if(index < 0){
        return state;
      }
      
      planning_projects = planning_projects.map((p, i) => {
        if(i === index){
          return {
            ...project,
            updated_by,
            updated_at,
          };
        }
        
        return p;
      });
      
      return {
        ...state,
        planning_projects,
      };
    }
    case C.RENAME_PLANNING_PROJECT: {
      const {id, name, updated_at, updated_by} = (action as T.RenamePlanningProjectAction).payload;
      let {planning_projects} = state;
      
      const {index, project} = getProject(planning_projects, id);
      if(!project){
        return state;
      }
      
      let {history} = project;
      if(shouldLogHistory(project)){
        const h: R.PlanHistory = {
          date: updated_at,
          item: "name" as "name",
          from: project.name,
          to: name,
          by: updated_by,
        };
        
        history = [...history];
        history.push(h);
      }
  
      planning_projects = planning_projects.map((p, i) => {
        if(i === index){
          return {
            ...project,
            name,
            updated_at,
            updated_by,
            history,
          };
        }
        
        return p;
      });
  
      return {
        ...state,
        planning_projects,
      };
    }
    case C.DELETE_PLANNING_PROJECT: {
      const {id} = (action as T.DeletePlanningProjectAction).payload;
      let {planning_projects} = state;
  
      const {index, project} = getProject(planning_projects, id);
      if(!project){
        return state;
      }
  
      planning_projects = [...planning_projects];
      planning_projects.splice(index, 1);
  
      return {
        ...state,
        planning_projects,
      };
    }
    case C.DELETE_PLANNING_PROJECTS: {
      const {idList} = (action as T.DeletePlanningProjectsAction).payload;
      let {planning_projects} = state;
      planning_projects = [...planning_projects];
    
      idList.forEach(id => {
        const {index, project} = getProject(planning_projects, id);
        if(!project){
          return state;
        }
  
        planning_projects.splice(index, 1);
      });
    
      return {
        ...state,
        planning_projects,
      };
    }
    case C.UPDATE_PLANNING_STATUS: {
      const {
        id,
        status,
        fixDate: _fixDate,
        updated_at,
        updated_by,
      } = (action as T.UpdatePlanningStatusAction).payload;
      let fixDate = _fixDate;
      let {planning_projects} = state;
  
      const {index, project} = getProject(planning_projects, id);
      if(!project){
        return state;
      }
      
      if(status === "InProgress" && typeof(project.fixDate) === "number"){
        fixDate = null;
      }
  
      let {history} = project;
      if(shouldLogHistory(project)){
        const h = {
          date: updated_at,
          item: "status" as "status",
          from: project.status,
          to: status,
          by: updated_by,
        };
    
        history = [...history];
        history.push(h);
        
        if(typeof(fixDate) === "number"){
          const h2 = {
            date: updated_at,
            item: "fixDate" as "fixDate",
            from: project.fixDate || null,
            to: fixDate,
            by: updated_by,
          };
  
          history = [...history];
          history.push(h2);
        }
        else if(status === "InProgress" && typeof(project.fixDate) === "number"){
          const h3 = {
            date: updated_at,
            item: "fixDate" as "fixDate",
            from: project.fixDate,
            to: null,
            by: updated_by,
          };
  
          history = [...history];
          history.push(h3);
        }
      }
  
      planning_projects = planning_projects.map((p, i) => {
        if(i === index){
          return {
            ...project,
            status,
            fixDate,
            updated_at,
            updated_by,
            history,
          };
        }
        
        return p;
      });
  
      return {
        ...state,
        planning_projects,
      };
    }
    case C.UPDATE_PLANNING_BUDGET: {
      const {id, budget, updated_at, updated_by} = (action as T.UpdatePlanningBudgetAction).payload;
      let {planning_projects} = state;
  
      const {index, project} = getProject(planning_projects, id);
      if(!project){
        return state;
      }
  
      let {history} = project;
      if(shouldLogHistory(project)){
        const h = {
          date: updated_at,
          item: "budget" as "budget",
          from: typeof(project.budget) === "number" ? project.budget : null,
          to: budget,
          by: updated_by,
        };
        
        history = [...history];
        history.push(h);
      }
  
      planning_projects = planning_projects.map((p, i) => {
        if(i === index){
          return {
            ...project,
            budget,
            updated_at,
            updated_by,
            history,
          };
        }
        
        return p;
      });
  
      return {
        ...state,
        planning_projects,
      };
    }
    case C.UPDATE_PLANNING_CURRENCY: {
      const {id, currencyId, updated_at, updated_by} = (action as T.UpdatePlanningCurrencyAction).payload;
      const {planning_projects} = state;
    
      const {index, project} = getProject(planning_projects, id);
      if(!project){
        return state;
      }
    
      let {history} = project;
      if(shouldLogHistory(project)){
        const h = {
          date: updated_at,
          item: "currencyId" as "currencyId",
          from: project.currencyId,
          to: currencyId,
          by: updated_by,
        };
      
        history = [...history];
        history.push(h);
      }
    
      const new_planning_projects = planning_projects.map((p, i) => {
        if(i === index){
          return {
            ...project,
            currencyId,
            updated_at,
            updated_by,
            history,
          };
        }
      
        return p;
      });
    
      return {
        ...state,
        planning_projects: new_planning_projects,
      };
    }
    case C.UPDATE_PLANNING_PROTECT: {
      const {id, protect, updated_at, updated_by} = (action as T.UpdatePlanningProtectAction).payload;
      let {planning_projects} = state;
    
      const {index, project} = getProject(planning_projects, id);
      if(!project){
        return state;
      }
    
      let {history} = project;
      if(shouldLogHistory(project)){
        const h = {
          date: updated_at,
          item: "protect" as "protect",
          from: project.protect,
          to: protect,
          by: updated_by,
        };
      
        history = [...history];
        history.push(h);
      }
  
      planning_projects = planning_projects.map((p, i) => {
        if(i === index){
          return {
            ...project,
            protect,
            updated_at,
            updated_by,
            history,
          };
        }
        
        return p;
      });
      
      return {
        ...state,
        planning_projects,
      };
    }
    case C.UPDATE_PLANNING_TAG_ONLY: {
      const {id, countTaggedFlowOnly, updated_at, updated_by} = (action as T.UpdatePlanningTagOnlyAction).payload;
      let {planning_projects} = state;
    
      const {index, project} = getProject(planning_projects, id);
      if(!project){
        return state;
      }
    
      let {history} = project;
      if(shouldLogHistory(project)){
        const h = {
          date: updated_at,
          item: "countTaggedFlowOnly" as "countTaggedFlowOnly",
          from: project.countTaggedFlowOnly,
          to: typeof(countTaggedFlowOnly) === "boolean" ? countTaggedFlowOnly : false,
          by: updated_by,
        };
      
        history = [...history];
        history.push(h);
      }
  
      planning_projects = planning_projects.map((p, i) => {
        if(i === index){
          return {
            ...p,
            countTaggedFlowOnly: typeof(countTaggedFlowOnly) === "boolean" ? countTaggedFlowOnly : false,
            updated_at,
            updated_by,
            history,
          };
        }
        
        return p;
      });
    
      return {
        ...state,
        planning_projects,
      };
    }
    case C.UPDATE_PLANNING_PERIOD_GROUP: {
      const {planId, periodGroupId, updated_at, updated_by} = (action as T.UpdatePlanningPeriodGroupAction).payload;
      let {planning_projects} = state;
  
      const {index, project} = getProject(planning_projects, planId);
      if(!project){
        return state;
      }
      
      if(periodGroupId === project.periodGroupId){
        return state;
      }
  
      let {history} = project;
      if(shouldLogHistory(project)){
        const h = {
          date: updated_at,
          item: "periodGroupId" as "periodGroupId",
          from: project.periodGroupId,
          to: periodGroupId,
          by: updated_by,
        };
        
        history = [...history];
        history.push(h);
      }
  
      planning_projects = planning_projects.map((p, i) => {
        if(i === index){
          return {
            ...project,
            periodGroupId,
            updated_at,
            updated_by,
            history,
          };
        }
        
        return p;
      });
  
      return {
        ...state,
        planning_projects,
      };
    }
    case C.CHANGE_PLANNED_SPENDING: {
      const {id, changes, updated_at, updated_by} = (action as T.ChangePlannedSpendingAction).payload;
      let {planning_projects} = state;
  
      const {index, project} = getProject(planning_projects, id);
      if(!project){
        return state;
      }
  
      let {planned_spending, history} = project;
      
      if(changes.length < 1){
        return state;
      }
  
      planned_spending = [...planned_spending];
      history = [...history];
      
      changes.forEach(c => {
        const {action: changeAction, index: changeIndex, data} = c;
        
        if(changeAction === "insert"){
          history.push({
            date: updated_at,
            item: "planned_spending",
            action: changeAction,
            index: changeIndex,
            prevData: null,
            data,
            by: updated_by,
          });
          
          planned_spending.splice(changeIndex, 0, data);
        }
        else if(changeAction === "edit"){
          history.push({
            date: updated_at,
            item: "planned_spending",
            action: changeAction,
            index: changeIndex,
            prevData: planned_spending[changeIndex],
            data,
            by: updated_by,
          });
          
          planned_spending[changeIndex] = data;
        }
        else if(changeAction === "remove"){
          history.push({
            date: updated_at,
            item: "planned_spending",
            action: changeAction,
            index: changeIndex,
            prevData: planned_spending[changeIndex],
            data: null,
            by: updated_by,
          });
          
          planned_spending.splice(changeIndex, 1);
        }
      });
  
      planning_projects = planning_projects.map((p, i) => {
        if(i === index){
          return {
            ...project,
            planned_spending,
            updated_at,
            updated_by,
            history,
          };
        }
        
        return p;
      });
      
      return {
        ...state,
        planning_projects,
      };
    }
    case C.ADD_REVIEW_FOR_A_PLAN: {
      const {
        id,
        review,
        updated_at,
        updated_by,
      } = (action as T.AddReviewForAPlanAction).payload;
      let {planning_projects} = state;
  
      const {index, project} = getProject(planning_projects, id);
      if(!project){
        return state;
      }
  
      let {reviews, history} = project;
  
      history = [...history];
      history.push({
        date: updated_at,
        item: "reviews",
        action: "insert",
        index: reviews.length,
        data: {...review},
        prevData: null,
        by: updated_by,
      });
  
      planning_projects = planning_projects.map((p, i) => {
        if(i === index){
          reviews = [...reviews, review];
          
          return {
            ...p,
            reviews,
            updated_at,
            updated_by,
            history,
          };
        }
        
        return p;
      });
      
      return {
        ...state,
        planning_projects,
      };
    }
    case C.REMOVE_REVIEW_FOR_A_PLAN: {
      const {projectId, reviewId, updated_at, updated_by} = (action as T.RemoveReviewForAPlanAction).payload;
      let {planning_projects} = state;
  
      const {index, project} = getProject(planning_projects, projectId);
      if(!project){
        return state;
      }
  
      let {reviews, history} = project;
      
      const index_review = reviews.findIndex(r => r.id === reviewId);
      if(index_review < 0){
        return state;
      }
  
      history = [...history];
      history.push({
        date: updated_at,
        item: "reviews",
        action: "remove",
        index: index_review,
        data: null,
        prevData: {...reviews[index_review]},
        by: updated_by,
      });
  
      reviews = [...reviews];
      reviews.splice(index_review, 1);
  
      planning_projects = planning_projects.map((p, i) => {
        if(i === index){
          return {
            ...project,
            reviews,
            updated_at,
            updated_by,
            history,
          };
        }
        
        return p;
      });
      
      return {
        ...state,
        planning_projects,
      };
    }
    case C.MODIFY_REVIEW_FOR_A_PLAN: {
      const {projectId, reviewId, review, updated_at, updated_by} = (action as T.ModifyReviewForAPlanAction).payload;
      let {planning_projects} = state;
  
      const {index, project} = getProject(planning_projects, projectId);
      if(!project){
        return state;
      }
  
      let {reviews, history} = project;
  
      const index_review = reviews.findIndex(r => r.id === reviewId);
      if(index_review < 0){
        return state;
      }
  
      history = [...history];
      history.push({
        date: updated_at,
        item: "reviews",
        action: "edit",
        index: index_review,
        data: {...review},
        prevData: {...reviews[index_review]},
        by: updated_by,
      });
      
      reviews = reviews.map((r, i) => {
        if(i === index_review){
          return {...review};
        }
        
        return r;
      });
  
      planning_projects = planning_projects.map((p, i) => {
        if(i === index){
          return {
            ...project,
            reviews,
            updated_at,
            updated_by,
            history,
          };
        }
        
        return p;
      });
  
      return {
        ...state,
        planning_projects,
      };
    }
    case C.UPDATE_STATUS_OF_REVIEW_FOR_A_PLAN: {
      const {projectId, reviewId, status, updated_at, updated_by} = (action as T.UpdateStatusOfReviewForPlanAction).payload;
      let {planning_projects} = state;
  
      const {index, project} = getProject(planning_projects, projectId);
      if(!project){
        return state;
      }
  
      let {reviews, history} = project;
  
      const index_review = reviews.findIndex(r => r.id === reviewId);
      if(index_review < 0){
        return state;
      }
  
      history = [...history];
      history.push({
        date: updated_at,
        item: "reviews",
        action: "edit",
        index: index_review,
        data: {...reviews[index_review], status},
        prevData: {...reviews[index_review]},
        by: updated_by,
      });
  
      reviews = reviews.map((r, i) => {
        if(i === index_review){
          return {...r, status};
        }
        
        return r;
      });
  
      planning_projects = planning_projects.map((p, i) => {
        if(i === index){
          return {
            ...project,
            reviews,
            updated_at,
            updated_by,
            history,
          };
        }
        
        return p;
      });
  
      return {
        ...state,
        planning_projects,
      };
    }
    default:
      break;
  }
  
  return state;
};

export default planningReducer;
