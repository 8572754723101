import * as S from "../reducers/Finance/state";
import * as C from "./constants";

export const getCurrencyInState = (financeState: S.FinanceState): S.Currency => {
  const {currencyId, currencies} = financeState;
  const currency = currencies.find(c => c.id === currencyId);
  
  if(!currency){
    throw new Error("Currency not found");
  }
  
  return currency;
};

const fxSignatureMap = {
  [`${C.CURRENCY_JPY.id}/${C.CURRENCY_USD.id}`]: "JPY/USD",
  [`${C.CURRENCY_JPY.id}/${C.CURRENCY_EUR.id}`]: "JPY/EUR",
  [`${C.CURRENCY_JPY.id}/${C.CURRENCY_CNY.id}`]: "JPY/CHY",
  [`${C.CURRENCY_USD.id}/${C.CURRENCY_EUR.id}`]: "USD/EUR",
  [`${C.CURRENCY_USD.id}/${C.CURRENCY_CNY.id}`]: "USD/CHY",
  [`${C.CURRENCY_EUR.id}/${C.CURRENCY_CNY.id}`]: "EUR/CHY",
  
  [`${C.CURRENCY_USD.id}/${C.CURRENCY_JPY.id}`]: "USD/JPY",
  [`${C.CURRENCY_EUR.id}/${C.CURRENCY_JPY.id}`]: "EUR/JPY",
  [`${C.CURRENCY_CNY.id}/${C.CURRENCY_JPY.id}`]: "CHY/JPY",
  [`${C.CURRENCY_EUR.id}/${C.CURRENCY_USD.id}`]: "EUR/USD",
  [`${C.CURRENCY_CNY.id}/${C.CURRENCY_USD.id}`]: "CHY/USD",
  [`${C.CURRENCY_CNY.id}/${C.CURRENCY_EUR.id}`]: "CHY/EUR",
};

interface IFXRecord {
  [t: string]: number;
}

interface IFXTable {
  [pair: string]: IFXRecord;
}

let FXTable: IFXTable|undefined;

function getRateFromTable(records: IFXRecord, date: S.CustomDate|null){
  if(date === null){
    return records.default;
  }
  
  if(typeof(records[date]) === "number"){
    return records[date];
  }
  
  const latest_point = Object.entries(records).reduce((acc: number|null, r) => {
    const [point, value] = r;
    if(point === "default"){
      return acc;
    }
    
    const point_date = +point;
    if(acc === null){
      return point_date;
    }
    
    if(acc < point_date && point_date <= date){
      return point_date;
    }
    
    return acc;
  }, null);
  
  if(latest_point === null){
    return records.default;
  }
  
  if(latest_point <= date){
    records[date] = records[latest_point];
  }
  else{
    records[date] = records.default;
  }
  
  return records[date];
  
}

function getRateTable(from: S.CurrencyId, to: S.CurrencyId){
  if(!FXTable){
    return undefined;
  }
  
  const pair = fxSignatureMap[`${to}/${from}`];
  if(!pair){
    return undefined;
  }
  return FXTable[pair];
}

export async function initializeFXTable(){
  const currencyPairs: IFXTable = {
    "JPY/USD": {
      20190101: 112,
      default: 110.50,
    },
    "JPY/EUR": {
      default: 124.82,
    },
    "JPY/CHY": {
      default: 16.31,
    },
    "USD/EUR": {
      default: 1.13,
    },
    "USD/CHY": {
      default: 0.15,
    },
    "EUR/CHY": {
      default: 0.13,
    },
  };
  
  const reversePairs: IFXTable = Object.entries(currencyPairs).reduce((acc: IFXTable, record) => {
    const [signature, table] = record;
    const pair = (signature as string).split("/");
    const reverseSignature = `${pair[1]}/${pair[0]}`;
    acc[reverseSignature] = Object.entries(table).reduce((acc2: IFXRecord, row) => {
      const [point, value] = row;
      acc2[point] = 1/value;
      return acc2;
    }, {});
    
    return acc;
  }, {});
  
  FXTable = {
    ...currencyPairs,
    ...reversePairs,
  };
  
  return true;
}

export async function updateFXTable(){
  return initializeFXTable();
}

export function getFXRate(from: S.CurrencyId, to: S.CurrencyId, date: S.CustomDate|null) {
  if(from === to){
    return 1.0;
  }
  
  const rateTable = getRateTable(from, to);
  if(!rateTable){
    throw new Error(`Currency pair ${from}/${to} does not exist`);
  }
  
  return getRateFromTable(rateTable, date);
}

export function getFXValue(value: number, from: S.CurrencyId, to: S.CurrencyId, customDate: S.CustomDate|null){
  const rate = getFXRate(from, to, customDate);
  return value * rate;
}

export function getFlowValueIn(currencyId: S.CurrencyId, flow: S.FlowData, currencyOfFlow: number){
  if(flow.value === null){
    return null;
  }
  
  return getFXValue(flow.value, currencyOfFlow, currencyId, flow.date);
}

export function getBalanceValueIn(currencyId: S.CurrencyId, balance: S.BalanceData, currencyOfBalance: number){
  if(balance.balance === null){
    return null;
  }
  
  return getFXValue(balance.balance, currencyOfBalance, currencyId, balance.date);
}

interface IAccountCurrencyMapper {
  [accountId: number]: number;
}

export function getAccountCurrencyMap(accounts: S.AccountMaster[]): IAccountCurrencyMapper {
  return accounts.reduce((acc: IAccountCurrencyMapper, account) => {
    acc[account.id] = account.currency;
    return acc;
  }, {});
}
