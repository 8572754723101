export interface ItemBase {
  id: number;
  name: string;
  order: number;
}

export interface GeneralGroup extends ItemBase {
  [key: string]: number|string|ItemBase[];
}

export interface GeneralItem extends ItemBase {
  [key: string]: number|string;
}

export interface GroupWithItem extends GeneralGroup {
  items: ItemBase[];
}

interface IManagerProps {
  items: ItemBase[];
  groups: ItemBase[];
  bindPropChild: string;
  bindPropParent: string;
  bindPropCategory?: string;
}

interface IManagerState {
  items: ItemBase[];
  groups: ItemBase[];
}

interface ISetStateFuncArg {
  items?: ItemBase[];
  groups?: ItemBase[];
}

class Manager {
  public props: IManagerProps = {items: [], groups: [], bindPropChild: "group", bindPropParent: "id"};
  public state: IManagerState = {items: [], groups: []};
  public undoStates: IManagerState[] = [];
  public redoStates: IManagerState[] = [];
  public active: boolean|null|{itemId: number, groupId: number|null, insertAt: number} = null;
  
  constructor(props: IManagerProps){
    let {groups, items} = props;
    
    groups = groups.map(group => ({...group}));
    items = items.map(item => ({...item}));
    
    groups.sort((a, b) => a.order - b.order);
    items.sort((a, b) => a.order - b.order);
    
    this.state = {
      groups,
      items,
    };
    
    this.undoStates = [];
    this.redoStates = [];
    
    this.props = props;
  }
  
  public getGroups(){
    return this.state.groups;
  }
  
  public getItems(){
    return this.state.items;
  }
  
  public getGroup(id: number){
    return this.state.groups.find(g => g.id === id);
  }
  
  public getItem(id: number){
    return this.state.items.find(i => i.id === id);
  }
  
  public getGroupsWithChildren(): GroupWithItem[] {
    const {bindPropParent, bindPropChild} = this.props;
    const {groups, items} = this.state;
    
    return groups.map(group => {
      const associatedItems = items.filter(item => (item as GeneralItem)[bindPropChild] === (group as GeneralGroup)[bindPropParent]);
      return {
        ...group,
        items: associatedItems,
      };
    });
  }
  
  public addGroup(group: GeneralGroup){
    let {groups} = this.state;
    const index = groups.findIndex(g => g.id === group.id);
    
    if(index > -1){
      groups = [...groups];
      groups[index] = group;
      this.setState({groups});
    }
    else{
      let maxId = groups.reduce((acc, g) => Math.max(acc, g.id), -1);
      maxId = typeof(maxId) === "number" ? maxId : -1;
      let maxOrder = groups.reduce((acc, g) => Math.max(acc, g.order), -1);
      maxOrder = typeof(maxOrder) === "number" ? maxOrder : -1;
      group = {
        ...group,
        id: maxId+1,
        order: maxOrder+1,
      };
      groups = groups.concat(group);
      this.setState({groups});
    }
  }
  
  public renameGroup(groupId: number, name: string){
    let {groups} = this.state;
    const index = groups.findIndex(g => g.id === groupId);
    groups = [...groups];
    
    if(index > -1){
      groups[index] = {...groups[index], name};
      this.setState({groups});
    }
  }
  
  public deleteGroup(groupId: number){
    // Also delete items associated with the group
    const {bindPropChild} = this.props;
    let {groups, items} = this.state;
    groups = groups.filter(group => group.id !== groupId);
    items = items.filter(item => (item as GeneralItem)[bindPropChild] !== groupId);
    
    this.setState({groups, items});
  }
  
  public deleteGroups(groupIds: number[]){
    // Also delete items associated with the group
    const {bindPropChild} = this.props;
    let {groups, items} = this.state;
    groups = groups.filter(group => !groupIds.includes(group.id));
    items = items.filter(item => !groupIds.includes(((item as GeneralItem)[bindPropChild] as number)));
    
    this.setState({groups, items});
  }
  
  public addItem(item: GeneralItem){
    let {items} = this.state;
    const index = items.findIndex(i => i.id === item.id);
  
    if(index > -1){
      items = [...items];
      items[index] = item;
      this.setState({items});
    }
    else{
      let maxId = items.reduce((acc, itm) => Math.max(acc, itm.id), -1);
      maxId = typeof(maxId) === "number" ? maxId : -1;
      let maxOrder = items.reduce((acc, itm) => Math.max(acc, itm.order), -1);
      maxOrder = typeof(maxOrder) === "number" ? maxOrder : -1;
      item = {
        ...item,
        id: maxId+1,
        order: maxOrder+1,
      };
      items = items.concat(item);
      this.setState({items});
    }
  }
  
  public renameItem(itemId: number, name: string){
    let {items} = this.state;
    const index = items.findIndex(i => i.id === itemId);
    items = [...items];
  
    if(index > -1){
      items[index] = {...items[index], name};
      this.setState({items});
    }
  }
  
  public changeCurrency(itemId: number, currencyId: number){
    let {items} = this.state;
    const index = items.findIndex(i => i.id === itemId);
    items = [...items];
  
    if(index > -1 && typeof((items[index] as GeneralItem).currency) === "number"){
      const item: GeneralItem = {...items[index], currency: currencyId};
      items[index] = item;
      this.setState({items});
    }
  }
  
  public deleteItem(itemId: number){
    let {items} = this.state;
    items = items.filter(item => item.id !== itemId);
  
    this.setState({items});
  }
  
  public deleteItems(itemIds: number[]){
    let {items} = this.state;
    items = items.filter(item => !itemIds.includes(item.id));
    
    this.setState({items});
  }
  
  public changeGroupOfItem(itemId: number, groupId: number, insertAt: number){
    const {bindPropChild} = this.props;
    const {groups} = this.state;
    let {items} = this.state;
    
    if(typeof(groupId) !== "number" || !groups.find(group => group.id === groupId)){
      return false;
    }
  
    const index = items.findIndex(itm => itm.id === itemId);
    if(index < 0){
      return false;
    }
  
    const item = {...items[index]};
    (item as GeneralItem)[bindPropChild] = groupId;
    
    if(typeof(insertAt) === "number"){
      let items2 = items.filter(i => i.id !== itemId);
      const insertIndex = items2.findIndex(i => i.id === insertAt);
      if(insertIndex > -1){
        items2.splice(insertIndex, 0, item);
        
        items2 = items2.map((itm, i) => {
          return {...itm, order: i};
        });
        
        this.setState({items: items2});
        return true;
      }
    }
    
    items = [...items];
    items = items.filter(i => i.id !== itemId);
    items.reverse();
    const index2 = items.findIndex(itm => (itm as GeneralItem)[bindPropChild] === groupId);
    if(index2 > -1){
      items.splice(index2, 0, item);
    }
    else{
      items.push(item);
    }
    items.reverse();
    
    items = items.map((itm, i) => {
      return {...itm, order: i};
    });
    
    this.setState({items});
    return true;
  }
  
  public changeCategoryOfGroup(groupId: number, category: string){
    const {bindPropCategory} = this.props;
    
    if(typeof(bindPropCategory) !== "string"){
      return false;
    }
    
    let {groups} = this.state;
  
    const index = groups.findIndex(g => g.id === groupId);
    if(typeof(groupId) !== "number" || index === -1){
      return false;
    }
  
    const group = {...groups[index]};
    (group as GeneralGroup)[bindPropCategory] = category;
    
    groups = [...groups];
    groups[index] = group;
    
    this.setState({groups});
    return true;
  }
  
  public changeGroupOrder(groupId: number, insertAt: number|null){
    let {groups} = this.state;
    groups = [...groups];
  
    const index = groups.findIndex(g => g.id === groupId);
    const group = groups.splice(index, 1)[0];
    
    groups.sort((a, b) => a.order - b.order);
    
    if(typeof(insertAt) === "number"){
      const targetIndex = groups.findIndex(g => g.id === insertAt);
      groups.splice(targetIndex, 0, group);
    }
    else{
      groups.push(group);
    }
    
    groups = groups.map((g, i) => {
      return {
        ...g,
        order: i,
      };
    });
    
    this.setState({groups});
  }
  
  public setState(newState: ISetStateFuncArg){
    this.undoStates.push(this.state);
    this.redoStates = [];
    
    this.state = {
      ...this.state,
      ...newState,
    };
  }
  
  public undo(){
    if(this.undoStates.length === 0){
      return false;
    }
  
    const oldState = this.undoStates.pop();
    if(!oldState){
      return false;
    }
  
    this.redoStates.push(this.state);
    this.state = {
      ...oldState,
    };
    
    return true;
  }
  
  public redo(){
    if(this.redoStates.length === 0){
      return false;
    }
  
    const nextState = this.redoStates.pop();
    if(!nextState){
      return false;
    }
  
    this.undoStates.push(this.state);
    this.state = {
      ...nextState,
    };
    
    return true;
  }
}

export default Manager;
